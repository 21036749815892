import styled from "@emotion/styled";
import { Theme } from "@mui/material";

export const Timer = styled.div<{ theme: Theme }>`
  font-family: monospace;
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
`;
