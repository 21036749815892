import Device, { DeviceType, DeviceOptions } from "./Device";
import { ConnectionOptions } from "../Connections/Connection";
import EndpointDcpSession from "../Endpoints/EndpointDcpSession";
import { IDevicePresencePayload, IDcpHostProtocolOptions } from "@proximie/dcp";
import { SimpleBroker, sendSessionInvitation } from "@proximie/dcp-mqtt";
import FeedType from "../../models/FeedType";
import { liveApiSockets } from "@proximie/dataregion-api";

const DEFAULT_MQTTS_PORT = 443;
const DEFAULT_WSS_PORT = 443;

export interface DeviceDcpInviteeOptions extends DeviceOptions {
  dcpHomeBrokerConfig: liveApiSockets.DcpBrokerConfig;
  dcpSessionBrokerConfig: liveApiSockets.DcpBrokerConfig;
}

// this device is used only for inviting a device to the session.
// Once the device is in the session it will use (eg) DeviceDcp
export default class DeviceDcpInvitee extends Device {
  public override readonly deviceType = DeviceType.DcpInvitee;
  public override readonly mediaType = FeedType.None;
  private endpoint: EndpointDcpSession | null = null;

  private retryTimerId: ReturnType<typeof setTimeout> | null = null;
  //private retryTimerId: number | null = null;
  private retryCurrentPeriod = 1000;

  constructor(deviceId: string, public options: DeviceDcpInviteeOptions) {
    super(deviceId, options);
  }

  private async connectToHomeBrokerAndInviteDeviceToSession(
    mediaSessionId: string,
  ): Promise<void> {
    console.debug({ deviceId: this.deviceId }, "Connecting to home broker");

    if (!this.endpoint) {
      throw new Error("No endpoint defined");
    }

    const homeBroker = new SimpleBroker();

    try {
      homeBroker.mqttBroker?.on("error", (error: Error) => {
        console.warn("Error from MQTT broker=", error);
      });
      await homeBroker.connect(
        EndpointDcpSession.BuildBrokerOptions(
          this.options.dcpHomeBrokerConfig,
          this.endpoint.tokenProvider,
        ),
      );

      const wssUrlObj = new URL(this.options.dcpSessionBrokerConfig.wssUrl);

      // The URL parser does not handle unknown protocol schemes properly so
      // we'll have to resort to a regex here
      // Doesn't work: const mqttsUrlObj = new URL(sessionBrokerConfig.mqttsUrl);
      const reResults = /^[^:]+:\/\/([^:/]+)(?::(\d+))?\/?/.exec(
        this.options.dcpSessionBrokerConfig.mqttsUrl,
      );
      const mqttsUrlObj = {
        hostname: reResults?.[1] || "",
        port: reResults?.[2] || "",
      };

      const protocolOptions: IDcpHostProtocolOptions = {
        secure: true,
        protocols: {
          ws: {
            host: wssUrlObj.hostname,
            port: Number(wssUrlObj.port) || DEFAULT_WSS_PORT,
            path: wssUrlObj.pathname,
          },
          mqtt: {
            host: mqttsUrlObj.hostname,
            port: Number(mqttsUrlObj.port) || DEFAULT_MQTTS_PORT,
          },
        },
      };

      await sendSessionInvitation({
        toDevice: this.deviceId,
        onHomeBroker: homeBroker,
        dcpProtocolOptions: protocolOptions,
        forSessionId: mediaSessionId,
      });

      console.debug({ deviceId: this.deviceId }, "Successfully invited device");
    } catch (error) {
      console.warn(
        { deviceId: this.deviceId },
        "Failed to connect to home broker - error=",
        error instanceof Error ? error.message : String(error),
      );

      throw error;
    } finally {
      await homeBroker.disconnect({ forced: true });
    }
  }

  private async inviteAndStartTimers(): Promise<void> {
    console.debug({ deviceId: this.deviceId }, "Starting invite timer");

    if (this.retryTimerId) {
      console.debug(
        { deviceId: this.deviceId },
        "Timer already running - ignoring",
      );
      return;
    }

    this.retryTimerId = setTimeout(async () => {
      console.debug({ deviceId: this.deviceId }, "Invite timer expired");
      try {
        if (!this.endpoint) {
          throw new Error("No endpoint defined");
        }

        await this.connectToHomeBrokerAndInviteDeviceToSession(
          this.endpoint.mediaSessionId,
        );
      } catch {
        // ignore errors - we'll repeat it next time
      } finally {
        this.retryCurrentPeriod *= 2;
        if (this.retryCurrentPeriod > 32000) {
          this.retryCurrentPeriod = 32000;
        }

        this.resetRetryTimer();
        this.inviteAndStartTimers();
      }
    }, this.retryCurrentPeriod);
  }

  private resetRetryTimer(): void {
    console.debug({ deviceId: this.deviceId }, "Invite timer stopped");

    if (this.retryTimerId) {
      clearTimeout(this.retryTimerId);
      this.retryTimerId = null;
    }
  }

  public override close(_error?: Error): Promise<void> {
    this.resetRetryTimer();

    this.endpoint?.manager?.off(
      "device:presence",
      this.devicePresenceHandler.bind(this),
    );

    return Promise.resolve();
  }

  private devicePresenceHandler(
    deviceId: string,
    presence: IDevicePresencePayload,
  ): void {
    if (deviceId !== this.deviceId) {
      return;
    }

    if (presence.available) {
      console.debug({ deviceId }, "Device has arrived");
      this.resetRetryTimer();
    } else {
      console.debug({ deviceId }, "Device has gone away");
      this.retryCurrentPeriod = 1000;
      this.inviteAndStartTimers();
    }
  }

  public override invoke(
    endpoint: EndpointDcpSession,
    _streamId: string,
    _options: ConnectionOptions,
  ): null {
    this.endpoint = endpoint;

    this.endpoint.manager?.on(
      "device:presence",
      this.devicePresenceHandler.bind(this),
    );

    this.inviteAndStartTimers();

    return null;
  }
}
