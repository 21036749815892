import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToDeprovisionSessionsExceptionMeta {
  originalError: Error;
}

export class FailedToDeprovisionSessionsException extends BaseException {
  constructor(
    message = "Failed to deprovision sessions resources",
    meta?: FailedToDeprovisionSessionsExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_DEPROVISION_SESSIONS;
  }
}
