import React from "react";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

export interface BreadcrumbsProps {
  ["data-testid"]?: string;
  ["data-cy"]?: string;
  children?: React.ReactNode;
}

export function Breadcrumbs({ children, ...rest }: BreadcrumbsProps) {
  return (
    <MUIBreadcrumbs
      separator={<ChevronRight />}
      aria-label="breadcrumb"
      sx={({ colors, typography }) => ({
        "& .MuiTypography-root": {
          ...typography.body2,
          color: colors.BlackMediumEmphasis,
        },
        "& .MuiBreadcrumbs-li:last-child .MuiTypography-root": {
          ...typography.body2,
          color: colors.BlackHighEmphasis,
        },
        "& .MuiSvgIcon-root": {
          color: colors.BlackMediumEmphasis,
        },
      })}
      {...rest}
    >
      {children}
    </MUIBreadcrumbs>
  );
}
