import React, { FC } from "react";
import RecordingIndicator from "../RecordingIndicator/RecordingIndicator";
import { Box, Button, useTheme } from "@mui/material";
import { Pause as PauseIcon } from "@mui/icons-material";
import {
  RecordingContext,
  RecordingStateStatus,
  useRecording as useRecordingHook,
} from "../../contexts/recording-context/recording-context";
import { Timer } from "./Timer.style";

export interface RecordingControlsProps {
  useRecording?: () => RecordingContext;
}

const RecordingControls: FC<RecordingControlsProps> = ({
  useRecording = useRecordingHook,
}: RecordingControlsProps): JSX.Element => {
  const theme = useTheme();
  const { elapsedTime, status, toggleRecording } = useRecording();
  const isRecording = status === RecordingStateStatus.Recording;

  return (
    <>
      <Box ml={4} flexShrink={0} display={"flex"} alignItems={"center"}>
        <Timer data-cy="recording-timer" theme={theme}>
          {elapsedTime}
        </Timer>{" "}
      </Box>
      <Box ml={2} flexShrink={0} display={"flex"} alignItems={"center"}>
        <RecordingIndicator isActive={isRecording} />
      </Box>
      <Box ml={2} flexShrink={0} display={"flex"} alignItems={"center"}>
        <Button
          data-cy={
            isRecording ? "recording-pause-button" : "recording-start-button"
          }
          startIcon={isRecording ? <PauseIcon /> : null}
          color="secondary"
          variant="contained"
          size="small"
          onClick={toggleRecording}
          style={{
            minWidth: 120,
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          {isRecording ? "Recording" : "Record"}
        </Button>
      </Box>
    </>
  );
};

export default RecordingControls;
