/* eslint-disable @typescript-eslint/no-explicit-any */
import ConnectionJanus from "./ConnectionJanus";
import { ConnectionType, Direction } from "../Connection";
import { JanusJS } from "@proximie/janus-gateway";
import { PrxMessage } from "./message";
import QualityVideoOutgoing, {
  QualityLevel,
  SimulcastLayers,
} from "../../Quality/QualityVideoOutgoing";
import { ConnectionMetadata } from "../../../../index";

// we don't want to resolve the send() promise until we get a "configured"
// or "error" message from Janus.  So, we define the Deferred class
// to make this easier
class Deferred<T> {
  public promise: Promise<T>;
  public resolve: (value: T | PromiseLike<T>) => void = () => null;
  public reject: (error: Error) => void = () => null;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

export default class ConnectionJanusVideoOutgoing extends ConnectionJanus {
  override connectionType = ConnectionType.JanusVideoOutgoing;
  override direction = Direction.Outgoing;
  private dfd: Deferred<void> | null = null;

  protected attachOptions: JanusJS.PluginOptions = {
    plugin: "OVERWRITE_ME",
    ...this.defaultAttachCallbacks,
    onmessage: this.onMessage.bind(this),
    onlocalstream: this.onLocalStream.bind(this),
  };

  private onMessage(message: PrxMessage, jsep?: JanusJS.JSEP): void {
    console.debug("VideoOut:onMessage - message=", message);
    if (jsep) {
      this.handle?.handleRemoteJsep({
        jsep,
      });
    }

    const event = message["videoroom"];
    if (message.error) {
      console.warn("VideoOut:onMessage: error=", message.error);
      if (this.dfd) {
        if (message["error_code"] === 432) {
          console.warn("Too many publishers");
          this.dfd.reject(new Error("Video capacity exceeded"));
        } else {
          this.dfd.reject(new Error(message.error));
        }
        // give calling process time to process before we delete it
        Promise.resolve(() => (this.dfd = null));
      }
      return;
    }

    switch (event) {
      case "joined":
        console.debug("VideoOut:onMessage Local user joined");
        return;
      case "destroyed":
        console.warn("VideoOut:onMessage destroyed");
        return;
      case "event":
        console.debug("VideoOut:onMessage event");
        if (message.configured === "ok" && this.dfd) {
          console.debug("All configured");
          this.dfd.resolve();
          // give calling process time to process before we delete it
          Promise.resolve(() => (this.dfd = null));
        } else if (message.leaving === "ok" && message.reason === "kicked") {
          console.debug("VideoOut:onMessage - i was kicked", message);
          this.close();
        }
        return;
      default:
        break;
    }

    console.warn("VideoOut:onMessage Unknown message=", message);
  }

  protected override join(params: ConnectionMetadata): Promise<void> {
    console.debug(
      {
        streamId: this.streamId,
      },
      "ConnectionJanusVideoOutgoing:join",
      params,
    );

    return new Promise<void>((resolve, reject) => {
      const register = {
        request: "join",
        room: this.mediaSessionId,
        ptype: "publisher",
        id: this.streamId,
        display: JSON.stringify(params),
      };

      this.handle?.send({
        message: register,
        success: resolve,
        error: (error: string) => reject(new Error(error)),
      } as unknown as JanusJS.PluginMessage);
    });
  }

  override send(stream: MediaStream): Promise<void> {
    console.debug("VdeoOut:send stream=", stream);

    const simulcast = (this.quality as QualityVideoOutgoing).isSimulcastActive(
      QualityLevel.High,
      SimulcastLayers.High,
    );

    let simulcastMaxBitrates: {
      [SimulcastLayers.High]?: number;
      [SimulcastLayers.Medium]?: number;
      [SimulcastLayers.Low]?: number;
    } = {};

    if (simulcast) {
      // start with the highest quality
      simulcastMaxBitrates = {
        [SimulcastLayers.High]: (
          this.quality as QualityVideoOutgoing
        ).getSimulcastBitrate(QualityLevel.High, SimulcastLayers.High),
        [SimulcastLayers.Medium]: (
          this.quality as QualityVideoOutgoing
        ).getSimulcastBitrate(QualityLevel.High, SimulcastLayers.Medium),
        [SimulcastLayers.Low]: (
          this.quality as QualityVideoOutgoing
        ).getSimulcastBitrate(QualityLevel.High, SimulcastLayers.Low),
      };
    }

    this.dfd = new Deferred();

    this.stream = stream;

    // add an ended handler - this gets called for a screenshare when the
    // banner "stop sharing" button is clicked.  For cameras it gets
    // called when the USB camera is disconnected.
    const [track] = stream.getVideoTracks();
    if (track) {
      track.onended = (): void => {
        console.debug(
          { streamId: this.streamId },
          "ConnectionJanusVideoOutgoing - track ended",
        );
        this.close(new Error("Track ended"));
      };

      track.onmute = (): void => {
        console.debug(
          { streamId: this.streamId },
          "ConnectionJanusVideoOutgoing - track muted",
        );
      };

      track.onunmute = (): void => {
        console.debug(
          { streamId: this.streamId },
          "ConnectionJanusVideoOutgoing - track unmuted",
        );
      };

      const constraints = (this.quality as QualityVideoOutgoing)
        .initialConstraints;

      if (typeof constraints.video !== "boolean") {
        track.applyConstraints(constraints.video);
      }
      if ("contentHint" in track) {
        // contentHint not present in definition yet
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (track as any).contentHint = "motion";
      }
    }

    this.handle?.createOffer({
      simulcast,
      simulcast2: simulcast,
      simulcastMaxBitrates,
      stream,
      media: { audio: false, videoRecv: false },
      success: (jsep: JanusJS.JSEP) => {
        this.emit("connecting");
        const publish = {
          request: "configure",
          audio: false,
          video: true,
          // to explicitly select from the available codecs, specify it here...
          //videocodec: "vp8",
        };
        this.handle?.send({
          message: publish,
          jsep,
        });
      },
      error: (error: any) => {
        console.warn("VideoOut:send failed - error=", error);
        this.dfd && this.dfd.reject(new Error(error));
        // give calling process time to process before we delete it
        Promise.resolve(() => (this.dfd = null));
      },
    });
    return this.dfd.promise;
  }
}
