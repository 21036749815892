import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface WebcamNotFoundExceptionMeta {
  originalError: Error;
}

export class WebcamNotFoundException extends BaseException {
  constructor(message = "Webcam not found", meta: WebcamNotFoundExceptionMeta) {
    super(message, meta);

    this.code = ExceptionCodes.WEBCAM_NOT_FOUND;
  }
}
