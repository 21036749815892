import { makeRoute } from "./api";
import * as t from "io-ts";
import { empty } from "./common";

export const MediaSessionPermissionsPresentation = t.type({
  canEditSession: t.boolean,
  canInviteParticipants: t.boolean,
  canCloseVideoFeed: t.boolean,
  canEndSession: t.boolean,
  canClearAllDrawings: t.boolean,
  canRecordSession: t.boolean,
  canSpotlightFeeds: t.boolean,
});

export const getMediaSessionPermissions = makeRoute(
  "/permissions/appointment/:mediaSessionId",
  empty,
  MediaSessionPermissionsPresentation,
);

export type MediaSessionPermissionsPresentation = t.TypeOf<
  typeof MediaSessionPermissionsPresentation
>;
