import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToJoinSessionException extends BaseException {
  constructor(message = "Not allowed to join session", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_JOIN_SESSION;
  }
}
