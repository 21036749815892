import { styled } from "@mui/material";

interface StyledRangeInputProps {
  value: number;
}

export const StyledRangeInput = styled("span")<StyledRangeInputProps>(
  ({ theme, value }) => `
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    background: ${theme.colors.Grey300};
    background-image: linear-gradient(${theme.colors.Grey500}, ${theme.colors.Grey500});
    background-size: ${value}% 100%;
    background-repeat: no-repeat;

    ::-webkit-slider-runnable-track {
      background: ${theme.colors.Grey300};
      height: 6px;
      border-radius: 4px;
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    ::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      background-color: ${theme.colors.Grey500};
      height: 6px;
      width: 6px;
      margin-left: -2.5px;
      border-radius: 4px;
      transition: all 300 ease-out;
    }
  }
`,
);
