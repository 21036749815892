import * as t from "io-ts";

const SessionOwner = t.type({
  id: t.string,
  profileId: t.union([t.undefined, t.null, t.number]),
  name: t.union([t.undefined, t.null, t.string]),
  email: t.union([t.undefined, t.null, t.string]),
});

const Participant = t.type({
  id: t.string,
  profileId: t.union([t.undefined, t.null, t.number]),
  role: t.string,
});

const SessionType = t.type({
  name: t.string,
  weighting: t.number,
});

const Stream = t.type({
  id: t.string,
  status: t.string,
  url: t.string,
});
const SessionAccess = t.type({
  name: t.string,
});

const Facility = t.type({
  id: t.string,
  name: t.string,
  isRemovable: t.union([t.undefined, t.boolean]),
});

const Room = t.type({
  id: t.string,
  name: t.string,
  isRemovable: t.union([t.undefined, t.boolean]),
});

const Organisation = t.type({
  id: t.string,
  name: t.string,
  type: t.string,
  allowImageOverlay: t.boolean,
  allowPublicVisibility: t.boolean,
  allowRecording: t.boolean,
  allowVideoSharing: t.boolean,
  allowAnalytics: t.boolean,
  allowWearables: t.boolean,
  allowQuickReschedule: t.boolean,
  allowStartNow: t.boolean,
  countryISO: t.string,
  externalParentId: t.string,
});

const UpdatedBy = t.type({
  email: t.string,
  name: t.union([t.null, t.string]),
});

const Session = t.type({
  id: t.string,
  title: t.string,
  owner: SessionOwner,
  participants: t.array(Participant),
  scheduledAt: t.string,
  estimatedDuration: t.number,
  createdAt: t.string,
  updatedAt: t.string,
  type: SessionType,
  access: SessionAccess,
  status: t.string,
  description: t.union([t.undefined, t.string]),
  deletedAt: t.union([t.undefined, t.null, t.string]),
  editedAt: t.union([t.undefined, t.string]),
  timePassedSinceLastEdit: t.union([t.undefined, t.string]),
  updatedBy: t.union([t.undefined, UpdatedBy]),
  surgicalType: t.union([t.undefined, t.string]),
});

const GetSessionResponse = t.type({
  id: t.string,
  session: Session,
  stream: t.union([t.undefined, Stream]),
  facility: t.union([t.undefined, Facility]),
  room: t.union([t.undefined, Room]),
  organisation: Organisation,
});

export type FullSession = t.TypeOf<typeof GetSessionResponse>;
export type Session = t.TypeOf<typeof Session>;
export type Organisation = t.TypeOf<typeof Organisation>;
export type Stream = t.TypeOf<typeof Stream>;
export type Facility = t.TypeOf<typeof Facility>;
export type Room = t.TypeOf<typeof Room>;
export type SessionOwner = t.TypeOf<typeof SessionOwner>;
export type Participant = t.TypeOf<typeof Participant>;
