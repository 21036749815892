import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class InvalidInviteUserRequestException extends BaseException {
  constructor(message = "Invalid invite user", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_INVITE_USER_REQUEST;
  }
}
