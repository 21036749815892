import Device from "../Devices/Device";
import DeviceDcp, { DcpType } from "../Devices/DeviceDcp";
import Monitor from "../Monitors/Monitor";
import MonitorDeviceDcp from "../Monitors/MonitorDeviceDcp";
import MonitorDeviceLocalCamera from "../Monitors/MonitorDeviceLocalCamera";
import EndpointVirtual from "../Endpoints/EndpointVirtual";

// this is a pseudo-monitor - it aggregates the DCP and local cameras to
// provide a unified view for the application
export default class MonitorCamera extends Monitor {
  constructor(
    private localMonitor: MonitorDeviceLocalCamera,
    private dcpMonitor: MonitorDeviceDcp,
    private hostDeviceId: string,
  ) {
    super(
      new EndpointVirtual({ mediaSessionId: "", profileId: 0, userUUID: "" }),
    );

    this.dcpMonitor.on("added", (device: DeviceDcp): void => {
      // only emit "added" when we have received the state and services
      const listener = (): void => {
        if (device.services && device.state) {
          console.debug(
            { deviceId: device.deviceId },
            "Camera monitor - added DCP device (eventually)",
            device,
          );
          this.emit("added", device);
          device.off("state", listener);
          device.off("services", listener);
        }
      };

      if (device.services && device.state) {
        console.debug(
          { deviceId: device.deviceId },
          "Camera monitor - added DCP device (immediately)",
          device,
        );
        this.emit("added", device);
      } else {
        device.on("state", listener);
        device.on("services", listener);
      }
    });
    this.localMonitor.on("added", (device: Device): void => {
      this.emit("added", device);
    });
    this.dcpMonitor.on("removed", (device: Device): void => {
      this.emit("removed", device);
    });
    this.localMonitor.on("removed", (device: Device): void => {
      this.emit("removed", device);
    });
  }

  get deviceList(): Device[] {
    return [
      ...(this.dcpMonitor.deviceList || []).filter(
        (device: DeviceDcp): boolean =>
          device.hasDcpType(DcpType.Stream) &&
          device.deviceId === this.hostDeviceId &&
          !!device.services &&
          !!device.state,
      ),
      ...this.localMonitor.deviceList,
    ];
  }
}
