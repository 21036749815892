import React, { FC, useEffect, useState, useCallback } from "react";
import { useEventListener } from "../../hooks";
import { MediaControlProps } from "../../MediaControls";
import { StyledTimestamp } from "../../MediaControls.style";

const normalizeTimeSegment = (num: number) => (num < 10 ? `0${num}` : `${num}`);

export const formatSecondsToHHMMSS = (time: number) => {
  const minutes = Math.floor(time / 60) % 60;
  const hours = Math.floor(time / 60 / 60);
  const seconds = Math.floor(time % 60);

  return `${normalizeTimeSegment(hours)}:${normalizeTimeSegment(
    minutes,
  )}:${normalizeTimeSegment(seconds)}`;
};

const TimeStamp: FC<MediaControlProps> = ({ element }: MediaControlProps) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const handler = useCallback(() => {
    setDuration(parseFloat(element.duration.toFixed(3)));
    setCurrentTime(element.currentTime);
  }, [element]);
  useEventListener("loadeddata", handler, element);

  useEffect(() => {
    let frameId: number;

    const getTime = () => {
      frameId = requestAnimationFrame(() => {
        setCurrentTime(element.currentTime);
        getTime();
      });
    };
    getTime();

    return () => cancelAnimationFrame(frameId);
  }, [element, setCurrentTime]);

  return (
    <span>
      <StyledTimestamp>{formatSecondsToHHMMSS(currentTime)}</StyledTimestamp>/
      <StyledTimestamp>{formatSecondsToHHMMSS(duration)}</StyledTimestamp>
    </span>
  );
};

export default TimeStamp;
