import { SpeedDial, styled } from "@mui/material";

export const SpeedDialStyled = styled(SpeedDial)(
  ({ theme }) => `
  .MuiTouchRipple-child {
    background-color: ${theme.colors.Grey700};
  }

  .MuiSpeedDial-fab {
    border-radius: 26px 26px 26px 26px;

    background: ${theme.colors.Grey900};
    padding: 0;
    margin: 0;
    width: 48px;
    height: 48px;
    box-shadow: 0 0 4px 0 ${theme.colors.BlackA600};

    transition: all 0.7s;
  }

  .MuiSpeedDial-fab.SpeedDialMenuOpen {
    border-radius: 26px 26px 0 0;
  }

  .MuiSpeedDial-fab:hover {
    background: ${theme.colors.Grey900};
  }

  .MuiSpeedDial-actions:not(.MuiSpeedDial-actionsClosed):before {
    border-top: 1px solid ${theme.colors.Grey700};
    margin: 0 4px 4px 4px;
    height: 0;
    content: "";
  }

  .MuiSpeedDial-actions {
    background: ${theme.colors.Grey900};
    padding: 0 4px 16px 4px;
    margin: 0;
    width: 48px;
    box-shadow: 0 1px 4px 0 ${theme.colors.BlackA600};
    border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    transition: 0.4s;
  }

  .MuiSpeedDial-actions .SpeedDialActionSelected {
    background-color: ${theme.colors.Secondary600};
    transition: all 0.1s;
  }

  .MuiSpeedDial-actionsClosed {
    background: transparent;
    border: 0;
    box-shadow: none;
  }

  .MuiSpeedDialAction-fab.SpeedDialActionSelected:hover {
    background-color: ${theme.colors.Secondary600};
  }

  .MuiSpeedDialAction-fab.ColorPickerOpen {
    background-color: ${theme.colors.Grey700};
    transition: all 0.1s;
  }

  .MuiSpeedDialAction-fab:hover {
    background-color: transparent;
    color: ${theme.colors.Grey300};

    p {
      color: ${theme.colors.Grey300};
    }
  }

  .MuiSpeedDialAction-fab {
    border-radius: 8px;
    background-color: transparent;
    color: ${theme.colors.WhiteA900};
    margin: 3px 0;
    width: 40px;
    height: 40px;

    div > * {
      width: 24px;
      height: 24px;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }

    p {
      padding: 0;
      margin: 0;
      border: 0;
      color: ${theme.colors.WhiteA900};
      text-align: center;
      white-space: nowrap;
      text-transform: none;
      /* font-family: Suisse In''l; */
      width: 100%;
      height: 10px;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`,
);
