import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface InvalidUserMediaConstraintExceptionMeta {
  originalError: Error;
}

export class InvalidUserMediaConstraintException extends BaseException {
  constructor(
    message = "Invalid user media constraint",
    meta: InvalidUserMediaConstraintExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_USER_MEDIA_CONSTRAINT;
  }
}
