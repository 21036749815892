/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { createContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { FlavorConfig, FlavorConfigurations, Fonts, Logos } from "./types";
import { ThemeOptions } from "@mui/material";
import WebFont from "webfontloader";
import { colors, Colors } from "libs/components/src/styles/colors";
import { generateTheme } from "libs/components/src/styles/mui-theme/proximie/theme";
import { getLogos } from "./getLogos";
import { getFavicons } from "./getFavicons";
import { Dictionary } from "libs/i18n-init/src/lib/Dictionary";

export const DEFAULT_FLAVOR = "default";
export const DEFAULT_LOGOS: Logos = {
  dashboardApp: "",
  mediaApp: "",
  dashboardAppMini: "",
};
export const DEFAULT_FAVICONS: string[] = [];

const uninterceptedAxios = axios.create();

const interpolateColors = (value: string, colors: Colors) =>
  value.replace(
    new RegExp(Object.keys(colors).join("|"), "gi"),
    (matched) => colors[matched],
  );

export interface FlavorContextType {
  colors: Colors;
  fonts: Fonts;
  theme: ThemeOptions;
  configurations: FlavorConfigurations;
  logos: Logos;
  favicons: string[];
  customDictionary: Partial<typeof Dictionary>;
}

export interface FlavorProviderValue extends FlavorContextType {
  flavor: string;
  getConfiguration: <Key extends keyof FlavorConfigurations>(
    key: Key,
  ) => FlavorConfigurations[Key];
  setFlavor: (newFlavor: string) => void;
}

export const FlavorContext = createContext<FlavorProviderValue>({
  flavor: DEFAULT_FLAVOR,
  colors: colors,
  theme: {},
  fonts: [],
  configurations: {},
  logos: DEFAULT_LOGOS,
  favicons: DEFAULT_FAVICONS,
  customDictionary: {},
  getConfiguration: (_configName: any) => void 0,
  setFlavor: (_newFlavor: string) => void 0,
});

export interface FlavorProviderProps {
  config: FlavorConfig;
  children: React.ReactNode | React.ReactNode[];
}

export interface UseFlavorProps {
  namespace?: string;
}

export const FlavorProvider: React.FC<FlavorProviderProps> = ({
  config: {
    baseUrl,
    defaultColors,
    defaultTheme,
    defaultConfigurations,
    isOrigin,
    initialFlavor = DEFAULT_FLAVOR,
  },
  children,
}: FlavorProviderProps) => {
  const [flavor, setFlavor] = useState<string>(initialFlavor);
  const [context, setContext] = useState<FlavorContextType>({
    colors: defaultColors,
    theme: defaultTheme,
    configurations: defaultConfigurations,
    fonts: [],
    logos: DEFAULT_LOGOS,
    favicons: DEFAULT_FAVICONS,
    customDictionary: {},
  });

  const [isReady, setReady] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.setFlavor = setFlavor;

  useEffect(() => {
    uninterceptedAxios
      .get(`${baseUrl}/flavor-rc-${flavor}.json`, {
        baseURL: isOrigin ? window.location.origin : undefined,
      })
      .then((response) => response.data)
      .then((rc: FlavorContextType) => {
        setContext((prevContext) => {
          const patchedColors = _.merge(
            prevContext.colors,
            rc.colors ?? {},
          ) as Colors;
          const patchedTheme = generateTheme(patchedColors);
          const interpolatedTheme = rc.theme
            ? (JSON.parse(
                interpolateColors(JSON.stringify(rc.theme), patchedColors),
              ) as ThemeOptions)
            : {};
          const theme = _.merge(patchedTheme, interpolatedTheme);
          const fonts = rc.fonts ?? [];
          const configurations = _.merge(
            prevContext.configurations,
            rc.configurations ?? {},
          );
          const logos = getLogos(flavor);
          const favicons = getFavicons(flavor);

          return {
            colors: patchedColors,
            theme,
            fonts,
            configurations,
            logos,
            favicons,
            customDictionary: rc.customDictionary ?? {},
          };
        });
      })
      .finally(() => setReady(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flavor]);

  useEffect(() => {
    if (context.fonts.length > 0) {
      const webfontConfig: WebFont.Config = context.fonts.reduce(
        (acc: WebFont.Config, font) => {
          if (font.module === "google") {
            const accGFonts = acc?.google?.families ?? [];
            return {
              ...acc,
              google: {
                families: [...accGFonts, font.family],
              },
            };
          }
          if (font.module === "custom") {
            const accCustomFonts = acc?.custom?.families ?? [];
            const accCustomUrls = acc?.custom?.urls ?? [];
            return {
              ...acc,
              custom: {
                families: [...accCustomFonts, ...font.families],
                urls: [...accCustomUrls, ...font.urls],
              },
            };
          }
          return acc;
        },
        {},
      );

      WebFont.load(webfontConfig);
    }
  }, [context.fonts]);

  const providerValue: FlavorProviderValue = useMemo(
    () => ({
      flavor,
      ...context,
      setFlavor: (newFlavor: string) => setFlavor(newFlavor),
      getConfiguration: <Key extends keyof FlavorConfigurations>(
        key: Key,
      ): FlavorConfigurations[Key] => context.configurations[key],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context],
  );

  return (
    <FlavorContext.Provider value={providerValue}>
      {isReady ? <>{children}</> : null}
    </FlavorContext.Provider>
  );
};
