export enum SupportTypes {
  SETTING_UP_HARDWARE = "SETTING_UP_HARDWARE",
  TROUBLE_JOINING_A_LIVE_SESSION = "TROUBLE_JOINING_A_LIVE_SESSION",
  FINDING_A_VIDEO_IN_THE_LIBRARY = "FINDING_A_VIDEO_IN_THE_LIBRARY",
  MANAGING_MY_ACCOUNT = "MANAGING_MY_ACCOUNT",
  GIVE_FEEDBACK_ABOUT_THE_PRODUCT = "GIVE_FEEDBACK_ABOUT_THE_PRODUCT",
  DOWNLOADING_A_VIDEO = "DOWNLOADING_A_VIDEO",
  CHANGING_THE_OWNER_OF_A_VIDEO = "CHANGING_THE_OWNER_OF_A_VIDEO",
  MOVING_A_VIDEO_TO_A_DIFFERENT_ORGANIZATION = "MOVING_A_VIDEO_TO_A_DIFFERENT_ORGANIZATION",
  SOMETHING_ELSE = "SOMETHING_ELSE",
  AUDIO_VIDEO_ISSUES_IN_THE_LIVE_SESSION = "AUDIO_VIDEO_ISSUES_IN_THE_LIVE_SESSION",
  SOMETHING_ELSE_IN_THE_LIVE_SESSION = "SOMETHING_ELSE_IN_THE_LIVE_SESSION",
}

export const supportSummaries: Record<SupportTypes, string> = {
  SETTING_UP_HARDWARE: "Setting up hardware",
  TROUBLE_JOINING_A_LIVE_SESSION: "Trouble joining a live session",
  FINDING_A_VIDEO_IN_THE_LIBRARY: "Finding a video in the library",
  MANAGING_MY_ACCOUNT: "Managing my account",
  GIVE_FEEDBACK_ABOUT_THE_PRODUCT: "Give feedback about the product",
  DOWNLOADING_A_VIDEO: "Downloading a video",
  CHANGING_THE_OWNER_OF_A_VIDEO: "Changing the owner of a video",
  MOVING_A_VIDEO_TO_A_DIFFERENT_ORGANIZATION:
    "Moving a video to a different organization",
  SOMETHING_ELSE: "Something else",
  AUDIO_VIDEO_ISSUES_IN_THE_LIVE_SESSION:
    "Audio/video issues in the live session",
  SOMETHING_ELSE_IN_THE_LIVE_SESSION: "Something else in the live session",
};

export const supportDescriptions: Record<SupportTypes, string> = {
  SETTING_UP_HARDWARE:
    "Share specifics about your hardware setup and any issues you're facing.",
  TROUBLE_JOINING_A_LIVE_SESSION:
    "Describe any issues you're encountering when joining a live session. Please include the session's URL, ID, name, date, and time.",
  FINDING_A_VIDEO_IN_THE_LIBRARY:
    "Let us know what video you're looking for by including the session name, date, time and session ID.",
  MANAGING_MY_ACCOUNT:
    "Describe the specific account actions you need help with.",
  GIVE_FEEDBACK_ABOUT_THE_PRODUCT:
    "We value your feedback! Share your thoughts and suggestions to help us enhance our product.",
  DOWNLOADING_A_VIDEO:
    "Provide details about the video you want to download, including its URL and name.",
  CHANGING_THE_OWNER_OF_A_VIDEO:
    "Specify the video you want to transfer ownership of (URL or name), along with the name and email of the new owner.",
  MOVING_A_VIDEO_TO_A_DIFFERENT_ORGANIZATION:
    "Specify the video you want to move (URL or name) and target organization. Include the reason for the request.",
  SOMETHING_ELSE:
    "Provide a detailed explanation of your request or issue. More information helps us assist you better.",
  AUDIO_VIDEO_ISSUES_IN_THE_LIVE_SESSION:
    "Describe any audio or video problems you're encountering. Include details about any hardware you are trying to connect.",
  SOMETHING_ELSE_IN_THE_LIVE_SESSION:
    "Please provide a detailed explanation of your request or issue.\nMore information helps us assist you better.",
};

export const supportDetails: Record<SupportTypes, string> = {
  SETTING_UP_HARDWARE: "Hardware (e.g. PxKit number):\nIssues:",
  TROUBLE_JOINING_A_LIVE_SESSION:
    "Session ID or URL:\nSession name:\nSession date/time:\nDescription:",
  FINDING_A_VIDEO_IN_THE_LIBRARY:
    "Session ID or URL (if known):\nSession name:\nSession date/time:\nOther comments:",
  MANAGING_MY_ACCOUNT: "",
  GIVE_FEEDBACK_ABOUT_THE_PRODUCT: "",
  DOWNLOADING_A_VIDEO:
    "Video URL:\nVideo name:\nReason for download:\nOther comments:",
  CHANGING_THE_OWNER_OF_A_VIDEO:
    "Video URL:\nVideo name:\nName of new owner:\nEmail of new owner:\nOther comments:",
  MOVING_A_VIDEO_TO_A_DIFFERENT_ORGANIZATION:
    "Video URL:\nVideo name:\nTarget organization name:\nReason for request:\nOther comments:",
  SOMETHING_ELSE: "Session ID or URL (if applicable):\nDetails:",
  AUDIO_VIDEO_ISSUES_IN_THE_LIVE_SESSION: "",
  SOMETHING_ELSE_IN_THE_LIVE_SESSION: "",
};

export const supportTypes = {
  "not-in-session": [
    SupportTypes.SETTING_UP_HARDWARE,
    SupportTypes.TROUBLE_JOINING_A_LIVE_SESSION,
    SupportTypes.FINDING_A_VIDEO_IN_THE_LIBRARY,
    SupportTypes.DOWNLOADING_A_VIDEO,
    SupportTypes.CHANGING_THE_OWNER_OF_A_VIDEO,
    SupportTypes.MOVING_A_VIDEO_TO_A_DIFFERENT_ORGANIZATION,
    SupportTypes.MANAGING_MY_ACCOUNT,
    SupportTypes.GIVE_FEEDBACK_ABOUT_THE_PRODUCT,
    SupportTypes.SOMETHING_ELSE,
  ],
  "in-session": [
    SupportTypes.AUDIO_VIDEO_ISSUES_IN_THE_LIVE_SESSION,
    SupportTypes.SOMETHING_ELSE_IN_THE_LIVE_SESSION,
  ],
};
