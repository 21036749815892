const DEVICE_ID_NAME = "dcpDeviceId";

export default async function readDeviceId(): Promise<string> {
  // return "" if no deviceId present
  try {
    return await Promise.race([
      new Promise<string>((resolve, reject) => {
        window.addEventListener("message", (event: MessageEvent) => {
          if (
            event.source !== window ||
            event.origin !== window.location.origin
          ) {
            return;
          }

          if (event.data.deviceId === "") {
            console.warn("Could not get deviceId from extension");
            reject(new Error("deviceId not initialised"));
          } else if (event.data.deviceId) {
            resolve(event.data.deviceId);
          }
        });

        window.postMessage({ request: "deviceId" }, window.location.origin);
      }),
      new Promise<string>((_resolve, reject) =>
        setTimeout(() => {
          console.debug("Extension is not installed");
          reject(new Error("Extension not installed"));
        }, 200),
      ),
    ]);
  } catch {
    return localStorage.getItem(DEVICE_ID_NAME) || "";
  }
}
