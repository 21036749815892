import { SvgIcon as MuiSvgIcon } from "@mui/material";
import React from "react";

export type ColorPickerIconProps = {
  color: string;
};

export const ColorPickerIcon = ({ color }: ColorPickerIconProps) => {
  return (
    <MuiSvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M12.7073 2.00134C7.17728 2.00134 2.70728 6.47134 2.70728 12.0013C2.70728 17.5313 7.17728 22.0013 12.7073 22.0013C18.2373 22.0013 22.7073 17.5313 22.7073 12.0013C22.7073 6.47134 18.2373 2.00134 12.7073 2.00134Z"
          fill={color}
        />
        <path
          d="M3.20728 12.0013C3.20728 6.74749 7.45342 2.50134 12.7073 2.50134C17.9611 2.50134 22.2073 6.74749 22.2073 12.0013C22.2073 17.2552 17.9611 21.5013 12.7073 21.5013C7.45342 21.5013 3.20728 17.2552 3.20728 12.0013Z"
          stroke="white"
          strokeOpacity="0.87"
        />
      </svg>
    </MuiSvgIcon>
  );
};
