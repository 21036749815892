export function getFavicons(flavor: string): string[] {
  return [
    `/assets/static_assets/flavoring/${flavor}/android-chrome-192x192.png`,
    `/assets/static_assets/flavoring/${flavor}/android-chrome-512x512.png`,
    `/assets/static_assets/flavoring/${flavor}/apple-touch-icon.png`,
    `/assets/static_assets/flavoring/${flavor}/favicon-16x16.png`,
    `/assets/static_assets/flavoring/${flavor}/favicon-32x32.png`,
    `/assets/static_assets/flavoring/${flavor}/favicon.ico`,
  ];
}
