import { AllUrlKeys, FetchResult } from "../../monitoring/monitors/XHRMonitor";

export type GoodPoorBad = "Good" | "Poor" | "Bad";
export type GoodPoorBadCalculating = "Good" | "Poor" | "Bad" | "Calculating";
export type GoodPoorBadNA = "Good" | "Poor" | "Bad" | "N/A";
export type GoodPoor = "Good" | "Poor";
export type GoodBad = "Good" | "Bad";
export type NormalPoorBad = "Normal" | "Poor" | "Bad";

export type NormalGoodPoorBad =
  | GoodPoorBad
  | GoodPoor
  | GoodBad
  | NormalPoorBad;

export type NormalGoodPoorBadCalculating =
  | GoodPoorBad
  | GoodPoor
  | GoodBad
  | NormalPoorBad
  | GoodPoorBadCalculating;

export type NormalGoodPoorBadNA =
  | GoodPoorBad
  | GoodPoor
  | GoodBad
  | NormalPoorBad
  | GoodPoorBadNA;

export type NormalGoodPoorBadNACalculating =
  | GoodPoorBad
  | GoodPoor
  | GoodBad
  | NormalPoorBad
  | GoodPoorBadNA
  | GoodPoorBadCalculating;
export type Criticality = GoodPoorBadCalculating;

export type StreamingProtocol = "UDP" | "TCP" | "TLS";
export type VideoQuality = "High" | "Medium" | "Low";

export type BandwidthValue = { value: number; criticality: GoodPoorBadNA };
export type LatencyValue = { value: number; criticality: NormalPoorBad };
export type PacketLossValue = { value: number; criticality: NormalPoorBad };
export type StreamingProtocolValue = {
  value: StreamingProtocol;
  criticality: GoodPoor;
};
export type ServiceReachabilityValue = {
  value: { [k in AllUrlKeys]?: FetchResult };
  criticality: GoodBad;
};
export type VideoQualityValue = {
  value: VideoQuality;
  criticality: GoodPoorBad;
};

export type NetworkReport = {
  outgoingBandwidth: BandwidthValue;
  incomingBandwidth: BandwidthValue;
  latency: LatencyValue;
  packetLoss: PacketLossValue;
  criticality: Criticality;
};

export type FirewallReport = {
  streamingProtocol: StreamingProtocolValue;
  serviceReachability: ServiceReachabilityValue;
  criticality: Criticality;
};

export type VideoReport = {
  videoQuality: VideoQualityValue;
  criticality: Criticality;
};

export type CriticalityReport = {
  network: NetworkReport;
  firewall: FirewallReport;
  video: VideoReport;
  criticality: Criticality;
};
