import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  CheckCircle,
  Error,
  ExpandLess,
  ExpandMore,
  Warning,
} from "@mui/icons-material";
import { TroubleShootingMenuOverviewStyled } from "./TroubleShootingMenuOverview.style";
import { useTroubleShooting } from "../TroubleShootingContext";
import { Subtitle3Typography } from "../shared/CustomTypography";

export const TroubleShootingMenuOverviewContainer = () => {
  const {
    criticalityReport: { criticality },
  } = useTroubleShooting();

  return {
    Good: <GoodTroubleShootingMenuOverview />,
    Poor: <PoorTroubleShootingMenuOverview />,
    Bad: <CriticalTroubleShootingMenuOverview />,
  }[criticality];
};

type TroubleShootingMenuOverviewProps = {
  icon: ReactNode;
  title?: string | ReactNode;
  message: string | ReactNode;
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  collapsible?: boolean;
};

export const TroubleShootingMenuOverview = ({
  icon,
  title,
  message,
  backgroundColor,
  textColor,
  borderColor,
  collapsible,
}: TroubleShootingMenuOverviewProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const [lineCount, setLineCount] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setLineCount((ref.current?.scrollHeight ?? 20) / 20);
  }, [ref, collapsed]);

  return (
    <TroubleShootingMenuOverviewStyled
      backgroundColor={backgroundColor}
      textColor={textColor}
      borderColor={borderColor}
      collapsed={collapsed}
      lineCount={lineCount}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        py={"8px"}
      >
        <Box padding={"0"} margin={"0"} width={"22px"} height={"22px"}>
          {icon}
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={title ? "4px" : "0"}
        py={"8px"}
        justifyContent={"center"}
      >
        <>
          {title && (
            <Subtitle3Typography fontWeight={600}>{title}</Subtitle3Typography>
          )}
          <Subtitle3Typography
            ref={ref}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            width={"365px"}
            noWrap={collapsed}
            sx={{
              transition: "100ms ease-in-out",
              "&.MuiTypography-noWrap": {
                height: "20px",
              },
            }}
          >
            {message}
          </Subtitle3Typography>
        </>
      </Box>
      {collapsible && (
        <Box py={"8px"}>
          {collapsed ? (
            <ExpandMore onClick={() => setCollapsed(false)} />
          ) : (
            <ExpandLess onClick={() => setCollapsed(true)} />
          )}
        </Box>
      )}
    </TroubleShootingMenuOverviewStyled>
  );
};

export const GoodTroubleShootingMenuOverview = () => {
  const { colors } = useTheme();

  return (
    <TroubleShootingMenuOverview
      icon={<CheckCircle style={{ color: colors.Green500 }} />}
      message={"Your audio and video should be working smoothly."}
      backgroundColor={colors.Green100}
      textColor={colors.Green700}
      borderColor={colors.Green300}
    />
  );
};

export const PoorTroubleShootingMenuOverview = () => {
  const { colors } = useTheme();
  return (
    <TroubleShootingMenuOverview
      icon={<Warning style={{ color: colors.Amber500 }} />}
      message={"The below issue(s) may be affecting your session."}
      backgroundColor={colors.Amber50}
      textColor={colors.BlackA900}
      borderColor={colors.Amber300}
    />
  );
};

export const CriticalTroubleShootingMenuOverview = () => {
  const { colors } = useTheme();

  return (
    <TroubleShootingMenuOverview
      icon={<Error style={{ color: colors.Red500 }} />}
      message={"The below issue(s) are affecting your session."}
      backgroundColor={colors.Red50}
      textColor={colors.Red800}
      borderColor={colors.Red300}
    />
  );
};
