import React from "react";

export type Permission = string;
export type Permissions = Permission[];

export type PermissionContextType = {
  isAllowedTo: (permission: Permission) => boolean;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => false,
};
export const PermissionContext =
  React.createContext<PermissionContextType>(defaultBehaviour);
