import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToRecordSessionException extends BaseException {
  constructor(meta?: unknown) {
    super("User is not allowed to record session", meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_RECORD_SESSION;
  }
}
