import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/app";
import { SocketIoProvider } from "./app/contexts/socket-io-context";
import { AuthProvider } from "./app/contexts/media-client-authenticated-user-context";
import { environment } from "./environments/environment";
import { defaultConfigurations } from "./app/common";
import { FeatureFlagProvider } from "@proximie/feature-flag-react-sdk";
import {
  colors,
  DialogProvider,
  EmbeddedClientProvider,
  ErrorBoundary,
  generateTheme,
  GlobalStyles,
  Permissions,
  SnackbarContext,
} from "@proximie/components";
import { NewRelicTrackerInit } from "./app/components/UsageTrackerInit/NewRelicTrackerInit";
import { UsageTrackerProvider } from "@proximie/tracking";
import { LoggerProvider } from "./app/contexts/logger-context";
import { isLocalOrE2EEnvironment } from "@proximie/common";
import { FlavorProvider, useFlavor } from "@proximie/flavorinator";
import { I18nInit } from "@proximie/i18n-init";

const { SnackbarProvider } = SnackbarContext;
const rootHTMLElement = document.getElementById("root");
if (!rootHTMLElement) {
  throw new Error("Root HTML element unfound");
}
const { PermissionProvider } = Permissions;
const root = ReactDOM.createRoot(rootHTMLElement);

const Main = () => {
  const flavorConfig = {
    defaultColors: colors,
    defaultTheme: generateTheme(colors),
    defaultConfigurations,
    baseUrl: environment.assets.flavorUrl,
    isOrigin: isLocalOrE2EEnvironment(environment.name),
    initialFlavor: "default",
  };
  return (
    <EmbeddedClientProvider>
      <PermissionProvider permissions={environment.permissions || []}>
        <FlavorProvider config={flavorConfig}>
          <FlavoredApp />
        </FlavorProvider>
      </PermissionProvider>
    </EmbeddedClientProvider>
  );
};

const FlavoredApp = () => {
  const { customDictionary } = useFlavor();
  return (
    <I18nInit customDictionary={customDictionary}>
      <GlobalStyles>
        <ErrorBoundary>
          <SnackbarProvider>
            <DialogProvider>
              <AuthProvider>
                <UsageTrackerProvider>
                  <SocketIoProvider>
                    <FeatureFlagProvider
                      config={{
                        appName: environment.featureFlag.appName,
                        url: environment.featureFlag.url,
                        environment:
                          environment.name ===
                          environment.featureFlag.environment
                            ? "development"
                            : "production",
                        clientKey: environment.featureFlag.apiKey || "",
                      }}
                    >
                      <LoggerProvider>
                        <NewRelicTrackerInit />
                        <App />
                      </LoggerProvider>
                    </FeatureFlagProvider>
                  </SocketIoProvider>
                </UsageTrackerProvider>
              </AuthProvider>
            </DialogProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </GlobalStyles>
    </I18nInit>
  );
};

root.render(<Main />);
