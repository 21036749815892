import * as t from "io-ts";
import { makeRoute } from "./dataregion-api";
import {
  MediaConnectionDetails,
  GetFeedParamsReponse,
} from "../lib-live-api/live-api-sockets";

const FetchConnectionSettingsRequest = t.type({
  mediaSessionId: t.string,
});

export type FetchConnectionSettingsRequest = t.TypeOf<
  typeof FetchConnectionSettingsRequest
>;

export const fetchConnectionSettings = makeRoute(
  "/streams/:mediaSessionId/connection-settings",
  FetchConnectionSettingsRequest,
  MediaConnectionDetails,
);

const GetFeedParamsRequest = t.type({
  mediaSessionId: t.string,
  //LATER deviceId: t.string,
  //LATER ordersInUse: t.array(t.string),
  feedId: t.union([t.string, t.undefined]),
  mediaType: t.number,
});

export type GetFeedParamsRequest = t.TypeOf<typeof GetFeedParamsRequest>;

export const getFeedParams = makeRoute(
  "/streams/:mediaSessionId/feed-params",
  GetFeedParamsRequest,
  GetFeedParamsReponse,
);
