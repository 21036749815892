import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class PlaybackNotAvailableException extends BaseException {
  constructor(meta?: unknown) {
    super("Playback is not yet available", meta);

    this.code = ExceptionCodes.PLAYBACK_NOT_AVAILABLE;
  }
}
