export type ColorName = keyof typeof colors;

export type Colors = typeof colors & Record<string, string>;

export const colors = {
  // Brand
  BrandPrimary: "#1A237E",
  BrandPrimaryLight: "#474F97",
  BrandPrimaryDark: "#121858",
  BrandSecondary: "#3D5AFE",
  BrandSecondaryLight: "#637BFE",
  BrandSecondaryDark: "#2A3EB1",
  BrandSecondary8: "#3D5AFE14",
  BrandSecondary18: "#3D5AFE2D",
  ProximieBlue: "#474F97",

  // Brand Scale
  Brand50: "#E8EAF6",
  Brand100: "#C5CAE9",
  Brand200: "#9FA8DA",
  Brand300: "#5C6AC0",
  Brand400: "#5C6AC0",
  Brand500: "#3F50B5",
  Brand600: "#3948AB",
  Brand700: "#303E9F",
  Brand800: "#283493",
  Brand900: "#1A237E",

  // Blue
  Blue50: "#EBF1FB",
  Blue100: "#E4EDFB",
  Blue200: "#D0E2FB",
  Blue300: "#B0D1FC",
  Blue400: "#82BDFE",
  Blue500: "#3DA7FE",
  Blue600: "#0B88D8",
  Blue700: "#0669A8",
  Blue800: "#034976",
  Blue900: "#072843",

  // Grey
  Grey50: "#F4F5F5",
  Grey100: "#EDEEF0",
  Grey200: "#EBECEF",
  Grey300: "#D1D5DB",
  Grey400: "#9CA3AF",
  Grey500: "#6B7280",
  Grey600: "#4B5563",
  Grey700: "#374151",
  Grey800: "#1F2937",
  Grey900: "#111827",
  NewGrey950: "#141417",
  Grey1000: "#121212",

  // Red
  Red50: "#FFEBEE",
  NewRed50: "#FDF3F3",
  Red100: "#FFCDD2",
  Red200: "#EF9A9A",
  Red300: "#E57373",
  NewRed300: "#F8A9A9",
  Red400: "#EF5350",
  Red500: "#F44336",
  NewRed500: "#E74C4C",
  Red600: "#E53935",
  NewRed600: "#D32F2F",
  Red700: "#D32F2F",
  NewRed700: "#B82323",
  Red800: "#C62828",
  NewRed800: "#A11212",
  Red900: "#B71C1C",

  // Green
  Green50: "#F3FAF7",
  NewGreen50: "#E6FFEF",
  Green100: "#DEF7EC",
  Green200: "#BCF0DA",
  Green300: "#84E1BC",
  NewGreen300: "#86EFAC",
  Green400: "#31C48D",
  Green500: "#0E9F6E",
  NewGreen500: "#17AB4D",
  Green600: "#057A55",
  NewGreen600: "#11833B",
  Green700: "#046C4E",
  NewGreen700: "#117436",
  Green800: "#03543F",
  NewGreen800: "#0C6930",
  Green900: "#014737",

  // Amber
  Amber50: "#FFF7EE",
  NewAmber50: "#FFFFEA",
  Amber100: "#FFEBD5",
  Amber200: "#FFD6A4",
  Amber300: "#FFC879",
  NewAmber300: "#FFD779",
  Amber400: "#FFB800",
  NewAmber400: "#FFC300",
  Amber500: "#E5A500",
  NewAmber500: "#E5A800",
  Amber600: "#956F00",
  NewAmber600: "#946601",
  Amber700: "#7C5800",
  NewAmber700: "#855400",
  Amber800: "#5E4200",
  Amber900: "#412D00",

  // White
  White: "#FFFFFF",
  WhiteDisabled: "#FFFFFF60",
  WhiteHighEmphasis: "#FEFEFFDD",
  WhiteMediumEmphasis: "#FFFFFFB2",

  WhiteA0: "#FFFFFF00",
  WhiteA50: "#FFFFFF0A",
  WhiteA100: "#FFFFFF14",
  WhiteA200: "#FFFFFF26",
  WhiteA300: "#FFFFFF4D",
  WhiteA400: "#FFFFFF63",
  WhiteA500: "#FFFFFF96",
  WhiteA600: "#FFFFFFA3",
  WhiteA700: "#FFFFFFB2",
  WhiteA800: "#FFFFFFC7",
  WhiteA900: "#FFFFFFDE",
  WhiteA950: "#FFFFFFDE",

  // Black
  Black: "#000000",
  BlackDisabled: "#00000060",
  BlackMediumEmphasis: "#00000099",
  BlackInactive: "#00000089",
  BlackLowEmphasis: "#0000001E",
  BlackHighEmphasis: "#000000DD",

  BlackA0: "#00000000",
  BlackA50: "#1134460A",
  BlackA100: "#0E1F3914",
  BlackA200: "#17253B26",
  BlackA300: "#0E182A4D",
  BlackA400: "#070D1D63",
  BlackA500: "#05091596",
  BlackA600: "#050915A3",
  BlackA700: "#03050CB2",
  BlackA800: "#03050CC7",
  BlackA900: "#04050CDE",
  BlackA950: "#030307F2",

  // Hover
  BrandPrimaryHover: "#1a237e0a",
};
