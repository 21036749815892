import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface UserNotFoundExceptionMeta {
  userId: number;
}
export class UserNotFoundException extends BaseException {
  constructor(message = "User not found", meta?: UserNotFoundExceptionMeta) {
    super(message, meta);

    this.code = ExceptionCodes.USER_NOT_FOUND;
  }
}
