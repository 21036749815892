import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  FormControlLabel,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "../../index";

type DeleteOrganisationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
};

export const DeleteOrganisationDialog = (
  props: DeleteOrganisationDialogProps,
) => {
  const [isChecked, setIsChecked] = useState(false);
  const [step, setStep] = useState(1);
  const { colors } = useTheme();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleNextStep = () => {
    setStep(2);
    setIsChecked(false);
  };

  const handleConfirmAndDelete = () => {
    props.onConfirm();
    handleClose();
  };

  const handleClose = () => {
    setStep(1);
    setIsChecked(false);
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth="xs">
      <DialogTitle>
        <Typography variant="h6" weight="semiBold" style={{ marginBottom: 8 }}>
          Delete organization
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {step === 1 && (
          <>
            <Typography
              variant="body1"
              color="BlackA500"
              style={{ marginBottom: 12 }}
            >
              Step 1: Confirm deletion
            </Typography>
            <DialogContentText style={{ marginBottom: 8 }} color="Grey950">
              Warning: All live and scheduled sessions will end, and all users
              will be blocked from accessing this organization.
            </DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              }
              label="I confirm I want to delete this organization."
              sx={{ color: colors.BlackA700, marginLeft: 1 }}
            />
          </>
        )}

        {step === 2 && (
          <>
            <Typography
              variant="body1"
              style={{ marginBottom: 12 }}
              color="BlackA500"
            >
              Step 2: Finalize deletion
            </Typography>
            <DialogContentText style={{ marginBottom: 8 }} color="Grey950">
              Important: To permanently delete your organization’s data, you
              must submit a support request. This can be done via the support
              form or directly in Salesforce.
            </DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              }
              label="I understand and will submit a support request immediately."
              sx={{ color: colors.BlackA700, marginLeft: 1 }}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        {step === 1 ? (
          <>
            <Button
              onClick={handleNextStep}
              variant="danger"
              disabled={!isChecked}
            >
              Confirm and continue
            </Button>
            <Button onClick={handleClose} variant="secondary">
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={handleConfirmAndDelete}
              variant="danger"
              disabled={!isChecked}
            >
              Delete organization
            </Button>
            <Button onClick={handleClose} variant="secondary">
              Cancel
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrganisationDialog;
