import { EndpointType } from "../Endpoint";
import EndpointJanus, { CreateOptions } from "./EndpointJanus";
import { ENDPOINT_CONFIG } from "../../../Constants";

export default class EndpointJanusAudio extends EndpointJanus {
  override endpointType = EndpointType.Audio;

  protected pluginName = "janus.plugin.audiobridge";
  protected createOptions: CreateOptions = {
    plugin: this.pluginName,
    ["audiolevel_event"]: ENDPOINT_CONFIG.audio.audiolevelEvent,
    ["audio_active_packets"]: ENDPOINT_CONFIG.audio.audioActivePackets,
    ["audio_level_average"]: ENDPOINT_CONFIG.audio.audioLevelAverage,
    audiocodec: ENDPOINT_CONFIG.audio.codec,
    description: `${this.mediaSessionId}`,
    record: ENDPOINT_CONFIG.audio.isRecordingEnabled,
    ["record_dir"]: `${ENDPOINT_CONFIG.audio.recordingDirectory}/${this.mediaSessionId}`,
    ["sampling_rate"]: ENDPOINT_CONFIG.audio.samplingRate,
    ["spatial_audio"]: ENDPOINT_CONFIG.audio.spatialAudio,
    publishers: ENDPOINT_CONFIG.audio.maxPublishers,
  };
  // room already created error
  protected ignoreErrors = [486];
}
