import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { SessionParticipant, SessionParticipants } from "./SessionParticipants";
import { augmentObjectWithSequentialID } from "../common/augmentObjectWithLegacyID";
import { useEffect } from "react";
import { UseSocketIoContext } from "../../contexts/socket-io-context";
import { liveApiSockets } from "@proximie/dataregion-api";

const getSessionParticipants: (
  sessionId: string,
) => () => Promise<SessionParticipant[]> = (sessionId: string) => async () => {
  const participants = await axios.get<SessionParticipants>(
    `${environment.apiUrl}/api/v2/sessions/${sessionId}/participants`,
  );
  return await augmentObjectWithSequentialID(participants.data.participants);
};

export const useSessionParticipants = (
  sessionId: string | undefined,
  opts?: SWRConfiguration,
) => {
  const { data, mutate, error } = useSWR<SessionParticipant[]>(
    sessionId ? `session-${sessionId}-participant` : null,
    getSessionParticipants(sessionId || ""),
    opts,
  );

  const socketIo = UseSocketIoContext();

  useEffect(() => {
    socketIo.on(liveApiSockets.MediaSessionEventType.participant_added, mutate);
    return () => {
      socketIo.off(
        liveApiSockets.MediaSessionEventType.participant_added,
        mutate,
      );
    };
  }, [socketIo, mutate]);

  return {
    sessionParticipants: data,
    areSessionParticipantsLoading: !error && !data,
    sessionParticipantsError: error,
  };
};
