import Device, { DeviceOptions } from "./Device";

export interface DeviceLocalOptions extends DeviceOptions {
  deviceInfo: MediaDeviceInfo;
}

export default abstract class DeviceLocal extends Device {
  constructor(deviceId: string, public options: DeviceLocalOptions) {
    super(deviceId, options);
    this.label = options.deviceInfo.label;
  }
}
