/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConnectionOptions, ConnectionType, Direction } from "../Connection";
import ConnectionJanus from "./ConnectionJanus";
import Endpoint, { EndpointType } from "../../Endpoints/Endpoint";
import { JanusJS } from "@proximie/janus-gateway";
import { PrxAudiobridgeEvents, PrxMessage } from "./message.d";
import DeviceLocalMicrophone from "../../Devices/DeviceLocalMicrophone";
import DeviceLocalSpeaker from "../../Devices/DeviceLocalSpeaker";
import AudioMixer from "../../../AudioMixer";
import WebRTCUtil from "../../../WebRTCUtil";
import AudioParticipant, {
  AudioParticipants,
} from "../../../models/AudioParticipant";
import { decodeStreamId } from "../../../MediaUtil";
import { ConnectionMetadata } from "@proximie/common";
import Device from "../../Devices/Device";

export interface ConnectionJanusAudioOptions extends ConnectionOptions {
  hasAudioMixer: boolean;
}

interface JanusAudioParticipant {
  id: string;
  display: string;
  setup: boolean;
  muted: boolean;
  talking?: boolean;
}

export default class ConnectionJanusAudio extends ConnectionJanus {
  override connectionType = ConnectionType.JanusAudio;
  override direction = Direction.Both;
  protected audioPlaybackElement: HTMLAudioElement = new Audio();
  protected audioPlaybackSpeakerDeviceId: string | null = null;
  protected audioPlaybackAudioVolume = 100;
  private participantsList: AudioParticipants = {};
  //eslint-disable-next-line @typescript-eslint/ban-types
  protected parseParticipantsList: Function = this.myParseParticipantsList;
  protected override createRoom = true;
  private audioCaptureMicDeviceId: string | null = null;
  // Note, audiomixer cannot auto start without user "gesture" due to autoplay policy by chrome: https://developer.chrome.com/blog/autoplay/#webaudio
  // User will need to either add second microphone, second speaker or enable local playback to enable audiomixer.
  private audioMixer: AudioMixer | null = null;
  private remoteMediaStream: MediaStream | null = null;
  private enableEchoCancellation = false;
  private enableNoiseSuppression = false;
  private enableAutoGainControl = false;
  private enableLocalPlayback = false;
  private selectedSpeakers: DeviceLocalSpeaker[] = [];
  private selectedMics: DeviceLocalMicrophone[] = [];

  protected attachOptions: JanusJS.PluginOptions = {
    plugin: "OVERWRITE_ME",
    opaqueId: this.endpoints[EndpointType.Audio]?.profileId.toString() || "",
    ...this.defaultAttachCallbacks,
    onmessage: this.onMessage.bind(this),
    onlocalstream: this.onLocalStream.bind(this),
    onremotestream: this.onRemoteStream.bind(this),
    mediaState: this.mediaState.bind(this),
  };

  constructor(
    endpoint: Endpoint,
    device: Device,
    public streamId: string,
    public options: ConnectionJanusAudioOptions,
  ) {
    super(endpoint, device, streamId, options);
  }

  override close(error?: Error): Promise<void> {
    this.audioMixer?.release();
    this.audioMixer = null;
    return super.close(error);
  }

  private get noAudioMixerMode(): boolean {
    return !this.options.hasAudioMixer;
  }

  private mediaState(media: "audio" | "video", state: boolean): void {
    console.debug("Audio:mediaState", media, state);
    if (
      state &&
      this.participantsList[this.streamId] &&
      this.participantsList[this.streamId].isMuted
    )
      this.setLocalMuteState(this.participantsList[this.streamId].isMuted);
  }

  private attachToAudioElement(stream: MediaStream): void {
    /**
     * Clear memory before reassigning src
     */
    try {
      this.audioPlaybackElement.pause();
      this.audioPlaybackElement.removeAttribute("src");
      this.audioPlaybackElement.load();
    } catch (error) {
      console.warn(
        { streamId: this.streamId },
        "Audio:onRemoteStream: Clearing Audio memory - error=",
        error,
      );
    }

    /**
     * Add new stream to audio element
     */
    this.audioPlaybackElement.autoplay = true;
    this.audioPlaybackElement.muted = false;
    this.audioPlaybackElement.srcObject = stream;
    this.setAudioVolume(this.audioPlaybackAudioVolume);
    if (this.audioPlaybackSpeakerDeviceId)
      this.setSpeaker(this.audioPlaybackSpeakerDeviceId);

    try {
      this.audioPlaybackElement.load();
    } catch (error) {
      console.error({ streamId: this.streamId }, error);
    }
  }

  protected override onRemoteStream(stream: MediaStream): void {
    super.onRemoteStream(stream);
    if (this.noAudioMixerMode) {
      this.attachToAudioElement(stream);
    } else {
      this.remoteMediaStream = stream;
      if (this.audioMixer) {
        this.audioMixer.addRemoteStream(stream);
      } else {
        this.attachToAudioElement(stream);
      }
    }
    setTimeout(() => {
      this.requestParticipants();
    }, 1000);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private onMessage(message: PrxMessage, jsep?: JanusJS.JSEP): void {
    console.debug(
      { streamId: this.streamId },
      "Audio:onMessage: message=",
      message,
      jsep,
    );

    if (jsep) {
      this.handle?.handleRemoteJsep({
        jsep,
      });
    }

    const event = message.audiobridge;
    if (message.error) {
      console.warn(
        { streamId: this.streamId },
        "Audio:onMessage: error=",
        message.error,
      );
    } else if (event === PrxAudiobridgeEvents.Joined) {
      if (message.participants) {
        this.parseParticipantsList(message.participants);
      }
    } else if (event === PrxAudiobridgeEvents.Destroyed) {
      console.warn(
        { streamId: this.streamId },
        "Audio:onMessage: Room destroyed",
      );
      this.close();
    } else if (event === PrxAudiobridgeEvents.Talking) {
      const id = message.id;
      if (id) {
        this.participantsList[id].isActive = true;
        this.emit("participantisactive", id, true);
      }
    } else if (event === PrxAudiobridgeEvents.StoppedTalking) {
      const id = message.id;
      if (id) {
        this.participantsList[id].isActive = false;
        this.emit("participantisactive", id, false);
      }
    } else if (event === PrxAudiobridgeEvents.Event) {
      if (message.result === "ok") {
        this.requestParticipants();
      } else if (message.participants) {
        this.parseParticipantsList(message.participants);
      } else if (message.leaving) {
        const id = message.leaving;
        console.debug(
          { streamId: this.streamId },
          "Audio:onMessage: Participant left=",
          id,
        );
        delete this.participantsList[id];
        this.emit("participantleft", id);
      }
    } else {
      console.warn(
        { streamId: this.streamId },
        "Audio:onMessage: Unknown message=",
        message,
      );
    }
  }

  protected override join(params: ConnectionMetadata): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      console.debug(
        {
          streamId: this.streamId,
        },
        "ConnectionJanusAudio:join",
        params,
      );

      const register = {
        request: "join",
        room: this.mediaSessionId,
        ptype: "publisher",
        id: this.streamId,
        display: JSON.stringify(params),
      };

      this.handle?.send({
        message: register,
        success: resolve,
        error: (error: string) => reject(new Error(error)),
      } as unknown as JanusJS.PluginMessage);
    });
  }

  override async open(): Promise<void> {
    const endpoint = this.endpoints[EndpointType.Audio];
    if (!endpoint) {
      throw new Error("No audio endpoint");
    }
    return this.startJanus(endpoint);
  }

  override send(stream: MediaStream): Promise<void> {
    console.log(
      { streamId: this.streamId },
      "Audio:send - stream=",
      stream,
      "pc=",
      this.pc,
    );
    if (this.pc?.connectionState === "connected") {
      return this.changeAudio(stream);
    } else {
      return this.publishAudio(stream);
    }
  }

  private publishAudio(stream: MediaStream): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.handle?.createOffer({
        stream,
        media: { audio: true, video: false },
        success: (jsep: JanusJS.JSEP | null) => {
          this.emit("connecting");
          const publish = {
            request: "configure",
            audio: true,
            video: false,
            muted: true,
          };
          if (jsep) this.handle?.send({ message: publish, jsep });
          resolve();
        },
        error: (error: any) => {
          console.warn(
            { streamId: this.streamId },
            "Audio:publish - createOffer failed - error=",
            error,
          );
          reject(new Error(error));
        },
      });
    });
  }

  private async changeAudio(stream: MediaStream): Promise<void> {
    const [track] = stream.getTracks();
    // preserve the muted state across mic changes
    track.enabled = !this.participantsList[this.streamId].isMuted;

    const [sender] = (
      this.handle?.webrtcStuff.pc as unknown as RTCPeerConnection
    ).getSenders();

    await sender.replaceTrack(track);

    this.emit("updated", stream);
  }

  setAudioVolume(percent: number): void {
    if (percent < 0 || percent > 100) {
      throw new Error("Volume out of range");
    }
    if (this.noAudioMixerMode || !this.audioMixer) {
      this.audioPlaybackElement.volume = percent / 100;
    } else {
      this.audioMixer.setSpeakerVolume(percent);
    }
  }

  private setLocalMuteState(muted: boolean): Promise<void> {
    console.debug(
      { streamId: this.streamId },
      "Audio: setLocalMuteState - muted=",
      muted,
    );
    if (!this.noAudioMixerMode) {
      this.audioMixer?.setMicMute(muted);
    }
    return new Promise((resolve, reject) => {
      if (this.participantsList[this.streamId]) {
        // Cannot use this.handle?.muteAudio() since this does not work across replaceTracks()
        const [sender] = (this.handle as any).webrtcStuff.pc.getSenders();
        sender.track.enabled = !muted;

        this.participantsList[this.streamId].isMuted = muted;
        this.emit("ismuted", muted);

        this.handle?.send({
          message: { request: "configure", muted },
          success: () => {
            this.emitParticipantsList();
            resolve();
          },
          error: (error: any) => {
            reject(new Error(error));
          },
        } as unknown as JanusJS.PluginMessage);
      }
    });
  }

  private setRemoteMuteStateByStreamId(
    streamId: string,
    muted: boolean,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.handle?.send({
        message: {
          request: muted ? "mute" : "unmute",
          room: this.endpoints[EndpointType.Audio]?.mediaSessionId || "",
          id: streamId,
        },
        success: () => {
          resolve();
        },
        error: (error: any) => {
          reject(new Error(error));
        },
      } as unknown as JanusJS.PluginMessage);
    });
  }

  async setMuteState(streamId: string, muted: boolean): Promise<void> {
    if (!this.participantsList[streamId]) {
      // do nothing
    } else if (this.participantsList[streamId].isLocal) {
      await this.setLocalMuteState(muted);
    } else {
      await this.setRemoteMuteStateByStreamId(streamId, muted);
    }
  }

  async muteAll(): Promise<void> {
    await Promise.all(
      Object.keys(this.participantsList).reduce((acc, myStreamId) => {
        if (
          myStreamId !== this.streamId &&
          !this.participantsList[myStreamId].isMuted
        ) {
          return [...acc, this.setRemoteMuteStateByStreamId(myStreamId, true)];
        }
        return acc;
      }, [] as Promise<void>[]),
    );
  }

  async setMic(deviceId: string): Promise<void> {
    this.audioCaptureMicDeviceId = deviceId;
    const stream = await WebRTCUtil.GetAudioMedia(deviceId);
    await this.send(stream);
  }

  async setMics(mics: DeviceLocalMicrophone[]): Promise<void> {
    if (mics.length === 0) {
      throw new Error("No mics given");
    }
    if (this.noAudioMixerMode) {
      await this.setMic(mics[0].deviceId);
    } else {
      this.selectedMics = mics;
      await this.setAudioMixerMode(
        this.audioPlaybackSpeakerDeviceId,
        mics[0].deviceId,
      );
      if (this.audioMixer) {
        await this.audioMixer.processSelectedMics(
          mics,
          this.participantsList[this.streamId].isMuted,
        );
        await this.sendLocalStream();
      } else {
        // Only one mic selected, no need for mixer mode
        await this.setMic(mics[0].deviceId);
      }
    }
  }

  setSpeaker(deviceId: string): void {
    this.audioPlaybackSpeakerDeviceId = deviceId;
    if (this.noAudioMixerMode || !this.audioMixer) {
      try {
        // Experimental functionality, use of any is due to it not existing in @types
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
        if (this.audioPlaybackSpeakerDeviceId !== null) {
          WebRTCUtil.AttachAudioElementToOutput(
            this.audioPlaybackElement,
            this.audioPlaybackSpeakerDeviceId,
          );
        }
      } catch (error) {
        console.warn(
          { streamId: this.streamId },
          "Audio:setSpeaker - failed - error=",
          error,
        );
        throw new Error(error);
      }
    }
  }

  async setSpeakers(speakers: DeviceLocalSpeaker[]): Promise<void> {
    if (speakers.length === 0) {
      throw new Error("No speakers given");
    }
    if (this.noAudioMixerMode) {
      this.setSpeaker(speakers[0].deviceId);
    } else {
      this.selectedSpeakers = speakers;
      await this.setAudioMixerMode(
        speakers[0].deviceId,
        this.audioCaptureMicDeviceId,
      );
      if (this.audioMixer) {
        await this.audioMixer.processSelectedSpeakers(speakers);
      } else {
        // Only one speaker selected, no need for mixer mode
        this.setSpeaker(speakers[0].deviceId);
      }
    }
  }

  setVolume(value: number): void {
    this.audioPlaybackAudioVolume = value;

    try {
      this.setAudioVolume(this.audioPlaybackAudioVolume);
    } catch (error) {
      console.warn(
        { streamId: this.streamId },
        "Audio:setVolume - failed - error=",
        error,
      );
      throw new Error(error);
    }
  }

  private toAudioParticipant(
    participant: JanusAudioParticipant,
  ): AudioParticipant {
    const { profileId } = decodeStreamId(participant.id);

    let metadata: ConnectionMetadata;
    try {
      metadata = JSON.parse(participant.display);
    } catch {
      // ignore all errors for backwards compatibility
      metadata = {};
    }

    return {
      userId: profileId,
      streamId: participant.id,
      userUUID: metadata.userId ?? metadata.userUUID,
      isConnected: participant.setup,
      isMuted: participant.muted,
      isActive: participant.talking,
      isLocal: participant.id === this.streamId,
      metadata,
    } as AudioParticipant;
  }

  private myParseParticipantsList(participants: JanusAudioParticipant[]): void {
    participants.forEach((participant: JanusAudioParticipant) => {
      this.participantsList[participant.id] =
        this.toAudioParticipant(participant);
    });
    this.emitParticipantsList();
  }

  // maybe have a DeviceAudio for passing this lot around....
  private emitParticipantsList(): void {
    console.debug("Audio participants=", {
      ...this.participantsList,
    });

    this.emit("participants", { ...this.participantsList });
  }

  async setEchoCancellation(enable: boolean): Promise<void> {
    this.enableEchoCancellation = enable;
    await this.audioMixer?.setEchoCancellation(enable);
  }

  async setNoiseSuppression(enable: boolean): Promise<void> {
    this.enableNoiseSuppression = enable;
    await this.audioMixer?.setNoiseSuppression(enable);
  }

  async setAutoGainControl(enable: boolean): Promise<void> {
    this.enableAutoGainControl = enable;
    await this.audioMixer?.setAutoGainControl(enable);
  }

  async setLocalPlayback(enable: boolean): Promise<void> {
    if (!this.noAudioMixerMode) {
      this.enableLocalPlayback = enable;
      await this.setAudioMixerMode(
        this.audioPlaybackSpeakerDeviceId,
        this.audioCaptureMicDeviceId,
      );
      await this.audioMixer?.setLocalPlayback(enable);
    }
  }

  private async sendLocalStream(): Promise<void> {
    // Possible mixer got released while waiting
    if (this.audioMixer) {
      await this.send(this.audioMixer.getLocalAudioStream());
    } else {
      console.warn(
        `[AudioMixer] AudioMixer was released before we could send its local output!`,
      );
    }
  }

  private async createAudioMixer(): Promise<void> {
    console.info(`[AudioMixer] Enabling AudioMixer`);
    this.audioMixer = new AudioMixer(
      this.selectedMics,
      this.selectedSpeakers,
      this.remoteMediaStream,
      this.participantsList[this.streamId].isMuted,
      this.audioPlaybackAudioVolume,
      {
        enableEchoCancellation: this.enableEchoCancellation,
        enableNoiseSuppression: this.enableNoiseSuppression,
        enableAutoGainControl: this.enableAutoGainControl,
        enableLocalPlayback: this.enableLocalPlayback,
      },
    );
    await this.audioMixer.resetAudioMixer();
    await this.sendLocalStream();
  }

  private async destroyAudioMixer(
    speakerDeviceId: string | null,
    micDeviceId: string | null,
  ): Promise<void> {
    console.info(`[AudioMixer] Disabling AudioMixer`);
    this.audioMixer?.release();
    this.audioMixer = null;
    // Reset webrtc side to stream from/to selected devices
    if (speakerDeviceId) {
      this.setSpeaker(speakerDeviceId);
    }
    if (micDeviceId) {
      await this.setMic(micDeviceId);
    }
  }

  private async setAudioMixerMode(
    speakerDeviceId: string | null,
    micDeviceId: string | null,
  ): Promise<void> {
    if (
      this.selectedSpeakers.length > 1 ||
      this.selectedMics.length > 1 ||
      this.enableLocalPlayback
    ) {
      // AudioMixer mode
      if (!this.audioMixer) {
        await this.createAudioMixer();
      }
    } else if (this.audioMixer) {
      // Single speaker/mic mode
      await this.destroyAudioMixer(speakerDeviceId, micDeviceId);
    }
  }
}
