import { LeafMonitor } from "../Monitor";

export type UDPReport = {
  online: boolean;
};

export class UDPMonitor extends LeafMonitor<UDPReport> {
  constructor() {
    super({});
  }

  run() {
    return undefined;
  }
}
