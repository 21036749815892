// Overriding console functions is not a best practice for logging
// Will be replaced with a proper client logging approach

import { LogLevel, Logger, LogProps } from "@proximie/common";

const originalError = console.error;
const originalInfo = console.info;
const originalWarn = console.warn;
const originalDebug = console.debug;
// So far we skip console.log to reduce noise

export default function overrideLogger(logger: Logger): void {
  console.error = (...args) => {
    const logProps = generateLogProps(...args);

    if (logProps.error) {
      const index = args.findIndex(
        (arg: unknown): boolean => arg instanceof Error,
      );
      // remove error from args
      const cloned = args.slice();
      cloned.splice(index, 1);

      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).newrelic?.noticeError(args[index], ...cloned);
    } else {
      const error = new Error(logProps.message);
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).newrelic?.noticeError(error, ...args);
    }

    logger.error(logProps);

    // To see the original file which called this console method in your browser console
    // Blackbox overrideLogger in your browser
    // How to: https://developers.google.com/web/tools/chrome-devtools/javascript/reference#settings-blackbox
    originalError(LogLevel.ERROR, ...args);
  };

  /// Log warnings via overriding console.warn
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  console.warn = (...args) => {
    const logProps = generateLogProps(...args);

    logger.warn(logProps);

    // To see the original file which called this console method in your browser console
    // Blackbox overrideLogger in your browser
    // How to: https://developers.google.com/web/tools/chrome-devtools/javascript/reference#settings-blackbox
    originalWarn(LogLevel.WARN, ...args);
  };

  /// Log just text via overriding console.info
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  console.info = (...args) => {
    const logProps = generateLogProps(...args);

    logger.info(logProps);

    // To see the original file which called this console method in your browser console
    // Blackbox overrideLogger in your browser
    // How to: https://developers.google.com/web/tools/chrome-devtools/javascript/reference#settings-blackbox
    originalInfo(LogLevel.INFO, ...args);
  };

  /// Log debug via overriding console.debug
  console.debug = (...args) => {
    const logProps = generateLogProps(...args);

    logger.info(logProps);

    // To see the original file which called this console method in your browser console
    // Blackbox overrideLogger in your browser
    // How to: https://developers.google.com/web/tools/chrome-devtools/javascript/reference#settings-blackbox
    originalDebug(LogLevel.INFO, ...args);
  };
}

function parseError(error: Error): Record<string, unknown> {
  const parsedError = {};
  Object.getOwnPropertyNames(error).forEach((key: string): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    parsedError[key] = error[key];
  }, error);
  return parsedError;
}

function isSimpleObject(arg: unknown): boolean {
  return typeof arg === "object" && !(arg instanceof Error);
}

function generateLogProps(...args: any): LogProps {
  // the rules are:
  // if the first argument is a (non-Error) object then they are considered to be attributes
  // a single Error can be anywhere in the arguments and added appropriately
  // the first string in the args will be the message
  // all other arguments will be added to the arguments
  const props: LogProps = {
    message: "",
    localTime: new Date().toISOString() as string,
  };

  if (isSimpleObject(args[0])) {
    props.attributes = args[0];
    args.shift();
  }

  args.forEach((arg: any): void => {
    if (arg instanceof Error && !props.error) {
      props.error = parseError(arg);
    } else if (typeof arg === "string" && !props.message) {
      props.message = arg;
    } else {
      if (!props.arguments) {
        props.arguments = [];
      }
      props.arguments.push(arg);
    }
  });

  if (props.error) {
    props.stackTrace = props.error.stack as string;
    delete props.error.stack;
    if (!props.message) {
      props.message = props.error.message as string;
    }
  } else {
    const stack = new Error().stack?.split("\n");
    stack?.splice(0, 3); // Exclude "Error:" and this file from stack
    props.stackTrace = stack?.join("\n");
  }

  if (!props.message) {
    props.message = "N/A";
  }

  return props;
}
