import { Logger, LogLevel, LogProps, MetaData } from "./common";

export type ConsoleLoggerProps = MetaData;

export const createConsoleLogger = (
  loggerProps: ConsoleLoggerProps,
): Logger => ({
  debug: (props: LogProps) =>
    console.debug({ level: LogLevel.INFO, ...loggerProps, ...props }),

  info: (props: LogProps) =>
    console.info({ level: LogLevel.INFO, ...loggerProps, ...props }),

  warn: (props: LogProps) =>
    console.warn({ level: LogLevel.WARN, ...loggerProps, ...props }),

  error: (props: LogProps) =>
    console.error({ level: LogLevel.ERROR, ...loggerProps, ...props }),
});
