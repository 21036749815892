import React, { FC } from "react";
import { AvatarStyled } from "./LetterAvatar.style";

export interface LetterAvatarProps {
  value?: string;
  dataCy?: string;
}

const LetterAvatar: FC<LetterAvatarProps> = (
  props: LetterAvatarProps,
): JSX.Element => {
  const title = props.value || "_";
  return (
    <AvatarStyled
      data-testid="profile-avatar"
      letter={title.charAt(0)}
      data-cy={props.dataCy}
    >
      {title.charAt(0)}
    </AvatarStyled>
  );
};

export default LetterAvatar;
