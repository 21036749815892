import {
  OutgoingVideo,
  FeedType,
  RectMask,
  ElipMask,
  MediaUtil,
} from "@proximie/media";

const LOCAL_STORAGE_VIDEO_KEY = "videos";

// {
// 	[userId]:{
// 		lastSessionId: sessionId,
// 		sessions:{
// 			[sessionId]:[
// 				video1,
// 				video2
// 			]
// 		}
// 	}
// }

// in localStorage we index the StoreInfo object by mediaSessionId.  We do not allow
// more than one member in this object to prevent any memory leaks
export type StoreInfo = Record<string, SessionInfo>;
export type SessionInfo = {
  lastSessionId: string;
  sessions: Record<string, VideoInfo[]>;
};
export type VideoInfo = {
  mediaSessionId: string;
  type: FeedType;
  streamId: string;
  order: string;
  deviceId: string;
  uniqueId: string;
  label: string;
  masks?: Array<RectMask | ElipMask>;
};

export default abstract class VideoStore {
  //LATER - should we ever store the video feeds in the backend?

  private static write(
    mediaSessionId: string,
    profileId: number,
    info: VideoInfo[],
  ): void {
    const sessionStore = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_VIDEO_KEY) ?? "{}",
    );

    //initialize user if empty
    if (!sessionStore[profileId])
      sessionStore[profileId] = {
        lastSessionId: mediaSessionId,
        sessions: {},
      };

    //apply new info
    sessionStore[profileId].sessions[mediaSessionId] = info;
    sessionStore[profileId].lastSessionId = mediaSessionId;

    //save to storage
    localStorage.setItem(LOCAL_STORAGE_VIDEO_KEY, JSON.stringify(sessionStore));
    console.log(
      "VideoStore set=",
      localStorage.getItem(LOCAL_STORAGE_VIDEO_KEY),
    ); //TODO - remove me
  }

  private static addMissingFields(videos?: VideoInfo[]): VideoInfo[] {
    // for backwards compatibility we set uniqueId to deviceId if it doesn't exist,
    // and generate order if it doesn't exist
    //LATER - remove me!
    return (videos ?? []).map((video: VideoInfo): VideoInfo => {
      if (!video.uniqueId) {
        video.uniqueId = video.deviceId;
      }
      if (!video.order) {
        video.order = MediaUtil.generateOrderFromStreamId(video.streamId);
      }
      return video;
    });
  }

  static get(mediaSessionId: string, profileId: number): VideoInfo[] {
    let sessionStore: StoreInfo;
    try {
      const item = localStorage.getItem(LOCAL_STORAGE_VIDEO_KEY) || "{}";
      console.log("VideoStore get=", item); //TODO - remove me
      sessionStore = JSON.parse(item);
    } catch {
      return [];
    }

    if (!sessionStore[profileId]) return [];

    return VideoStore.addMissingFields(
      sessionStore[profileId].sessions[mediaSessionId],
    );
  }

  static getByProfileId(profileId: number): SessionInfo {
    let sessionStore: StoreInfo;
    try {
      const item = localStorage.getItem(LOCAL_STORAGE_VIDEO_KEY) || "{}";
      sessionStore = JSON.parse(item);
    } catch {
      return {
        lastSessionId: "",
        sessions: {},
      };
    }

    if (!sessionStore[profileId]) {
      return {
        lastSessionId: "",
        sessions: {},
      };
    } else {
      Object.values(sessionStore[profileId].sessions).forEach(
        (videos: VideoInfo[]): void => {
          VideoStore.addMissingFields(videos);
        },
      );
      return sessionStore[profileId];
    }
  }

  static add(
    mediaSessionId: string,
    profileId: number,
    connection: OutgoingVideo,
    deviceId: string,
    uniqueId: string,
    label: string,
    masks?: Array<RectMask | ElipMask>,
  ): void {
    const videos = this.get(mediaSessionId, profileId).filter(
      (video: VideoInfo): boolean => video.streamId !== connection.streamId,
    );

    videos.push({
      mediaSessionId,
      streamId: connection.streamId,
      order: connection.order,
      type: connection.type,
      deviceId,
      uniqueId,
      label,
      masks,
    });
    this.write(mediaSessionId, profileId, videos);
  }

  static remove(
    mediaSessionId: string,
    profileId: number,
    streamId: string,
  ): void {
    const videos = this.get(mediaSessionId, profileId).filter(
      (video: VideoInfo): boolean => video.streamId !== streamId,
    );

    this.write(mediaSessionId, profileId, videos);
  }

  static setMasks(
    mediaSessionId: string,
    profileId: number,
    streamId: string,
    masks: Array<RectMask | ElipMask>,
  ): void {
    const videos = this.get(mediaSessionId, profileId).map(
      (video: VideoInfo): VideoInfo =>
        video.streamId === streamId ? { ...video, masks } : video,
    );

    this.write(mediaSessionId, profileId, videos);
  }

  static clearMasks(
    mediaSessionId: string,
    profileId: number,
    streamId: string,
  ): void {
    const videos = this.get(mediaSessionId, profileId).map(
      (video: VideoInfo): VideoInfo =>
        video.streamId === streamId ? { ...video, masks: undefined } : video,
    );

    this.write(mediaSessionId, profileId, videos);
  }
}
