import { LeafMonitor } from "../Monitor";

export type BrowserReport = {
  online: boolean;
  bandwidthEstimation: number;
  effectiveType: string;
  rtt: number;
  isReducedDataUsageActive: boolean;
};

type NetworkInformation = {
  downlink: number;
  effectiveType: string;
  rtt: number;
  saveData: boolean;
};

export class BrowserMonitor extends LeafMonitor<BrowserReport> {
  constructor() {
    super({});
  }

  run() {
    const networkInfo: NetworkInformation = (window.navigator as any)[
      "connection"
    ];

    return {
      online: window.navigator.onLine,
      bandwidthEstimation: networkInfo?.downlink,
      effectiveType: networkInfo?.effectiveType,
      rtt: networkInfo?.rtt,
      isReducedDataUsageActive: networkInfo?.saveData,
    };
  }
}
