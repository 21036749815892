import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  RecordingContext,
  RecordingStateStatus,
  useRecording as useRecordingHook,
} from "../../contexts/recording-context/recording-context";
import RecordingIndicator from "../RecordingIndicator/RecordingIndicator";

export interface RecordingStatusProps {
  useRecording?: () => RecordingContext;
}

const RecordingStatus: FC<RecordingStatusProps> = ({
  useRecording = useRecordingHook,
}) => {
  const { status } = useRecording();
  const isRecordingStarted =
    status === RecordingStateStatus.Recording ||
    status === RecordingStateStatus.Paused;
  const isRecordingActive = status === RecordingStateStatus.Recording;

  if (isRecordingStarted) {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <RecordingIndicator isActive={isRecordingActive} />
        <Typography variant="body2" component={"span"} sx={{ ml: 2 }}>
          {isRecordingActive ? "Recording in progress" : "Recording paused"}
        </Typography>
      </Box>
    );
  }

  // RecordingStateStatus.NotStarted
  return null;
};

export default RecordingStatus;
