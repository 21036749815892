import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
import { ReactComponent as Contact } from "../../assets/icons/contact.svg";
import { ReactComponent as Contract } from "../../assets/icons/contract.svg";
import { ReactComponent as Document } from "../../assets/icons/document.svg";
import { ReactComponent as Expand } from "../../assets/icons/expand.svg";
import { ReactComponent as Eraser } from "../../assets/icons/eraser.svg";
import { ReactComponent as Hamburger } from "../../assets/icons/hamburger.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Library } from "../../assets/icons/library.svg";
import { ReactComponent as Like } from "../../assets/icons/like.svg";
import { ReactComponent as LikeInactive } from "../../assets/icons/like_inactive.svg";
import { ReactComponent as Logout } from "../../assets/icons/log_out.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as MicActive } from "../../assets/icons/mic_active.svg";
import { ReactComponent as MicMuted } from "../../assets/icons/mic_muted.svg";
import { ReactComponent as MicUnmuted } from "../../assets/icons/mic_unmuted.svg";
import { ReactComponent as NavCollapse } from "../../assets/icons/nav_collapse.svg";
import { ReactComponent as NavRightArrow } from "../../assets/icons/nav_right_arrow.svg";
import { ReactComponent as Network } from "../../assets/icons/network.svg";
import { ReactComponent as Notification } from "../../assets/icons/notification.svg";
import { ReactComponent as Profile } from "../../assets/icons/profile.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Secure } from "../../assets/icons/secure.svg";
import { ReactComponent as Sort } from "../../assets/icons/sort.svg";
import { ReactComponent as View } from "../../assets/icons/view.svg";
import { ReactComponent as Comments } from "../../assets/icons/comments.svg";
import { ReactComponent as List } from "../../assets/icons/list.svg";
import { ReactComponent as Grid } from "../../assets/icons/grid.svg";
import { ReactComponent as Help } from "../../assets/icons/help.svg";
import { ReactComponent as AddParticipant } from "../../assets/icons/add-participant.svg";
import { ReactComponent as Exit } from "../../assets/icons/exit.svg";
import { ReactComponent as AddOrg } from "../../assets/icons/add_org.svg";
import { ReactComponent as Options } from "../../assets/icons/options.svg";
import { ReactComponent as EditPen } from "../../assets/icons/edit-pen.svg";
import { ReactComponent as Fullscreen } from "../../assets/icons/fullscreen.svg";
import { ReactComponent as FullscreenExit } from "../../assets/icons/fullscreen-exit.svg";
import { ReactComponent as ScreenShare } from "../../assets/icons/screen_share.svg";
import { ReactComponent as CameraAdd } from "../../assets/icons/camera_add.svg";
import { ReactComponent as Speaker } from "../../assets/icons/speaker.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Audio } from "../../assets/icons/audio.svg";
import { ReactComponent as Organisation } from "../../assets/icons/orgs.svg";
import { ReactComponent as Chat } from "../../assets/icons/chat.svg";
import { ReactComponent as Bullet } from "../../assets/icons/bullet.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";

const iconMappings = {
  addOrg: AddOrg,
  addParticipant: AddParticipant,
  audio: Audio,
  calendar: Calendar,
  chat: Chat,
  comment: Comment,
  comments: Comments,
  contact: Contact,
  contract: Contract,
  document: Document,
  editPen: EditPen,
  eraser: Eraser,
  exit: Exit,
  expand: Expand,
  grid: Grid,
  hamburger: Hamburger,
  help: Help,
  home: Home,
  library: Library,
  like: Like,
  list: List,
  logo: Logo,
  micActive: MicActive,
  micUnmuted: MicUnmuted,
  micMuted: MicMuted,
  options: Options,
  organisation: Organisation,
  logout: Logout,
  navCollapse: NavCollapse,
  navRightArrow: NavRightArrow,
  network: Network,
  notification: Notification,
  profile: Profile,
  search: Search,
  secure: Secure,
  sort: Sort,
  view: View,
  likeInactive: LikeInactive,
  fullscreen: Fullscreen,
  fullscreenExit: FullscreenExit,
  screenShare: ScreenShare,
  cameraAdd: CameraAdd,
  speaker: Speaker,
  settings: Settings,
  info: Info,
  bullet: Bullet,
  check: Check,
};

export default iconMappings;
