import * as t from "io-ts";
import { makeRoute } from "./api";
import { empty } from "./common";

//response structure
const versionNumber = t.type({
  version: t.string,
});

//route
export const get = makeRoute("/version", empty, versionNumber);
export type versionNumber = t.TypeOf<typeof versionNumber>;
