import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface MissingEnvironmentVariablesExceptionMeta {
  environmentVariables: string[];
}

export class MissingEnvironmentVariablesException extends BaseException {
  constructor(
    message = "Missing environment variables",
    meta: MissingEnvironmentVariablesExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.MISSING_ENVIRONMENT_VARIABLES;
  }
}
