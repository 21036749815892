import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToUpdateUserRoleException extends BaseException {
  constructor(message = "Not allowed to update user role", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_UPDATE_USER_ROLE;
  }
}
