import { styled } from "@mui/material/styles";
import { fontWeights, TypographysCSS } from "../../styles/typography";
import { spacersAsString } from "../../styles/spacers";

const BaseButton = styled("button")(
  ({ theme }) => `
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  border: 0px solid transparent;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${`${spacersAsString.none} ${spacersAsString.sm}`};
  cursor: pointer;

  &.size-small {
    height: 40px;
    padding: ${`${spacersAsString.none} ${spacersAsString.md}`};
    border-radius: 4px;

    // font style
    ${TypographysCSS.subtitle3}
    font-weight: ${fontWeights.semiBold};
    text-align: center;
    white-space: nowrap;
  }

  &.size-medium {
    height: 48px;
    padding: ${`${spacersAsString.none} ${spacersAsString.lg}`};
    border-radius: 4px;

    // font style
    ${TypographysCSS.body1}
    font-weight: ${fontWeights.semiBold};
    text-align: center;
    white-space: nowrap;
  }

  &.size-large {
    height: 50px;
    padding: ${`${spacersAsString.none} ${spacersAsString.lg}`};
    border-radius: 4px;

    // font style
    ${TypographysCSS.h6}
    font-weight: ${fontWeights.regular};
    text-align: center;
    white-space: nowrap;
  }

  &:disabled {
    border-color: ${theme.colors.Grey100};
    background-color: ${theme.colors.Grey100};
    color: ${theme.colors.BlackDisabled};
    cursor: not-allowed;

    &.size-large {
      font-weight: ${fontWeights.semiBold};
    }
  }
`,
);

export const PrimaryButton = styled(BaseButton)(
  ({ theme }) => `
  border-color: ${theme.colors.BrandSecondary};
  background-color: ${theme.colors.BrandSecondary};
  color: ${theme.colors.White};

  &:hover:not(:disabled) {
    border-color: ${theme.colors.BrandSecondaryLight};
    background-color: ${theme.colors.BrandSecondaryLight};
    color: ${theme.colors.White};

    &:not(.state-focused, .state-inactive, :active) {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
        0px 8px 16px rgba(0, 0, 0, 0.08);
    }
  }

  &:active:not(:disabled) {
    border-color: ${theme.colors.BrandSecondaryDark};
    background-color: ${theme.colors.BrandSecondaryDark};
    color: ${theme.colors.White};
  }
`,
);

export const SecondaryButton = styled(BaseButton)(
  ({ theme }) => `
  border-width: 1px;
  border-color: ${theme.colors.BrandPrimary};
  background-color: ${theme.colors.White};
  color: ${theme.colors.BrandPrimary};

  &.size-large {
    font-weight: ${fontWeights.medium};
  }

  &:hover:not(:disabled) {
    border-width: 1px;
    border-color: ${theme.colors.BrandPrimary};
    background-color: ${theme.colors.Brand50};
    color: ${theme.colors.BrandPrimary};
  }

  &:active:not(:disabled) {
    border-width: 1px;
    border-color: ${theme.colors.BrandPrimary};
    background-color: ${theme.colors.Brand200};
    color: ${theme.colors.BrandPrimary};
  }
`,
);

export const AccentButton = styled(BaseButton)(
  ({ theme }) => `
  border-color: ${theme.colors.BrandPrimary};
  background-color: ${theme.colors.BrandPrimary};
  color: ${theme.colors.White};

  &:hover:not(:disabled) {
    border-color: ${theme.colors.BrandPrimaryLight};
    background-color: ${theme.colors.BrandPrimaryLight};
    color: ${theme.colors.White};

    &:not(.state-focused, :active, .state-inactive, :disabled) {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
        0px 8px 16px rgba(0, 0, 0, 0.08);
    }
  }

  &:active:not(:disabled) {
    border-color: ${theme.colors.BrandPrimaryDark};
    background-color: ${theme.colors.BrandPrimaryDark};
    color: ${theme.colors.White};
  }
`,
);

export const DangerButton = styled(BaseButton)(
  ({ theme }) => `
  border-color: ${theme.colors.Red700};
  background-color: ${theme.colors.Red700};
  color: ${theme.colors.White};

  &:hover:not(:disabled) {
    border-color: ${theme.colors.Red600};
    background-color: ${theme.colors.Red600};
    color: ${theme.colors.White};

    &:not(.state-focused, :active, .state-inactive, :disabled) {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
        0px 8px 16px rgba(0, 0, 0, 0.08);
    }
  }

  &:active:not(:disabled) {
    border-color: ${theme.colors.Red900};
    background-color: ${theme.colors.Red900};
    color: ${theme.colors.White};
  }
`,
);

export const GhostButton = styled(BaseButton)(
  ({ theme }) => `
  border-color: transparent;
  background-color: transparent;
  color: ${theme.colors.BrandSecondary};

  &.size-large {
    font-weight: ${fontWeights.medium};
  }

  &:hover:not(:disabled) {
    border-color: ${theme.colors.BrandSecondary8};
    background-color: ${theme.colors.BrandSecondary8};
    color: ${theme.colors.BrandSecondary};
  }

  &:active:not(:disabled) {
    border-color: ${theme.colors.BrandSecondary18};
    background-color: ${theme.colors.BrandSecondary18};
    color: ${theme.colors.BrandSecondary};
  }

  &:disabled {
    background-color: transparent;
  }
`,
);
