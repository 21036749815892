import * as api from "@opentelemetry/api-logs";
import { AnyValueMap } from "@opentelemetry/api-logs";
import {
  BatchLogRecordProcessor,
  LoggerProvider,
} from "@opentelemetry/sdk-logs";
import { Config, Logger, LogLevel, LogProps, MetaData } from "./common";
import { DynamicHeaderOTLPLogExporter } from "./exporters/DynamicHeaderOTLPLogExporter";
import { getCircularReplacer } from "../utils/getCirculareReplacer";

const SEVERITY_NUMBER_MAP = {
  [LogLevel.INFO]: api.SeverityNumber.INFO,
  [LogLevel.DEBUG]: api.SeverityNumber.DEBUG,
  [LogLevel.WARN]: api.SeverityNumber.WARN,
  [LogLevel.ERROR]: api.SeverityNumber.ERROR,
} as const;

export interface OtelBrowserConfig extends Config {
  concurrencyLimit?: number;
  timeoutMillis?: number;
  maxQueueSize?: number;
  scheduledDelay?: number;
  maxExportBatchSize?: number;
}

const DEFAULT_CONCURRENCY_LIMIT = 5 as const;
const DEFAULT_TIMEOUT_MILLIS = 30000 as const;
const DEFAULT_MAX_QUEUE_SIZE = 1024 as const;
const DEFAULT_SCHEDULED_DELAY = 1000 as const;
const DEFAULT_MAX_EXPORT_BATCH_SIZE = 1024 as const;

export class OtelBrowserLogger implements Logger {
  constructor(private config: OtelBrowserConfig, private metadata: MetaData) {
    const provider = new LoggerProvider();

    const exporter = new DynamicHeaderOTLPLogExporter(
      {
        headers: {
          authorization: `Bearer ${config.auth}`,
        },
        url: config.hostURL,
        concurrencyLimit: config.concurrencyLimit ?? DEFAULT_CONCURRENCY_LIMIT,
        timeoutMillis: config.timeoutMillis ?? DEFAULT_TIMEOUT_MILLIS,
      },
      {
        authorization: () => `Bearer ${config.auth}`,
      },
    );

    const processor = new BatchLogRecordProcessor(exporter, {
      maxQueueSize: config.maxQueueSize ?? DEFAULT_MAX_QUEUE_SIZE,
      maxExportBatchSize:
        config.maxExportBatchSize ?? DEFAULT_MAX_EXPORT_BATCH_SIZE,
      exportTimeoutMillis: config.timeoutMillis ?? DEFAULT_TIMEOUT_MILLIS,
      scheduledDelayMillis: config.scheduledDelay ?? DEFAULT_SCHEDULED_DELAY,
    });

    provider.addLogRecordProcessor(processor);
    api.logs.setGlobalLoggerProvider(provider);
  }

  log(payload: LogProps, level: LogLevel) {
    const date = new Date();

    const { attributes, message, arguments: args, ...rest } = payload;

    const logger = api.logs.getLogger("default");
    const argumentsString = JSON.stringify(args, getCircularReplacer());

    logger.emit({
      timestamp: date,
      observedTimestamp: date,
      severityText: level,
      severityNumber: SEVERITY_NUMBER_MAP[level],
      attributes: {
        ...attributes,
        ...rest,
        arguments: argumentsString,
        ...this.metadata,
      } as unknown as AnyValueMap,
      body: message,
    });
  }

  debug = (payload: LogProps) => this.log(payload, LogLevel.DEBUG);
  info = (payload: LogProps) => this.log(payload, LogLevel.INFO);
  warn = (payload: LogProps) => this.log(payload, LogLevel.WARN);
  error = (payload: LogProps) => this.log(payload, LogLevel.ERROR);

  setConfig(payload: Partial<OtelBrowserConfig>) {
    this.config = { ...this.config, ...payload };
  }

  setMetadata(metadata: Partial<MetaData>) {
    this.metadata = { ...this.metadata, ...metadata };
  }
}
