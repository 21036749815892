import { Box, Toolbar, useTheme } from "@mui/material";
import React, { FC, ReactNode } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Logo } from "../Logo/Logo";

interface AppBarProps {
  children?: ReactNode;
  hideLogo?: boolean;
}

const AppBar: FC<AppBarProps> = (props: AppBarProps) => {
  const { colors, palette } = useTheme();

  const minRes = useMediaQuery("(min-width:1340px)");
  return (
    <Toolbar
      component={Box}
      bgcolor={colors.Grey900}
      color={palette.primary.contrastText}
      sx={{
        height: "56px !important",
        minHeight: "56px !important",
        justifyContent: "space-between",
        paddingRight: "0 !important",
      }}
    >
      <Box flexShrink={0}>
        {(!props.hideLogo || minRes) && (
          <Logo variant={"media-app"} wrapperStyle={{ paddingLeft: 0 }} />
        )}
      </Box>
      {props.children}
    </Toolbar>
  );
};
export default AppBar;
