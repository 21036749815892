import { PlayArrow } from "@mui/icons-material";
import { IconButton, Slider, styled } from "@mui/material";

export const StyledMediaControls = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.text.secondary};
`,
);

export const StyledPlayButton = styled(PlayArrow)``;

export const StyledTimestamp = styled("span")`
  display: inline-block;
  width: 5.5em;
  padding: 0 0.5em;
  font-size: 14px;
`;

export const StyledControlRow = styled("div")`
  display: flex;
  align-items: center;
  margin: 0 -13px;
`;

export const StyledSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-thumb": {
    boxShadow: "unset !important",
    backgroundColor: `${theme.colors.Grey500} !important`,
    color: `${theme.colors.Grey500} !important`,
  },
  "& .MuiSlider-track": {
    backgroundColor: `${theme.colors.Grey500} !important`,
    color: `${theme.colors.Grey500} !important`,
  },
  "& .MuiSlider-rail": {
    backgroundColor: `${theme.colors.Grey300} !important`,
    color: `${theme.colors.Grey300} !important`,
  },
}));

export const StyledIconButton = styled(IconButton)(
  (props: { hover?: boolean }) => ({
    "&.MuiIconButton-root": {
      backgroundColor: props.hover ? undefined : "unset",
    },
  }),
);
