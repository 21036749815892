export const Role = {
  Owner: "Owner",
  Moderator: "Moderator",
  Participant: "Participant",
};

export const Permission = {
  LIST_PARTICIPANTS: "LIST_PARTICIPANTS",
  INVITE_PARTICIPANTS: "INVITE_PARTICIPANTS",
  START_RECORDING: "START_RECORDING",
  STOP_RECORDING: "STOP_RECORDING",
  MUTE_SELF: "MUTE_SELF",
  UNMUTE_SELF: "UNMUTE_SELF",
  MUTE_PARTICIPANTS: "MUTE_PARTICIPANTS",
  UNMUTE_PARTICIPANTS: "UNMUTE_PARTICIPANTS",
  MUTE_ALL: "MUTE_ALL",
  ADD_OWN_FEED: "ADD_OWN_FEED",
  REMOVE_OWN_FEED: "REMOVE_OWN_FEED",
  ADD_OTHER_PARTICIPANTS_FEED: "ADD_OTHER_PARTICIPANTS_FEED",
  REMOVE_OTHER_PARTICIPANTS_FEED: "REMOVE_OTHER_PARTICIPANTS_FEED",
  END_SESSION: "END_SESSION",
  LAST_STATE_PREVIEW: "LAST_STATE_PREVIEW",
  INSTANT_VIDEO: "INSTANT_VIDEO",
  ADD_PRIVACY_CONTROL_ON_OWN_FEED: "ADD_PRIVACY_CONTROL_ON_OWN_FEED",
  EDIT_PRIVACY_CONTROL_ON_OWN_FEED: "EDIT_PRIVACY_CONTROL_ON_OWN_FEED",
  ADD_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED:
    "ADD_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED",
  EDIT_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED:
    "EDIT_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED",
  VIEW_CASE: "VIEW_CASE",
  MANAGE_CASES: "MANAGE_CASES",
  EDIT_CASES: "EDIT_CASES",
  EDIT_SESSION_DETAILS: "EDIT_SESSION_DETAILS",
  CHANGE_SESSION_OWNERSHIP: "CHANGE_SESSION_OWNERSHIP",
  SPOTLIGHT_FEED: "SPOTLIGHT_FEED",
  REMOVE_SPOTLIGHTED_FEED: "REMOVE_SPOTLIGHTED_FEED",
};

const PermissionsForRoles = (
  isSessionOwnerTransferEnabled: boolean,
  // TODO: this should probably not live here. also a matrix of permissions might be better
  // where a session owner can do everything a moderator can do in addition to other stuff
  isSpotlightEnabled: boolean,
) => ({
  [Role.Owner]: [
    Permission.LIST_PARTICIPANTS,
    Permission.INVITE_PARTICIPANTS,
    Permission.START_RECORDING,
    Permission.STOP_RECORDING,
    Permission.MUTE_PARTICIPANTS,
    Permission.MUTE_ALL,
    Permission.ADD_OWN_FEED,
    Permission.REMOVE_OWN_FEED,
    Permission.REMOVE_OTHER_PARTICIPANTS_FEED,
    Permission.END_SESSION,
    Permission.LAST_STATE_PREVIEW,
    Permission.INSTANT_VIDEO,
    Permission.ADD_PRIVACY_CONTROL_ON_OWN_FEED,
    Permission.EDIT_PRIVACY_CONTROL_ON_OWN_FEED,
    Permission.ADD_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED,
    Permission.EDIT_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED,
    Permission.VIEW_CASE,
    Permission.MANAGE_CASES,
    Permission.EDIT_CASES,
    Permission.EDIT_SESSION_DETAILS,
    Permission.CHANGE_SESSION_OWNERSHIP,
    ...(isSpotlightEnabled
      ? [Permission.SPOTLIGHT_FEED, Permission.REMOVE_SPOTLIGHTED_FEED]
      : []),
  ],
  [Role.Moderator]: [
    Permission.LIST_PARTICIPANTS,
    Permission.MUTE_SELF,
    Permission.UNMUTE_SELF,
    Permission.MUTE_PARTICIPANTS,
    Permission.MUTE_ALL,
    Permission.ADD_OWN_FEED,
    Permission.REMOVE_OWN_FEED,
    Permission.ADD_PRIVACY_CONTROL_ON_OWN_FEED,
    ...(isSessionOwnerTransferEnabled
      ? [
          Permission.INVITE_PARTICIPANTS,
          Permission.START_RECORDING,
          Permission.STOP_RECORDING,
          Permission.REMOVE_OTHER_PARTICIPANTS_FEED,
          Permission.END_SESSION,
          Permission.EDIT_PRIVACY_CONTROL_ON_OWN_FEED,
          Permission.EDIT_SESSION_DETAILS,
          Permission.ADD_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED,
          Permission.EDIT_PRIVACY_CONTROL_ON_OTHER_PARTICIPANTS_FEED,
        ]
      : []),
    ...(isSpotlightEnabled
      ? [Permission.SPOTLIGHT_FEED, Permission.REMOVE_SPOTLIGHTED_FEED]
      : []),
  ],
  [Role.Participant]: [
    Permission.LIST_PARTICIPANTS,
    Permission.MUTE_SELF,
    Permission.UNMUTE_SELF,
    Permission.MUTE_PARTICIPANTS,
    Permission.ADD_OWN_FEED,
    Permission.REMOVE_OWN_FEED,
    Permission.ADD_PRIVACY_CONTROL_ON_OWN_FEED,
  ],
});

export const checkPermission = (
  role: string | undefined,
  permission: string | string[],
  isSessionOwnerTransferEnabled = true,
  isSpotlightEnabled = true,
): boolean => {
  const finalRole = Object.keys(Role).includes(role || "")
    ? role || Role.Participant
    : Role.Participant;

  return PermissionsForRoles(isSessionOwnerTransferEnabled, isSpotlightEnabled)[
    finalRole
  ]?.some((p) =>
    Array.isArray(permission) ? permission.includes(p) : p === permission,
  );
};
