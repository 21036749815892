import proximieLogo from "./images/proximie_logo.png";
import proximieLogoOnBlue from "./images/proximie_logo_on_blue.png";
import proximieLogoOnBlack from "./images/proximie_logo_on_black.png";
import hamburger from "./icons/hamburger.svg";
import avatar from "./icons/avatar.png";

export default {
  images: {
    proximieLogo,
    proximieLogoOnBlue,
    proximieLogoOnBlack,
  },
  icons: {
    hamburger,
    avatar,
  },
};
