import { TypographyProps, useTheme } from "@mui/material";
import React from "react";
import { NormalGoodPoorBadNA } from "./TroubleShootingTypes";
import { Subtitle3Typography } from "./shared/CustomTypography";

export type TroubleShootingStatusTextProps = {
  text: string;
};

const TroubleShootingStatusText = ({
  text,
  ...props
}: TroubleShootingStatusTextProps & TypographyProps) => {
  return (
    <Subtitle3Typography {...props} fontWeight={600}>
      {text}
    </Subtitle3Typography>
  );
};

const NormalTroubleShootingStatusText = () => {
  const { colors } = useTheme();
  return <TroubleShootingStatusText text={"Normal"} color={colors.Green600} />;
};

const GoodTroubleShootingStatusText = () => {
  const { colors } = useTheme();
  return <TroubleShootingStatusText text={"Good"} color={colors.Green600} />;
};

const NATroubleShootingStatusText = () => {
  const { colors } = useTheme();
  return <TroubleShootingStatusText text={"N/A"} color={colors.BlackA400} />;
};

const PoorTroubleShootingStatusText = () => {
  const { colors } = useTheme();
  return <TroubleShootingStatusText text={"Poor"} color={colors.Amber600} />;
};

const BadTroubleShootingStatusText = () => {
  const { colors } = useTheme();
  return <TroubleShootingStatusText text={"Bad"} color={colors.Red600} />;
};

type TroubleShootingStatusTextContainerProps = {
  criticality: NormalGoodPoorBadNA;
};

export const TroubleShootingStatusTextContainer = ({
  criticality,
}: TroubleShootingStatusTextContainerProps) => {
  return {
    Normal: <NormalTroubleShootingStatusText />,
    Good: <GoodTroubleShootingStatusText />,
    "N/A": <NATroubleShootingStatusText />,
    Poor: <PoorTroubleShootingStatusText />,
    Bad: <BadTroubleShootingStatusText />,
  }[criticality];
};
