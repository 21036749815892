import * as t from "io-ts";
import { makeNoContentRoute, makeRoute } from "./api";
import { empty } from "./common";
import { nullable } from "io-ts/lib/Type";
import { Country } from "./countries";
import { ProfileNoRelations, profilePresentation } from "./profiles";

export enum Roles {
  ADMIN = 1,
  STANDARD = 2,
}

export enum RoleNames {
  ADMIN = "Admin",
  STANDARD = "Standard",
}

const sharedFields = {
  name: t.string,
  orgType: t.any,
  allowVideoSharing: t.boolean,
  allowWearables: t.boolean,
  allowImageOverlay: t.boolean,
  allowRecording: t.boolean,
  allowAnalytics: t.boolean,
  allowQuickReschedule: t.boolean,
  allowStartNow: t.boolean,
  allowSessionCreation: t.boolean,
  allowPublicVisibility: t.boolean,
};

const createOrganisationRequest = t.type({
  ...sharedFields,
  countryId: nullable(t.union([t.undefined, t.number])),
  countryISO: nullable(t.union([t.undefined, t.string])),
});

const updateOrganisationRequest = t.type({ name: t.string, orgType: t.any });

const inviteToOrganisationRequest = t.type({
  orgId: t.number,
  standardUserEmails: t.array(t.string),
  adminEmails: t.array(t.string),
});

const reInviteToOrganisationRequest = t.type({
  orgId: t.number,
  email: t.string,
});

const updateMembershipRoleRequest = t.type({
  organisationId: t.number,
  profileId: t.number,
  roleId: t.number,
});

const MemberSafeToBeRemovedResponse = t.type({
  memberSafeToBeRemoved: t.boolean,
});

const removeMemberFromOrganisationRequest = t.type({
  organisationId: t.number,
  profileId: t.number,
});

const reInviteBody = t.type({
  status: t.number,
});

export const Organisation = t.type({
  ...sharedFields,
  id: t.number,
  country: nullable(t.union([Country, t.undefined])),
  organisationId: t.string,
  externalParentId: nullable(t.union([t.undefined, t.string])),
});

export type OrganisationPresentation = t.TypeOf<typeof Organisation>;

export const shortOrganisationPresentation = t.type({
  id: t.number,
  name: t.string,
  country: Country,
  organisationId: t.string,
  allowRecording: t.boolean,
  allowAnalytics: t.boolean,
  allowWearables: t.boolean,
});
const Organisations = t.array(Organisation);

export const list = makeRoute("/organisations", empty, Organisations);
export const get = makeRoute("/organisations/:id", empty, Organisation);
export const create = makeRoute(
  "/organisations",
  createOrganisationRequest,
  Organisation,
);
export const update = makeNoContentRoute(
  "/organisations/:id",
  updateOrganisationRequest,
);
export const getProfilesByOrganisation = makeRoute(
  "/organisations/:id/members",
  empty,
  t.array(ProfileNoRelations),
);

//used in admin panel
export const getUsersByOrganisation = makeRoute(
  "/organisations/:id/users",
  empty,
  t.array(profilePresentation),
);

export const inviteToOrganisation = makeNoContentRoute(
  "/organisations/:id/invite",
  inviteToOrganisationRequest,
);

export const reInviteToOrganisation = makeRoute(
  "/organisations/:id/re-invite",
  reInviteToOrganisationRequest,
  reInviteBody,
);

export const updateMembershipRole = makeNoContentRoute(
  "/organisations/:id/org-membership",
  updateMembershipRoleRequest,
);

export const removeMemberFromOrganisation = makeNoContentRoute(
  "/organisations/:id/org-membership",
  removeMemberFromOrganisationRequest,
);

export const isMemberSafeToBeRemoved = makeRoute(
  "/organisations/:organisationId/users/:profileId/safe-to-remove",
  empty,
  MemberSafeToBeRemovedResponse,
);

export type shortOrganisationPresentation = t.TypeOf<
  typeof shortOrganisationPresentation
>;
export type organisationPresentation = t.TypeOf<typeof Organisation>;
export type updateOrganisationRequest = t.TypeOf<
  typeof updateOrganisationRequest
>;
export type inviteToOrganisationRequest = t.TypeOf<
  typeof inviteToOrganisationRequest
>;
export type reInviteToOrganisationRequest = t.TypeOf<
  typeof reInviteToOrganisationRequest
>;
export type createOrganisationRequest = t.TypeOf<
  typeof createOrganisationRequest
>;
export type updateMembershipRoleRequest = t.TypeOf<
  typeof updateMembershipRoleRequest
>;
export type removeMemberFromOrganisationRequest = t.TypeOf<
  typeof removeMemberFromOrganisationRequest
>;
export type MemberSafeToBeRemovedPresentation = t.TypeOf<
  typeof MemberSafeToBeRemovedResponse
>;
