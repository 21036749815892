import i18next from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { Dictionary } from "./Dictionary";
import React, { useEffect } from "react";
import _ from "lodash";

i18next.use(initReactI18next).init({
  ns: "default",
  defaultNS: "default",
  lng: "en",
  resources: {
    en: {
      default: Dictionary,
    },
  },
  returnNull: true,
  interpolation: { escapeValue: true },
});

type I18nInitProps = {
  children: React.ReactNode | React.ReactNode[];
  customDictionary?: Partial<typeof Dictionary>;
};

export const I18nInit = ({ children, customDictionary }: I18nInitProps) => {
  const { ready } = useTranslation();
  useEffect(() => {
    i18next.addResourceBundle(
      "en",
      "default",
      _.merge({}, Dictionary, customDictionary),
    );
  }, [customDictionary]);

  return ready ? <>{children}</> : null;
};
