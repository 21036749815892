import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import iconMappings from "./mappings";
import { IconKey } from "../../../types/IconKey";

export interface IconProps extends SvgIconProps {
  children?: React.ReactNode;
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
  fontSize?: "small" | "inherit" | "medium" | "large";
  htmlColor?: string;
  shapeRendering?: string;
  titleAccess?: string;
  viewBox?: string;
  name: IconKey;
}

const Icon: FC<IconProps> = (props: IconProps): JSX.Element => {
  return <SvgIcon component={iconMappings[props.name]} {...props} />;
};

export default Icon;
