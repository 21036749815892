import React from "react";
import { usePermission } from "./usePermission";
import { Permission } from "./PermissionContext";

type Props = {
  to: Permission;
  fallback?: React.ReactNode;
  children: React.ReactNode;
};

export const Restricted: React.FunctionComponent<Props> = ({
  to,
  fallback,
  children,
}) => {
  const allowed = usePermission(to);

  if (allowed) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
};
