import * as t from "io-ts";
import { makeRoute } from "./dataregion-api";

const FetchTelestrationHistoryRequest = t.type({
  mediaSessionId: t.string,
  id: t.string,
});

export const fetchTelestrationHistory = makeRoute(
  "/media-session-events/:mediaSessionId/telestration/:id",
  FetchTelestrationHistoryRequest,
  // NB. for efficiency (there might be thousands of elements) we do not
  // validate the contents of the response.  TBH, it's probably a bit late
  // anyway - there is no validation on the socket.io connection when the
  // event is received.
  t.array(t.any),
);
