import { Popper, styled } from "@mui/material";

export const TroubleShootingMenuStyled = styled(Popper)(
  ({ theme }) => `
  background: ${theme.colors.White};
  width: 472px;
  border-radius: 6px;
  padding: 16px;
  padding-right: calc(16px - 2rem);
  z-index: 5;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid ${theme.colors.BlackA600};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-height: calc(100% - 56px - 24px - 8px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
`,
);
