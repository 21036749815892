import { DeviceType } from "./Device";
import DeviceLocalCamera from "./DeviceLocalCamera";
import Endpoint from "../Endpoints/Endpoint";
import Connection, { ConnectionOptions } from "../Connections/Connection";
import FeedType from "../../models/FeedType";

export default class DeviceLocalCameraPTZ extends DeviceLocalCamera {
  public override readonly deviceType = DeviceType.CameraPTZ;
  public override readonly mediaType = FeedType.Camera;
  //  private ptzOwner = new PtzOwner();

  public override invoke(
    endpoint: Endpoint,
    streamId: string,
    options: ConnectionOptions,
  ): Connection | null {
    //    this.ptzOwner.addConnection(connection);

    return super.invoke(endpoint, streamId, options);
  }
}
