import * as zod from "zod";
import { supportDetails } from "./constants";

export const CustomerSupportTicketSchema = zod.object({
  summary: zod.string().trim().min(1, "Please select a summary"),
  contactEmail: zod
    .string()
    .trim()
    .min(1, "Please provide a contact email")
    .email("This is not a valid email."),
  organization: zod
    .string()
    .min(1, { message: "Please select an organization" }),
  description: zod.custom<string>(
    (value) => {
      const stringValue = value as string;
      const defaultStrings = Object.values(supportDetails);
      return stringValue.length > 0 && !defaultStrings.includes(stringValue);
    },
    { message: "Please provide a description" },
  ),
});

export type CustomerSupportTicketData = zod.infer<
  typeof CustomerSupportTicketSchema
>;
