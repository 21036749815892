export interface UsageTrackerEvents<Payload = void> {
  id: symbol;
  name: string;
}

const SessionOwnerChanged: UsageTrackerEvents = {
  id: Symbol(),
  name: "session_owner_changed",
};

const ModeratorInvitedToSession: UsageTrackerEvents = {
  id: Symbol(),
  name: "moderator_invited_to_session",
};

type FeedSpotlightPayload = {
  spotlightedBy: string;
  streamId: string;
};

const FeedSpotlighted: UsageTrackerEvents<FeedSpotlightPayload> = {
  id: Symbol(),
  name: "feed_spotlighted",
};

const FeedSpotlightRemoved: UsageTrackerEvents<FeedSpotlightPayload> = {
  id: Symbol(),
  name: "feed_spotlight_removed",
};

export const UsageTrackerEvents = {
  SessionOwnerChanged,
  ModeratorInvitedToSession,
  FeedSpotlighted,
  FeedSpotlightRemoved,
};
