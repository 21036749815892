import { SvgIcon as MuiSvgIcon } from "@mui/material";
import React from "react";

export type DrawIconProps = {
  color?: string;
};

export const DrawIcon = ({ color }: DrawIconProps = { color: "white" }) => {
  return (
    <MuiSvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M19.8097 10.3913L20.8697 9.33134C21.6497 8.55134 21.6497 7.28134 20.8697 6.50134L19.4597 5.09134C18.6797 4.31134 17.4097 4.31134 16.6297 5.09134L15.5697 6.15134L19.8097 10.3913ZM14.1497 7.56134L4.95972 16.7613V21.0013H9.19972L18.3897 11.8113L14.1497 7.56134ZM19.9597 17.5013C19.9597 19.6913 17.4197 21.0013 14.9597 21.0013C14.4097 21.0013 13.9597 20.5513 13.9597 20.0013C13.9597 19.4513 14.4097 19.0013 14.9597 19.0013C16.4997 19.0013 17.9597 18.2713 17.9597 17.5013C17.9597 17.0313 17.4797 16.6313 16.7297 16.3013L18.2097 14.8213C19.2797 15.4513 19.9597 16.2913 19.9597 17.5013ZM5.53972 13.3513C4.56972 12.7913 3.95972 12.0613 3.95972 11.0013C3.95972 9.20134 5.84972 8.37134 7.51972 7.64134C8.54972 7.18134 9.95972 6.56134 9.95972 6.00134C9.95972 5.59134 9.17972 5.00134 7.95972 5.00134C6.69972 5.00134 6.15972 5.61134 6.12972 5.64134C5.77972 6.05134 5.14972 6.10134 4.72972 5.76134C4.31972 5.42134 4.23972 4.81134 4.57972 4.38134C4.68972 4.24134 5.71972 3.00134 7.95972 3.00134C10.1997 3.00134 11.9597 4.32134 11.9597 6.00134C11.9597 7.87134 10.0297 8.72134 8.31972 9.47134C7.37972 9.88134 5.95972 10.5013 5.95972 11.0013C5.95972 11.3113 6.38972 11.6013 7.02972 11.8613L5.53972 13.3513Z"
          fill={color}
          fillOpacity="0.87"
        />
      </svg>
    </MuiSvgIcon>
  );
};
