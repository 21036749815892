import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class FailedWebSocketHealthCheckException extends BaseException {
  constructor(message = "Failed web-socket health check", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_WEB_SOCKET_HEALTH_CHECK;
  }
}
