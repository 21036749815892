import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class FailedToSetMicrophoneException extends BaseException {
  constructor(message = "Failed to set a microphone", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_SET_MICROPHONE;
  }
}
