import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import { makeNoContentRoute, makeRoute } from "./api";

import { empty } from "./common";
import { RecordingResource } from "./recording-resources";

export const enum RecordingState {
  DATASYNC_COMPLETED = "DatasyncCompletedEvent",
  POST_PROCESSING_COMPLETED = "PostProcessingCompletedEvent",
  FEED_TRANSCODING_COMPLETED = "FeedTranscodingCompletedEvent",
  FEED_HLS_TRANSCODING_COMPLETED = "FeedHlsTranscodingCompletedEvent",
  GRID_TRANSCODING_COMPLETED = "GridTranscodingCompletedEvent",
  GRID_HLS_TRANSCODING_COMPLETED = "GridHlsTranscodingCompletedEvent",
  DELETED = "DeleteRequestedEvent",
}

export enum RecordingStatus {
  AWAITING = "AWAITING",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
}

export const VideoSource = t.type({
  name: t.string,
  url: t.string,
  height: t.number,
  width: t.number,
  duration: t.number,
  posterImage: t.string,
});
export type VideoSource = t.TypeOf<typeof VideoSource>;

export const Recording = t.type({
  title: t.string,
  recordingId: t.string,
  description: t.string,
  duration: t.number,
  thumbnail: t.string,
  scheduledDateAndTime: t.union([DateFromISOString, t.string]),
  owner: t.type({
    name: t.string,
    title: t.string,
  }),
  organisation: t.type({
    organisationId: t.string,
    countryId: t.number,
  }),
  speciality: t.string,
  status: t.keyof(RecordingStatus),
  sources: t.array(VideoSource),
});
export type Recording = t.TypeOf<typeof Recording>;

export const Recordings = t.array(Recording);
export type Recordings = t.TypeOf<typeof Recordings>;

export const PartialRecording = t.type({
  recordingId: t.string,
  organisationId: t.string,
  status: t.keyof(RecordingStatus),
});
export type PartialRecording = t.TypeOf<typeof PartialRecording>;

export const getRecordings = makeRoute(
  "/organisations/:organisationId/recordings",
  empty,
  Recordings,
);

export const RecordingPatch = t.intersection([
  t.type({ recordingId: t.string }),
  t.partial({
    status: t.any,
    recordingResources: t.array(RecordingResource),
  }),
]);

export type RecordingPatch = t.TypeOf<typeof RecordingPatch>;
export const updateRecording = makeNoContentRoute(
  "/m2m/recordings/:recordingId",
  RecordingPatch,
);

export const getRecording = makeRoute(
  "/recordings/:recordingId",
  empty,
  Recording,
);

const CreateRecordingRequest = t.type({
  recordingId: t.string,
  organisationId: t.string,
});
export type CreateRecordingRequest = t.TypeOf<typeof CreateRecordingRequest>;

export const createRecording = makeRoute(
  "/m2m/recordings",
  CreateRecordingRequest,
  PartialRecording,
);
