export const splitSupportLines = (
  supportLines: Record<
    string,
    {
      label: string;
      link: string;
    }
  >,
) => {
  const splitLines: {
    whatsappLines: Record<string, { label: string; link: string }>;
    emailLines: Record<string, { label: string; link: string }>;
    phoneLines: Record<string, { label: string; link: string }>;
  } = { whatsappLines: {}, emailLines: {}, phoneLines: {} };

  Object.entries(supportLines).forEach(([key, value]) => {
    if (key === "WhatsApp") {
      splitLines.whatsappLines[key] = value;
    } else if (value.link.includes("mailto")) {
      splitLines.emailLines[key] = value;
    } else {
      splitLines.phoneLines[key] = value;
    }
  });

  return splitLines;
};
