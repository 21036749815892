const nth = (d: number) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDatePresentationString = (date: Date | string): string => {
  const dateObject = new Date(date);
  const formattedDay = dateObject.toLocaleDateString(undefined, {
    day: "numeric",
  });
  const formattedMonth = dateObject.toLocaleDateString(undefined, {
    month: "short",
  });
  const formattedYear = dateObject.toLocaleDateString(undefined, {
    year: "numeric",
  });

  return `${formattedDay}${nth(
    dateObject.getDate(),
  )} ${formattedMonth}, ${formattedYear}`;
};
