import styled from "@emotion/styled";

export const LiveSessionParticipantsStyled = styled.div`
  position: relative;
  width: 100%;
  min-width: 300px;
  max-width: 500px;
  overflow: hidden;
  height: auto;
  display: block;
  z-index: 1;
`;
