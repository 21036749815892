import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class FailedToGetMediaCredentialsException extends BaseException {
  constructor(message = "Failed to get media credentials", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_GET_MEDIA_CREDENTIALS;
  }
}
