import {
  MetaData,
  Logger,
  NewRelicConfig,
  AppName,
  AppEnvironment,
} from "./common";

import { createConsoleLogger } from "./console-logger";
// import { createNewRelicLogger, NewRelicLoggerProps } from "./newrelic-logger";
import { createNewRelicBrowserLogger } from "./newrelic-browser-logger";

export interface LoggerProps extends MetaData {
  configs: {
    newRelic: NewRelicConfig;
  };
}

export const createLogger = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  configs,
  ...otherProps
}: LoggerProps): Logger => {
  // FYI Waiting on PHI and PII redaction
  // if (props.env === AppEnvironment.LOCALHOST) {
  //   return createConsoleLogger(props);
  // }
  // return createNewRelicLogger({ ...props, config: newRelic });

  if (
    otherProps.app === AppName.LIVE &&
    otherProps.env !== AppEnvironment.LOCALHOST
  ) {
    return createNewRelicBrowserLogger({
      ...otherProps,
      config: configs.newRelic,
    });
  }
  return createConsoleLogger(otherProps);
};
