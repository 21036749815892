import QualityVideoIncoming from "./QualityVideoIncoming";

export default class QualityVideoIncomingScreenshare extends QualityVideoIncoming {
  public controls = null;

  protected upgrade(): boolean {
    throw new Error("Not supported");
  }

  protected downgrade(): boolean {
    throw new Error("Not supported");
  }
}
