import React, { FC } from "react";
import { Box } from "@mui/material";
import LiveSessionChipIconStyled from "./LiveSessionChipIcon.style";
import { Sensors } from "@mui/icons-material";

const LiveSessionChipIcon: FC = () => {
  return (
    <LiveSessionChipIconStyled>
      <Box
        borderRadius="2px"
        color="auxilliary.contrastText"
        bgcolor="auxilliary.main"
        mb="8px"
        className="session-chips uppercase"
      >
        <Sensors />
        <Box component={"span"} ml={0.5}>
          live
        </Box>
      </Box>
    </LiveSessionChipIconStyled>
  );
};

export default LiveSessionChipIcon;
