import Janus from "@proximie/janus-gateway";
import adapter from "webrtc-adapter";
import fetch from "node-fetch";

export function init(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    if (Janus) {
      try {
        const deps = Janus.useDefaultDependencies({
          adapter: adapter,
          fetch: fetch,
        });

        Janus.init({
          debug: false,
          dependencies: deps,
          callback: () => {
            console.log("Janus initialized");
            resolve();
          },
        });
      } catch (error) {
        console.error(error);
        reject(new Error(error));
      }
    } else {
      // probably testing...
      console.warn("Failed to initialise Janus");
      resolve();
    }
  });
}
