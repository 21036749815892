import styled from "@emotion/styled";

export const FabricCanvasContainerStyled = styled.div<{
  scale: number;
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  .canvas-container {
    position: absolute !important;
    transform: scale(${({ scale }) => scale});
  }
`;
