import QualityVideoOutgoingCamera from "./ServerAdapter/Quality/QualityVideoOutgoingCamera";
import QualityVideoOutgoingScreenshare from "./ServerAdapter/Quality/QualityVideoOutgoingScreenshare";
import { MediaDeviceInfoList } from "./models/MediaDeviceInfoList";
import Device from "./ServerAdapter/Devices/Device";
import DeviceScreen from "./ServerAdapter/Devices/DeviceScreen";
import DeviceLocal from "./ServerAdapter/Devices/DeviceLocal";

export type MediaDeviceKind = "audioinput" | "audiooutput" | "videoinput";

export interface MediaDeviceKinds {
  audioinput: MediaDeviceKind;
  audiooutput: MediaDeviceKind;
  videoinput: MediaDeviceKind;
}

export type PermissionReason =
  | "granted"
  | "browser_denied"
  | "system_denied"
  | "error";

// IMPORTANT: Use Default Node Types, try not to build bespoke models/interfaces
export default abstract class WebRTCUtil {
  static readonly KINDS: MediaDeviceKinds = {
    audioinput: "audioinput",
    audiooutput: "audiooutput",
    videoinput: "videoinput",
  };

  static async CheckMissingDevices(
    mics: Device[],
    speakers: Device[],
    cams: Device[],
  ): Promise<MediaDeviceInfo[]> {
    const missingDevices: MediaDeviceInfo[] = [];

    //get all connected devices
    const connectedDevices = await WebRTCUtil.GetDeviceList();

    //check mic
    mics.forEach((mic: Device): void => {
      if (
        !connectedDevices.audioinput.find(
          (connectedMic: MediaDeviceInfo): boolean =>
            mic.deviceId === connectedMic?.deviceId,
        )
      ) {
        missingDevices.push((mic as DeviceLocal).options?.deviceInfo);
      }
    });

    //check speaker
    speakers.forEach((speaker: Device): void => {
      if (
        !connectedDevices.audiooutput.find(
          (connectedMic: MediaDeviceInfo): boolean =>
            speaker.deviceId === connectedMic?.deviceId,
        )
      ) {
        missingDevices.push((speaker as DeviceLocal).options?.deviceInfo);
      }
    });

    //check cams
    cams.forEach((cam) => {
      const foundCam = connectedDevices.videoinput.find(
        (item) => item.deviceId === cam.deviceId,
      );

      if (!foundCam) {
        missingDevices.push((cam as any).options.deviceInfo);
      }
    });

    return missingDevices;
  }

  static RemoveDuplicates(list: MediaDeviceInfo[]): MediaDeviceInfo[] {
    return list.filter(
      (device) =>
        device.deviceId !== "default" && device.deviceId !== "communications",
    );
  }

  static async RequestDevicePermission(
    type: PermissionName,
  ): Promise<PermissionReason> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        // TS definition of PermissionName is wrong
        audio: (type as unknown) === "microphone",
        video: (type as unknown) === "camera",
      });

      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      return "granted";
    } catch (error) {
      if (error.toString().includes("Permission denied by system")) {
        //system settings permission
        return "system_denied";
      } else if (error.toString().includes("Permission denied")) {
        //browser permission
        return "browser_denied";
      } else {
        console.warn("Unexpected error=", error);
        throw error;
      }
    }
  }

  static async CheckAndRequestDevicePermission(
    name: PermissionName,
  ): Promise<PermissionReason> {
    try {
      const result = await navigator.permissions.query({
        name,
      });
      switch (result.state) {
        case "granted":
          return "granted";
        case "denied":
          console.warn("Device permission has been denied");
          return "browser_denied";
        case "prompt":
          return await WebRTCUtil.RequestDevicePermission(name);
      }
    } catch (error) {
      console.warn("Failed to get device permissions - error=", error);
      throw error;
    }
  }

  static async GetDeviceList(
    keepDuplicates?: boolean,
  ): Promise<MediaDeviceInfoList> {
    if (!navigator.mediaDevices?.enumerateDevices) {
      throw new Error("enumerateDevices() not supported.");
    }

    let micPermission: PermissionReason;
    try {
      micPermission = await WebRTCUtil.CheckAndRequestDevicePermission(
        // TS defifinitions are inoomplete
        "microphone" as PermissionName,
      );
    } catch (error) {
      console.warn(
        `Failed to get microphone permissions - error=${error.message}`,
      );
      micPermission = "error";
    }

    let camPermission: PermissionReason;
    try {
      camPermission = await WebRTCUtil.CheckAndRequestDevicePermission(
        // TS defifinitions are incomplete
        "camera" as PermissionName,
      );
    } catch (error) {
      console.warn(`Failed to get camera permissions - error=${error.message}`);
      camPermission = "error";
    }

    console.debug(
      `Permissions: camera=${camPermission} microphone=${micPermission}`,
    );

    const videoinput: MediaDeviceInfo[] = [];
    const audioinput: MediaDeviceInfo[] = [];
    const audiooutput: MediaDeviceInfo[] = [];

    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
    console.debug(`Device list=${JSON.stringify(enumerateDevices)}`);

    enumerateDevices.forEach((d: MediaDeviceInfo) => {
      switch (d.kind) {
        case WebRTCUtil.KINDS.audioinput:
          if (micPermission === "granted") {
            audioinput.push(d);
          }
          break;
        case WebRTCUtil.KINDS.audiooutput:
          audiooutput.push(d);
          break;
        case WebRTCUtil.KINDS.videoinput:
          if (camPermission === "granted") {
            videoinput.push(d);
          }
          break;
        // ignore any other kind
      }
    });

    return {
      videoinput,
      audioinput: keepDuplicates
        ? audioinput
        : WebRTCUtil.RemoveDuplicates(audioinput),
      audiooutput: keepDuplicates
        ? audiooutput
        : WebRTCUtil.RemoveDuplicates(audiooutput),
    };
  }

  static async GetAudioMedia(
    deviceId?: string,
    echoCancellation?: boolean,
    noiseSuppression?: boolean,
    autoGainControl?: boolean,
  ): Promise<MediaStream> {
    return navigator.mediaDevices.getUserMedia({
      //audio: typeof deviceId !== "undefined" ? { deviceId } : true,
      audio: {
        deviceId: deviceId ? { exact: deviceId } : undefined,
        echoCancellation: echoCancellation || false,
        noiseSuppression: noiseSuppression || false,
        autoGainControl: autoGainControl || false,
      },
      video: false,
    });
  }

  static async GetVideoMedia(deviceId: string): Promise<MediaStream> {
    const constraints =
      QualityVideoOutgoingCamera.getInitialConstraints(deviceId);
    constraints.video = {
      ...(constraints.video as MediaTrackConstraints),
      ...{ pan: true, tilt: true, zoom: true },
    };
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  static async GetDisplayMedia(): Promise<
    DeviceScreen | "system_denied" | "cancelled"
  > {
    const constraints = QualityVideoOutgoingScreenshare.getInitialConstraints();
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia(constraints);
      return new DeviceScreen("DEVICE_SCREEN", { stream });
    } catch (error) {
      if (error.toString().includes("Permission denied by system")) {
        return "system_denied";
      } else if (error.toString().includes("Permission denied")) {
        return "cancelled";
      } else {
        throw error;
      }
    }
  }

  static isPTZ(stream: MediaStream): boolean {
    // returns true if the stream supports any of the pan, tilt, zoom functions
    const [track] = stream.getVideoTracks();
    //eslint-disable-next-lien @typescript-eslint/no-explicit-any
    const settings = track.getSettings() as any;

    return !!(
      settings &&
      ("pan" in settings || "tilt" in settings || "zoom" in settings)
    );
  }

  // Attach audio output device to video element using device/sink ID.
  static AttachAudioElementToOutput(
    element: HTMLAudioElement,
    deviceId: string,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (typeof (element as any).sinkId !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (element as any)
        .setSinkId(deviceId)
        .then(() => {
          console.debug(`Success, audio output device attached: ${deviceId}`);
        })
        .catch((error: Error) => {
          const errorMessage = error;
          if (error.name === "SecurityError") {
            errorMessage.message = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
        });
    } else {
      console.warn("Browser does not support output device selection.");
    }
  }

  static GetVideoCardInfo(): { vendor: string; renderer: string } | null {
    const gl = document.createElement("canvas").getContext("webgl");
    if (!gl) {
      console.log("no webgl");
      return null;
    }

    const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    if (!debugInfo) {
      console.log("no WEBGL_debug_renderer_info");
      return null;
    }

    return {
      vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
      renderer: gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
    };
  }

  static readonly bitrateToReadable = (bitrate: number): string => {
    if (bitrate > 1000 * 1000)
      return `${Math.round((100 * bitrate) / (1000 * 1000)) / 100} Mbps`;
    if (bitrate > 1000)
      return `${Math.round((100 * bitrate) / 1000) / 100} Kbps`;
    return `${bitrate} bps`;
  };
}
