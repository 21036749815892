import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from "react";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Snackbar,
  SnackbarCloseReason,
  SnackbarOrigin,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DEFAULT_AUTO_HIDE_DURATION,
  SnackbarMessage,
  SnackbarState,
} from "./common";

export interface SnackbarProviderProps {
  autoHideDuration?: number | null;
  anchorOrigin?: SnackbarOrigin;
  children: typeof React.Children | ReactElement;
}

export interface ShowSnackbarProps {
  message: SnackbarMessage;
  severity?: AlertColor;
  autoHideDuration?: number | null;
  anchorOrigin?: SnackbarOrigin;
  color?: string;
  bgColor?: string;
}

export interface SnackbarContextInterface {
  showSnackbar: (props: ShowSnackbarProps) => void;
  closeSnackbar: () => void;
}

const DEFAULT_SNACKBAR_CONTEXT: SnackbarContextInterface = {
  showSnackbar: () => null,
  closeSnackbar: () => null,
};

export const SnackbarContext = createContext<SnackbarContextInterface>(
  DEFAULT_SNACKBAR_CONTEXT,
);

export const useSnackbarContext = () => useContext(SnackbarContext);

export const SnackbarProvider = ({
  autoHideDuration,
  anchorOrigin = { vertical: "top", horizontal: "center" },
  children,
}: SnackbarProviderProps) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [state, setState] = useState<SnackbarState | null>(null);

  const showSnackbar = ({
    severity,
    message,
    autoHideDuration: autoHideDurationProps,
    anchorOrigin: anchorOriginProps,
    color,
    bgColor,
  }: ShowSnackbarProps) => {
    const severityAutoHideDuration =
      severity === "error" ? null : DEFAULT_AUTO_HIDE_DURATION;
    const defaultAutoHideDuration =
      autoHideDuration !== undefined
        ? autoHideDuration
        : severityAutoHideDuration;

    setState({
      message,
      autoHideDuration:
        autoHideDurationProps !== undefined
          ? autoHideDurationProps
          : defaultAutoHideDuration,
      anchorOrigin: anchorOriginProps || anchorOrigin,
      severity: severity || "error",
      open: true,
      color,
      bgColor,
    });
  };

  const closeSnackbar = () => {
    if (state) {
      setState({ ...state, open: false });
    }
  };

  const contextValue: SnackbarContextInterface = {
    showSnackbar,
    closeSnackbar,
  };

  const onCloseSnackbar = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason !== "clickaway") {
      closeSnackbar();
    }
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      <>
        {children}
        {state && (
          <Snackbar
            key={`snackbar-${new Date().getTime()}`}
            open={state.open}
            onClose={onCloseSnackbar}
            autoHideDuration={state.autoHideDuration}
            anchorOrigin={state.anchorOrigin}
            data-cy="snackbar"
            sx={{
              "&.MuiSnackbar-root": { top: isMd ? "8px" : "65px" },
            }}
          >
            <Alert
              icon={state.message.icon || false}
              variant="filled"
              severity={state.severity}
              onClose={closeSnackbar}
              data-testid="snackbarAlert"
              data-cy="snackbar-alert"
              sx={{ color: state?.color, backgroundColor: state?.bgColor }}
            >
              {state.message.title && (
                <AlertTitle>{state.message.title}</AlertTitle>
              )}
              {state.message.body}
            </Alert>
          </Snackbar>
        )}
      </>
    </SnackbarContext.Provider>
  );
};
