import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class Auth0UserAlreadyExistsException extends BaseException {
  constructor(message = "User already exists on Auth0", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.AUTH0_USER_ALREADY_EXISTS;
  }
}
