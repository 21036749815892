import React from "react";
import {
  RecordingIndicatorWrapper,
  RecordingIndicatorPulse,
} from "./RecordingIndicator.style";

const RecordingIndicator = ({
  isActive,
}: {
  isActive: boolean;
}): JSX.Element => {
  return (
    <RecordingIndicatorWrapper
      isActive={isActive}
      data-testid="recording-indicator"
      data-cy="recording-indicator"
    >
      <RecordingIndicatorPulse isActive={isActive} />
    </RecordingIndicatorWrapper>
  );
};

export default RecordingIndicator;
