import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class FailedToAttachMediaServerSessionException extends BaseException {
  constructor(
    message = "Failed to attach media server session",
    meta?: unknown,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_ATTACH_MEDIA_SERVER_SESSION;
  }
}
