import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface UnableToScreenShareExceptionMeta {
  originalError: Error;
}

export class UnableToScreenShareException extends BaseException {
  constructor(
    message = "Unable to screenshare",
    meta: UnableToScreenShareExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.UNABLE_TO_SCREENSHARE;
  }
}
