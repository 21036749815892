import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToReIdentifyExceptionMeta {
  deIds: string[];
  originalError?: Error;
}

export class FailedToReIdentifyException extends BaseException {
  // FYI:
  // RE-IDENTIFICATION
  // input                                     |  output
  // d41018f5-a2b2-4820-9eef-d03e03ce4967      |  user@proximie.com
  //
  constructor(
    message = "Failed to re-identify entities",
    meta: FailedToReIdentifyExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_RE_IDENTIFY;
  }
}
