import React, { useEffect } from "react";

export interface PrefetchProps {
  imageUrls: string[];
}

export const Prefetch = (props: PrefetchProps) => {
  useEffect(() => {
    props.imageUrls.forEach((url) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = url;
      link.as = "image";
      document.head.appendChild(link);
    });
  }, [props.imageUrls]);

  return null;
};
