import React, { FC, KeyboardEventHandler } from "react";
import {
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  Select,
  SelectChangeEvent,
  Box,
  Divider,
  InputAdornment,
  OutlinedInput,
  ListSubheader,
  useTheme,
  Theme,
} from "@mui/material";
import { Check, Search } from "@mui/icons-material";
import { Button } from "../Button/Button";
import OverflowTip from "../OverflowTooltip/OverflowTooltip";

type FilterItem = {
  value: string;
  name: string;
  count: number;
  group?: string;
};

type Group = {
  value: string;
  name: string;
  items: FilterItem[];
};

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom" as const,
    horizontal: 173,
  },
  MenuListProps: {
    style: {
      paddingTop: "0px",
    },
  },
  PaperProps: {
    style: {
      width: "346px",
      minHeight: "320px",
      maxHeight: "520px",
    },
  },
};

const stopEventPropagation: KeyboardEventHandler<
  HTMLInputElement | HTMLTextAreaElement
> = (e) => {
  return e.stopPropagation();
};

export interface FilterDropdownProps {
  title: string;
  value: string[];
  displaySearch?: boolean;
  onChange: (value: string[]) => void;
  onSearchChange?: (value: string) => void;
  items?: FilterItem[];
  groupedItems?: Group[];
}

const FilterDropdown: FC<FilterDropdownProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSearchChange = () => {},
  items,
  onChange,
  title,
  value,
  displaySearch,
  groupedItems,
}) => {
  const { colors } = useTheme<Theme>();

  const allItems = items
    ? items
    : groupedItems?.flatMap(({ items }) => items) || [];

  const onSelectAll = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    const allValue = allItems.map((item) => item.value);
    onChange(allValue);
  };

  const onClearAll = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    onChange([]);
  };

  const onDropdownClose = () => {
    onSearchChange("");
  };

  const onChangeHandle = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    const arrayValue = Array.isArray(value) ? value : [value];
    const filteredFalsyValues = arrayValue.filter(Boolean);

    onChange(filteredFalsyValues);
  };

  return (
    <FormControl>
      <Select
        multiple
        sx={{
          ":hover": {
            backgroundColor: colors.Secondary400,
          },
          ":active": {
            backgroundColor: colors.Secondary300,
            borderColor: colors.Brand900,
          },
        }}
        size="small"
        displayEmpty
        onClose={onDropdownClose}
        value={value}
        onChange={onChangeHandle}
        MenuProps={MenuProps}
        renderValue={(items) =>
          items.length ? `${title} (${items.length})` : title
        }
      >
        <Box
          sx={{
            display: "flex",
            position: "sticky",
            top: "0px",
            padding: "8px 8px 0px 6px",
            backgroundColor: colors.White,
            flexDirection: "column",
            alignItems: "flex-end",
            zIndex: 9,
          }}
        >
          {value.length ? (
            <Button variant="secondary" size="small" onClick={onClearAll}>
              Clear
            </Button>
          ) : (
            <Button variant="secondary" size="small" onClick={onSelectAll}>
              <Check /> All
            </Button>
          )}
          {displaySearch && (
            <OutlinedInput
              size="small"
              placeholder="Search..."
              fullWidth
              sx={{
                marginTop: "16px",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              inputProps={{ "aria-label": "enter search term" }}
              onKeyDown={stopEventPropagation}
              onKeyUp={stopEventPropagation}
              onChange={(e) => {
                e.stopPropagation();
                onSearchChange(e.target.value);
              }}
            />
          )}
          <Divider
            sx={{ width: "calc(100% + 16px)", margin: "6px  -8px 0 0" }}
          />
        </Box>

        {!!groupedItems &&
          groupedItems.map((group) => [
            <ListSubheader
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontWeight: 400,
                backgroundColor: "#F5F7F8",
                lineHeight: "20px",
                height: "48px",
                display: "flex",
                alignItems: "flex-end",
                paddingBottom: "8px",
              }}
              key={group.value}
            >
              <OverflowTip>{group.name}</OverflowTip>
            </ListSubheader>,
            ...group.items.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  <Checkbox checked={value.includes(item.value)} />
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  />
                  <Box>{item.count}</Box>
                </MenuItem>
              );
            }),
          ])}

        {!!items &&
          items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={value.includes(item.value)} />
              <ListItemText
                primary={item.name}
                primaryTypographyProps={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              />
              <Box>{item.count}</Box>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default FilterDropdown;
