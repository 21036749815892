import * as t from "io-ts";
import { makeRoute } from "./api";
import { empty } from "./common";
import { nullable } from "io-ts/lib/Type";

export const Country = t.type({
  id: nullable(t.union([t.undefined, t.number])),
  name: t.string,
  ISOCode: t.string,
});

export const list = makeRoute("/countries", empty, t.array(Country));
export type countryPresentation = t.TypeOf<typeof Country>;
