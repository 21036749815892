import { DialogContentText } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import { spacersAsString } from "../../";
import { FeatureFlags } from "../../";
import { splitSupportLines } from "./utils";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const LineList = (props: {
  lines: Record<string, { label: string; link: string }>;
  Icon: React.ElementType;
}) => {
  const { lines, Icon } = props;
  const entries = Object.entries(lines);

  if (entries.length === 0) {
    return null;
  }

  return (
    <ul
      style={{
        paddingInlineStart: spacersAsString.none,
        marginBlockStart: spacersAsString.xs,
        marginBlockEnd: spacersAsString.xs,
      }}
    >
      {_.map(entries, (value, key) => (
        <li
          key={`${key}`}
          style={{
            listStyleType: "disc",
            display: "flex",
            alignItems: "center",
            marginBottom: spacersAsString.sm,
          }}
        >
          {key === 0 ? (
            <Icon fontSize="small" />
          ) : (
            <div style={{ width: 20 }} />
          )}
          <DialogContentText style={{ marginLeft: spacersAsString.sm }}>
            {value[0]}: <a href={value[1].link}>{value[1].label}</a>
          </DialogContentText>
        </li>
      ))}
    </ul>
  );
};

type SupportLinesProps = {
  lines: Record<string, { label: string; link: string }>;
};

export const SupportLines = (props: SupportLinesProps) => {
  const hideWhatsappNumberFeatureFlag = useFlag(
    FeatureFlags.CUSTOMER_SUPPORT_WHATSAPP,
  );
  const { phoneLines, emailLines, whatsappLines } = splitSupportLines(
    props.lines,
  );

  return (
    <div>
      <LineList lines={phoneLines} Icon={PhoneIcon} />
      <LineList lines={emailLines} Icon={EmailIcon} />
      {!hideWhatsappNumberFeatureFlag && (
        <LineList lines={whatsappLines} Icon={WhatsAppIcon} />
      )}
    </div>
  );
};
