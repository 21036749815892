import { DeviceType } from "./Device";
import DeviceLocal, { DeviceLocalOptions } from "./DeviceLocal";
import Endpoint from "../Endpoints/Endpoint";
import Connection, { ConnectionOptions } from "../Connections/Connection";
import ConnectionJanusVideoOutgoing from "../Connections/Janus/ConnectionJanusVideoOutgoing";
import QualityVideoOutgoingCamera from "../Quality/QualityVideoOutgoingCamera";
import FeedType from "../../models/FeedType";

const VIRTUAL_CAMERA_STRING = " (Windows Virtual Camera)";
// bitrate derived from 2Mbps stream with 3 simulcast layers (75%, 20% and 5%) plus 5% overhead
const VIRTUAL_CAMERA_BITRATE = 2800000;

export default class DeviceLocalCamera extends DeviceLocal {
  public override deviceType = DeviceType.Camera;
  public override readonly mediaType = FeedType.Camera;

  constructor(deviceId: string, public options: DeviceLocalOptions) {
    super(deviceId, options);

    // remove the VIRTUAL_CAMERA_STRING from the label if it exists
    // MediaDeviceInfo.label is not always defined, see
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo
    const deviceInfo: MediaDeviceInfo = options.deviceInfo;
    if (deviceInfo.label && deviceInfo.label.endsWith(VIRTUAL_CAMERA_STRING)) {
      this.label = this.label.slice(0, -VIRTUAL_CAMERA_STRING.length);
    }
  }

  public override invoke(
    endpoint: Endpoint,
    streamId: string,
    options: ConnectionOptions,
  ): Connection | null {
    let bitrate: number | undefined;
    const deviceInfo: MediaDeviceInfo = this.options.deviceInfo;
    // MediaDeviceInfo.label is not always defined, see
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo
    if (deviceInfo.label && deviceInfo.label.endsWith(VIRTUAL_CAMERA_STRING)) {
      bitrate = VIRTUAL_CAMERA_BITRATE;
    } else {
      bitrate = endpoint.config.bitrate;
    }

    const quality = new QualityVideoOutgoingCamera({
      bitrate,
    });

    return new ConnectionJanusVideoOutgoing(endpoint, this, streamId, {
      ...options,
      quality,
    });
  }
}
