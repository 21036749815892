import DeviceLocalMicrophone from "./ServerAdapter/Devices/DeviceLocalMicrophone";
import DeviceLocalSpeaker from "./ServerAdapter/Devices/DeviceLocalSpeaker";
import WebRTCUtil from "./WebRTCUtil";

// I.e. speaker
class AudioOutput {
  private readonly _deviceId: string;
  private readonly _groupId: string;
  private readonly _name: string;
  private readonly _context: AudioContext;
  private readonly _gainNode: GainNode;
  private readonly _localSourceNodes: MediaStreamAudioSourceNode[] = [];
  private _remoteSourceNode: MediaStreamAudioSourceNode | null = null;
  private _volume: number;

  constructor(deviceId: string, groupId: string, name: string, volume: number) {
    this._deviceId = deviceId;
    this._groupId = groupId;
    this._name = name;
    this._volume = volume;
    this._context = new AudioContext({
      // @ts-ignore: sinkId - experimental
      sinkId: deviceId,
    });
    this._gainNode = this._context.createGain();
    console.debug(
      `[AudioMixer] AudioOutput(): ${this._deviceId} created: groupId=${groupId}, name=${name}, volume=${volume}`,
    );
  }

  public get groupId(): string {
    return this._groupId;
  }

  public get name(): string {
    return this._name;
  }

  public get volume(): number {
    return this._volume;
  }

  public setVolume(percent: number): void {
    this._volume = percent;
    this._gainNode.gain.value = this._volume / 100;
    console.debug(
      `[AudioMixer] AudioOutput::setVolume(): ${this._deviceId}: volume=${this._volume}`,
    );
  }

  public connect(enableLocalPlayback: boolean): void {
    this._remoteSourceNode?.connect(this._gainNode);
    if (enableLocalPlayback) {
      this._localSourceNodes.forEach((node) => {
        node.connect(this._gainNode);
      });
    }
    this._gainNode.connect(this._context.destination);
  }

  public disconnect(): void {
    this._remoteSourceNode?.disconnect();
    this._localSourceNodes.forEach((node) => {
      node.disconnect();
    });
    this._gainNode.disconnect();
  }

  public async release(): Promise<void> {
    this.disconnect();
    await this._context.close();
    console.debug(
      `[AudioMixer] AudioOutput::release(): ${this._deviceId} released`,
    );
  }

  public addLocalStream(stream: MediaStream): void {
    const localSourceNode = this._context.createMediaStreamSource(stream);
    this._localSourceNodes.push(localSourceNode);
  }

  public addRemoteStream(stream: MediaStream): void {
    this._remoteSourceNode?.disconnect();
    this._remoteSourceNode = this._context.createMediaStreamSource(stream);
  }
}

// I.e. microphone
class AudioInput {
  private readonly _deviceId: string;
  private readonly _groupId: string;
  private readonly _name: string;
  private readonly _stream: MediaStream;
  private _muted = true;

  constructor(
    deviceId: string,
    groupId: string,
    name: string,
    stream: MediaStream,
    muted: boolean,
  ) {
    this._deviceId = deviceId;
    this._groupId = groupId;
    this._name = name;
    this._stream = stream;
    console.debug(
      `[AudioMixer] AudioInput(): ${this._deviceId} created: groupId=${groupId}, name=${name}, muted=${muted}`,
    );
    this.setMute(muted);
  }

  public get groupId(): string {
    return this._groupId;
  }

  public get name(): string {
    return this._name;
  }

  public get stream(): MediaStream {
    return this._stream;
  }

  public setMute(muted: boolean) {
    this._muted = muted;
    if (this._stream.getAudioTracks().length > 0) {
      const [track] = this._stream.getAudioTracks();
      track.enabled = !muted;
      console.debug(
        `[AudioMixer] AudioInput::setMute(): ${
          this._deviceId
        }: muted=${muted}, echoCancellation=${
          track.getSettings().echoCancellation
        }, noiseSuppression=${
          track.getSettings().noiseSuppression
        }, autoGainControl=${track.getSettings().autoGainControl}`,
      );
    }
  }

  public release(): void {
    this._stream.getTracks().forEach((track) => {
      track.stop();
    });
    console.debug(
      `[AudioMixer] AudioInput::release(): ${this._deviceId} released`,
    );
  }
}

// Local microphone mix to send to remote side
class AudioLocalMixer {
  private readonly _context: AudioContext;
  private readonly _destNode: MediaStreamAudioDestinationNode; // This is the mixed stream that gets streamed to remote side, eg destination
  private readonly _sourceNodes = new Map<string, MediaStreamAudioSourceNode>();

  constructor() {
    // We dont play locally this mix, its meant for sending to remote side only
    this._context = new AudioContext({
      // @ts-ignore: sinkId - experimental
      sinkId: { type: "none" },
    });
    this._destNode = this._context.createMediaStreamDestination();
  }

  public getDestStream(): MediaStream {
    return this._destNode.stream;
  }

  public disconnect(): void {
    this._sourceNodes.forEach((node) => {
      node.disconnect();
    });
    this._sourceNodes.clear();
  }

  public async release(): Promise<void> {
    this.disconnect();
    await this._context.close();
  }

  public addLocalStream(deviceId: string, stream: MediaStream): void {
    const sourceNode = this._context.createMediaStreamSource(stream);
    sourceNode.connect(this._destNode);
    this._sourceNodes.set(deviceId, sourceNode);
  }
}

export default class AudioMixer {
  private selectedMics: DeviceLocalMicrophone[];
  private selectedSpeakers: DeviceLocalSpeaker[];
  private remoteStream: MediaStream | null;
  private globalMute: boolean;
  private globalVolume: number;
  private enableEchoCancellation: boolean;
  private enableNoiseSuppression: boolean;
  private enableAutoGainControl: boolean;
  private enableLocalPlayback: boolean;
  private audioOutputs = new Map<string, AudioOutput>();
  private audioInputs = new Map<string, AudioInput>();
  private readonly audioLocalMix = new AudioLocalMixer();

  constructor(
    selectedMics: DeviceLocalMicrophone[],
    selectedSpeakers: DeviceLocalSpeaker[],
    remoteStream: MediaStream | null,
    globalMute: boolean,
    globalVolume: number,
    options: {
      enableEchoCancellation: boolean;
      enableNoiseSuppression: boolean;
      enableAutoGainControl: boolean;
      enableLocalPlayback: boolean;
    },
  ) {
    this.selectedMics = selectedMics;
    this.selectedSpeakers = selectedSpeakers;
    this.remoteStream = remoteStream;
    this.globalMute = globalMute;
    this.globalVolume = globalVolume;
    this.enableEchoCancellation = options.enableEchoCancellation;
    this.enableNoiseSuppression = options.enableNoiseSuppression;
    this.enableAutoGainControl = options.enableAutoGainControl;
    this.enableLocalPlayback = options.enableLocalPlayback;
  }

  private isSameDevice(input: AudioInput, output: AudioOutput): boolean {
    if (output.groupId === input.groupId) {
      return true;
    }
    // Some USB devices will have different groupId's even though on same device. Use name matching...
    // Pattern may need adjustment, for now expecting "Microphone (M99+)"/"Speaker (M99+)" to match or "Microphone (2- M99+) (10d6:dd00)"/"Speaker (2- M99+) (10d6:dd00)", etc.
    const inputName = input.name.match(" \\(.*\\).*");
    const outputName = output.name.match(" \\(.*\\).*");
    return !!(
      inputName?.length === 1 &&
      outputName?.length === 1 &&
      inputName[0] === outputName[0]
    );
  }

  private resetSpeakerMixer(): void {
    this.audioOutputs.forEach((output) => {
      output.disconnect();
      output.connect(this.enableLocalPlayback);
    });
  }

  private async releaseOutputs(): Promise<void> {
    for (const [_deviceId, output] of this.audioOutputs) {
      await output.release();
    }
    this.audioOutputs.clear();
  }

  private releaseInputs(): void {
    for (const [_deviceId, input] of this.audioInputs) {
      input.release();
    }
    this.audioInputs.clear();
  }

  public async release(): Promise<void> {
    this.releaseOutputs();
    this.releaseInputs();
    await this.audioLocalMix.release();
  }

  public getLocalAudioStream(): MediaStream {
    return this.audioLocalMix.getDestStream();
  }

  public async setEchoCancellation(enable: boolean): Promise<void> {
    console.debug(`[AudioMixer] setEchoCancellation(): ${enable}`);
    if (this.enableEchoCancellation !== enable) {
      this.enableEchoCancellation = enable;
      await this.resetAudioMixer();
    }
  }

  public async setNoiseSuppression(enable: boolean): Promise<void> {
    console.debug(`[AudioMixer] setNoiseSuppression(): ${enable}`);
    if (this.enableNoiseSuppression !== enable) {
      this.enableNoiseSuppression = enable;
      await this.resetAudioMixer();
    }
  }

  public async setAutoGainControl(enable: boolean): Promise<void> {
    console.debug(`[AudioMixer] setAutoGainControl(): ${enable}`);
    if (this.enableAutoGainControl !== enable) {
      this.enableAutoGainControl = enable;
      await this.resetAudioMixer();
    }
  }

  public async setLocalPlayback(enable: boolean): Promise<void> {
    console.debug(`[AudioMixer] setLocalPlayback(): ${enable}`);
    if (this.enableLocalPlayback !== enable) {
      this.enableLocalPlayback = enable;
      await this.resetAudioMixer();
    }
  }

  public async resetAudioMixer(): Promise<void> {
    console.debug(
      `[AudioMixer] resetAudioMixer(): enableLocalPlayback=${this.enableLocalPlayback}, enableEchoCancellation=${this.enableEchoCancellation}, enableNoiseSuppression=${this.enableNoiseSuppression}, enableAutoGainControl=${this.enableAutoGainControl}, mics=${this.selectedMics.length}, speakers=${this.selectedSpeakers.length}`,
    );
    const newOutputs = new Map<string, AudioOutput>();
    const newInputs = new Map<string, AudioInput>();
    const deviceList = await WebRTCUtil.GetDeviceList(true);

    this.audioLocalMix.disconnect();

    // Save all selected input microphone MediaStreams
    for (const inputDevice of deviceList.audioinput) {
      const selected = this.selectedMics.find(
        (myDevice: DeviceLocalMicrophone): boolean =>
          myDevice.deviceId === inputDevice.deviceId,
      );
      if (!selected) {
        continue;
      }
      const oldInput = this.audioInputs.get(inputDevice.deviceId);
      if (oldInput) {
        oldInput.release(); // Need to stop the track for new settings to take effect
        this.audioInputs.delete(inputDevice.deviceId);
      }
      const inputAudioStream = await WebRTCUtil.GetAudioMedia(
        inputDevice.deviceId,
        this.enableEchoCancellation,
        this.enableNoiseSuppression,
        this.enableAutoGainControl,
      );
      const input = new AudioInput(
        inputDevice.deviceId,
        inputDevice.groupId,
        inputDevice.label,
        inputAudioStream,
        this.globalMute,
      );
      newInputs.set(inputDevice.deviceId, input);
    }

    // For each speaker create AudioContext and GainNode for mixing and controlling volume to that speaker
    for (const outputDevice of deviceList.audiooutput) {
      const selected = this.selectedSpeakers.find(
        (myDevice: DeviceLocalSpeaker): boolean =>
          myDevice.deviceId === outputDevice.deviceId,
      );
      if (!selected) {
        continue;
      }
      const oldOutput = this.audioOutputs.get(outputDevice.deviceId);
      let volume = this.globalVolume;
      if (oldOutput) {
        volume = oldOutput.volume;
        await oldOutput.release();
        this.audioOutputs.delete(outputDevice.deviceId);
      }
      const output = new AudioOutput(
        outputDevice.deviceId,
        outputDevice.groupId,
        outputDevice.label,
        volume,
      );
      // Add local source stream nodes for each audioInput/microphone to mix into this audioOutput/speaker
      newInputs.forEach((input) => {
        // ... excluding the mic on same device
        if (!this.isSameDevice(input, output)) {
          output.addLocalStream(input.stream);
        }
      });
      // Also add existing remote stream if any
      if (this.remoteStream) {
        output.addRemoteStream(this.remoteStream);
      }
      output.connect(this.enableLocalPlayback);
      newOutputs.set(outputDevice.deviceId, output);
    }

    // Cleanup any remaining old devices if any
    await this.releaseOutputs();
    this.releaseInputs();

    this.audioOutputs = newOutputs;
    this.audioInputs = newInputs;

    // Add local source stream nodes for each audioInput/microphone to mix for sending to remote dest
    this.audioInputs.forEach((input, deviceId) => {
      this.audioLocalMix.addLocalStream(deviceId, input.stream);
    });
  }

  public async processSelectedMics(
    mics: DeviceLocalMicrophone[],
    muted: boolean,
  ): Promise<void> {
    console.debug(
      `[AudioMixer] processSelectedMics(): count=${mics.length}, muted = ${muted}`,
    );
    this.selectedMics = mics;
    this.globalMute = muted;
    await this.resetAudioMixer();
  }

  public async processSelectedSpeakers(
    speakers: DeviceLocalSpeaker[],
  ): Promise<void> {
    console.debug(
      `[AudioMixer] processSelectedSpeakers(): count=${speakers.length}`,
    );
    this.selectedSpeakers = speakers;
    await this.resetAudioMixer();
  }

  public setMicMute(muted: boolean): void {
    console.debug(`[AudioMixer] setMicMute() muted=`, muted);
    this.globalMute = muted;
    this.audioInputs.forEach((input) => {
      input.setMute(muted);
    });
  }

  public setSpeakerVolume(percent: number): void {
    console.debug(`[AudioMixer] setSpeakerVolume() percent=`, percent);
    this.globalVolume = percent;
    this.audioOutputs.forEach((output) => {
      output.setVolume(percent);
    });
  }

  public addRemoteStream(stream: MediaStream): void {
    console.debug(`[AudioMixer] addRemoteStream() stream=`, stream);
    this.remoteStream = stream;
    this.audioOutputs.forEach((output) => {
      output.addRemoteStream(stream);
    });
    this.resetSpeakerMixer();
  }
}
