import React from "react";
import { AlertProps as MuiAlertProps, AlertTitle } from "@mui/material";
import { OutlinedAlertStyled } from "./OutlinedAlert.style";

export interface AlertProps extends MuiAlertProps {
  children: JSX.Element | string;
  margintop?: number;
  marginbottom?: number;
  marginleft?: number;
  marginright?: number;
  title?: string;
}

const OutlinedAlert = (props: AlertProps) => {
  const {
    children,
    title,
    margintop = 0,
    marginbottom = 0,
    marginleft = 0,
    marginright = 0,
    ...restOfProps
  } = props;

  return (
    <OutlinedAlertStyled
      margintop={margintop}
      marginbottom={marginbottom}
      marginleft={marginleft}
      marginright={marginright}
      variant="outlined"
      {...restOfProps}
    >
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      {children}
    </OutlinedAlertStyled>
  );
};

export default OutlinedAlert;
