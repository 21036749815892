import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class InvalidSpotlightMessageFormatException extends BaseException {
  constructor(message = "Invalid spotlight message format", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_SPOTLIGHT_MESSAGE_FORMAT;
  }
}
