import styled from "@emotion/styled";

const SessionInProgressStyled = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

export const SessionInProgressTelestrationControlsStyled = styled.div`
  left: 10px;
  position: fixed;
  top: 60px;
`;

export default SessionInProgressStyled;
