import React from "react";
import {
  Permission,
  Permissions,
  PermissionContext,
} from "./PermissionContext";

type Props = {
  permissions?: Permissions;
  children: React.ReactNode;
};

export const PermissionProvider: React.FunctionComponent<Props> = ({
  permissions = [],
  children,
}) => {
  const isAllowedTo = (permission: Permission) =>
    permissions.includes(permission);

  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};
