import React, { FC } from "react";
import { VideoGridStyled } from "./VideoGrid.styles";
import { Box } from "@mui/material";

interface VideoGridProps {
  children?: React.ReactNode;
  emptySlot: React.ReactNode;
}

const VideoGrid: FC<VideoGridProps> = ({
  children,
  emptySlot,
}: VideoGridProps): JSX.Element => {
  const childArray = React.Children.toArray(children);
  let numberChildren = childArray.length;
  if (numberChildren > 4) {
    console.log("VideoGrid received more than 4 Connection");
    numberChildren = 4;
  }

  const hasEmptySlot = numberChildren === 0;

  return (
    <VideoGridStyled data-testid="video-grid" numberOfVideos={numberChildren}>
      {children}
      {hasEmptySlot && (
        <Box
          alignItems="center"
          justifyContent="center"
          position="relative"
          key="empty-slot"
        >
          {emptySlot}
        </Box>
      )}
    </VideoGridStyled>
  );
};

export default VideoGrid;
