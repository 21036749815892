export enum TestResult {
  Pass,
  Fail,
  Unknown,
  DidNotRun,
}

export interface TestResultsList {
  socketIO: TestResult;
  audioServer: TestResult;
  audioMedia: TestResult;
  videoServer: TestResult;
  videoMedia: TestResult;
}

export enum TestList {
  socketIO = "socketIO",
  audioServer = "audioServer",
  audioMedia = "audioMedia",
  videoServer = "videoServer",
  videoMedia = "videoMedia",
}

export const defaultTestResultsList: TestResultsList = {
  socketIO: TestResult.Unknown,
  audioServer: TestResult.Unknown,
  audioMedia: TestResult.Unknown,
  videoServer: TestResult.Unknown,
  videoMedia: TestResult.Unknown,
};
