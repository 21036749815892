import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToSendOrgInvitationEmailMeta {
  originalError: Error;
}

export class FailedToSendOrgInvitationEmail extends BaseException {
  constructor(
    message = "Failed to send organization invitation email",
    meta?: unknown,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_SEND_ORG_INVITATION_EMAIL;
  }
}
