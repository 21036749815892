import React, {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AppName,
  Logger,
  createLogger,
  getEnvironment,
} from "@proximie/common";
import overrideLogger from "../overrideLogger";
import { useAuthenticatedUser } from "./media-client-authenticated-user-context";
import { UseSocketIoContext } from "./socket-io-context";
import { liveApiSockets } from "@proximie/dataregion-api";
import { useUser } from "../hooks/useUser";

export interface LoggerContext {
  token: string | undefined;
}

interface LoggerProviderProps {
  children: ReactNode;
}

const LoggerContext = createContext<LoggerContext | null>(null);

export const LoggerProvider = ({ children }: LoggerProviderProps) => {
  const authContext = useAuthenticatedUser();
  const socketContext = UseSocketIoContext();
  const { user } = useUser();

  const [logger, setLogger] = useState<Logger | undefined>(undefined);

  const [token, setToken] = useState<string | undefined>(undefined);

  const loggerProviderValue = useMemo(() => ({ token }), [token]);

  const generateLogger = ({
    newRelicLogUrl,
    mediaSessionId,
    environmentName,
  }: {
    mediaSessionId: string;
    newRelicLogUrl: string;
    environmentName: string;
  }) => {
    const token = authContext?.token || "NO_TOKEN";

    setToken(token);

    const logger = createLogger({
      configs: {
        newRelic: {
          auth: token,
          hostURL: newRelicLogUrl || "",
        },
      },
      mediaSessionId,
      app: AppName.LIVE,
      env: getEnvironment(environmentName),
      version: "1.0",
    });

    setLogger(logger);

    if (newRelicLogUrl) {
      overrideLogger(logger);
    }
  };

  useEffect(() => {
    if (authContext?.token && logger) {
      setToken(authContext?.token);

      logger?.setConfig?.({ auth: authContext?.token });
    }
  }, [authContext?.token, logger]);

  useEffect(() => {
    if (user?.profileReference && logger) {
      logger?.setMetadata?.({ profileReference: user.profileReference });
    }
  }, [user?.profileReference, logger]);

  useEffect(() => {
    socketContext.on(
      liveApiSockets.MediaSessionEventType.sessionJoined,
      generateLogger,
    );

    return () => {
      socketContext.off(
        liveApiSockets.MediaSessionEventType.sessionJoined,
        generateLogger,
      );
    };
  }, []);

  return (
    <LoggerContext.Provider value={loggerProviderValue}>
      {children}
    </LoggerContext.Provider>
  );
};
