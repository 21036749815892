import * as t from "io-ts";
import { makeRoute } from "./dataregion-api";

const healthStatus = t.type({
  status: t.string,
});

export const getDataregionApiHealth = makeRoute(
  "/health",
  t.type({}),
  healthStatus,
);

export const getLiveApiHealth = makeRoute(
  "/health/liveApi",
  t.type({}),
  healthStatus,
);

export type HealthStatus = t.TypeOf<typeof healthStatus>;
