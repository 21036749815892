import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface DeniedAccessToWebcamExceptionMeta {
  originalError: Error;
}

export class DeniedAccessToWebcamException extends BaseException {
  constructor(
    message = "Denied access to webcam exception",
    meta: DeniedAccessToWebcamExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.DENIED_ACCESS_TO_WEBCAM;
  }
}
