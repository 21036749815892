export type ColorName = keyof typeof colors;

export type Colors = typeof colors & Record<string, string>;

export const colors = {
  // Brand Scale
  Brand50: "#E8EAF6",
  Brand100: "#C5CAE9",
  Brand200: "#A3ACDC",
  Brand300: "#8597D6",
  Brand400: "#5C6AC0",
  Brand500: "#3F50B5",
  Brand600: "#3948AB",
  Brand700: "#303E9F",
  Brand800: "#283493",
  Brand900: "#1A237E",
  Brand950: "#121858",

  // Secondary600
  Secondary50: "#EEF2FF",
  Secondary100: "#DAE2FF",
  Secondary200: "#BCCCFF",
  Secondary300: "#9DBCFF",
  Secondary400: "#7695FF",
  Secondary500: "#637BFE",
  Secondary600: "#3D5AFE",
  Secondary700: "#2A3EB1",
  Secondary800: "#25379D",
  Secondary900: "#1B2874",
  Secondary950: "#151F59",

  // Blue
  Blue50: "#EBF1FB",
  Blue100: "#E4EDFB",
  Blue200: "#D0E2FB",
  Blue300: "#B0D1FC",
  Blue400: "#82BDFE",
  Blue500: "#389AEB",
  Blue600: "#0A7AC2",
  Blue700: "#0669A8",
  Blue800: "#034976",
  Blue900: "#072843",
  Blue950: "#041829",

  // Grey
  Grey50: "#F4F5F5",
  Grey100: "#EDEEF0",
  Grey200: "#E5E7EB",
  Grey300: "#D1D5DB",
  Grey400: "#9CA3AF",
  Grey500: "#6B7280",
  Grey600: "#4B5563",
  Grey700: "#374151",
  Grey800: "#1F2937",
  Grey900: "#111827",
  Grey950: "#121212",

  // Red
  Red50: "#FFEBEE",
  Red100: "#FFCDD2",
  Red200: "#EF9A9A",
  Red300: "#E57373",
  Red400: "#EF5350",
  Red500: "#F44336",
  Red600: "#E1302D",
  Red700: "#D32F2F",
  Red800: "#C62828",
  Red900: "#B71C1C",
  Red950: "#9E1818",

  // Green
  Green50: "#F3FAF7",
  Green100: "#DEF7EC",
  Green200: "#BCF0DA",
  Green300: "#84E1BC",
  Green400: "#31C48D",
  Green500: "#0E9F6E",
  Green600: "#057A55",
  Green700: "#046C4E",
  Green800: "#03543F",
  Green900: "#014737",
  Green950: "#012E24",

  // Amber
  Amber50: "#FFFFEA",
  Amber100: "#FFFDCC",
  Amber200: "#FFF394",
  Amber300: "#FBD879",
  Amber400: "#FFBE32",
  Amber500: "#F06C00",
  Amber600: "#CF4A00",
  Amber700: "#C44600",
  Amber800: "#B24000",
  Amber900: "#993700",
  Amber950: "#542C15",

  // White
  White: "#FFFFFF",
  WhiteA0: "#FFFFFF00",
  WhiteA50: "#FFFFFF0A",
  WhiteA100: "#FFFFFF14",
  WhiteA200: "#FFFFFF26",
  WhiteA300: "#FFFFFF4D",
  WhiteA400: "#FFFFFF63",
  WhiteA500: "#FFFFFF96",
  WhiteA600: "#FFFFFFA3",
  WhiteA700: "#FFFFFFB2",
  WhiteA800: "#FFFFFFC7",
  WhiteA900: "#FFFFFFDE",
  WhiteA950: "#FFFFFFDE",

  // Black
  Black: "#000000",
  BlackA0: "#00000000",
  BlackA50: "#1134460A",
  BlackA100: "#0E1F3914",
  BlackA200: "#17253B26",
  BlackA300: "#0E182A4D",
  BlackA400: "#070D1D63",
  BlackA500: "#05091596",
  BlackA600: "#050915A3",
  BlackA700: "#03050CB2",
  BlackA800: "#03050CC7",
  BlackA900: "#04050CDE",
  BlackA950: "#030307F2",
};
