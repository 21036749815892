import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { augmentObjectWithSequentialID } from "../common/augmentObjectWithLegacyID";
import { user } from "@proximie/api";

const getUser: () => Promise<user.User> = async () => {
  const user = (
    await axios.get<user.User>(`${environment.apiUrl}/api/v2/users/me`)
  ).data;
  const [userWithLegacyId] = await augmentObjectWithSequentialID<user.User>([
    user,
  ]);
  return userWithLegacyId;
};

export const useUser = (opts?: SWRConfiguration) => {
  const { data, error } = useSWR<user.User>(`user`, getUser, opts);

  return {
    user: data,
    isUserLoading: !error && !data,
    error: error,
  };
};
