import Regular from "./SuisseIntl-Regular.woff";
import Italic from "./SuisseIntl-RegularItalic.woff";
import Medium from "./SuisseIntl-Medium.woff";
import Bold from "./SuisseIntl-Bold.woff";
import MediumItalic from "./SuisseIntl-MediumItalic.woff";
import SemiBold from "./SuisseIntl-SemiBold.woff";
import SemiBoldItalic from "./SuisseIntl-SemiBoldItalic.woff";
import BoldItalic from "./SuisseIntl-BoldItalic.woff";
import Black from "./SuisseIntl-Black.woff";
import BlackItalic from "./SuisseIntl-BlackItalic.woff";

const Fonts = {
  Regular,
  Italic,
  Medium,
  Bold,
  MediumItalic,
  SemiBold,
  SemiBoldItalic,
  BoldItalic,
  Black,
  BlackItalic,
};

export default Fonts;
