export const DNS_RECORD_NAMES = {
  DEFAULT: "proximieengineering.com",
  E2E: "proximieengineering.com",
  DEVELOPMENT: "proximieengineering.com",
  STAGING: "proximiestaging.com",
  PRODUCTION: "proximie.net",
};

export const ENV_NAMES = {
  LOCAL: "local",
  DEFAULT: "default",
  E2E: "e2e",
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",
};

export const REGION_NAMES = {
  EU_CENTRAL_1: "eu-central-1",
  AP_SOUTHEAST_1: "ap-southeast-1",
  AP_SOUTHEAST_2: "ap-southeast-2",
  US_EAST_2: "us-east-2",
  ME_JEDDAH_1: "jeddah",
  ME_DUBAI_1: "dubai",
};
