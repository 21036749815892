import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToSendAppointmentInvitationEmailMeta {
  originalError: Error;
}

export class FailedToSendAppointmentInvitationEmail extends BaseException {
  constructor(
    message = "Failed to send appointment invitation email",
    meta?: unknown,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_SEND_APPOINTMENT_INVITATION_EMAIL;
  }
}
