import { EventEmitter } from "events";

export enum EndpointType {
  None,
  Dcp,
  Audio,
  Video,
  Echo,
  Virtual,
}

export interface EndpointConfig {
  mediaSessionId: string;
  profileId: number;
  userUUID: string;
  bitrate?: number;
}

//eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEndpointRequestHeader {}
export default abstract class Endpoint extends EventEmitter {
  readonly mediaSessionId: string;
  readonly profileId: number;
  readonly userUUID: string;
  public abstract readonly endpointType: EndpointType;

  constructor(public config: EndpointConfig) {
    super();

    this.mediaSessionId = config.mediaSessionId;
    this.profileId = config.profileId;
    this.userUUID = config.userUUID;
  }

  abstract connect(): Promise<void>;

  close(error?: Error): Promise<void> {
    if (error) {
      this.emit("error", error);
    } else {
      this.emit("closed");
    }
    return Promise.resolve();
  }

  //LATER - should Endpoint be a member of Device rather than being tied together in Connection
  request(_header: IEndpointRequestHeader, _value: unknown): Promise<void> {
    return Promise.resolve();
  }
}
