import styled from "@emotion/styled";
import proximieBackgroundProgressive from "./../../../src/assets/images/Proximie-Background-Progressive.png";

const AuthBackground = styled.div`
  background: url("${proximieBackgroundProgressive}");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: fixed;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
`;

export default AuthBackground;
