import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from "react";
import { UsageTracker } from "./usage-tracker";

export interface UsageTrackerProviderProps {
  children: typeof React.Children | ReactElement;
}

export interface UsageTrackerContext {
  tracker: UsageTracker;
}

const DEFAULT_USAGE_TRACKER_CONTEXT: UsageTrackerContext = {
  tracker: new UsageTracker(),
};

export const UsageTrackerContext = createContext<UsageTrackerContext>(
  DEFAULT_USAGE_TRACKER_CONTEXT,
);

export const useUsageTracker = () => useContext(UsageTrackerContext);

export const UsageTrackerProvider = ({
  children,
}: UsageTrackerProviderProps) => {
  const [tracker] = useState<UsageTracker>(new UsageTracker());

  return (
    <UsageTrackerContext.Provider value={{ tracker }}>
      <>{children}</>
    </UsageTrackerContext.Provider>
  );
};
