import { AppEnvironment, AppName, Config, Logger, MetaData } from "./common";

import { createConsoleLogger } from "./console-logger";
// import { createNewRelicLogger, NewRelicLoggerProps } from "./newrelic-logger";
import { createNewRelicBrowserLogger } from "./newrelic-browser-logger";
import { OtelBrowserLogger } from "./otel-browser-logger";

export type LoggerTransport = "nr_http" | "otlp";

export interface LoggerProps extends MetaData {
  config: Config;
  transport?: LoggerTransport;
}

export const createLogger = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config,
  transport,
  ...otherProps
}: LoggerProps): Logger => {
  if (
    otherProps.app === AppName.LIVE &&
    otherProps.env !== AppEnvironment.LOCALHOST
  ) {
    switch (transport) {
      case "otlp":
        return new OtelBrowserLogger(config, otherProps);
      case "nr_http":
      default:
        return createNewRelicBrowserLogger({
          ...otherProps,
          config,
        });
    }
  }
  return createConsoleLogger(otherProps);
};
