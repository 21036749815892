import React, { FC, Fragment } from "react";
import { navigateToDashboard } from "../../utils/index";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Logo } from "@proximie/components";
import { useDashboardUrl } from "../../hooks/useDashboardUrl";

const SessionEnd: FC = () => {
  const { colors } = useTheme();
  const dashboardUrl = useDashboardUrl();

  return (
    <Fragment>
      <Box m={2} style={{ position: "absolute" }} color="darkblue">
        <Logo variant="dashboard-app" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Box>
          <ErrorOutlineIcon />
        </Box>
        <Box>
          <Typography variant="h5">Session ended</Typography>
        </Box>
        <Box mt={2}>
          <Typography
            style={{ color: colors.BlackMediumEmphasis }}
            variant={"body2"}
            fontSize="14px"
          >
            This session has ended.
          </Typography>
        </Box>
        <Box mt={3}>
          <Button
            data-cy="close-session"
            variant="contained"
            color={"secondary"}
            onClick={() => navigateToDashboard(dashboardUrl)}
          >
            Back to dashboard
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default SessionEnd;
