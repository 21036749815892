import React, { useState, ReactNode } from "react";
import TextButton from "../TextButton/TextButton";
import {
  StyledTextButton,
  StyledContainer,
  StyledCollapsibleText,
} from "./CollapsibleText.style";

export interface CollapsibleTextProps {
  children: ReactNode & string;
  minLines?: number;
  maxLines?: number;
  truncateLength?: number; // The number of characters that the collapsed text should truncate to
}

const truncateString = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export function CollapsibleText({
  children,
  minLines = 1,
  maxLines,
  truncateLength = 50,
}: CollapsibleTextProps) {
  const [expanded, setExpanded] = useState(false);
  const truncatedText = truncateString(children, truncateLength);

  const ToggleBtn = () => (
    <StyledTextButton>
      <TextButton onClick={() => setExpanded(!expanded)}>
        {expanded ? "show less" : "show more"}
      </TextButton>
    </StyledTextButton>
  );
  return (
    <StyledContainer>
      <StyledCollapsibleText
        expanded={expanded}
        minLines={minLines}
        maxLines={maxLines}
      >
        {!expanded ? (
          truncatedText
        ) : (
          <>
            {children} &nbsp;
            <ToggleBtn />
          </>
        )}
      </StyledCollapsibleText>
      {!expanded && truncatedText.length < children.length && <ToggleBtn />}
    </StyledContainer>
  );
}

export default CollapsibleText;
