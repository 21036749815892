import MonitorDeviceLocal from "./MonitorDeviceLocal";
import DeviceLocalMicrophone from "../Devices/DeviceLocalMicrophone";
import { MediaDeviceKind } from "../../../index";

export default class MonitorDeviceLocalMicrophone extends MonitorDeviceLocal {
  protected kind: MediaDeviceKind = "audioinput";

  protected override createDevice(
    info: MediaDeviceInfo,
  ): DeviceLocalMicrophone {
    return new DeviceLocalMicrophone(info.deviceId, {
      deviceInfo: info,
    });
  }
}
