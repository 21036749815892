import { decode } from "io-ts-promise";
import { api, noContentApi, replaceIn } from "@proximie/api";
import axios, { AxiosRequestConfig } from "axios";

export const get = async <Req, Res>(
  apiObject: api<Req, Res>,
  idsInUrl: Record<string, number | string> = {},
  config: AxiosRequestConfig = {},
): Promise<Res> => {
  const path = replaceIn(apiObject.path, idsInUrl);
  const rawRes = await axios.get(path, config);
  return decode(apiObject.resCodec, rawRes.data);
};

export const post = async <Req, Res>(
  apiObject: api<Req, Res>,
  body: Req,
  config: AxiosRequestConfig = {},
): Promise<Res> => {
  const rawRes = await axios.post(apiObject.path, body, config);
  return decode(apiObject.resCodec, rawRes.data);
};

export const postWithIds = async <Req, Res>(
  apiObject: api<Req, Res>,
  idsInUrl: Record<string, number | string> = {},
  body: Req,
  config: AxiosRequestConfig = {},
): Promise<Res> => {
  const path = replaceIn(apiObject.path, idsInUrl);
  const rawRes = await axios.post(path, body, config);
  return decode(apiObject.resCodec, rawRes.data);
};

export const patchWithIds = async <Req, Res>(
  apiObject: api<Req, Res>,
  idsInUrl: Record<string, number | string> = {},
  body: Req,
  config: AxiosRequestConfig = {},
): Promise<Res> => {
  const path = replaceIn(apiObject.path, idsInUrl);
  const rawRes = await axios.patch(path, body, config);
  return decode(apiObject.resCodec, rawRes.data);
};

export const patchWithIdsNoContent = async <Req>(
  apiObject: noContentApi<Req>,
  idsInUrl: Record<string, number | string> = {},
  body: Req,
  config: AxiosRequestConfig = {},
): Promise<void> => {
  const path = replaceIn(apiObject.path, idsInUrl);
  await axios.patch(path, body, config);
};

export const put = async <Req>(
  apiObject: noContentApi<Req>,
  idsInUrl: Record<string, number | string> = {},
  body: Req,
  config: AxiosRequestConfig = {},
): Promise<void> => {
  const path = replaceIn(apiObject.path, idsInUrl);
  await axios.put(path, body, config);
};

export const deleteHTTP = async <Req>(
  apiObject: noContentApi<Req>,
  idsInUrl: Record<string, number | string> = {},
  body: Req,
): Promise<void> => {
  const path = replaceIn(apiObject.path, idsInUrl);
  await axios.delete(path, {
    data: body,
  });
};
