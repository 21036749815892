import { Organisation } from "./session";

export type Member = {
  id: string;
  profileId?: number;
  name?: string;
  phone?: string;
  email: string;
  userTitle?: string;
  speciality?: string;
  invitedAt?: string;
  organisation: Organisation;
};
