import React from "react";
import { SvgIcon as MuiSvgIcon } from "@mui/material";

type SignalCellularAltProps = {
  color: string;
  firstBarColor?: string;
};

export const SignalCellularAlt = (
  { color, firstBarColor }: SignalCellularAltProps = { color: "white" },
) => (
  <MuiSvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M17.2539 4H20.2539V20H17.2539V4ZM5.25391 14H8.25391V20H5.25391V14ZM11.2539 9H14.2539V20H11.2539V9Z"
        fill={color}
        fillOpacity="0.87"
      />
      {firstBarColor && (
        <path
          d="M5.25391 14H8.25391V20H5.25391V14Z"
          fill={firstBarColor}
          fillOpacity="0.87"
        />
      )}
    </svg>
  </MuiSvgIcon>
);
