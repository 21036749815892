export const BandwidthIssueDescription =
  "You need a faster internet connection for optimal performance.";
export const LatencyIssueDescription =
  "High latency is causing noticeable delays in audio and video streaming.";
export const PacketLossIssueDescription =
  "High packet loss causing issues with audio/video.";
export const StreamingProtocolIssueDescription = (protocol: string) =>
  `Suboptimal protocol (${protocol}) in use, UDP recommended.`;
export const ServiceReachabilityIssueDescription =
  "Some critical streaming services are blocked.";
export const VideoIssueDescription =
  "The system is adjusting video quality to compensate for network issues.";
