import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class UserAlreadyExistsInOrganisationException extends BaseException {
  constructor(message = "User already exists in organization", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.USER_ALREADY_EXISTS_IN_ORGANISATION;
  }
}
