import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { augmentObjectWithSequentialID } from "../common/augmentObjectWithLegacyID";
import { Member } from "@proximie/api";

const getMembers: (organisationId: string) => () => Promise<Member[]> =
  (organisationId?: string) => async () => {
    const members = (
      await axios.get(
        `${environment.apiUrl}/api/v2/organisations/${organisationId}/members`,
      )
    ).data.members;

    return await augmentObjectWithSequentialID<Member>(members);
  };

export const useMembers = (
  organisationId: string | undefined,
  opts?: SWRConfiguration,
) => {
  const { data, error } = useSWR<Member[]>(
    organisationId ? `organisation-${organisationId}-members` : null,
    getMembers(organisationId || ""),
    opts,
  );

  return {
    members: data,
    areMembersLoading: !error && !data,
    membersError: error,
  };
};
