import Monitor from "./Monitor";
import Device from "../Devices/Device";
import DeviceLocal from "../Devices/DeviceLocal";
import EndpointVirtual from "../Endpoints/EndpointVirtual";
import WebRTCUtil, { MediaDeviceKind } from "../../WebRTCUtil";

type DeviceList = Record<string, DeviceLocal>;

export default abstract class MonitorDeviceLocal extends Monitor {
  protected abstract kind: MediaDeviceKind;
  private devices: DeviceList = {};
  private defaultGroupId = "";

  constructor(protected room: EndpointVirtual) {
    super(room);

    if (!navigator.mediaDevices?.enumerateDevices) {
      throw new Error("enumerateDevices not supported.");
    }

    navigator.mediaDevices.addEventListener(
      "devicechange",
      this.generateDeviceList.bind(this),
    );

    // get some initial values
    this.generateDeviceList();
  }

  async initialisePermissionListener(name: PermissionName): Promise<void> {
    navigator.permissions
      .query({
        name: name,
      })
      .then((status: PermissionStatus) => {
        status.onchange = (): void => {
          console.info(
            `Permission for ${name} has changed - now=${status.state}`,
          );
          this.generateDeviceList();
        };
      });
  }

  protected abstract createDevice(info: MediaDeviceInfo): DeviceLocal;

  private async generateDeviceList(): Promise<void> {
    // get the device list including duplicates (esp. the defaults)
    const deviceList = await WebRTCUtil.GetDeviceList(true);

    deviceList[this.kind].forEach((info: MediaDeviceInfo): void => {
      if (info.deviceId === "communications") {
        return;
      }

      if (info.deviceId === "default") {
        // store the groupId - we need it for determining which is the default device
        this.defaultGroupId = info.groupId;
        return;
      }

      if (this.devices[info.deviceId]) {
        // we've already got it
        return;
      }

      this.devices[info.deviceId] = this.createDevice(info);
      this.emit("added", this.devices[info.deviceId]);
    });

    // check if any have been removed
    Object.keys(this.devices).forEach((deviceId: string): void => {
      if (
        !deviceList[this.kind].find(
          (info: MediaDeviceInfo): boolean => info.deviceId === deviceId,
        )
      ) {
        const removedDevice = this.devices[deviceId];
        delete this.devices[deviceId];
        this.emit("removed", removedDevice);
        removedDevice.emit("closed");
      }
    });
  }

  override get deviceList(): Device[] {
    return Object.values(this.devices);
  }

  getDefaultDeviceOrAny(orAny?: boolean): DeviceLocal | undefined {
    const devices = this.deviceList as DeviceLocal[];

    const defaultDevice = devices.find(
      (device: DeviceLocal): boolean =>
        device.options.deviceInfo.groupId === this.defaultGroupId,
    );

    if (defaultDevice) {
      return defaultDevice;
    } else if (orAny) {
      return devices[0];
    } else {
      return undefined;
    }
  }
}
