import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToDeleteMediaSessionExceptionMeta {
  mediaSessionId: string;
}

export class FailedToDeleteMediaSessionException extends BaseException {
  constructor(
    message = "Failed to delete media session",
    meta?: FailedToDeleteMediaSessionExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_DELETE_MEDIA_SESSION;
  }
}
