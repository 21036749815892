export interface DurationOptionsType {
  id: number;
  label: string;
  value: string;
}
const pluraliseDuration = (value: number, description: string) => {
  if (value === 1) {
    return `${value} ${description}`;
  }

  return `${value} ${description}s`;
};

export const GetDurationDisplay = (
  durationMilliSeconds: number,
  abbreviated = false,
): string => {
  const seconds = Math.floor((durationMilliSeconds / 1000) % 60),
    minutes = Math.floor((durationMilliSeconds / (1000 * 60)) % 60),
    hours = Math.floor(durationMilliSeconds / (1000 * 60 * 60));

  const hourInMs = 3600000;

  if (durationMilliSeconds >= hourInMs) abbreviated = true;

  const hourDescription = abbreviated ? "hr" : "hour";
  const minuteDescription = abbreviated ? "min" : "minute";

  let hoursDisplay = "";
  let minutesDisplay = "";
  let secondsDisplay = "";

  if (hours > 0) hoursDisplay = pluraliseDuration(hours, hourDescription);
  if (minutes > 0)
    minutesDisplay = pluraliseDuration(minutes, minuteDescription);
  if (seconds > 0) secondsDisplay = pluraliseDuration(seconds, "second");

  return `${hoursDisplay} ${minutesDisplay} ${secondsDisplay}`
    .trimEnd()
    .trimStart();
};

export const GetDurationOptions = (): DurationOptionsType[] => {
  return [
    {
      id: 900000,
      label: "15 mins",
      value: "900000",
    },
    {
      id: 1800000,
      label: "30 mins",
      value: "1800000",
    },
    {
      id: 2700000,
      label: "45 mins",
      value: "2700000",
    },
    {
      id: 3600000,
      label: "1 hr",
      value: "3600000",
    },
    {
      id: 5400000,
      label: "1.5 hrs",
      value: "5400000",
    },
    {
      id: 7200000,
      label: "2 hrs",
      value: "7200000",
    },
    {
      id: 9000000,
      label: "2.5 hrs",
      value: "9000000",
    },
    {
      id: 10800000,
      label: "3 hrs",
      value: "10800000",
    },
    {
      id: 14400000,
      label: "4 hrs",
      value: "14400000",
    },
    {
      id: 18000000,
      label: "5 hrs",
      value: "18000000",
    },
    {
      id: 21600000,
      label: "6 hrs",
      value: "21600000",
    },
    {
      id: 25200000,
      label: "7 hrs",
      value: "25200000",
    },
    {
      id: 28800000,
      label: "8 hrs",
      value: "28800000",
    },
    {
      id: 32400000,
      label: "9 hrs",
      value: "32400000",
    },
    {
      id: 36000000,
      label: "10 hrs",
      value: "36000000",
    },
    {
      id: 39600000,
      label: "11 hrs",
      value: "39600000",
    },
    {
      id: 43200000,
      label: "12 hrs",
      value: "43200000",
    },
  ];
};
export const DEFAULT_ESTIMATED_DURATION: DurationOptionsType =
  GetDurationOptions()[3];
export const DEFAULT_ESTIMATED_DURATION_INCREASED: DurationOptionsType =
  GetDurationOptions()[4];
export const GetRangedDuration = (duration: number): DurationOptionsType => {
  const hours = Math.floor(duration / (1000 * 60 * 60));
  if (hours < 1) return RANGE_DURATIONS[0];
  if (hours < 4) return RANGE_DURATIONS[1];
  else return RANGE_DURATIONS[2];
};
export const GetDurationFromRangeId = (durationRangeId: number): number => {
  return GetDurationFromRange({ id: durationRangeId, label: "", value: "" });
};

export const GetDurationFromRange = (
  durationRange: DurationOptionsType,
): number => {
  if (durationRange.id == 1) return 1800000; //30 minutes
  if (durationRange.id == 2) return 9000000; // 2.5 hours
  if (durationRange.id == 3) return 28800000; //8 hours
  else return 43200000; //12 hours
};

export const RANGE_DURATIONS: DurationOptionsType[] = [
  { id: 1, label: "1 hr or less", value: "LessThanOneHour" },
  { id: 2, label: "1-4 hrs", value: "OneToThreeHours" },
  { id: 3, label: "4-12 hrs", value: "FourToTwelveHours" },
];
