import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToDeleteRecordsByDeIdsExceptionMeta {
  deIds: string[];
  dataregionUrl: string;
  originalError?: Error;
}

export class FailedToDeleteRecordsByDeIdsException extends BaseException {
  constructor(
    message = "Failed to delete records by deIds",
    meta: FailedToDeleteRecordsByDeIdsExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_DELETE_RECORDS_BY_DE_IDS;
  }
}
