import * as React from "react";
import MUIStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Box, useTheme } from "@mui/material";
import { Typography } from "../Typography/Typography";
import Icon from "../Icon/Icon";

type Step = {
  label: string;
  required?: boolean;
};

export interface StepperProps {
  steps: Step[];
  activeStep: number;
}

function StepIcon(props: {
  active?: boolean;
  completed?: boolean;
  stepNum: number;
}) {
  const { colors } = useTheme();
  const { active, completed, stepNum } = props;
  const commonStyles = {
    width: "24px",
    height: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "64px",
    border: `1px solid ${colors.Grey500}`,
    color: colors.BlackA700,
    "& path": {
      transform: "translate(18%, 26%)",
    },
  };

  if (completed) {
    return (
      <Box sx={commonStyles}>
        <Icon name="check" color="inherit" />
      </Box>
    );
  }

  if (active) {
    return (
      <Box
        sx={{
          ...commonStyles,
          borderColor: colors.Brand600,
          background: colors.Brand600,
          color: colors.White,
        }}
      >
        <Typography variant="subtitle3">{stepNum}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={commonStyles}>
      <Typography variant="subtitle3">{stepNum}</Typography>
    </Box>
  );
}

export default function Stepper({ steps, activeStep = 0 }: StepperProps) {
  const { colors } = useTheme();
  return (
    <MUIStepper activeStep={activeStep}>
      {steps.map(({ label, required = true }, index) => {
        const stepProps: { completed?: boolean; active?: boolean } = {
          completed: index < activeStep,
          active: index === activeStep,
        };
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        if (!required) {
          labelProps.optional = (
            <Typography variant="subtitle3">Optional</Typography>
          );
        }

        const Icon = () => <StepIcon {...stepProps} stepNum={index + 1} />;

        return (
          <Step key={label} {...stepProps}>
            <StepLabel
              StepIconComponent={Icon}
              {...labelProps}
              sx={{
                ".Mui-active": {
                  fontWeight: stepProps?.active
                    ? "600!important"
                    : "400!important",
                },
                ".Mui-completed": {
                  fontWeight: stepProps?.active
                    ? "600!important"
                    : "400!important",
                  color: `${colors.BlackA700}!important`,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </MUIStepper>
  );
}
