import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DialogActions,
  DialogTitle,
  spacersAsString,
} from "../../index";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import UAParser from "ua-parser-js";

import {
  CustomerSupportTicketData,
  CustomerSupportTicketSchema,
} from "./validation";
import {
  supportDescriptions,
  supportDetails,
  supportSummaries,
  supportTypes,
  SupportTypes,
} from "./constants";
import { Typography } from "../Typography/Typography";

import { SupportLines } from "../SupportLines/SupportLines";

type Organisation = {
  id: string;
  name: string;
};

type CustomerSupportDialogProps = {
  onClose: () => void;
  onError: () => void;
  open: boolean;
  baseUrl: string;
  mode: "in-session" | "not-in-session";
  supportLines: Record<string, { label: string; link: string }>;
  showSupportForm: boolean;
  context: {
    userName?: string;
    userId?: string;
    organizationName?: string;
    sessionId?: string;
    streamId?: string;
    email?: string;
    organisations?: Organisation[];
  };
};

const ORG_NOT_APPLICABLE = "ORG_NOT_APPLICABLE";

export const CustomerSupportDialog = (props: CustomerSupportDialogProps) => {
  const relevantTypes = supportTypes[props.mode];
  const { showSupportForm, supportLines } = props;

  const { userId, sessionId, streamId, email, organisations } = props.context;
  const isSingleOrg =
    organisations?.length === 1 || props.mode === "in-session";

  const defaultValues = {
    summary: "",
    contactEmail: email,
    organization: isSingleOrg ? organisations?.[0]?.id : "",
    description: "",
  };

  const {
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors, isSubmitting },
  } = useForm<CustomerSupportTicketData>({
    mode: "onChange",
    defaultValues,
    resolver: zodResolver(CustomerSupportTicketSchema),
  });

  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const onSubmit = async (data: CustomerSupportTicketData) => {
    const info = UAParser(navigator.userAgent);

    const payload = {
      summary: data.summary,
      contactEmail: data.contactEmail,
      description: data.description,
      organizationId:
        data.organization === ORG_NOT_APPLICABLE ? null : data.organization,
      userId,
      sessionId,
      streamId,
      os: `${info.os.name}:${info.os.version}`,
      browser: `${info.browser.name}:${info.browser.version}`,
      device: `${info.device.type}:${info.device.vendor}:${info.device.model}`,
      userAgent: window.navigator.userAgent,
      networkConnection: (window.navigator as any)?.connection?.type,
    };

    try {
      await fetch(`${props.baseUrl}/v1/support-tickets`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      setHasSuccess(true);
    } catch (error) {
      console.error("Error:", error);
      setHasError(true);
      props.onError();
    }
  };

  watch("summary");
  const summary = getValues("summary");

  const onChangeSummary = (
    event: React.ChangeEvent<{ value: SupportTypes }>,
  ) => {
    const summaryKey = event.target.value;
    setValue("description", supportDetails[summaryKey]);
    resetField("description", {
      keepTouched: false,
      keepDirty: false,
      keepError: false,
      defaultValue: supportDetails[summaryKey],
    });
  };

  const handleCloseDialog = (
    _event: React.SyntheticEvent,
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    const shouldNotClose =
      reason === "backdropClick" && (!hasSuccess || hasError);
    if (shouldNotClose) {
      return;
    }

    return props.onClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={props.open}
      onClose={handleCloseDialog}
      TransitionProps={{
        onExited: () => {
          setHasSuccess(false);
          reset();
        },
      }}
      PaperProps={{ style: { alignSelf: "flex-start" } }}
    >
      <DialogTitle onClose={props.onClose}>
        {hasSuccess ? "Thank you for reaching out" : "Contact support"}
      </DialogTitle>
      <DialogContent>
        {hasSuccess ? (
          <DialogContentText data-cy="customer-support-ticket-assignment">
            You should receive an email soon with the details of your request.
          </DialogContentText>
        ) : (
          <>
            <DialogContentText>
              For urgent requests, please contact our customer support team on:
            </DialogContentText>

            <SupportLines lines={supportLines} />

            {showSupportForm && (
              <>
                <FormControl
                  fullWidth
                  error={Boolean(errors.summary)}
                  sx={{ marginTop: spacersAsString.xl }}
                >
                  <InputLabel
                    id="customer-support-summary-label"
                    style={{ backgroundColor: "white" }}
                  >
                    What can we help you with?
                  </InputLabel>
                  <Select
                    labelId="customer-support-summary-label"
                    data-testid="customer-support-summary"
                    data-cy="customer-support-summary"
                    defaultValue={""}
                    fullWidth
                    {...register("summary", { onChange: onChangeSummary })}
                    error={Boolean(errors.summary)}
                  >
                    {relevantTypes.map((type) => (
                      <MenuItem value={type} key={type}>
                        {supportSummaries[type]}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.summary && (
                    <FormHelperText error={Boolean(errors.summary?.message)}>
                      {errors.summary?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                {summary && (
                  <>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.contactEmail)}
                      sx={{ marginTop: spacersAsString.xl }}
                    >
                      <TextField
                        label="Contact email"
                        data-testid="customer-support-contact-email"
                        data-cy="customer-support-contact-email"
                        type="text"
                        fullWidth
                        {...register("contactEmail")}
                        error={Boolean(errors.contactEmail)}
                      />
                      {errors.contactEmail && (
                        <FormHelperText
                          error={Boolean(errors.contactEmail?.message)}
                        >
                          {errors.contactEmail?.message}
                        </FormHelperText>
                      )}
                    </FormControl>

                    {props.mode === "not-in-session" && (
                      <>
                        <Typography
                          variant="subtitle2"
                          weight="semiBold"
                          style={{ marginTop: spacersAsString.xl }}
                        >
                          Organization
                        </Typography>
                        <Typography
                          variant="body1"
                          color="BlackA700"
                          style={{ marginTop: spacersAsString.sm }}
                        >
                          Please select the organization this request relates
                          to.
                        </Typography>
                        <FormControl
                          fullWidth
                          error={Boolean(errors.organization)}
                          sx={{ marginTop: spacersAsString.md }}
                        >
                          <InputLabel
                            id="customer-support-organization-label"
                            style={{ backgroundColor: "white" }}
                            data-cy="customer-support-organization-label"
                          >
                            Organization
                          </InputLabel>
                          <Select
                            labelId="customer-support-organization-label"
                            data-testid="customer-support-organization"
                            data-cy="customer-support-organization"
                            fullWidth
                            defaultValue={defaultValues.organization}
                            {...register("organization")}
                            error={Boolean(errors.organization)}
                          >
                            <MenuItem value={ORG_NOT_APPLICABLE}>
                              Not applicable
                            </MenuItem>
                            {props.context.organisations?.map((org) => (
                              <MenuItem value={org.id} key={org.id}>
                                {org.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.organization && (
                            <FormHelperText
                              error={Boolean(errors.organization?.message)}
                            >
                              {errors.organization?.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}

                    <Typography
                      variant="subtitle2"
                      weight="semiBold"
                      style={{ marginTop: spacersAsString.xl }}
                    >
                      Description
                    </Typography>
                    <Typography
                      variant="body1"
                      color="BlackA700"
                      style={{ marginTop: spacersAsString.sm }}
                      data-cy="customer-support-description-label"
                    >
                      {supportDescriptions[summary as SupportTypes]}
                    </Typography>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.description)}
                      sx={{ marginTop: spacersAsString.md }}
                    >
                      <TextField
                        InputLabelProps={{ disableAnimation: true }}
                        label="Description"
                        data-testid="customer-support-description"
                        data-cy="customer-support-description"
                        type="text"
                        minRows={2}
                        maxRows={5}
                        multiline
                        fullWidth
                        {...register("description")}
                        error={Boolean(errors.description)}
                      />
                      {errors.description && (
                        <FormHelperText
                          error={Boolean(errors.description?.message)}
                        >
                          {errors.description?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      {hasSuccess || !showSupportForm ? (
        <DialogActions>
          <Button
            variant="primary"
            onClick={props.onClose}
            data-cy="close-thank-you-support-ticket"
            data-testid="close-support-ticket"
          >
            Close
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            data-cy="submit-support-ticket"
            data-testid="submit-support-ticket"
            disabled={!isValid || isSubmitting}
          >
            Submit
          </Button>
          <Button
            variant="secondary"
            onClick={props.onClose}
            data-cy="cancel-support-ticket"
            data-testid="cancel-support-ticket"
          >
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
