import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  & .camera-add-button-big {
    width: calc(100% - 46px);
    height: calc(100% - 46px);
    margin: 20px;

    .proximie-logo {
      width: 13px;
      opacity: 0.5;
    }

    .add-video-text {
      font-weight: 400;
      text-transform: none;
      color: inherit;
    }
  }
`;
