import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class MediaSessionNotFoundException extends BaseException {
  constructor(message = "Media session not found", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.MEDIA_SESSION_NOT_FOUND;
  }
}
