import { EndpointType } from "../Endpoint";
import EndpointJanus, {
  CreateOptions,
  EndpointJanusConfig,
} from "./EndpointJanus";
import { ENDPOINT_CONFIG } from "../../../Constants";

export interface EndpointJanusVideoConfig extends EndpointJanusConfig {
  codecs?: string;
  bitrate?: number;
}

export default class EndpointJanusVideo extends EndpointJanus {
  override endpointType = EndpointType.Video;

  protected pluginName = "janus.plugin.videoroom";
  protected createOptions: CreateOptions = {
    plugin: this.pluginName,
    description: `${this.mediaSessionId}`,
    record: ENDPOINT_CONFIG.video.isRecordingEnabled,
    ["rec_dir"]: `${ENDPOINT_CONFIG.video.recordingDirectory}/${this.mediaSessionId}`,
    ["fir_freq"]: ENDPOINT_CONFIG.video.firFrequency,
    publishers: ENDPOINT_CONFIG.video.maxPublishers,
  };
  // room already created error
  protected ignoreErrors = [427];

  constructor(public config: EndpointJanusVideoConfig) {
    super(config);

    config.codecs = config.codecs ?? ENDPOINT_CONFIG.video.codecs;
    config.bitrate = config.bitrate ?? ENDPOINT_CONFIG.video.bitrate;

    this.createOptions = {
      ...this.createOptions,
      videocodec: config.codecs,
      bitrate: config.bitrate,
    };
  }
}
