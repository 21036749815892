import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface InvalidMaskingLoggerExceptionMeta {
  requestedApiPath: string;
}

export class InvalidMaskingLoggerException extends BaseException {
  constructor(
    message = "Invalid masking logger",
    meta: InvalidMaskingLoggerExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_MASKING_LOGGER;
  }
}
