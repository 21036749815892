import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ElectronEmbeddingProvider } from "./EmbeddingProvider";

interface EmbeddedClientProviderProps {
  children: ReactNode;
}

type EmbeddingData = {
  virtualCameraPattern?: string;
  virtualCameraSubsectionTitle?: string;
};

export interface EmbeddedClientContext {
  data: EmbeddingData | null;
  isEmbedded: boolean;
}

const DEFAULT_CAMERA_LABEL_CONTEXT: EmbeddedClientContext = {
  data: {},
  isEmbedded: false,
};

const EmbeddedClientContext = createContext<EmbeddedClientContext>(
  DEFAULT_CAMERA_LABEL_CONTEXT,
);

export const useEmbeddedClient = () => useContext(EmbeddedClientContext);

export const EmbeddedClientProvider = ({
  children,
}: EmbeddedClientProviderProps) => {
  const embeddingProviderRef = useRef(new ElectronEmbeddingProvider());

  const [isEmbedded, setIsEmbedded] = useState<boolean>(
    embeddingProviderRef.current.isEmbedded,
  );
  const [data, setData] = useState<EmbeddingData | null>(
    embeddingProviderRef.current.data,
  );

  useEffect(() => {
    const embeddingProvider = embeddingProviderRef.current;
    const onEmbeddingDataUpdated = () => {
      setIsEmbedded(embeddingProvider.isEmbedded);
      setData(embeddingProvider.data);
    };

    void embeddingProvider.init().then(onEmbeddingDataUpdated);
    embeddingProvider.on("update", onEmbeddingDataUpdated);

    return () => {
      embeddingProvider.off("update", onEmbeddingDataUpdated);
      void embeddingProvider.destroy();
    };
  }, [embeddingProviderRef]);

  return (
    <EmbeddedClientContext.Provider
      value={{
        isEmbedded,
        data,
      }}
    >
      {children}
    </EmbeddedClientContext.Provider>
  );
};
