import React from "react";
import {
  Member,
  Organisation,
  permissions,
  Session,
  user,
} from "@proximie/api";
import { ChatMessage } from "../components/Chat/Chat";
import ServerParams from "./ServerParams";
import SessionParams from "./SessionParams";
import SocketIoClientWrapper from "../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";
import { Connection } from "@proximie/media";

export interface ShowForPermissionProps {
  invert?: boolean;
  permission: string | string[];
  additionalPredicate?: (isAllowed: boolean) => boolean;
  children: any;
}

export type FeedSpotlight = {
  streamId: string;
  spotlightedBy: { profileUuid: string };
};

export enum SessionFeedView {
  SPOTLIGHT_VIEW,
  GRID_VIEW,
}

export default interface SessionContext {
  serverParams?: ServerParams | null;
  sessionParams: SessionParams | null;
  permissions?: permissions.MediaSessionPermissionsPresentation | null;
  session?: Session;
  organisation?: Organisation;
  members?: Member[];
  inviteParticipant: (invitedProfileId: string) => void;
  chats?: ChatMessage[];
  endSession?: () => Promise<void>;
  leaveSession?: () => void;
  submitChat?: (message: string) => void;
  socket?: SocketIoClientWrapper;
  ShowForPermission: React.FC<ShowForPermissionProps>;
  checkMyPermission: (permission: string | string[]) => boolean;
  role?: string;
  user?: user.User;
  spotlight: {
    spotlightedFeed: FeedSpotlight | null;
    requestSpotlightedFeeds: () => void;
    setSpotlightedFeed: (streamId: string | null) => void;
  };
  view: SessionFeedView;
  setView: (view: SessionFeedView) => void;
  setSessionVideos: (videos: Connection[]) => void;
}
