import * as t from "io-ts";
import { makeRoute } from "./api";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

const AuthorisationRequest = t.type({
  auth0UUID: t.string,
  email: t.string,
  auth0Phone: t.union([t.string, t.undefined]),
});

const AuthorisedUser = t.type({
  profileId: t.number,
  currentOrgId: t.union([t.number, t.undefined]),
  currentOrgUuid: t.union([t.string, t.undefined]),
  currentPrivileges: t.record(t.string, t.boolean),
});

export const createOrGet = makeRoute(
  "/user-auths",
  AuthorisationRequest,
  AuthorisedUser,
);
export const getUserForOrg = makeRoute(
  "/user-auths/organisation/:id",
  t.type({ id: NumberFromString }),
  AuthorisedUser,
);

export type AuthorisedUser = t.TypeOf<typeof AuthorisedUser>;
export type AuthorisationRequest = t.TypeOf<typeof AuthorisationRequest>;
