import { useSessionContext } from "../../contexts/session-context/session-context";
import {
  readUserSessionSettings,
  saveUserSettings,
} from "./sessionStorageSettingsRepository";

export const useSessionUserSettings = () => {
  const { user } = useSessionContext();

  return {
    getUserSettings: readUserSessionSettings(user?.id),
    updateUserSettings: saveUserSettings(user?.id),
  };
};
