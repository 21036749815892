import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useEffect,
  useState,
} from "react";
import { isFinite } from "lodash";
import { MediaControlProps } from "../../MediaControls";
import { StyledRangeInput } from "./ProgressBar.style";

const RangeInput = ({
  value,
  max,
  min = 0,
  onChange,
}: {
  value: number;
  max: number;
  min: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) => (
  <input
    type="range"
    min={min}
    max={max}
    value={isFinite(value) ? value : 0}
    onChange={onChange}
    data-testid={"progressbar"}
  />
);

const ProgressBar: FC<MediaControlProps> = ({ element }: MediaControlProps) => {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    let frameId: number;

    const getTime = () => {
      // Update value based on video progress on every frame
      frameId = requestAnimationFrame(() => {
        const videoCurrentTimeAsPercentage =
          (element.currentTime / element.duration) * 100;

        if (videoCurrentTimeAsPercentage !== currentTime) {
          setCurrentTime(videoCurrentTimeAsPercentage);
        }
        getTime();
      });
    };
    getTime();

    return () => cancelAnimationFrame(frameId);
  }, [element, currentTime, setCurrentTime]);

  // Define the number of possible values on the range input
  const granularity = 10000;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // convert value from range element to video timestamp
    element.currentTime =
      element.duration * (parseFloat(event.target.value) / granularity);
  };

  return (
    <StyledRangeInput value={currentTime}>
      <RangeInput
        value={(currentTime * granularity) / 100}
        min={0}
        max={granularity}
        onChange={handleChange}
      />
    </StyledRangeInput>
  );
};

export default ProgressBar;
