export enum FeedType {
  None = 0,
  Audio = 1,
  Camera = 2,
  Screen = 3,
  RemoteHand = 4,
  Monitor = 5,
}

export default FeedType;
