import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToCreateAppointmentException extends BaseException {
  constructor(message = "Not allowed to create appointment", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_CREATE_APPOINTMENT;
  }
}
