import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const TroubleShootingIndicatorStyled = styled(Box, {
  shouldForwardProp: (name) => name !== "hoverColor",
})<{
  backgroundColor: string;
  hoverColor: string;
}>`
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 16px 16px;
  margin: 0;
  width: 56px;
  height: 56px;

  :hover {
    background: ${({ hoverColor }) => hoverColor};
  }
`;
