import React, { DragEvent, DragEventHandler, useState } from "react";
import { StyledDropArea } from "./FileInput.styles";

interface DropAreaProps {
  onDrop: (files: FileList | null) => void;
}

const DropArea = (props: React.PropsWithChildren<DropAreaProps>) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const handleDrop: DragEventHandler<HTMLDivElement> = function (
    evt: DragEvent,
  ) {
    setIsDraggedOver(false);
    evt.stopPropagation();
    evt.preventDefault();
    const transfer = evt.dataTransfer;
    if (transfer?.files.length > 0) {
      props.onDrop(transfer.files);
      return;
    }
    props.onDrop(null);
  };
  const onDragOver: DragEventHandler<HTMLDivElement> = function (
    e: DragEvent<HTMLDivElement>,
  ) {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggedOver(true);
  };
  const onDragEnd: DragEventHandler<HTMLDivElement> = function (
    e: DragEvent<HTMLDivElement>,
  ) {
    e.stopPropagation();
    e.preventDefault();
    setIsDraggedOver(false);
  };
  return (
    <StyledDropArea
      data-testid="droparea"
      isDraggedOver={isDraggedOver}
      onDrop={handleDrop}
      onDragEnter={onDragOver}
      onDragOver={onDragOver}
      onDragLeave={onDragEnd}
      onDragEnd={onDragEnd}
    >
      {props.children}
    </StyledDropArea>
  );
};

export default DropArea;
