import { Monitor } from "../Monitor";
import { TCPMonitor, TCPReport } from "./TCPMonitor";
import { UDPMonitor, UDPReport } from "./UDPMonitor";

export type ProtocolReport = {
  tcp: TCPReport;
  udp: UDPReport;
};

type SubReport = TCPReport | UDPReport;

export class ProtocolMonitor extends Monitor<ProtocolReport, SubReport> {
  constructor() {
    super({
      subMonitors: { tcp: new TCPMonitor(), udp: new UDPMonitor() },
    });
  }
}
