/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { HTMLAttributes } from "react";

import {
  AlertContent,
  AlertIcon,
  AlertType,
  InlineAlertContainer,
  InlineAlertTypeMapping,
  RegularAlertContainer,
  RegularAlertTypeMapping,
} from "./Alert.style";
import { useTheme } from "@mui/material";
import { Typography } from "../../index";

export interface AlertProps extends HTMLAttributes<HTMLElement> {
  type: AlertType;
  icon?: boolean;
  title?: string;
  inline?: boolean;
  style?: Record<string, any>;
  ["data-cy"]?: string;
}

// Note this component is a work in progress
// TODO: Inline title spacing, Inline actions
// TODO: Regular all spacings, Regular actions
export const Alert = ({ type = "warning", ...props }: AlertProps) => {
  const { colors } = useTheme();
  const { icon, iconColor, ...rest } = props.inline
    ? InlineAlertTypeMapping(colors)[type]
    : RegularAlertTypeMapping(colors)[type];
  const AlertContainer = props.inline
    ? InlineAlertContainer
    : RegularAlertContainer;

  return (
    <AlertContainer data-cy={props["data-cy"]} {...rest} style={props.style}>
      {props.icon && <AlertIcon color={iconColor}>{icon}</AlertIcon>}
      <AlertContent>
        {props.title && (
          <header>
            <Typography variant="subtitle3">{props.title}</Typography>
          </header>
        )}
        <Typography variant="body2">{props.children}</Typography>
      </AlertContent>
    </AlertContainer>
  );
};
