import EventEmitter from "events";
import { UsageTrackerEvents } from "./usage-tracker-events";

type ExtractPayload<Type> = Type extends UsageTrackerEvents<infer X>
  ? X
  : never;

interface Subscription {
  stop: () => void;
}

export class UsageTracker {
  public prefix = "[USAGE_TRACKER]";
  private static emitter = new EventEmitter();

  public track<
    Event extends UsageTrackerEvents<unknown>,
    Payload extends ExtractPayload<Event>,
  >(
    event: Event,
    ...[payload]: [Payload] extends [void] ? [] : [Payload]
  ): void {
    UsageTracker.emitter.emit(event.id, payload);
  }

  public on<Event extends UsageTrackerEvents<unknown>>(
    event: Event,
    listener: (...args: unknown[]) => void,
  ): Subscription {
    UsageTracker.emitter.on(event.id, listener);

    return {
      stop: () => this.off(event, listener),
    };
  }

  public off<Event extends UsageTrackerEvents<unknown>>(
    event: Event,
    listener: (...args: unknown[]) => void,
  ): void {
    UsageTracker.emitter.off(event.id, listener);
  }
}
