import { LeafMonitor } from "../Monitor";

export type DNSReport = {
  online: boolean;
};

export class DNSMonitor extends LeafMonitor<DNSReport> {
  constructor() {
    super({});
  }

  run() {
    return undefined;
  }
}
