import React, { FC } from "react";
import { Box, Typography } from "@mui/material";

interface PageTitleProps {
  children: React.ReactNode;
}

const PageTitle: FC<PageTitleProps> = (props: PageTitleProps) => {
  return (
    <Box py={4}>
      <Typography variant={"h5"} component={"h1"}>
        {props.children}
      </Typography>
    </Box>
  );
};

export default PageTitle;
