import * as t from "io-ts";

const MandatoryMembers = t.type({
  name: t.string,
  format: t.string,
});

export const Metadata = t.partial({
  duration: t.number,
  resolution: t.partial({
    width: t.number,
    height: t.number,
  }),
});

const OptionalMembers = t.partial({
  metadata: Metadata,
  url: t.string,
});

export const RecordingResource = t.intersection([
  MandatoryMembers,
  OptionalMembers,
]);

export type Metadata = t.TypeOf<typeof Metadata>;
export type RecordingResource = t.TypeOf<typeof RecordingResource>;
