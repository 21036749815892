import React, { useEffect, useState } from "react";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { FeedSpotlight, SessionFeedView } from "../../models/SessionContext";
import WindowIcon from "@mui/icons-material/Window";
import { SpotlightIcon } from "../icons/SpotlightIcon/SpotlightIcon";
import {
  SpotlightTooltip,
  TooltipType,
} from "../SpotlightTooltip/SpotlightTooltip";
import IconButton from "@mui/material/IconButton";
import { useSessionUserSettings } from "../../hooks/useSessionUserSettings/useSessionUserSettings";
import { useTheme } from "@mui/material";

interface SpotlightControlsProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SpotlightControls: React.FC<SpotlightControlsProps> = ({
  onClick,
}) => {
  const { colors } = useTheme();

  const { getUserSettings, updateUserSettings } = useSessionUserSettings();
  const showTooltipForSpotlighter =
    getUserSettings().spotlight?.showTooltipForSpotlighter;
  const showTooltipForFollowingSpotlight =
    getUserSettings().spotlight?.showTooltipForFollowingSpotlight;

  const [prevSpotlightedFeed, setPrevSpotlightedFeed] =
    useState<FeedSpotlight | null>(null);

  const {
    view,
    spotlight: { spotlightedFeed },
    user,
  } = useSessionContext();

  const [spotlightType, setSpotlightType] = useState<TooltipType>(
    TooltipType.NORMAL,
  );
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    // if there's no tooltip default to normal
    if (spotlightedFeed?.streamId == null) {
      setPrevSpotlightedFeed(null);
      setSpotlightType(TooltipType.NORMAL);
      if (spotlightType !== TooltipType.NORMAL) {
        setIsTooltipOpen(false);
      }
    } else if (spotlightedFeed.streamId !== prevSpotlightedFeed?.streamId) {
      setPrevSpotlightedFeed(spotlightedFeed);
      if (spotlightedFeed.spotlightedBy.profileUuid === user?.id) {
        if (showTooltipForSpotlighter) {
          setSpotlightType(TooltipType.FOR_SPOTLIGHTER);
          setIsTooltipOpen(true);
          updateUserSettings({
            spotlight: {
              showTooltipForSpotlighter: false,
            },
          });
        }
      } else {
        if (showTooltipForFollowingSpotlight) {
          setSpotlightType(TooltipType.FOR_FOLLOWING_SPOTLIGHT);
          setIsTooltipOpen(true);
          updateUserSettings({
            spotlight: {
              showTooltipForFollowingSpotlight: false,
            },
          });
        }
      }
    }
  }, [
    view,
    spotlightedFeed,
    user,
    prevSpotlightedFeed?.streamId,
    showTooltipForSpotlighter,
    showTooltipForFollowingSpotlight,
    updateUserSettings,
  ]);

  const handleSpotlightClose = () => {
    setIsTooltipOpen(false);
  };

  const handleViewSelectorButtonClicked = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setIsTooltipOpen(false);
    onClick(event);
  };

  return (
    <SpotlightTooltip
      type={spotlightType}
      placement="top"
      handleClose={handleSpotlightClose}
      open={isTooltipOpen}
      {...(spotlightType === TooltipType.NORMAL
        ? {}
        : {
            componentsProps: {
              tooltip: {
                sx: {
                  bgcolor: colors.Black,
                  minWidth: "382px",
                  borderRadius: "8px",
                  mr: "8px",
                },
              },
              arrow: {
                sx: {
                  color: colors.Black,
                },
              },
            },
            PopperProps: {
              modifiers: [
                {
                  name: "preventOverflow",
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: false,
                    rootBoundary: "document",
                    padding: 8,
                  },
                },
              ],
            },
          })}
      onOpen={() => {
        setIsTooltipOpen(true);
      }}
      onClose={() => {
        if (spotlightType === TooltipType.NORMAL) {
          setIsTooltipOpen(false);
        }
      }}
      TransitionProps={{
        onExited: () => setSpotlightType(TooltipType.NORMAL),
      }}
    >
      <IconButton
        data-testid="view-mode-button"
        onClick={handleViewSelectorButtonClicked}
      >
        {view === SessionFeedView.GRID_VIEW ? (
          <WindowIcon data-testid="grid-view-icon" sx={{ color: "white" }} />
        ) : (
          <SpotlightIcon
            data-testid="spotlight-view-icon"
            sx={{ color: "white" }}
          />
        )}
      </IconButton>
    </SpotlightTooltip>
  );
};
