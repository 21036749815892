import { Dialog, DialogContent } from "@mui/material";
import { DialogTitle } from "@proximie/components";
import React, { FC } from "react";
import { Loading } from "../../components";

const SessionStart: FC = () => (
  <Dialog open={true}>
    <DialogTitle>Session starting</DialogTitle>

    <DialogContent>
      Please wait whilst you are connected to your peers.
      <Loading />
    </DialogContent>
  </Dialog>
);

export default SessionStart;
