import { ParticipantProps } from "@proximie/components";
import {
  AudioParticipant,
  ServerParams as MediaServerParams,
} from "@proximie/media";
import queryString from "query-string";
import ServerParams from "../models/ServerParams";
import SessionParams from "../models/SessionParams";
import { environment } from "../../environments/environment";

export const isValidSessionParams = (queryParams: string): boolean => {
  const parsed = queryString.parse(queryParams);
  const endpoint = parsed["endpoint"] as string;
  let streamId = parsed["stream_id"] as string;

  if (!streamId) {
    streamId = parsed["session-id"] as string;
  }

  return !!endpoint && !!streamId;
};

export const getSessionParamsFromURLQueryOrNull = (
  searchString?: string,
): SessionParams | null => {
  const searchParams = new URLSearchParams(
    searchString || window.location.search,
  );
  const endpoint = searchParams.get("endpoint") || undefined;
  let streamId = searchParams.get("stream_id") || undefined;
  const sessionId = searchParams.get("session_id") || undefined;

  if (!streamId) {
    streamId = searchParams.get("session-id") || undefined;
  }

  if (!!endpoint && !!streamId) {
    return {
      endpoint,
      sessionId,
      mediaSessionId: streamId,
    };
  } else {
    return null;
  }
};

//LATER - why have we got two different shapes?
export const serverParamsToMediaServerParams = (
  serverParams: ServerParams,
  isWatchRTCEnabled?: boolean,
): MediaServerParams => {
  return {
    type: "janus",
    videoServer: serverParams.videoServer,
    audioServer: serverParams.audioServer,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    iceServers: serverParams.iceServers,
    username: serverParams.username,
    credential: serverParams.credential,
    dcpHomeBroker: {
      wssUrl: serverParams.dcpHomeBroker?.wssUrl || "",
      mqttsUrl: serverParams.dcpHomeBroker?.mqttsUrl || "",
    },
    dcpSessionBroker: {
      wssUrl: serverParams.dcpSessionBroker?.wssUrl || "",
      mqttsUrl: serverParams.dcpSessionBroker?.mqttsUrl || "",
    },
    watchRTC: isWatchRTCEnabled ? serverParams.watchRTC : undefined,
  };
};

export const audioParticipantToParticipant = (
  audioParticipant: AudioParticipant,
): ParticipantProps => {
  return {
    name: audioParticipant.userDisplayName || "",
    id: audioParticipant.userId,
    userUUID: audioParticipant.userUUID,
    isConnected: audioParticipant.isConnected,
    isMuted: audioParticipant.isMuted,
    isActive: audioParticipant.isActive,
    isLocal: audioParticipant.isLocal,
    metadata: audioParticipant.metadata,
    streamId: audioParticipant.streamId,
  };
};

export const audioParticipantsToParticipants = (
  audioParticipants: AudioParticipant[],
): ParticipantProps[] => {
  if (audioParticipants) {
    const participantsArray: ParticipantProps[] = [];
    for (const p in audioParticipants) {
      participantsArray.push(
        audioParticipantToParticipant(audioParticipants[p]),
      );
    }
    return participantsArray;
  } else {
    return [];
  }
};

export const generateURLRoot = () => {
  let dashboardDomain = `my.${environment.domain}`;

  if (environment.domain.includes("localhost")) {
    dashboardDomain = `${environment.domain}:4200`;
  }

  const protocol = environment.domain.includes("localhost") ? "http" : "https";
  return `${protocol}://${dashboardDomain}`;
};

export const navigateToDashboard = (dashboardUrl?: string) => {
  if (dashboardUrl) {
    window.location.href = dashboardUrl;
  } else {
    window.location.href = generateURLRoot();
  }
};
