/*
The Janus type definitions are not complete.  This file
aims to fill-in what is missing.  When the Janus type
definitions are complete then this file may be obsoleted.
*/
import { JanusJS } from "@proximie/janus-gateway";

interface PrxResultObject {
  status: MessageType;
  id?: string;
  uplink?: number;
}

export const enum PrxAudiobridgeEvents {
  Attached = "attached",
  Joined = "joined",
  Destroyed = "destroyed",
  Event = "event",
  Talking = "talking",
  StoppedTalking = "stopped-talking",
}

export const enum PrxVideoroomEvents {
  Attached = "attached",
  Joined = "joined",
  Destroyed = "destroyed",
  Event = "event",
}

export const enum PrxEchotestEvents {
  Event = "event",
}

export interface PrxParticipant {
  id: number | string;
  display?: string;
}

export interface PrxAudioParticipant extends PrxParticipant {
  setup?: boolean;
  muted?: boolean;
  active?: boolean;
}

export interface PrxVideoParticipant extends PrxParticipant {
  video_codec?: string;
  simulcast?: boolean;
}

export interface PrxMessage extends JanusJS.Message {
  result?: string | PrxResultObject;
  error?: JanusJS.Error;
  audiobridge?: PrxAudiobridgeEvents;
  videoroom?: PrxVideoroomEvents;
  echotest?: PrxEchotestEvents;
  participants?: PrxAudioParticipant[];
  publishers?: PrxVideoParticipant[];
  id?: number | string;
  leaving?: number | string;
  unpublished?: number | string;
  substream?: number;
  temporal?: number;
  configured?: string;
  reason?: string;
  error_code?: number;
}

export interface PrxPluginMessage extends JanusJS.PluginMessage {
  // eslint-disable-next-line @typescript-eslint/ban-types
  success: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  error: Function;
}

export interface PrxPluginOptions extends JanusJS.PluginOptions {
  onmessage?: (message: PrxMessage, jsep?: JSEP) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  success: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  error: Function;
}
