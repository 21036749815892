import styled from "@emotion/styled";
interface StyledCollapsibleTextProps {
  expanded: boolean;
  minLines?: number;
  maxLines?: number;
}

export const StyledCollapsibleText = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props: StyledCollapsibleTextProps) =>
    props.expanded ? props.maxLines || "unset" : props.minLines};
  overflow: hidden;
  word-break: break-all;
`;
export const StyledContainer = styled.div`
  display: flex;
`;

export const StyledTextButton = styled.span`
  white-space: nowrap;
  display: inline-block;
  a {
    text-decoration: none;
  }
`;
