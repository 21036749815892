import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import SocketIoClientWrapper from "../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";
import { useAuthenticatedUser } from "./media-client-authenticated-user-context";

const socketIoClientWrapper = new SocketIoClientWrapper();
const context = createContext<SocketIoClientWrapper>(socketIoClientWrapper);
export const UseSocketIoContext = () => useContext(context);

interface SocketIoProviderProps {
  children: ReactNode;
}

export const SocketIoProvider: FC<SocketIoProviderProps> = (
  props: SocketIoProviderProps,
) => {
  const user = useAuthenticatedUser();
  useEffect(() => {
    if (user?.token) {
      socketIoClientWrapper.refreshCredentials(user?.token);
    }
  }, [user?.token]);

  return (
    <context.Provider value={socketIoClientWrapper}>
      {props.children}
    </context.Provider>
  );
};
