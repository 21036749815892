import * as t from "io-ts";
import { empty } from "./common";
import { makeRoute } from "./api";

export const shortSpecialityPresentation = t.type({
  id: t.number,
  name: t.string,
});

export const list = makeRoute(
  "/specialities",
  empty,
  t.array(shortSpecialityPresentation),
);

export type shortSpecialityPresentation = t.TypeOf<
  typeof shortSpecialityPresentation
>;
