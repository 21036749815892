import * as t from "io-ts";

export type api<Req, Res> = {
  path: string;
  reqCodec: t.Type<Req>;
  resCodec: t.Type<Res>;
};

export type noContentApi<Req> = {
  path: string;
  reqCodec: t.Type<Req>;
};

export function makeRoute<ReqCodec extends t.Any, ResCodec extends t.Any>(
  endpoint: string,
  reqCodec: ReqCodec,
  resCodec: ResCodec,
  apiVersion = 1,
): api<t.TypeOf<ReqCodec>, t.TypeOf<ResCodec>> {
  const path = `/api/v${apiVersion}${endpoint}`;
  return { path, reqCodec, resCodec };
}

export function makeNoContentRoute<ReqCodec extends t.Any>(
  endpoint: string,
  reqCodec: ReqCodec,
): noContentApi<t.TypeOf<ReqCodec>> {
  const path = `/api/v1${endpoint}`;
  return { path, reqCodec };
}

export function replaceIn(
  path: string,
  urlIdentifiers: Record<string, boolean | string | number | undefined>,
): string {
  const regExp = /:(\w+)?/g;
  return path.replace(regExp, (matched) => {
    const key = matched.slice(1);
    const newValue = urlIdentifiers[key] || "undefined";
    return String(newValue);
  });
}
