import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class InvalidChatEventException extends BaseException {
  constructor(message = "Invalid chat event", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_CHAT_EVENT;
  }
}
