import styled from "@emotion/styled";
import { OBJECT_FIT_CONTAIN, OBJECT_FIT_COVER } from "../constants";

export const VideoControlsStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 6px;
  right: 6px;
  left: 0;
  z-index: 2;
  margin-left: 8px;

  pointer-events: none;
  & > * {
    pointer-events: all;
  }
`;

// Apply to video and canvas pure elements
// Only 1 video and/or 1 canvas will be styled
// size mimics https://www.w3schools.com/cssref/css3_pr_background-size.asp

export const VideoMediaStyled = styled.div<{ size: string }>`
  display: flex;
  position: relative;
  height: 100%;
  flex-grow: 1;
  justify-content: center;

  & > video,
  & div:has(.canvas-container) {
    position: absolute;
    height: ${({ size }) => (size === OBJECT_FIT_CONTAIN ? "auto" : "100%")};
    max-height: ${({ size }) =>
      size === OBJECT_FIT_CONTAIN ? "100%" : "none"};
    max-width: ${({ size }) => (size === OBJECT_FIT_CONTAIN ? "100%" : "none")};
    min-height: 100%;
    min-width: 100%;
    object-fit: ${({ size }) =>
      size === OBJECT_FIT_CONTAIN ? OBJECT_FIT_CONTAIN : OBJECT_FIT_COVER};
    width: ${({ size }) => (size === OBJECT_FIT_CONTAIN ? "auto" : "100%")};
    top: 0;
    left: 0;
  }

  & > video {
    z-index: 0;
  }

  & > canvas {
    z-index: 1;
  }
`;

export const VideoStyled = styled.video`
  background: transparent;
`;
