import UAParser from "ua-parser-js";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
} from "@mui/material";
// Cannot be shortened; creates a circular dependency
import { DialogTitle } from "../DialogTitle/DialogTitle";
import { useTranslation } from "react-i18next";
import { useFlavor } from "@proximie/flavorinator";

export const BrowserCheck = () => {
  const [showBrowserWarning, setShowBrowserWarning] = useState<boolean>(false);
  const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);
  const { t } = useTranslation();
  const { getConfiguration } = useFlavor();
  const appName = getConfiguration("appName");

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("hideBrowserWarning", "true");
    }
    setShowBrowserWarning(false);
  };

  const isSupportedBrowser = (): boolean => {
    const info = UAParser(navigator.userAgent);
    const deviceType = info.device.type;
    const isChromeOrEdge = ["Chrome", "Edge", "Electron"].includes(
      info.browser.name || "",
    );

    // FYI from Greg: I had to leave undefined as okay, as, for example, my mac was returning undefined for deviceType
    const isDesktop = deviceType === undefined || deviceType === "desktop";
    return isDesktop && isChromeOrEdge;
  };

  useEffect(() => {
    setShowBrowserWarning(
      !isSupportedBrowser() && !localStorage.getItem("hideBrowserWarning"),
    );
  }, []);

  return showBrowserWarning ? (
    <Dialog
      open={showBrowserWarning}
      onClose={handleClose}
      data-testid={"invalid-browser"}
    >
      <DialogTitle>Your browser is unsupported</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("pages.browserCheck.message", { appName })}
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={dontShowAgain}
              onChange={(e) => setDontShowAgain(e.target.checked)}
              name="dont-show-again"
              color="primary"
            />
          }
          label="Don't show me again"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color={"secondary"}
          variant={"contained"}
          size={"large"}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
