import { ExceptionCodes } from "../exception-codes";

export abstract class BaseException extends Error {
  public message: string;
  public code: ExceptionCodes = ExceptionCodes.INTERNAL_SERVER_ERROR;
  public meta: unknown;

  constructor(message: string, meta?: unknown) {
    super(message);
    this.message = message;
    this.meta = meta;

    // https://stackoverflow.com/questions/31626231/custom-error-class-in-typescript/41429145#41429145
    Object.setPrototypeOf(this, new.target.prototype);
    // https://gist.github.com/justmoon/15511f92e5216fa2624b#gistcomment-2970358
    Error.captureStackTrace && Error.captureStackTrace(this, this.constructor);
  }
}
