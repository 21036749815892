import React, { FC } from "react";
import { NameCardAvatarStyled } from "./NameCard.style";
import { Avatar, Box, Typography } from "@mui/material";

interface NameCardProps {
  name: string;
}

const NameCard: FC<NameCardProps> = (props: NameCardProps) => {
  const { name } = props;

  return (
    <Box display={"flex"} alignItems={"center"}>
      <NameCardAvatarStyled data-testid="avatar">
        <Avatar sx={{ width: 30, height: 30 }} />
      </NameCardAvatarStyled>
      <Typography variant="body1" color="inherit">
        {name}
      </Typography>
    </Box>
  );
};

export default NameCard;
