import React from "react";
import { Box, ButtonBase, Grid } from "@mui/material";
import { colors } from "@proximie/components";
import { BRUSH_SIZES } from "../../hooks/useTelestration";

export const WidthPicker = ({
  onWidthChange,
  width,
  color,
}: {
  onWidthChange: (thickness: number) => void;
  width: number;
  color: string;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={"center"}
      gap={"8px"}
      borderRadius={"10px"}
      paddingTop={"8px"}
      paddingBottom={"8px"}
    >
      {BRUSH_SIZES.map((bWidth, index) => {
        const border =
          bWidth === width
            ? `2px solid ${colors.BrandSecondaryLight}`
            : `1px solid ${colors.WhiteMediumEmphasis}`;

        return (
          <Grid key={index} component={Box} xs={1}>
            <ButtonBase
              key={index}
              onClick={() => {
                onWidthChange(bWidth);
              }}
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                padding: 10,
                border,
                backgroundColor: colors.Grey800,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Box
                width={bWidth}
                height={bWidth}
                borderRadius={"50%"}
                border={`1px solid ${colors.WhiteMediumEmphasis}`}
                boxSizing={"border-box"}
                bgcolor={color}
              />
            </ButtonBase>
          </Grid>
        );
      })}
    </Box>
  );
};
