import { SessionFeedView } from "../../models/SessionContext";
import React from "react";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import { FeatureFlags } from "../constants";
import SpotlightGrid from "../SpotlightGrid/SpotlightGrid";
import VideoGrid from "../VideoGrid/VideoGrid";

export const ViewMode = ({
  view,
  children,
  emptySlot,
}: {
  view: SessionFeedView;
  children: React.ReactNode;
  emptySlot: React.ReactNode;
}) => {
  const isSpotlightEnabled = useFlag(FeatureFlags.SPOTLIGHT);

  return isSpotlightEnabled &&
    React.Children.toArray(children).length > 1 &&
    view === SessionFeedView.SPOTLIGHT_VIEW ? (
    <SpotlightGrid>{children}</SpotlightGrid>
  ) : (
    <VideoGrid emptySlot={emptySlot}>{children}</VideoGrid>
  );
};
