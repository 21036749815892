import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class FailedToFetchProfileException extends BaseException {
  constructor(message = "Failed to fetch profile information", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_FETCH_PROFILE_EXCEPTION;
  }
}
