import styled from "@emotion/styled";

export const VideoGridStyled = styled.div<{ numberOfVideos: number }>`
  display: grid;
  height: ${({ numberOfVideos }) => (numberOfVideos === 2 ? "auto" : "100%")};
  grid-template-columns: ${({ numberOfVideos }) => {
    if (numberOfVideos <= 1) {
      return "100%";
    } else {
      return "50% 50%";
    }
  }};
  grid-template-rows: ${({ numberOfVideos }) => {
    if (numberOfVideos <= 2) {
      return "100%";
    } else {
      return "50% 50%";
    }
  }};
`;
