import * as emailAddresses from "email-addresses";
import * as cheerio from "cheerio";

export const maskEmail = (email: string | null): string => {
  if (!email) {
    return "";
  }

  const username = email.split("@")[0];
  const redacted = username.slice(0, 3) + "*".repeat(username.length - 3);
  return redacted + "@***...";
};

export const sanitizeEmail = (email: string): string => {
  return email.replace(/[\u200E\u200F\u202A-\u202E]/g, "").trim();
};

export const isEmailValid = (email: string): boolean => {
  /* invalid email will result in null value */

  const isValid = !email.includes(" ");

  const parsedEmail = emailAddresses.parseOneAddress(email);
  return !!parsedEmail && isValid;
};

export const getJoinedNameAndEmail = (name: string, email: string) => {
  return `${name} <${email}>`;
};

/**
Removes Html tags from a string

Possible alternatives:
- DOMParser, only with browser
- jsdom, only with node >= 16
*/
export function stripHtml(html: string): string {
  const domQueryFn = cheerio.load(`${html}`);
  return domQueryFn.text();
}

/**
Removes Html tags and any substrings that possibly could identify a link
*/
export function stripHtmlAndLinks(html: string): string {
  return stripHtml(html)
    .replace(/www/g, "")
    .replace(/https/g, "")
    .replace(/http/g, "")
    .replace(/:\/\//g, "")
    .replace(/(?!\.+)([^ ])\.([^ ])/g, "$1. $2");
}
