import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToInviteUsersException extends BaseException {
  constructor(message = "Not allowed to invite users", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_INVITE_USERS;
  }
}
