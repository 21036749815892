import Endpoint, {
  EndpointType,
  EndpointConfig,
  IEndpointRequestHeader,
} from "./Endpoint";
import {
  IStreamCapabilityStateValue,
  makeSessionDeviceComponentOperationTopicPath,
} from "@proximie/dcp";
import {
  SessionRemoteDeviceManager,
  IBrokerOptions,
  LoggerType,
} from "@proximie/dcp-mqtt";
import DcpBrokerConfig from "../../models/DcpBrokerConfig";

export interface EndpointDcpConfig extends EndpointConfig {
  brokerConfig: DcpBrokerConfig;
  tokenProvider: () => string;
}

export interface IEndpointDcpRequestHeader extends IEndpointRequestHeader {
  deviceId: string;
  component: string;
  serviceName: string;
}

export default class EndpointDcpSession extends Endpoint {
  override endpointType = EndpointType.Dcp;

  public manager: SessionRemoteDeviceManager | null = null;
  protected readonly brokerConfig: DcpBrokerConfig;
  public readonly tokenProvider: () => string;

  constructor(config: EndpointDcpConfig) {
    super(config);

    this.brokerConfig = config.brokerConfig;
    this.tokenProvider = config.tokenProvider;
  }

  static BuildBrokerOptions(
    brokerConfig: DcpBrokerConfig,
    tokenProvider: () => string,
  ): IBrokerOptions {
    const homeUrlObj = new URL(brokerConfig.wssUrl);
    return {
      protocol: homeUrlObj.protocol === "wss:" ? "wss" : "ws",
      host: homeUrlObj.hostname,
      port: Number(homeUrlObj.port),
      path: homeUrlObj.pathname,
      url: brokerConfig.wssUrl,
      reconnectPeriod: 5000,
      connectTimeout: 10000,
      credentialsProvider: () => ({
        username: homeUrlObj.username || "JWT",
        password: tokenProvider(),
      }),
      isRetryInitialConnection: true,
    };
  }

  override connect(): Promise<void> {
    console.debug("EndpointDcpSession:connect", this.mediaSessionId);

    this.manager = new SessionRemoteDeviceManager(this.mediaSessionId, {
      lwtGracePeriodMs: 1000,
      //LATER - when we sort out logging properly just send the logging object
      logger: {
        debug: console.debug,
        warn: console.warn,
        error: console.warn,
        info: console.info,
        log: console.log,
      } as LoggerType,
    });

    this.manager.on("error", (error: Error) => {
      console.log("DCP error=", error);
    });

    this.manager.on("disconnect", () => {
      console.log("disconnected");
    });

    this.manager.on("reconnect", () => {
      console.log("reconnect");
    });

    return this.manager
      .connect(
        EndpointDcpSession.BuildBrokerOptions(
          this.brokerConfig,
          this.tokenProvider,
        ),
      )
      .catch((error: Error) => {
        this.manager = null;
        throw error;
      });
  }

  override async close(error?: Error): Promise<void> {
    if (this.manager) {
      await this.manager.disconnect({ forced: false });
      this.manager = null;
      return super.close(error);
    }
  }

  override request(
    header: IEndpointDcpRequestHeader,
    value: IStreamCapabilityStateValue,
  ): Promise<void> {
    const topic = makeSessionDeviceComponentOperationTopicPath({
      sessionId: this.mediaSessionId,
      deviceId: header.deviceId,
      component: header.component,
      operation: "REQUEST",
    });

    return this.manager
      ? this.manager.publishObject(
          topic,
          {
            request: {
              [header.serviceName]: {
                STREAM: value,
              },
            },
          },
          { retain: false },
        )
      : Promise.resolve();
  }
}
