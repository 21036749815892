import { Logos } from "./types";

export function getLogos(flavor: string): Logos {
  // Local development
  // return {
  //   dashboardApp: `/assets/flavors/prox/dashboard-app-logo.svg`,
  //   mediaApp: `/assets/flavors/prox/media-app-logo.svg`,
  //   dashboardAppMini: `/assets/flavors/prox/dashboard-app-mini-logo.svg`,
  // };

  return {
    dashboardApp: `/assets/static_assets/flavoring/${flavor}/dashboard-app-logo.svg`,
    mediaApp: `/assets/static_assets/flavoring/${flavor}/media-app-logo.svg`,
    dashboardAppMini: `/assets/static_assets/flavoring/${flavor}/dashboard-app-mini-logo.svg`,
  };
}
