import React from "react";
import {
  Box,
  DialogTitleProps as MuiDialogTitleProps,
  IconButton,
  Divider,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Typography } from "../Typography/Typography";
import { spacersAsString } from "../../styles/spacers";

interface DialogTitleProps extends MuiDialogTitleProps {
  hasDivider?: boolean;
  onClose?: () => void;
}

export const DialogTitle = ({
  children,
  hasDivider,
  onClose,
}: DialogTitleProps) => {
  return (
    <Box className="dialog-title" display={"flex"} alignItems={"flex-start"}>
      <Box
        sx={{
          flex: 1,
          overflow: "hidden",
          alignSelf: "center",
        }}
      >
        <Typography
          variant="h6"
          weight="semiBold"
          style={{
            lineHeight: "160%",
          }}
          noWrap
        >
          {children}
        </Typography>
      </Box>
      {onClose && (
        <IconButton
          sx={{
            flexShrink: 0,
            marginLeft: spacersAsString.none,
            marginTop: `-${spacersAsString.sm}`,
            marginRight: `-${spacersAsString.sm}`,
            marginBottom: spacersAsString.none,
          }}
          onClick={onClose}
          data-testid="closeDialog"
        >
          <Close />
        </IconButton>
      )}
      {hasDivider && (
        <Divider
          sx={{
            width: "100%",
            alignSelf: "stretch",
            marginTop: spacersAsString.md,
          }}
        />
      )}
    </Box>
  );
};
