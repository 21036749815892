import React, { ChangeEvent, useState } from "react";
import { Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/FileUpload";
import { validateFile, ValidationRules } from "@proximie/media-validator";
import { Button } from "../Button/Button";
import DropArea from "./DropArea";
import { Typography } from "../Typography/Typography";
import { StyledUploadButton, StyledWrapper } from "./FileInput.styles";
import { Alert } from "../Alert/Alert";
import { useTranslation } from "react-i18next";
import { useFlavor } from "@proximie/flavorinator";

type InvalidFile = {
  file: File;
  error: string;
};

export interface FileInputProps {
  onValidationSuccess: (files: FileList) => void;
  onValidationError?: (invalidFiles: InvalidFile[]) => void;
  onChange?: (files: FileList | null) => void;
  validationRules?: Partial<ValidationRules>;
  maxFiles?: number;
}

const ONE_GIGABYTE_IN_BYTES = 1 * 1000 * 1000 * 1000;

const defaultValidationConfig = {
  allowedExtensions: ["mp4"],
  allowedMimetypes: ["video/mp4"],
  disallowedStrings: ["&"],
  maxFilesize: 4 * ONE_GIGABYTE_IN_BYTES,
  minFilesize: 1 * 1000 * 1000,
};

function renderErrors(invalidFiles: InvalidFile[], error = "") {
  const errors = [];
  if (invalidFiles.length > 0) {
    errors.push(
      ...invalidFiles.map(({ file, error }) => {
        return (
          <Grid item key={file.name} xs={12}>
            <Alert type="error" icon inline>
              {error}
            </Alert>
          </Grid>
        );
      }),
    );
  }
  if (error) {
    errors.push(
      <Alert type="error" icon inline>
        {error}
      </Alert>,
    );
  }
  return errors;
}

const FileInput = ({
  onValidationSuccess: onValidationSuccessCallback,
  onValidationError: onValidationErrorCallback,
  onChange: onChangeCallback,
  maxFiles = 1,
  validationRules: validationRulesProp = {},
}: FileInputProps) => {
  const validationRules = Object.assign(
    {},
    defaultValidationConfig,
    validationRulesProp,
  );
  const [error, setError] = useState("");
  const [invalidFiles, setInvalidFiles] = useState<InvalidFile[]>([]);
  const { t } = useTranslation();
  const { getConfiguration } = useFlavor();
  const appName = getConfiguration("appName");

  function onValidationSuccess(files: FileList) {
    setError("");
    onValidationSuccessCallback(files);
  }

  async function validateFileList(files?: FileList) {
    if (!files || files.length === 0) return;
    if (files.length > maxFiles) {
      setError(`Maximum number of files allowed is ${maxFiles}`);
      return;
    } else {
      setError("");
    }

    const invalid = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { isValid, error = "" } = await validateFile(file, validationRules);
      if (!isValid) {
        invalid.push({
          error,
          file,
        });
      }
    }

    setInvalidFiles(invalid);
    if (invalid.length === 0) {
      onValidationSuccess(files);
    } else if (onValidationErrorCallback && invalid.length > 0) {
      onValidationErrorCallback(invalid);
    }
  }

  function pickFile() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = validationRules.allowedMimetypes.join(",");
    fileInput.addEventListener("change", (event) => {
      const e = event as unknown as ChangeEvent<HTMLInputElement>;
      if (onChangeCallback) {
        onChangeCallback(e.target.files);
      }
      if (e.target.files && e.target.files.length > 0) {
        validateFileList(e.target.files);
      }
    });
    fileInput.click();
  }

  const handleDrop = (files: FileList | null) => {
    if (files && files.length > 0) {
      if (onChangeCallback) {
        onChangeCallback(files);
      }
      validateFileList(files);
    }
  };

  return (
    <div data-testid="file">
      <DropArea onDrop={handleDrop} data-cy="file-drop-area">
        <StyledWrapper>
          <Grid
            container
            spacing={1}
            alignItems={"center"}
            alignContent={"center"}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} mb={2}>
              <StyledUploadButton onClick={pickFile}>
                <UploadFileIcon sx={{ width: "72px", height: "72px" }} />
              </StyledUploadButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="BlackMediumEmphasis">
                Drag and drop video files to upload
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="BlackMediumEmphasis">
                Supported file types:{" "}
                {validationRules.allowedExtensions.join(", ").toUpperCase()}
              </Typography>
            </Grid>
            {renderErrors(invalidFiles, error)}
            <Grid item container xs={12} margin={2} justifyContent="center">
              <Button
                type="button"
                onClick={pickFile}
                variant="primary"
                size="small"
                data-cy="file-upload-btn"
              >
                Select File
              </Button>
            </Grid>
          </Grid>
        </StyledWrapper>
        <Box sx={{ marginTop: "auto", textAlign: "center", padding: "20px" }}>
          <Typography variant="body2" color="BlackMediumEmphasis">
            {t("forms.uploadVideo.fileInput.acknowledgement", {
              appName,
            })}
            <Link
              target="_blank"
              href="https://www.proximie.com/terms-of-service"
              rel="noopener noreferrer"
            >
              Terms of Service
            </Link>
            .
          </Typography>
        </Box>
      </DropArea>
    </div>
  );
};

export default FileInput;
