import jwt_decode from "jwt-decode";
export const numToPaddedString = (num: number): string => {
  const str = `${Math.floor(num)}`;
  return str.padStart(2, "0");
};

export const getElapsedTimeString = (milliseconds: number): string => {
  let formattedTime = "00:00:00";

  if (
    typeof milliseconds === "number" &&
    !isNaN(milliseconds) &&
    milliseconds > 0
  ) {
    let seconds = milliseconds / 1000;
    const hourInSeconds = 3600;
    const minuteInSeconds = 60;

    const hours = Math.floor(seconds / hourInSeconds);
    const minutes = Math.floor((seconds % hourInSeconds) / minuteInSeconds);
    seconds = Math.floor(seconds % 60);

    formattedTime = `${numToPaddedString(hours)}:${numToPaddedString(
      minutes,
    )}:${numToPaddedString(seconds)}`;
  }

  return formattedTime;
};

export const getElapsedTimeStringFromDate: (startedAt: Date) => string = (
  startedAt,
) => {
  const startTime = startedAt?.valueOf();
  const nowTime = new Date().valueOf();
  const milliseconds = nowTime - startTime;

  return getElapsedTimeString(milliseconds);
};

export const getExpFromToken = (token: string) => {
  try {
    const decoded = jwt_decode(token) as { exp: number };
    return decoded.exp;
  } catch (e) {
    return null;
  }
};

export const getMillisecondsTillTokenExpiry = (token: string) => {
  const exp = getExpFromToken(token);
  if (exp === null) {
    return null;
  }
  const now = Date.now();
  return exp * 1000 - now;
};
