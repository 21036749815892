import { Session } from "@proximie/api";

export const usePermissions = (
  session: Session | undefined,
  userId: string | undefined,
) => {
  const isOwnerOrModerator =
    (session?.owner.id === userId ||
      session?.participants.some(
        (participant) =>
          participant.id === userId && participant.role === "MODERATOR",
      )) ??
    false;

  return {
    canEditSession: isOwnerOrModerator,
    canInviteParticipants: isOwnerOrModerator,
    canCloseVideoFeed: isOwnerOrModerator,
    canEndSession: isOwnerOrModerator,
    canClearAllDrawings: isOwnerOrModerator,
    canRecordSession: isOwnerOrModerator,
    canSpotlightFeeds: isOwnerOrModerator,
  };
};
