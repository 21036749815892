import Device, { DeviceType } from "./Device";
import Connection from "../Connections/Connection";
import FeedType from "../../models/FeedType";

export default class DeviceAudio extends Device {
  public override readonly deviceType = DeviceType.Virtual;
  public override readonly mediaType = FeedType.None;

  public override invoke(): Connection | null {
    throw new Error("Cannot make a connection from a virtual device");
  }
}
