import Endpoint, { EndpointType } from "./Endpoint";

export default class EndpointVirtual extends Endpoint {
  override endpointType = EndpointType.Virtual;

  override connect(): Promise<void> {
    return Promise.resolve();
  }

  override close(): Promise<void> {
    // nothing to do for virtual endpoints
    return Promise.resolve();
  }
}
