import React, { FC, useEffect, useState } from "react";
import {
  CloseFullScreen,
  IsFullScreen,
  OpenFullScreen,
} from "./FullscreenUtil";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import { StyledIconButton } from "../MediaControls/MediaControls.style";
import { Tooltip } from "@mui/material";

export type FullscreenToggleProps = {
  element?: HTMLElement;
};

const FullscreenToggle: FC<FullscreenToggleProps> = (
  props: FullscreenToggleProps,
) => {
  const [isFullscreen, setFullscreen] = useState<boolean>(IsFullScreen());

  useEffect(() => {
    props.element?.addEventListener("fullscreenchange", () => {
      setFullscreen(IsFullScreen());
    });
  }, [props.element]);

  return (
    <Tooltip
      title={isFullscreen ? "Exit fullscreen" : "Fullscreen"}
      placement="top"
    >
      <StyledIconButton
        aria-label={isFullscreen ? "fullscreenExit" : "fullscreen"}
        size="small"
        color="inherit"
        onClick={() => {
          if (isFullscreen) {
            CloseFullScreen();
            setFullscreen(false);
          } else {
            OpenFullScreen(props.element);
            setFullscreen(true);
          }
        }}
        data-cy={"full-screen-btn"}
        id={"full-screen-btn"}
      >
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </StyledIconButton>
    </Tooltip>
  );
};

export default FullscreenToggle;
