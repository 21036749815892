import { FC, PropsWithChildren, ReactNode } from "react";
import { useFlag } from "@proximie/feature-flag-react-sdk";

interface Props {
  flagName: string;
  children: ReactNode;
}

export const FeatureFlag: FC<PropsWithChildren<Props>> = ({
  flagName,
  children,
}: Props) => {
  const isEnabled = useFlag(flagName);

  if (!isEnabled) {
    return null;
  }

  return children as JSX.Element;
};
