import { Logger, MetaData, LogLevel, LogProps } from "./common";

export type ConsoleLoggerProps = MetaData;

export const createConsoleLogger = (
  loggerProps: ConsoleLoggerProps,
): Logger => ({
  info: (props: LogProps) =>
    console.info({ level: LogLevel.INFO, ...loggerProps, ...props }),

  warn: (props: LogProps) =>
    console.warn({ level: LogLevel.WARN, ...loggerProps, ...props }),

  error: (props: LogProps) =>
    console.error({ level: LogLevel.ERROR, ...loggerProps, ...props }),
});
