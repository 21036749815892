import * as t from "io-ts";
import { empty } from "./common";
import { makeRoute } from "./api";
import { nullable } from "io-ts/lib/Type";

export const userTitlePresentation = t.type({
  id: nullable(t.union([t.undefined, t.number])),
  name: t.string,
});

export const list = makeRoute(
  "/user-titles",
  empty,
  t.array(userTitlePresentation),
);

export type userTitlePresentation = t.TypeOf<typeof userTitlePresentation>;
