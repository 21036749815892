import React from "react";
import { Box, ButtonBase, Grid, useTheme } from "@mui/material";

const colorPool = [
  { hex: "#000000", name: "Black" },
  { hex: "#FFFFFF", name: "White" },
  { hex: "#BE2929", name: "Red" },
  { hex: "#FF5353", name: "Light red" },
  { hex: "#CBA316", name: "Gold" },
  { hex: "#FFFF59", name: "Yellow" },
  { hex: "#26A742", name: "Green" },
  { hex: "#3EFF9E", name: "Mint" },
  { hex: "#2C46CF", name: "Blue" },
  { hex: "#68B4FF", name: "Light blue" },
  { hex: "#732BBF", name: "Purple" },
  { hex: "#C488FF", name: "Lilac" },
  { hex: "#CE249E", name: "Magenta" },
  { hex: "#FF73CE", name: "Pink" },
];

interface ColorPickerProps {
  onColorClick: (color: string) => void;
}

export const ColorPicker: (props: ColorPickerProps) => JSX.Element = ({
  onColorClick,
}: ColorPickerProps) => {
  const { colors } = useTheme();

  return (
    // Box width is two columns of the grid item width (32px) plus 8px spacing
    <Box width={"72px"}>
      <Grid container spacing={"8px"} columns={2}>
        {colorPool.map((color) => (
          <Grid item key={color.hex} component={Box} xs={1}>
            <ButtonBase
              data-cy={color.hex}
              title={color.name}
              style={{ display: "block", width: "32px" }}
              focusRipple={true}
              centerRipple={true}
              onClick={() => onColorClick(color.hex)}
            >
              <Box
                bgcolor={color.hex}
                width={"32px"}
                height={"32px"}
                borderRadius={"50%"}
                boxSizing={"border-box"}
                border={`1px solid ${colors.WhiteDisabled}`}
              />
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
