import { EventEmitter } from "events";
import Endpoint from "../Endpoints/Endpoint";
import Device from "../Devices/Device";

//eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MonitorOptions {}

export default abstract class Monitor extends EventEmitter {
  constructor(protected room: Endpoint, protected options?: MonitorOptions) {
    super();

    // terminate this monitor if the underlying endpoint is closed
    this.room.on("closed", () => {
      this.close();
    });
  }

  close(): Promise<void> {
    console.debug("Closing monitor");
    return Promise.resolve();
  }

  abstract get deviceList(): Device[];
}
