import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class ServerAdapterDestroyedException extends BaseException {
  constructor(message = "Server adapter destroyed", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.SERVER_ADAPTER_DESTROYED;
  }
}
