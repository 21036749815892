import React from "react";
import {
  Divider,
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from "@mui/material";
import { spacersAsString } from "../../styles/spacers";

export interface DialogActionsProps extends MuiDialogActionsProps {
  hasDivider?: boolean;
}

export function DialogActions({
  hasDivider,
  children,
  ...otherProps
}: DialogActionsProps) {
  return (
    <MuiDialogActions {...otherProps}>
      {hasDivider && (
        <Divider
          sx={{
            width: "100%",
            alignSelf: "strecth",
            marginBottom: spacersAsString.md,
          }}
        />
      )}
      {children}
    </MuiDialogActions>
  );
}
