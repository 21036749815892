import styled from "@emotion/styled";

export const LiveSessionChipIconStyled = styled.div`
  .session-chips {
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0 6px;
  }
`;

export default LiveSessionChipIconStyled;
