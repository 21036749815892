// Convenience class to allow deferred settlement of promise.
// Usage:
//   function f(): Promise<number> {
//     const dfd = new Deferred<number>
//     setTimeout(() => {
//       dfd.resolve(99);
//     }, 1000);
//     return dfd.promise;
//   }

export default class Deferred<T> {
  public promise: Promise<T>;
  public resolve: (value: T | PromiseLike<T>) => void = () => null;
  public reject: (error: Error) => void = () => null;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
