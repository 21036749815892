import { LeafMonitor } from "../Monitor";

export type SocketReport = {
  online: boolean;
};

export class SocketMonitor extends LeafMonitor<SocketReport> {
  constructor() {
    super({});
  }

  run() {
    return undefined;
  }
}
