import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface RecordingNotFoundExceptionMeta {
  recordingId: string;
}

export class RecordingNotFoundException extends BaseException {
  constructor(meta: RecordingNotFoundExceptionMeta) {
    super("Recording not found", meta);

    this.code = ExceptionCodes.RECORDING_NOT_FOUND;
  }
}
