import * as t from "io-ts";
import { makeNoContentRoute, makeRoute } from "./api";
import { nullable } from "io-ts/lib/Type";
import { Country } from "./countries";
import { empty } from "./common";
import { shortSpecialityPresentation } from "./specialities";
import { organisationMembershipPresentation } from "./organisation-membership";
import { userTitlePresentation } from "./user-title";

export const ProfileNoRelations = t.type({
  id: t.number,
  profileId: t.union([t.undefined, t.string]),
  name: nullable(t.string),
  email: nullable(t.string),
});

export const shortProfilePresentation = t.type({
  id: t.number,
  profileId: t.union([t.undefined, t.string]),
  name: nullable(t.string),
  speciality: nullable(
    t.type({
      name: t.string,
    }),
  ),
});

export const MinimalProfilePresentation = t.type({
  id: t.number,
  profileId: t.union([t.undefined, t.string]),
  name: t.string,
});

export const profilePresentation = t.type({
  id: t.number,
  profileId: t.string,
  name: nullable(t.string),
  phone: nullable(t.string),
  email: t.string,
  city: nullable(t.string),
  country: nullable(Country),
  speciality: nullable(shortSpecialityPresentation),
  userTitle: nullable(userTitlePresentation),
  orgMemberships: t.array(organisationMembershipPresentation),
  hasAcceptedTerms: t.boolean,
  profileReference: t.string,
});

export const updateMyProfileRequest = t.type({
  name: t.string,
  phone: nullable(t.string),
  city: nullable(t.string),
  countryId: nullable(t.union([t.undefined, t.number])),
  countryISO: nullable(t.union([t.undefined, t.string])),
  specialityId: nullable(t.union([t.undefined, t.number])),
  speciality: nullable(t.union([t.undefined, t.string])),
  hasAcceptedTerms: t.boolean,
  userTitleId: nullable(t.union([t.undefined, t.number])),
  userTitle: nullable(t.union([t.undefined, t.string])),
});

export const updateProfileRequest = t.type({
  id: t.number,
  name: t.string,
  phone: t.string,
  city: t.string,
  countryId: t.union([t.undefined, t.number]),
  countryISO: t.union([t.undefined, t.string]),
  userTitleId: nullable(t.union([t.undefined, t.number])),
  userTitle: nullable(t.union([t.undefined, t.string])),
  specialityId: nullable(t.union([t.undefined, t.number])),
  speciality: nullable(t.union([t.undefined, t.string])),
});

export const authenticateUserRequest = t.type({
  userId: t.number,
  auth0UUID: t.string,
});

export const getMyProfile = makeRoute(
  "/my-profile",
  empty,
  profilePresentation,
);

export const getUserById = makeRoute("/users/:id", empty, profilePresentation);

export const updateMyProfile = makeNoContentRoute(
  "/my-profile",
  updateMyProfileRequest,
);

export const updateProfileById = makeNoContentRoute(
  "/profiles/:id",
  updateProfileRequest,
);

export const authenticateUser = makeRoute(
  "/m2m/users/:id",
  authenticateUserRequest,
  empty,
);

export const m2mGetUserById = makeRoute(
  "/m2m/users/:id",
  empty,
  profilePresentation,
);

export type shortProfilePresentation = t.TypeOf<
  typeof shortProfilePresentation
>;
export type MinimalProfilePresentation = t.TypeOf<
  typeof MinimalProfilePresentation
>;
export type profileNoRelationsPresentation = t.TypeOf<
  typeof ProfileNoRelations
>;
export type profilePresentation = t.TypeOf<typeof profilePresentation>;
export type updateMyProfileRequest = t.TypeOf<typeof updateMyProfileRequest>;
export type updateProfileRequest = t.TypeOf<typeof updateProfileRequest>;
export type authenticateUserRequest = t.TypeOf<typeof authenticateUserRequest>;
