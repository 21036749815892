import React, { FC } from "react";
import MaterialGrid, {
  GridProps as MaterialGridProps,
} from "@mui/material/Grid";

export interface GridProps extends MaterialGridProps {
  children: React.ReactNode;
}

const Grid: FC<GridProps> = (props: GridProps) => {
  return <MaterialGrid {...props} />;
};

export default Grid;
