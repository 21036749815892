import styled from "@emotion/styled";

const DisplayTimeStyled = styled.div`
  font-family: monospace;
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
`;

export default DisplayTimeStyled;
