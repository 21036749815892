import { Box, styled } from "@mui/material";

export const TroubleShootingReportContainerStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 1100px;

  height: min(702px, 100vh);
  max-height: 702px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable;
`;
