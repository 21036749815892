import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToUpdateMediaSessionExceptionMeta {
  originalError: Error;
  mediaSessionId: string;
}

export class FailedToUpdateMediaSessionException extends BaseException {
  constructor(
    message = "Failed to update media session",
    meta: FailedToUpdateMediaSessionExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_UPDATE_MEDIA_SESSION;
  }
}
