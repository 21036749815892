import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToRemoveMemberFromOrganisationException extends BaseException {
  constructor(message = "Not allowed to remove member", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_REMOVE_MEMBER_FROM_ORGANISATION;
  }
}
