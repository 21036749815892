import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { Button, DialogActions, DialogTitle } from "@proximie/components";
import { useTranslation } from "react-i18next";
import { useFlavor } from "@proximie/flavorinator";

export enum PERMISSION_ERROR_CODES {
  ALLOW_CAMMIC,
  BLOCKED_MIC,
  BLOCKED_CAM,
  SHARE_SCREEN,
}

const PERMISSION_ERROR_CONTENTS = {
  [PERMISSION_ERROR_CODES.ALLOW_CAMMIC]: {
    title: "liveSession.browserPermissionDialog.allowCamMicTitle",
    message: "liveSession.browserPermissionDialog.allowCamMicMessage",
  },
  [PERMISSION_ERROR_CODES.BLOCKED_MIC]: {
    title: "liveSession.browserPermissionDialog.blockedMicTitle",
    message: "liveSession.browserPermissionDialog.blockedMicMessage",
  },
  [PERMISSION_ERROR_CODES.BLOCKED_CAM]: {
    title: "liveSession.browserPermissionDialog.blockedCamTitle",
    message: "liveSession.browserPermissionDialog.blockedCamMessage",
  },
  [PERMISSION_ERROR_CODES.SHARE_SCREEN]: {
    title: "liveSession.browserPermissionDialog.shareScreenTitle",
    message: "liveSession.browserPermissionDialog.shareScreenMessage",
  },
};

export interface BrowserPermissionsDialogProps {
  error: PERMISSION_ERROR_CODES;
  dismissError: (error: PERMISSION_ERROR_CODES) => void;
}

const BrowserPermissionsDialog = (props: BrowserPermissionsDialogProps) => {
  const { t } = useTranslation();
  const { getConfiguration } = useFlavor();

  const handleClose = () => {
    props.dismissError(props.error);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      data-testid="browser-permission-dialog"
    >
      <DialogTitle onClose={handleClose}>
        {t(PERMISSION_ERROR_CONTENTS[props.error].title, {
          appName: getConfiguration("appName"),
        })}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
          {t(PERMISSION_ERROR_CONTENTS[props.error].message, {
            appName: getConfiguration("appName"),
          })
            .split("<br/>")
            .join("\n")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleClose}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BrowserPermissionsDialog;
