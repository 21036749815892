import { useRef, useEffect, EffectCallback } from "react";

export function useTimeout(callback: () => void, delay: number | null) {
  const timeoutRef = useRef<number | null>(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay) {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(timeoutRef.current ?? undefined);
    }
    return () => void 0;
  }, [delay]);

  return timeoutRef;
}

export function useInterval(callback: EffectCallback, delay: number | null) {
  const savedCallback = useRef<EffectCallback | undefined>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current?.();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => void 0;
  }, [delay]);
}
