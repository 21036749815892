import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToSpotlightFeedException extends BaseException {
  constructor(meta?: unknown) {
    super("User is not allowed to spotlight feed", meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_SPOTLIGHT_FEED;
  }
}
