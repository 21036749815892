import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface SessionAlreadyStartedExceptionMeta {
  sessionId: string;
}

export class SessionAlreadyStartedException extends BaseException {
  constructor(
    message = "Session already started",
    meta?: SessionAlreadyStartedExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.SESSION_ALREADY_STARTED;
  }
}
