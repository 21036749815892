import { Chip, styled } from "@mui/material";

export const NumberChip = styled(Chip)(
  ({ theme }) => `
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 6.25px;
  height: 15px;
  border-radius: 5px;
  background-color: ${theme.colors.Red600};

  span {
    padding-left: 6px;
    padding-right: 6px;
  }
`,
);
