const Dictionary = {
  liveSession: {
    videoMaskingEditor: {
      description: "out sensitive information such as names, faces or tattoos.",
      explanation:
        "The Privacy Control Tool does not ensure full or effective de-identification or anonymization of the content, including in accordance with applicable laws, rules or regulations.",
      warningTooltipLine1:
        "The Privacy Control Tool does not ensure full or effectivede-identification or anonymization of the content, including in accordance with applicable laws, rules or regulations. Masks drawn over objects do not track or follow movement. Adequate precautions should be taken when starting a session.",
      warningTooltipLine2:
        'Masks drawn in the video feed can be used to obscure some or parts of the video feed at the user or Session Owner\'s discretion. The Privacy Enhancement Tool is provided "as-is" with no warranties or guarantees provided regarding its effectiveness or fitness for a particular purpose. {{appName}} is not liable for any unintended or unwanted obscurations of content or failure of obscurations of content.',
    },
    browserPermissionDialog: {
      allowCamMicTitle: "Allow {{appName}} to use your camera and microphone",
      allowCamMicMessage:
        "{{appName}} needs access to your camera and microphone so that other participants can hear you and see your video feeds. Click on the lock icon in your browser address bar to allow access in your settings.",
      blockedMicTitle: "Microphone is blocked",
      blockedMicMessage:
        "{{appName}} needs access to your microphone. Click on the lock icon in your browser address bar to allow access in your settings. <br/><br/> If the problem still occurs, check your privacy settings on your device to make sure that you allow the browser to access your microphone.",
      blockedCamTitle: "Camera is blocked",
      blockedCamMessage:
        "{{appName}} needs access to your camera. Click on the lock icon in your browser address bar to allow access in your settings. <br/><br/> If the problem still occurs, check your privacy settings on your device to make sure that you allow the browser to access your camera.",
      shareScreenTitle: "Allow {{appName}} to share your screen",
      shareScreenMessage:
        "Open System Preferences > Security & Privacy > Screen Recording to grant access to the browser (e.g. Chrome).",
    },
    troubleshooter: {
      tip1: {
        body: "Find and connect to a different network (e.g. use 'Guest Wi-Fi' or a public Wi-Fi). {{appName}} will remain fully encrypted",
      },
      tip3: {
        body: '"If none of the above are successful, contact our 24hr {{appName}} Customer Support',
      },
    },
    ptzPermissionLabel: {
      //i am owner
      requested: "{{name}} requests interactive device control",
      owned: "You control this interactive device",
      denied: "Request denied. You are still in control",
      received: "Request successful. You now control this interactive device",
      //i am NOT owner
      ended: "Interactive device control ended",
      given: "Interactive device control given to {{name}}",
      busy: "Interactive device still in use by {{name}}. Try again later",
    },
    sessionInfoPanel: {
      sessionNameHeader: "Session Name",
      specialityHeader: "Speciality",
      descriptionHeader: "Description",
      ownerHeader: "Owner",
      participantsHeader: "Participants Invited",
    },
    authFailedDialog: {
      title: "Something went wrong",
      content:
        "Unable to load login page.  Check your network and firewalls and try again.",
      button: "Try again",
    },
  },
  insights: {
    header: {
      title: "{{appName}} - Insights",
    },
  },
  user: {
    noOrganisationWarning:
      "Looks like you're all logged in, but can't access an\n organisation. Please reach out to your organisation's admin or\n contact {{appName}} support for assistance.",
  },
  library: {
    cases: {
      error: "An error has occurred; please try again later",
      tabs: {
        allRecordings: {
          title: "All recordings",
          description: `All videos shared by your organization can be found here ({{ nItems }}).`,
          emptyMessage:
            "No recordings have been created by you or shared by your organization.",
        },
        myRecordings: {
          title: "My recordings",
          description: `You can edit all your recordings ({{ nItems }}).`,
          emptyMessage:
            "No recordings have been created or uploaded by you in this organization.",
        },
        myBookmarks: {
          title: "Bookmarked",
          description: `All your bookmarked recordings can be found here ({{ nItems }}).`,
          emptyMessage: "No recordings have been bookmarked.",
        },
      },
    },
    playback: {
      migrationAlert:
        "You are viewing a session from an older version of {{appName}}. Please expect limited features.",
      options: {
        edit: "Edit details",
        archive: "Archive recording",
      },
      archiveDialog: {
        title: "Are you sure you want to archive this recording?",
        description:
          "Your video will be removed from the library.\nPlease contact customer support if you wish to restore the recording.",
        confirm: "Archive",
        cancel: "Cancel",
      },
    },
  },
  forms: {
    connectDevice: {
      deviceId: {
        label: "{{appName}} device id",
        placeholder: "{{appName}} device id",
      },
    },
    uploadVideo: {
      defaultTitle: "Upload video",
      stepper: {
        details: "Details",
        sharing: "Sharing",
      },
      fileInput: {
        acknowledgement:
          "By submitting your videos to {{appName}}, you acknowledge that you\n agree to {{appName}}'s ",
      },
      caseEditForm: {
        title: "Details",
        uploadFile: "Uploading file: {{fileName}}",
        caseTitlePlaceholder: "Add title of video",
        caseDescriptionPlaceholder: "Description (optional)",
        caseSpecialityLabel: "Speciality",
        confirmSurgicalContent: "Confirm this is surgical content",
        nextBtn: "Next",
        backBtn: "Cancel",
      },
      privacyStep: {
        title: "Sharing",
        description:
          "Choose who can see your video. You can update your sharing permissions at any time",
        privateOptionLabel: "Only Me",
        privateOptionDescription: "Only visible to you (owner of video)",
        organizationOptionLabel: "Organization: {{orgName}}",
        organizationOptionDescription:
          "Your whole selected organization can view this video",
        nextBtn: "Done",
        backBtn: "Back",
      },
    },
  },
  pages: {
    home: {
      header: {
        title: "{{appName}} - Home",
      },
      filters: {
        speciality: "Speciality",
        sessionOwner: "Session owner",
        room: "Room",
      },
    },
    profile: {
      header: {
        title: "{{appName}} - Profile",
      },
    },
    settings: {
      header: {
        title: "{{appName}} - Settings",
      },
    },
    session: {
      form: {
        speciality: "Speciality",
        room: "Operating room / theater name",
        sessionOwner: "Session owner",
        sessionOwnerTooltip:
          "The session owner has full control over the session. They decide if other people in the organization can view the recorded session.",

        participants: "Participants",
        participantsTooltip:
          "Participants can add video feeds, mute / unmute themselves, annotate and chat. They can only access the recording if the session owner shares it with the organization.",
      },
      edit: {
        header: {
          title: "{{appName}} - Edit session",
        },
      },
      schedule: {
        header: {
          title: "{{appName}} - Schedule session",
        },
      },
    },
    library: {
      cases: {
        header: {
          title: "{{appName}} - Cases",
        },
      },
      playback: {
        header: {
          title: "{{appName}} - Playback",
        },
      },
      editor: {
        header: {
          title: "{{appName}} - Editor",
        },
      },
    },
    organisation: {
      settings: {
        header: {
          title: "{{appName}} - Organisation",
        },
      },
      create: {
        header: {
          title: "{{appName}} - Create organisation",
        },
      },
    },
    user: {
      editProfile: {
        header: {
          title: "{{appName}} - Edit profile",
        },
      },
    },
    browserCheck: {
      message:
        "{{appName}} works best on the latest versions of Google Chrome or Microsoft Edge on a laptop or desktop computer.",
    },
    deviceAuth: {
      header: {
        title: "Log in | {{appName}} Web",
      },
    },
  },
  common: {
    api: {
      errorCodes: {
        APPOINTMENT_NOT_FOUND: "Appointment not found",
        DEFAULT_ORGANISATION_NOT_FOUND: "Default organization not found",
        INVALID_MEDIA_SESSION_ID: "Invalid media session ID",
        NOT_ALLOWED_TO_CREATE_ORGANISATION:
          "Not allowed to create organization",
        NOT_ALLOWED_TO_GET_ORGANISATION: "Not allowed to get organization",
        NOT_ALLOWED_TO_INVITE_USERS: "Not allowed to invite users",
        NOT_ALLOWED_TO_UPDATE_APPOINTMENT: "Not allowed to update appointment",
        NOT_ALLOWED_TO_UPDATE_APPOINTMENT_INVITATION:
          "Not allowed to invite appointment invitation",
        NOT_ALLOWED_TO_UPDATE_ORGANISATION:
          "Not allowed to update organization",
        NOT_ALLOWED_TO_UPDATE_USER: "Not allowed to update user",
        NOT_ALLOWED_TO_UPDATE_USER_ROLE: "Not allowed to update user role",
        UNAUTHENTICATED: "Unauthenticated",
        UNSUPPORTED_COUNTRY: "Unsupported country",
        UNSUPPORTED_NUMBER_OF_EMAILS: "Unsupported number of emails",
        USER_ALREADY_EXISTS_IN_ORGANISATION:
          "User already exists in this organization",
        ORGANISATION_ALREADY_EXISTS: "Organization already exists",
        USER_DOES_NOT_EXISTS_IN_ORGANISATION:
          "User does not exist in this organization",
        USER_NOT_FOUND: "User not found",
        NOT_ALLOWED_TO_GET_ORGANISATION_USERS:
          "Not allowed to get organization users",
        NOT_ALLOWED_TO_REMOVE_ADMIN:
          "Not allowed to remove admin from organization",
        NOT_ALLOWED_TO_REMOVE_MEMBER_FROM_ORGANISATION:
          "Not allowed to remove member from organization",
        NOT_ALLOWED_TO_REMOVE_MEMBER_IN_SESSION:
          "Not allowed to remove member from organization",
        SESSION_ALREADY_STARTED:
          "Session has already started, you cannot remove participants",
        ENDPOINT_NOT_INITIALIZED:
          "Thank you for joining. Please wait for the session to begin.",
        VIDEO_CAPACITY_EXCEPTION_EXCEEDED:
          "This session currently has the maximum number of four video feeds. To add a different feed, first you must end one of the existing feeds.",
      },
    },
    components: {
      snackbar: {
        messages: {
          unstableConnection:
            "Your internet connection is unstable. You should be reconnected shortly. If this problem persists, try refreshing your browser.",
          noAudioInputFound:
            "No audio input has been found. Please check your connections, and your devices such as microphones, cameras and any other audio source you may be using.",
          somethingWentWrong: "Something went wrong",
          recordingInProgress: "This session is being recorded",
          unableToGetRecordingStatus: "Unable to get recording status",
          unableToChangeRecordingStatus: "Unable to change recording status",
          videoStalledFailure:
            "The video stream has encountered an error. We will try to re-establish the connection.",
          allFeedsAdded:
            "All your video devices were successfully added to the session.",
          noFeedsAdded:
            "None of the feeds from your previous session can be found. Check connections and try adding them again.",
          cameraDisconnectedTitle_one: "Video device disconnected",
          cameraDisconnectedTitle_other: "Video devices disconnected",
          cameraDisconnected_one:
            "Device {{deviceList}} Check the connection and power supply, then try adding the video devices again.",
          cameraDisconnected_other:
            "Device {{deviceList}} Check the connection and power supply, then try adding the video device again.",
          genericDevicesDisconnectedTitle_one: "Device disconnected",
          genericDevicesDisconnectedTitle_other: "Devices disconnected",
          genericDevicesDisconnected_one:
            "Device: {{deviceList}} Check its connection and power supply, then try adding the device again.",
          genericDevicesDisconnected_other:
            "Device: {{deviceList}} Check its connection and power supply, then try adding the devices again.",
          micDisconnectedTitle: "Microphone disconnected",
          micDisconnected_one:
            "Device {{deviceList}} Check its connection and power supply, then try selecting the microphone again.",
          micDisconnected_other:
            "Device {{deviceList}} Check its connection and power supply, then try selecting the microphone again.",

          noConnectedDevicesFoundTitle: "No video devices connected",
          noConnectedDevicesFound:
            "Check the connection and power supply then click ‘Add video device’.",
          maxDevicesAllowed: "Maximum 4 devices allowed",
          videoKicked: "A {{role} has removed your video feed(s)}}",
          videoKickedSessionOwner:
            "The session owner has removed your video feed(s)",
          spotlightRemoved: "Spotlight removed",
          failedToSubmitSupportTicketTitle: "Error sending request",
          failedToSubmitSupportTicket:
            "Apologies, there was an issue processing your request.\n Try again or contact support.",
          captureUserDetailsError:
            "Failed to update user and accept terms & conditions",
          acceptAppointmentInvitationError:
            "Failed to accept appointment invitation",
          changeRoleSuccess: "Role updated successfully",
          changeRoleError: "Failed to update role",
          createOrganisationError: "Failed to create organization",
          createFacilityError: "Failed to create facility",
          createFacilitySuccess: "Facility created successfully",
          removeFacilityError: "Failed to remove facility",
          createRoomError: "Failed to create room",
          createRoomSuccess: "Room created successfully",
          removeRoomError: "Failed to remove room",
          createOrganisationSuccess: "Organization created successfully",
          deleteOrganisationSuccess:
            "Organization flagged for deletion. Remember to create a support request.",
          createSessionSuccess: "Session scheduled",
          updateSessionSuccess: "Changes saved",
          userSettingsUpdated: "User settings updated",
          createSessionError: "Failed to schedule session",
          failedToCheckIfMemberCanBeRemoved:
            "Failed to check if member can be removed",
          inviteUsersSuccess: "You have successfully sent your invitation(s)",
          inviteUserError:
            "Unable to invite member. Please try again later or contact support",
          joinOrganisationSuccess: "You've been added to {{organisationName}}",
          reInviteUserSuccess: "Invitation has been resent",
          reInviteUserError: "Failed to resend invitation",
          updateOrganisationSuccess:
            "You have successfully updated your organization",
          updateOrganisationError: "Failed to update the organization",
          failedToRemoveMember: "Failed to remove member from organization",
          memberWasSuccessfullyRemoved: "Member is successfully removed",
          updateProfileSuccess: "Profile updated successfully",
          updateProfileError: "Failed to update profile",
          updateYourProfileSuccess:
            "You have successfully updated your profile",
          updateYourProfileError: "Failed to update your profile",
          userAlreadyExistsInOrganisation:
            "This member has already been invited to the organization",
          updateSessionError: "Failed to update session",
          deleteSessionSuccess: "Session has been deleted successfully.",
          endSessionSuccess: "Session has ended successfully.",
          failedToDeleteSession: "Failed to delete appointment",
          failedToEndSession: "Failed to end session",
          deviceConnectionSuccess: "Interactive device connected successfully",
          deviceDisconnectionSuccess:
            "Interactive device disconnected successfully",
          deviceConnectionFailure:
            "Interactive device could not be connected; please check the details and try again",
          savedBookmark: "You have successfully bookmarked the session",
          saveBookmarkFailed:
            "Failed to bookmark session. Please try again later.",
          deletedBookmark: "Bookmark has been removed",
          deleteBookmarkFailed:
            "Failed to remove bookmark. Please try again later.",
          deleteCaseFailure:
            "Failed to remove session recording. Please try again later",
          toggledPrivacy: "Privacy settings updated",
          togglePrivacyFailed: "Failed to update privacy settings",
          deleteCaseSuccess: "Your recording has been archived",
          editorExportFailed: "Failed to export the video",
          notificationSettingsUpdated: "Notification settings updated",
          videoSettingsUpdated: "Video settings updated",
          recordingDetailsSaved: "Recording details have been saved",
          uploadSubmitFailed: "Failed to submit upload of file {{fileName}}",
          uploadToS3Failed:
            "Error while uploading file {{fileName}} to the library",
          uploadCancelled: "Upload cancelled",
          organisationAlreadyExists: "Organization already exists",
          retrieveCaseError:
            "Issue loading recording. Try again or contact support",
          deviceRenamed: "Video device renamed to {{name}}",
          deviceRenamedFailure:
            "Error renaming video device. Please try again.",
        },
      },
    },
    footer: {
      termsOfService: "Terms of service",
      privacyPolicy: "Privacy statement",
    },
  },
};

type Interpolations = {
  appName: string;
};

export { Dictionary, Interpolations };
