import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";

type UserSettings = {
  version: number;
  settings: {
    liveSession: {
      lastStatePreviewBehavior: "PROMPT" | "AUTOMATICALLY_ADD";
    };
  };
};

const defaultUserSettings: UserSettings = {
  version: 0,
  settings: {
    liveSession: {
      lastStatePreviewBehavior: "PROMPT",
    },
  },
};

const getUserSettings: () => Promise<UserSettings> = async () =>
  (await axios.get(`${environment.apiUrl}/api/v2/users/me/settings`)).data;

export const useUserSettings = (opts?: SWRConfiguration) => {
  const { data, error } = useSWR<UserSettings>(
    `user-settings`,
    getUserSettings,
    opts,
  );

  return {
    isUserSettingsLoading: !error && !data,
    userSettingsError: error,
    settings: (data || defaultUserSettings).settings,
  };
};
