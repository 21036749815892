import React, { FC, useState, useCallback } from "react";
import { PlayArrow, Pause } from "@mui/icons-material";
import { useEventListener } from "../../hooks";
import { MediaControlProps } from "../../MediaControls";
import { StyledIconButton } from "../../MediaControls.style";

const PlayPauseButton: FC<MediaControlProps> = ({
  element,
}: MediaControlProps) => {
  const [playing, setPlaying] = useState(false);
  const playHandler = useCallback(() => {
    setPlaying(true);
  }, [setPlaying]);

  const pauseHandler = useCallback(() => {
    setPlaying(false);
  }, [setPlaying]);

  useEventListener("play", playHandler, element);
  useEventListener("pause", pauseHandler, element);

  const togglePlay = () => {
    if (!element) {
      return;
    }
    if (playing) {
      element.pause();
    } else {
      element.play();
    }
  };

  return (
    <StyledIconButton onClick={togglePlay} disableRipple={true}>
      {!playing ? <PlayArrow /> : <Pause />}
    </StyledIconButton>
  );
};

export default PlayPauseButton;
