import QualityVideoIncoming from "./QualityVideoIncoming";
import { ModeType } from "./Quality";
import { INBOUND_CAMERA_QUALITY_LIMITS } from "../../Constants";
import { JanusJS } from "@proximie/janus-gateway";

enum TemporalOptions {
  Low = 0,
  Medium = 1,
  High = 2,
}

enum SubstreamOptions {
  Low = 0,
  Medium = 1,
  High = 2,
}

export default class QualityVideoIncomingCamera extends QualityVideoIncoming {
  private initialStalledSubtream?: SubstreamOptions;

  public controls = {
    mode: {
      value: ModeType.Automatic,
      options: [
        { value: ModeType.Manual, label: "Manual" },
        { value: ModeType.Automatic, label: "Automatic" },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoInCam: auto value=",
          newValue,
        );
        this.controls.mode.value = Number(!!newValue);
        this.reset();
      },
    },
    temporal: {
      value: TemporalOptions.High,
      options: [
        {
          value: TemporalOptions.High,
          label: TemporalOptions[TemporalOptions.High],
        },
        {
          value: TemporalOptions.Medium,
          label: TemporalOptions[TemporalOptions.Medium],
        },
        {
          value: TemporalOptions.Low,
          label: TemporalOptions[TemporalOptions.Low],
        },
      ],
      onChange: async (newValue: number): Promise<void> => {
        if (newValue < TemporalOptions.Low || newValue > TemporalOptions.High) {
          console.warn(
            {
              streamId: this.streamId,
            },
            "QualityVideoInCam: invalid temporal value=",
            newValue,
          );
          return;
        }
        if (!this.isAutoMode) {
          console.debug(
            {
              streamId: this.streamId,
            },
            "QualityVideoInCam: temporal value=",
            newValue,
          );
          try {
            await this.setTemporal(newValue);
          } catch {
            //ignore error
          }
        }
      },
    },
    substream: {
      value: SubstreamOptions.High,
      options: [
        {
          value: SubstreamOptions.High,
          label: SubstreamOptions[SubstreamOptions.High],
        },
        {
          value: SubstreamOptions.Medium,
          label: SubstreamOptions[SubstreamOptions.Medium],
        },
        {
          value: SubstreamOptions.Low,
          label: SubstreamOptions[SubstreamOptions.Low],
        },
      ],
      onChange: async (newValue: number): Promise<void> => {
        if (
          newValue < SubstreamOptions.Low ||
          newValue > SubstreamOptions.High
        ) {
          console.warn(
            {
              streamId: this.streamId,
            },
            "QualityVideoInCam: invalid substream value=",
            newValue,
          );
          return;
        }
        if (!this.isAutoMode) {
          console.debug(
            {
              streamId: this.streamId,
            },
            "QualityVideoInCam: substream value=",
            newValue,
          );
          try {
            await this.setSubstream(newValue);
          } catch {
            //ignore error
          }
        }
      },
    },
  };
  protected limits = INBOUND_CAMERA_QUALITY_LIMITS;

  private get isAutoMode() {
    return !!this.controls.mode.value;
  }

  protected downgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "INBOUND ALG downgrade",
      this.controls.temporal.value,
      this.controls.substream.value,
    );
    if (
      this.controls.substream.value === SubstreamOptions.Low &&
      this.controls.temporal.value === TemporalOptions.Low
    ) {
      console.warn(
        {
          streamId: this.streamId,
        },
        "Cannot go any lower",
      );
      return false;
    }

    this.reset();

    if (this.controls.substream.value > this.controls.temporal.value) {
      // reduce temporal first
      console.log(
        {
          streamId: this.streamId,
        },
        "INBOUND ALG: Reduce substream=",
        this.controls.substream.value - 1,
      );
      this.setSubstream(this.controls.substream.value - 1);
    } else {
      console.log(
        {
          streamId: this.streamId,
        },
        "INBOUND ALG: Reduce temporal=",
        this.controls.temporal.value - 1,
      );
      this.setTemporal(this.controls.temporal.value - 1);
    }
    return true;
  }

  protected upgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    console.debug(
      {
        streamId: this.streamId,
      },
      "INBOUND ALG upgrade",
      this.controls.temporal.value,
      this.controls.substream.value,
    );
    if (
      this.controls.substream.value === SubstreamOptions.High &&
      this.controls.temporal.value === TemporalOptions.High
    ) {
      return false;
    }

    this.reset();

    if (this.controls.temporal.value < this.controls.substream.value) {
      console.log(
        {
          streamId: this.streamId,
        },
        "INBOUND ALG: Increase temporal=",
        this.controls.temporal.value + 1,
      );
      this.setTemporal(this.controls.temporal.value + 1);
    } else {
      console.log(
        {
          streamId: this.streamId,
        },
        "INBOUND ALG: Increase substream=",
        this.controls.substream.value + 1,
      );
      this.setSubstream(this.controls.substream.value + 1);
    }
    return true;
  }

  protected override stalled(): void {
    console.debug(
      {
        streamId: this.streamId,
      },
      "VideoIn:stalled",
      this.controls.substream.value,
      this.initialStalledSubtream,
    );
    this.reset();

    if (typeof this.initialStalledSubtream === "undefined") {
      this.initialStalledSubtream = this.controls.substream.value;

      if (this.initialStalledSubtream === SubstreamOptions.Low) {
        // we could stall when in low substream if the sender updated its encodings
        // so that there was an inactive low quality substream.  In this case
        // attempt to go up
        this.setSubstream(this.controls.substream.value + 1);
        return;
      }
    }

    if (
      this.initialStalledSubtream === SubstreamOptions.Low ||
      this.controls.substream.value === SubstreamOptions.Low
    ) {
      super.stalled();
      return;
    }

    // we don't invoke downgrade() here 'cos we explicitly want to
    // change the substream
    this.setSubstream(this.controls.substream.value - 1);
  }

  protected override flowing(): void {
    console.debug(
      {
        streamId: this.streamId,
      },
      "VideoIn:flowing",
      this.initialStalledSubtream,
    );
    this.initialStalledSubtream = undefined;
  }

  get substream() {
    return this.controls.substream.value;
  }

  set substream(value: number) {
    const oldValue = this.controls.substream.value;
    this.controls.substream.value = value;
    this.emit("substream", oldValue, value);
    this.reset();
  }

  setSubstream(value: number): Promise<void> {
    return new Promise((resolve, reject) => {
      console.debug(
        {
          streamId: this.streamId,
        },
        "VideoIn:setSubstream",
        value,
      );
      const body = { request: "configure", substream: value };
      this.handle?.send({
        message: body,
        success: () => {
          console.debug(
            {
              streamId: this.streamId,
            },
            "VideoIn:setSubstream - done",
          );
          this.substream = value;
          resolve();
        },
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error: any) => {
          reject(new Error(error));
        },
      } as unknown as JanusJS.PluginMessage);
    });
  }

  get temporal() {
    return this.controls.temporal.value;
  }

  set temporal(value: number) {
    const oldValue = this.controls.temporal.value;
    this.controls.temporal.value = value;
    this.emit("temporal", oldValue, value);
    this.reset();
  }

  setTemporal(value: number): Promise<void> {
    return new Promise((resolve, reject) => {
      console.debug(
        {
          streamId: this.streamId,
        },
        "VideoIn:setTemporal",
        value,
      );
      const body = { request: "configure", temporal: value };
      this.handle?.send({
        message: body,
        success: () => {
          console.debug(
            {
              streamId: this.streamId,
            },
            "setTemporal - done",
          );
          this.temporal = value;
          resolve();
        },
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error: any) => {
          reject(new Error(error));
        },
      } as unknown as JanusJS.PluginMessage);
    });
  }
}
