import React from "react";
import { SnackbarOrigin } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";

export const DEFAULT_AUTO_HIDE_DURATION = 4000;

export interface SnackbarMessage {
  body: string;
  title?: string;
  icon?: React.ReactNode;
}

export interface SnackbarState {
  message: SnackbarMessage;
  severity?: AlertColor;
  autoHideDuration?: number | null;
  anchorOrigin?: SnackbarOrigin;
  open: boolean;
  color?: string;
  bgColor?: string;
}

export type GetNode<T> = (messageProps?: Record<string, T>) => React.ReactNode;

export type MessageMap = Record<string, GetNode<unknown>>;
