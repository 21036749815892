import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class PlaybackNotAllowedException extends BaseException {
  constructor(meta?: unknown) {
    super("User is not allowed to playback recording", meta);

    this.code = ExceptionCodes.PLAYBACK_NOT_ALLOWED;
  }
}
