import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const LOCAL_STORAGE_LABEL_MAP_KEY = "camera-label-map";
const WINDOW_DISPATCH_KEY = "camera-labels-updated";
const MAX_SIZE = 100;

interface LabelMappingElement {
  inLabel: string;
  outLabel: string;

  [key: string]: unknown; // for future use
}

type LabelMapping = Array<LabelMappingElement>;

interface CameraLabelProviderProps {
  children: ReactNode;
}

export interface CameraLabelContext {
  set: (inLabel: string, outLabel: string) => void;
  remove: (inLabel: string) => void;
  get: (inLabel?: string) => string;
  mappings: LabelMapping;
}

const DEFAULT_CAMERA_LABEL_CONTEXT: CameraLabelContext = {
  set: () => {
    return;
  },
  remove: () => {
    return;
  },
  get: (inLabel?: string) => inLabel ?? "UNKNOWN",
  mappings: [],
};

function escapeString(value: string): string {
  return value
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll('"', "&quot;")
    .replaceAll("'", "&#x27;");
}

function unEscapeString(value: string) {
  return value
    .replaceAll("&amp;", "&")
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .replaceAll("&quot;", '"')
    .replaceAll("&#x27;", "'");
}

const CameraLabelContext = createContext<CameraLabelContext>(
  DEFAULT_CAMERA_LABEL_CONTEXT,
);

export const useCameraLabel = () => useContext(CameraLabelContext);

export const CameraLabelProvider = ({ children }: CameraLabelProviderProps) => {
  const [mappings, setMappings] = useState<LabelMapping>([]);

  useEffect(() => {
    // initialise the state fron localStorage
    try {
      const mapppingsStr =
        localStorage.getItem(LOCAL_STORAGE_LABEL_MAP_KEY) || "[]";
      const myMappings = JSON.parse(mapppingsStr);
      if (Array.isArray(myMappings)) {
        setMappings(myMappings);
        console.debug("cameraLabels initialised = ", myMappings);
      }
    } catch {
      // ignore error - the mappings are initialised to []
    }
  }, []);

  const write = (myMappings: LabelMapping): void => {
    localStorage.setItem(
      LOCAL_STORAGE_LABEL_MAP_KEY,
      JSON.stringify(myMappings),
    );

    // notify PxKit of the update
    window.dispatchEvent(
      new CustomEvent(WINDOW_DISPATCH_KEY, { detail: myMappings }),
    );

    console.debug("cameraLabels updated = ", myMappings);
  };

  const set = useCallback((inLabel: string, outLabel: string): void => {
    setMappings((myMappings: LabelMapping): LabelMapping => {
      // remove any existing mapping for this label
      myMappings = myMappings.filter(
        (myElem: LabelMappingElement): boolean => myElem.inLabel !== inLabel,
      );

      const escapedOutLabel = escapeString(outLabel);

      //NB. in the event that we have exceeded MAX_SIZE mappings then
      // we remove the oldest one that was set
      myMappings = [
        ...myMappings,
        { inLabel, outLabel: escapedOutLabel },
      ].slice(-MAX_SIZE);

      write(myMappings);

      return myMappings;
    });
  }, []);

  const remove = useCallback((inLabel: string): void => {
    setMappings((myMappings: LabelMapping): LabelMapping => {
      myMappings = myMappings.filter(
        (elem: LabelMappingElement): boolean => elem.inLabel !== inLabel,
      );

      write(myMappings);

      return myMappings;
    });
  }, []);

  const get = useCallback(
    (inLabel?: string): string => {
      if (!inLabel) {
        return "UNKNOWN";
      }

      const elem = mappings.find(
        (myElem: LabelMappingElement): boolean => myElem.inLabel === inLabel,
      );

      if (!elem) {
        return inLabel;
      }

      return unEscapeString(elem.outLabel);
    },
    [mappings],
  );

  const value = useMemo(
    () => ({ get, set, remove, mappings }),
    [get, set, remove, mappings],
  );

  return (
    <CameraLabelContext.Provider value={value}>
      {children}
    </CameraLabelContext.Provider>
  );
};
