import React from "react";
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import {
  Circle,
  CircleOutlined,
  Rectangle,
  RectangleOutlined,
} from "@mui/icons-material";
import { Shapes } from "@proximie/media";

export interface ShapeSelectorProps {
  maskingShape: Shapes | null;
  onChange: (_: React.MouseEvent<HTMLElement>, newShape: Shapes) => void;
  isDisabled: boolean;
}

const ShapeSelector = (props: ShapeSelectorProps) => {
  const { colors } = useTheme();

  const { maskingShape: shape, onChange, isDisabled } = props;

  const btnSxSize = {
    minHeight: "56px",
    minWidth: "56px",
    borderColor: colors.Grey300,
  };

  return (
    <ToggleButtonGroup
      value={shape}
      exclusive
      onChange={onChange}
      color="secondary"
      size="large"
      sx={{ bgcolor: "white" }}
      data-testid="shape-selector"
    >
      <ToggleButton
        key={Shapes.Rect}
        value={Shapes.Rect}
        sx={btnSxSize}
        disabled={isDisabled}
        data-testid="rectangle-shape"
      >
        {shape === Shapes.Rect ? <Rectangle /> : <RectangleOutlined />}
      </ToggleButton>
      <ToggleButton
        key={Shapes.Elip}
        value={Shapes.Elip}
        sx={btnSxSize}
        disabled={isDisabled}
        data-testid="ellipse-shape"
      >
        {shape === Shapes.Elip ? <Circle /> : <CircleOutlined />}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ShapeSelector;
