import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class DefaultOrganisationNotFoundException extends BaseException {
  constructor(message = "Default organization not found", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.DEFAULT_ORGANISATION_NOT_FOUND;
  }
}
