export type OrganisationMembership = {
  id: string;
  name: string;
  role: string;
  invitedAt: string;
};
export type User = {
  id: string;
  profileId?: number;
  email: string;
  hasAcceptedTerms: boolean;
  name?: string;
  city?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  userTitle?: string;
  speciality?: string;
  countryISO?: string;
  profileReference?: string;
  organisationMemberships?: Array<OrganisationMembership>;
};
