import { Box, styled } from "@mui/material";

export const TroubleShootingMenuOverviewStyled = styled(Box, {
  shouldForwardProp: (name) =>
    name !== "textColor" && name !== "collapsed" && name !== "lineCount",
})<{
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  collapsed: boolean;
  lineCount: number;
}>`
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  gap: 6px;
  color: ${({ textColor }) => textColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  width: 100%;
  overflow: hidden;
  min-height: ${({ collapsed, lineCount }) =>
    collapsed ? 36 : lineCount * 20 + 36 ?? 76}px;
  max-height: 98px;
  transition: 100ms ease-in-out;
`;
