import React, { useRef, useState, useEffect } from "react";
import { Tooltip } from "@mui/material";

const OverflowTip = ({ children }: { children: React.ReactNode }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setIsOverflow(
      (textElementRef?.current?.scrollWidth || 0) >
        (textElementRef?.current?.clientWidth || 0),
    );
  }, []);
  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
