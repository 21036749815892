import React, { FC, SyntheticEvent, useCallback, useState } from "react";
import { Flexbox, useEmbeddedClient } from "@proximie/components";
import {
  Box,
  Button as MUIButton,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Edit, VideoCall } from "@mui/icons-material";
import { Wrapper } from "./AddVideo.styles";
import { UseServerAdapterContext } from "../../contexts/server-adapter-context";
import { useCameraLabel } from "../../contexts/camera-label-context";
import { Connection, Device, DeviceDcp, DeviceMetadata } from "@proximie/media";
import { RenameDeviceDialog } from "../RenameDeviceDialog";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import { FeatureFlags } from "../constants";

export interface AddVideoProps {
  isCondensed?: boolean;
  sendCamera: (device: Device) => void;
  videos: Connection[];
  checkCam?: () => void;
}

type Devices = {
  virtual: Device[];
  dcp: Device[];
  real: Device[];
};

const AddVideo: FC<AddVideoProps> = ({
  isCondensed = true,
  sendCamera,
  videos,
  checkCam,
}: AddVideoProps): JSX.Element => {
  const context = UseServerAdapterContext();
  const { get: getCameraLabel } = useCameraLabel();
  const { isEmbedded, data } = useEmbeddedClient();
  const { virtualCameraPattern, virtualCameraSubsectionTitle } = data ?? {};

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [availableDevices, setAvailableDevices] = useState<Devices>({
    virtual: [],
    dcp: [],
    real: [],
  });
  const isFeedRenamingEnabled = useFlag(FeatureFlags.FEED_RENAME);

  const updateDeviceList = useCallback(() => {
    const list: Device[] = [...(context?.cameraMonitor?.deviceList ?? [])].sort(
      ({ label: labelA }, { label: labelB }) =>
        getCameraLabel(labelA).localeCompare(getCameraLabel(labelB)),
    );

    const virtualDevices =
      isEmbedded && virtualCameraPattern
        ? list.filter((device: Device) =>
            new RegExp(virtualCameraPattern).test(device.label),
          )
        : [];

    const dcpDevices = list.filter(
      (device: Device) => device instanceof DeviceDcp,
    );

    const realDevices = list.filter(
      (device: Device) =>
        !virtualDevices.some((d) => d.deviceId === device.deviceId) &&
        !dcpDevices.some((d) => d.deviceId === device.deviceId),
    );

    setAvailableDevices({
      virtual: virtualDevices,
      dcp: dcpDevices,
      real: realDevices,
    });
  }, [context, getCameraLabel, isEmbedded, virtualCameraPattern]);

  const addDeviceList = useCallback(
    (device: Device): void => {
      device.on("state", updateDeviceList);
      updateDeviceList();
    },
    [updateDeviceList],
  );

  const removeDeviceList = useCallback(
    (device: Device): void => {
      device.off("state", updateDeviceList);
      updateDeviceList();
    },
    [updateDeviceList],
  );

  const openVideoDeviceList = (event: SyntheticEvent): void => {
    checkCam?.();
    setAnchorEl(event.currentTarget);

    if (context?.cameraMonitor) {
      context.cameraMonitor.on("added", addDeviceList);
      context.cameraMonitor.on("removed", removeDeviceList);

      context.cameraMonitor.deviceList.forEach((device: Device): void => {
        device.on("state", updateDeviceList);
      });

      updateDeviceList();
    }
  };

  const closeVideoDeviceList = (): void => {
    setAnchorEl(null);

    if (context?.cameraMonitor) {
      context.cameraMonitor.off("added", addDeviceList);
      context.cameraMonitor.off("removed", removeDeviceList);

      context.cameraMonitor.deviceList.forEach((device: Device): void => {
        device.off("state", updateDeviceList);
      });
    }
  };

  const selectVideoDevice = (device: Device): void => {
    closeVideoDeviceList();
    sendCamera(device);
  };

  const isSelected = (device: Device): boolean => {
    return !!videos.find((connection: Connection): boolean => {
      if (!(device instanceof DeviceDcp)) {
        return !!connection.getDeviceById(device.deviceId);
      }

      if (!connection.params?.devices) {
        return !!connection.getDeviceById(device.deviceId);
      }

      return !!connection.params.devices.find(
        //eslint-disable-next-line sonarjs/no-identical-functions
        (metadata: DeviceMetadata): boolean =>
          Boolean(
            metadata.deviceId === device.deviceId &&
              metadata.component === device.options.component,
          ),
      );
    });
  };

  const isDisabled = (device: Device): boolean => {
    if (!(device instanceof DeviceDcp)) {
      // we can have multiple instances of local devices
      return false;
    }

    const stream = device.state?.STREAM;
    //LATER - pre-LMS backwards compatibility
    if (stream?.streaming || (stream?.mode && stream?.mode !== "none")) {
      return true;
    }

    return !!videos.find((connection: Connection): boolean => {
      if (!connection.params?.devices) {
        return false;
      }
      return !!connection.params.devices.find(
        //eslint-disable-next-line sonarjs/no-identical-functions
        (metadata: DeviceMetadata): boolean =>
          metadata.deviceId === device.deviceId &&
          metadata.component === device.options.component,
      );
    });
  };

  const renderVirtualDevices = (devices: Device[]): JSX.Element[] | null => {
    if (devices.length <= 0) {
      return null;
    }

    return [
      <Divider role="presentation" key={`${devices[0].deviceId}-START`}>
        {virtualCameraSubsectionTitle}
      </Divider>,
      ...renderDevices(devices),
      <Divider role="presentation" key={`${devices[0].deviceId}-END`} />,
    ];
  };

  const renderDcpDevices = (devices: Device[]): JSX.Element[] | null => {
    if (devices.length <= 0) {
      return null;
    }

    const firstDevice = devices[0] as DeviceDcp;

    return [
      <Divider role="presentation" key={`${devices[0].deviceId}-START`}>
        {firstDevice.options.dcpDevice.devicePresence.label}
      </Divider>,
      ...renderDevices(devices),
      <Divider role="presentation" key={`${devices[0].deviceId}-END`} />,
    ];
  };

  const renderDevices = (devices: Device[]): JSX.Element[] =>
    devices.map(
      (device): JSX.Element => (
        <MenuItem
          key={device.uniqueId}
          sx={{
            padding: "0",
            margin: "0",
            paddingRight: isFeedRenamingEnabled ? "8px" : "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "8px",

            "&.Mui-disabled": {
              opacity: "1",
            },
          }}
          selected={isSelected(device)}
          disabled={isDisabled(device)}
          onClick={() => selectVideoDevice(device)}
          data-cy="add-camera-video"
        >
          <Box
            sx={{
              paddingY: "12px",
              paddingLeft: "16px",
              minWidth: isFeedRenamingEnabled ? "216px" : "100%",
              maxWidth: isFeedRenamingEnabled ? "240px" : "100%",
              width: isFeedRenamingEnabled ? "auto" : "100%",
              overflow: isFeedRenamingEnabled ? "hidden" : "visible",
              textOverflow: isFeedRenamingEnabled ? "ellipsis" : "visible",
              display: "block",

              ".Mui-disabled &": {
                opacity: "0.38",
              },
            }}
          >
            {getCameraLabel(device.label)}
          </Box>
          {isFeedRenamingEnabled && (
            <Box
              sx={{
                cursor: "pointer",

                ".Mui-disabled &": {
                  cursor: "pointer",
                  pointerEvents: "all",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                openDeviceRename(device.label);
              }}
              padding={0}
              margin={0}
            >
              <IconButton
                sx={{ padding: "12px" }}
                data-testid={`rename-device-button-${device.label}`}
              >
                <Edit
                  width={"20px"}
                  height={"20px"}
                  sx={{ color: colors.BrandPrimaryDark }}
                />
              </IconButton>
            </Box>
          )}
        </MenuItem>
      ),
    );

  const { colors, ...theme } = useTheme();
  // Device Rename Stuff
  const [deviceRenameOpen, setDeviceRenameOpen] = useState<boolean>(false);
  const [deviceToRename, setDeviceToRename] = useState<string | null>(null);
  const openDeviceRename = (deviceToRename: string) => {
    setDeviceToRename(deviceToRename);
    setDeviceRenameOpen(true);
  };
  const closeDeviceRename = () => {
    setDeviceToRename(null);
    setDeviceRenameOpen(false);
  };

  return (
    <>
      {deviceToRename && (
        <RenameDeviceDialog
          open={deviceRenameOpen}
          onClose={closeDeviceRename}
          defaultName={deviceToRename}
        />
      )}
      <Menu
        id="camera-selection"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={
          isCondensed
            ? {
                vertical: "top",
                horizontal: "center",
              }
            : { vertical: "center", horizontal: "center" }
        }
        transformOrigin={
          isCondensed
            ? {
                vertical: "bottom",
                horizontal: "center",
              }
            : { vertical: "center", horizontal: "center" }
        }
        onClose={closeVideoDeviceList}
        keepMounted
        autoFocus
        MenuListProps={{
          sx: {
            padding: "8px 0",
          },
        }}
      >
        {[
          ...availableDevices.virtual,
          ...availableDevices.dcp,
          ...availableDevices.real,
        ].length === 0 ? (
          <MenuItem>No camera devices found...</MenuItem>
        ) : (
          [
            renderVirtualDevices(availableDevices.virtual),
            renderDcpDevices(availableDevices.dcp),
            renderDevices(availableDevices.real),
          ]
        )}
      </Menu>
      {isCondensed && (
        <Tooltip title="Add video device" placement="top">
          <MUIButton
            aria-label="Add video device"
            size="small"
            color="inherit"
            data-cy="camera-add-button-small"
            onClick={openVideoDeviceList}
          >
            <VideoCall style={{ width: "32px", height: "32px" }} />
          </MUIButton>
        </Tooltip>
      )}
      {!isCondensed && (
        <Wrapper>
          <MUIButton
            sx={{
              border: 2,
              borderStyle: "dashed",
              borderColor: theme.palette.text.secondary,
            }}
            aria-label="Add video device"
            title="Add video device"
            size="small"
            color="primary"
            data-cy="camera-add-button-big"
            className="camera-add-button-big"
            onClick={openVideoDeviceList}
          >
            <Flexbox
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <VideoCall fontSize={"large"} />
                <Typography
                  variant="h6"
                  component={"div"}
                  className="add-video-text"
                >
                  Add video device
                </Typography>
              </Box>
            </Flexbox>
          </MUIButton>
        </Wrapper>
      )}
    </>
  );
};

export default AddVideo;
