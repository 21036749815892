import React, { FC } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { Box, IconButton, useTheme } from "@mui/material";
import { Theme } from "../../styles/mui-theme/proximie/theme";

export interface FilterChipProps {
  label: string;
  value: string;
  onRemove: (value: string) => void;
}

const FilterChip: FC<FilterChipProps> = ({ label, value, onRemove }) => {
  const onClick = () => onRemove(value);
  const { colors } = useTheme<Theme>();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "16px",
        padding: "4px 6px",
        paddingLeft: "10px",
        fontSize: "13px",
        gap: "6px",
        fontWeight: 400,
        backgroundColor: colors.Grey200,
        ":hover": {
          backgroundColor: colors.Grey300,
        },
      }}
    >
      {label}
      <IconButton onClick={onClick} sx={{ padding: 0 }}>
        <CancelIcon sx={{ color: colors.Grey400 }} />
      </IconButton>
    </Box>
  );
};

export default FilterChip;
