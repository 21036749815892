import { Monitor } from "../Monitor";
import { BrowserMonitor, BrowserReport } from "./BrowserMonitor";
import { XHRMonitor, XHRReport } from "./XHRMonitor";
import { WebRTCMonitor, WebRTCReport } from "./WebRTCMonitor";
import { SocketMonitor, SocketReport } from "./SocketMonitor";
import { ProtocolMonitor, ProtocolReport } from "./ProtocolMonitor";
import { DNSMonitor, DNSReport } from "./DNSMonitor";

export type NetworkReport = {
  browser: BrowserReport;
  xhr: XHRReport;
  socket: SocketReport;
  webrtc: WebRTCReport;
  protocol: ProtocolReport;
  dns: DNSReport;
};

type SubReport =
  | BrowserReport
  | XHRReport
  | SocketReport
  | WebRTCReport
  | ProtocolReport
  | DNSReport;

export class NetworkMonitor extends Monitor<NetworkReport, SubReport> {
  constructor() {
    super({
      subMonitors: {
        browser: new BrowserMonitor(),
        xhr: new XHRMonitor(),
        socket: new SocketMonitor(),
        webrtc: new WebRTCMonitor(),
        protocol: new ProtocolMonitor(),
        dns: new DNSMonitor(),
      },
    });
  }
}
