import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class UnsupportedNumberOfEmailsException extends BaseException {
  constructor(
    message = "You can only invite 100 members at a time",
    meta?: unknown,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.UNSUPPORTED_NUMBER_OF_EMAILS;
  }
}
