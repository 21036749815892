export const ORGANISATION_ID_QUERY_PARAM_NAME = "organisation-id";
export const TIMEZONE_HEADER_PARAM_NAME = "x-timezone";

export const PROBLEMS = {
  BAD_REQUEST: {
    statusCode: 400,
    errorCode: "BAD_REQUEST",
    message: "The request was malformed or missing required fields.",
  },
  UNAUTHORIZED: {
    statusCode: 401,
    errorCode: "UNAUTHORIZED",
    message: "The request was not authenticated.",
  },
  FORBIDDEN: {
    statusCode: 403,
    errorCode: "FORBIDDEN",
    message: "You do not have the correct permissions for this request.",
  },
  NOT_FOUND: {
    statusCode: 404,
    errorCode: "NOT_FOUND",
    message: "The specified resource was not found.",
  },
  METHOD_NOT_ALLOWED: {
    statusCode: 405,
    errorCode: "METHOD_NOT_ALLOWED",
    message: "The request method is not allowed for the specified resource.",
  },
  CONFLICT: {
    statusCode: 409,
    errorCode: "CONFLICT",
    message: "The resource you are trying to access is in a conflicting state.",
  },
  UNPROCESSABLE_ENTITY: {
    statusCode: 422,
    errorCode: "UNPROCESSABLE_ENTITY",
    message: "Unable to process the contained instructions.",
  },
  RESOURCE_LOCKED: {
    statusCode: 423,
    errorCode: "RESOURCE_LOCKED",
    message: "The resource cannot be modified.",
  },
  INTERNAL_SERVER_ERROR: {
    statusCode: 500,
    errorCode: "INTERNAL_SERVER_ERROR",
    message: "An unexpected error occurred.",
  },
};
