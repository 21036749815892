import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  BandwidthValue,
  CriticalityReport,
  FirewallReport,
  GoodBad,
  GoodPoor,
  GoodPoorBad,
  GoodPoorBadNA,
  LatencyValue,
  NetworkReport,
  NormalGoodPoorBadNA,
  NormalPoorBad,
  PacketLossValue,
  ServiceReachabilityValue,
  StreamingProtocol,
  StreamingProtocolValue,
  VideoQualityValue,
  VideoReport,
} from "./TroubleShootingTypes";
import { useGlobalMonitor } from "../../contexts/global-monitor-context";
import { GlobalReport } from "../../monitoring/GlobalMonitor";
import { DeepPartial } from "../../monitoring/Monitor";
import { FixedFifo, SummaryStats } from "@proximie/media";
import {
  ConnectionReport,
  QualityLevel,
} from "../../monitoring/monitors/WebRTCMonitor";
import {
  FetchResult,
  OptionalUrlKeys,
  RequiredUrlKeys,
} from "../../monitoring/monitors/XHRMonitor";
import {
  GOOD_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION,
  GOOD_LATENCY_VALUE_PER_CONNECTION,
  GOOD_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION,
  GOOD_PACKET_LOSS_VALUE_PER_CONNECTION,
  POOR_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION,
  POOR_LATENCY_VALUE_PER_CONNECTION,
  POOR_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION,
  POOR_PACKET_LOSS_VALUE_PER_CONNECTION,
} from "./shared/TroubleShootingReportCutOffPoints";
import { useInterval } from "../../hooks/common/useInterval";

const GRACE_PERIOD = 15000;
const LOG_INTERVAL = 10000;

type TroubleShootingContextProps = {
  openSupportTicket: () => void;
  children: typeof React.Children | ReactElement | ReactElement[];
};

type LastCriticalState = {
  criticalityReport: CriticalityReport | null;
  timestamp: Date;
};

export type TroubleShootingContext = {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  networkExpanded: boolean;
  setNetworkExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  firewallExpanded: boolean;
  setFirewallExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  videoExpanded: boolean;
  setVideoExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  openSupportTicket: () => void;
  detailedReportOpen: boolean;
  setDetailedReportOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pressed: boolean;
  setPressed: React.Dispatch<React.SetStateAction<boolean>>;
  arrowRef: HTMLElement | null;
  setArrowRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  criticalityReport: CriticalityReport;
  lastCriticalState: LastCriticalState;
};

export const DefaultTroubleShootingContext: TroubleShootingContext = {
  menuOpen: false,
  setMenuOpen: () => null,
  networkExpanded: false,
  setNetworkExpanded: () => null,
  firewallExpanded: false,
  setFirewallExpanded: () => null,
  videoExpanded: false,
  setVideoExpanded: () => null,
  openSupportTicket: () => null,
  detailedReportOpen: false,
  setDetailedReportOpen: () => null,
  pressed: false,
  setPressed: () => null,
  arrowRef: null,
  setArrowRef: () => null,
  criticalityReport: {
    network: {
      outgoingBandwidth: { value: 0, criticality: "N/A" },
      incomingBandwidth: { value: 0, criticality: "N/A" },
      latency: { value: 0, criticality: "Normal" },
      packetLoss: { value: 0, criticality: "Normal" },
      criticality: "Good",
    },
    firewall: {
      streamingProtocol: { value: "UDP", criticality: "Good" },
      serviceReachability: { value: {}, criticality: "Good" },
      criticality: "Good",
    },
    video: {
      videoQuality: { value: "High", criticality: "Good" },
      criticality: "Good",
    },
    criticality: "Poor",
  },
  lastCriticalState: {
    criticalityReport: null,
    timestamp: new Date(),
  },
};

const TroubleShootingContext = createContext<TroubleShootingContext>(
  DefaultTroubleShootingContext,
);

export const useTroubleShooting = () => useContext(TroubleShootingContext);

function didCriticalityValueWorsen(
  previousCriticality?: NormalGoodPoorBadNA,
  newCriticality?: NormalGoodPoorBadNA,
) {
  if (previousCriticality == null) {
    return false;
  }

  if (
    (previousCriticality === "Good" ||
      previousCriticality === "Normal" ||
      previousCriticality === "N/A") &&
    (newCriticality === "Poor" || newCriticality === "Bad")
  ) {
    return true;
  }

  return previousCriticality === "Poor" && newCriticality === "Bad";
}

function didBandwidthWorsen(
  previousValue: BandwidthValue,
  newValue: BandwidthValue,
) {
  return (
    didCriticalityValueWorsen(
      previousValue.criticality,
      newValue.criticality,
    ) && newValue.value < previousValue.value
  );
}

function didLatencyWorsen(previousValue: LatencyValue, newValue: LatencyValue) {
  return newValue.value > previousValue.value;
}

function didPacketLossWorsen(
  previousValue: PacketLossValue,
  newValue: PacketLossValue,
) {
  return (
    didCriticalityValueWorsen(
      previousValue.criticality,
      newValue.criticality,
    ) && newValue.value > previousValue.value
  );
}

function didStreamingProtocolWorsen(
  previousValue: StreamingProtocolValue,
  newValue: StreamingProtocolValue,
) {
  return (
    (previousValue.value === "UDP" &&
      (newValue.value === "TCP" || newValue.value === "TLS")) ||
    (previousValue.value === "TCP" && newValue.value === "TLS")
  );
}

function didServiceReachabilityWorsen(
  previousValue: ServiceReachabilityValue,
  newValue: ServiceReachabilityValue,
) {
  return (
    didCriticalityValueWorsen(
      previousValue.criticality,
      newValue.criticality,
    ) &&
    previousValue.criticality === "Good" &&
    newValue.criticality === "Bad"
  );
}

function didVideoQualityWorsen(
  previousValue: VideoQualityValue,
  newValue: VideoQualityValue,
) {
  return (
    didCriticalityValueWorsen(
      previousValue.criticality,
      newValue.criticality,
    ) &&
    ((previousValue.value === "High" &&
      (newValue.value === "Medium" || newValue.value === "Low")) ||
      (previousValue.value === "Medium" && newValue.value === "Low"))
  );
}

export function didCriticalityWorsen(
  previousReport: CriticalityReport | null,
  newReport: CriticalityReport,
) {
  if (previousReport == null) {
    return false;
  }

  if (
    previousReport.criticality === "Good" &&
    (newReport.criticality === "Poor" || newReport.criticality === "Bad")
  ) {
    return true;
  }

  if (
    previousReport.criticality === "Poor" &&
    newReport.criticality === "Bad"
  ) {
    return true;
  }

  if (
    (previousReport.criticality === "Poor" &&
      newReport.criticality === "Poor") ||
    (previousReport.criticality === "Bad" && newReport.criticality === "Bad")
  ) {
    const {
      network: { outgoingBandwidth, incomingBandwidth, latency, packetLoss },
      firewall: { streamingProtocol, serviceReachability },
      video: { videoQuality },
    } = newReport;
    const {
      network: {
        outgoingBandwidth: prevOutgoingBandwidth,
        incomingBandwidth: prevIncomingBandwidth,
        latency: prevLatency,
        packetLoss: prevPacketLoss,
      },
      firewall: {
        streamingProtocol: prevStreamingProtocol,
        serviceReachability: prevServiceReachability,
      },
      video: { videoQuality: prevVideoQuality },
    } = previousReport;

    if (
      didBandwidthWorsen(prevOutgoingBandwidth, outgoingBandwidth) ||
      didBandwidthWorsen(prevIncomingBandwidth, incomingBandwidth) ||
      didLatencyWorsen(prevLatency, latency) ||
      didPacketLossWorsen(prevPacketLoss, packetLoss) ||
      didStreamingProtocolWorsen(prevStreamingProtocol, streamingProtocol) ||
      didServiceReachabilityWorsen(
        prevServiceReachability,
        serviceReachability,
      ) ||
      didVideoQualityWorsen(prevVideoQuality, videoQuality)
    ) {
      return true;
    }
  }

  return false;
}

function calculateCriticality(
  subCriticalityReports: NormalGoodPoorBadNA[],
): GoodPoorBad {
  let criticality: GoodPoorBad = "Good";

  if (
    subCriticalityReports.every(
      (cr) => cr === "Good" || cr === "Normal" || cr === "N/A",
    )
  ) {
    criticality = "Good";
  }
  if (subCriticalityReports.some((cr) => cr === "Poor")) {
    criticality = "Poor";
  }
  if (subCriticalityReports.some((cr) => cr === "Bad")) {
    criticality = "Bad";
  }

  return criticality;
}

function calculateAverageOutgoingBandwidth(
  connection: ConnectionReport | undefined,
): number {
  return connection?.guiStats?.bitrate ?? 0;
}

function calculateAverageIncomingBandwidth(
  connection: ConnectionReport | undefined,
): number {
  return connection?.guiStats?.bitrate ?? 0;
}

function generateOutgoingBandwidthReport({
  network,
}: DeepPartial<GlobalReport>): BandwidthValue {
  const outgoingConnections = Object.values(
    network?.webrtc?.connections ?? {},
  ).filter((c) => c?.direction === "Outgoing");

  const [
    actualOutgoingBandwidth,

    expectedGoodOutgoingBandwidth,
    expectedPoorOutgoingBandwidth,
  ] = outgoingConnections.reduce(
    (
      [
        actualOutgoingBandwidth,
        expectedGoodOutgoingBandwidth,
        expectedPoorOutgoingBandwidth,
      ],
      connection,
    ) => [
      actualOutgoingBandwidth +
        calculateAverageOutgoingBandwidth(connection as ConnectionReport),
      expectedGoodOutgoingBandwidth +
        GOOD_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION(
          connection as ConnectionReport,
        ),
      expectedPoorOutgoingBandwidth +
        POOR_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION(
          connection as ConnectionReport,
        ),
    ],
    [0, 0, 0],
  );

  let criticality: GoodPoorBadNA = "Good";
  if (actualOutgoingBandwidth >= expectedGoodOutgoingBandwidth) {
    criticality = "Good";
  }
  if (
    actualOutgoingBandwidth < expectedGoodOutgoingBandwidth &&
    actualOutgoingBandwidth >= expectedPoorOutgoingBandwidth
  ) {
    criticality = "Poor";
  }
  if (actualOutgoingBandwidth < expectedPoorOutgoingBandwidth) {
    criticality = "Bad";
  }

  if (
    actualOutgoingBandwidth <= 0 ||
    outgoingConnections.every(
      (c) => (c?.statsHistory as FixedFifo<SummaryStats>)?.length() <= 0,
    )
  ) {
    criticality = "N/A";
  }

  return {
    value: actualOutgoingBandwidth,
    criticality,
  };
}

function generateIncomingBandwidthReport({
  network,
}: DeepPartial<GlobalReport>): BandwidthValue {
  const incomingConnections = Object.values(
    network?.webrtc?.connections ?? {},
  ).filter((c) => c?.direction === "Incoming");

  const [
    actualIncomingBandwidth,
    expectedGoodIncomingBandwidth,
    expectedPoorIncomingBandwidth,
  ] = incomingConnections.reduce(
    (
      [
        actualIncomingBandwidth,
        expectedGoodIncomingBandwidth,
        expectedPoorIncomingBandwidth,
      ],
      connection,
    ) => [
      actualIncomingBandwidth +
        calculateAverageIncomingBandwidth(connection as ConnectionReport),
      expectedGoodIncomingBandwidth +
        GOOD_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION(
          connection as ConnectionReport,
        ),
      expectedPoorIncomingBandwidth +
        POOR_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION(
          connection as ConnectionReport,
        ),
    ],
    [0, 0, 0],
  );

  let criticality: GoodPoorBadNA = "Good";
  if (actualIncomingBandwidth >= expectedGoodIncomingBandwidth) {
    criticality = "Good";
  }
  if (
    actualIncomingBandwidth < expectedGoodIncomingBandwidth &&
    actualIncomingBandwidth >= expectedPoorIncomingBandwidth
  ) {
    criticality = "Poor";
  }
  if (actualIncomingBandwidth < expectedPoorIncomingBandwidth) {
    criticality = "Bad";
  }

  if (
    actualIncomingBandwidth <= 0 ||
    incomingConnections.every(
      (c) => (c?.statsHistory as FixedFifo<SummaryStats>)?.length() <= 0,
    )
  ) {
    criticality = "N/A";
  }

  return {
    value: actualIncomingBandwidth,
    criticality,
  };
}

function generatePacketLossReport({
  network,
}: DeepPartial<GlobalReport>): PacketLossValue {
  const connections = Object.values(network?.webrtc?.connections ?? {});

  const averagePacketLoss =
    connections.reduce(
      (acc, connection) => acc + (connection?.guiStats?.packetLoss ?? 0),
      0,
    ) / connections.length;

  let criticality: NormalPoorBad = "Normal";
  if (averagePacketLoss < GOOD_PACKET_LOSS_VALUE_PER_CONNECTION()) {
    criticality = "Normal";
  }
  if (
    averagePacketLoss >= GOOD_PACKET_LOSS_VALUE_PER_CONNECTION() &&
    averagePacketLoss < POOR_PACKET_LOSS_VALUE_PER_CONNECTION()
  ) {
    criticality = "Poor";
  }
  if (averagePacketLoss >= POOR_PACKET_LOSS_VALUE_PER_CONNECTION()) {
    criticality = "Bad";
  }
  return {
    value: averagePacketLoss,
    criticality,
  };
}

function generateLatencyReport({
  network,
}: DeepPartial<GlobalReport>): LatencyValue {
  const validConnections = Object.values(
    network?.webrtc?.connections ?? {},
  ).filter((c) => (c?.guiStats?.rtt ?? 0) > 0);

  const averageLatency = validConnections.reduce(
    (acc, connection, _, array) =>
      acc + (connection?.guiStats?.rtt ?? 0) / array.length,
    0,
  );

  let criticality: NormalPoorBad = "Normal";
  if (averageLatency < GOOD_LATENCY_VALUE_PER_CONNECTION()) {
    criticality = "Normal";
  }
  if (
    averageLatency >= GOOD_LATENCY_VALUE_PER_CONNECTION() &&
    averageLatency < POOR_LATENCY_VALUE_PER_CONNECTION()
  ) {
    criticality = "Poor";
  }
  if (averageLatency >= POOR_LATENCY_VALUE_PER_CONNECTION()) {
    criticality = "Bad";
  }
  return {
    value: averageLatency,
    criticality,
  };
}

function generateNetworkReport(
  report: DeepPartial<GlobalReport>,
): NetworkReport {
  const outgoingBandwidthReport = generateOutgoingBandwidthReport(report);
  const incomingBandwidthReport = generateIncomingBandwidthReport(report);
  const packetLossReport = generatePacketLossReport(report);
  const latencyReport = generateLatencyReport(report);

  const criticality: GoodPoorBad = calculateCriticality([
    outgoingBandwidthReport.criticality,
    incomingBandwidthReport.criticality,
    packetLossReport.criticality,
    latencyReport.criticality,
  ]);

  return {
    outgoingBandwidth: outgoingBandwidthReport,
    incomingBandwidth: incomingBandwidthReport,
    packetLoss: packetLossReport,
    latency: latencyReport,
    criticality,
  };
}

function generateStreamingProtocolReport(
  report: DeepPartial<GlobalReport>,
): StreamingProtocolValue {
  const connections = Object.values(
    report.network?.webrtc?.connections ?? {},
  ).filter((c) => c?.guiStats?.type !== "host");

  let [streamingProtocol, criticality]: [StreamingProtocol, GoodPoor] =
    connections.reduce(
      ([protocol, criticality], connection) => {
        if (protocol === "TLS" || connection?.guiStats?.protocol === "tls") {
          return ["TLS", "Poor"];
        }
        if (protocol === "TCP" || connection?.guiStats?.protocol === "tcp") {
          return ["TCP", "Poor"];
        }
        if (protocol === "UDP" || connection?.guiStats?.protocol === "udp") {
          return ["UDP", "Good"];
        }

        return [protocol, criticality];
      },
      ["UDP", "Poor"] as [StreamingProtocol, GoodPoor],
    );

  if (connections?.length <= 0) {
    streamingProtocol = "UDP";
    criticality = "Good";
  }

  if (connections.every((c) => c?.guiStats == null)) {
    streamingProtocol = "UDP";
    criticality = "Good";
  }

  return {
    value: streamingProtocol,
    criticality,
  };
}

function generateServiceReachabilityReport(
  report: DeepPartial<GlobalReport>,
): ServiceReachabilityValue {
  const optional = (report.network?.xhr?.optional ?? {}) as Record<
    OptionalUrlKeys,
    FetchResult
  >;
  const required = (report.network?.xhr?.required ?? {}) as Record<
    RequiredUrlKeys,
    FetchResult
  >;

  let serviceReachability: GoodBad = "Good";

  if (Object.values(required).some(({ success }) => !success)) {
    serviceReachability = "Bad";
  }

  return {
    value: {
      ...required,
      ...optional,
    },
    criticality: serviceReachability,
  };
}

function generateFirewallReport(
  report: DeepPartial<GlobalReport>,
): FirewallReport {
  const streamingProtocolReport = generateStreamingProtocolReport(report);
  const serviceReachabilityReport = generateServiceReachabilityReport(report);

  const criticality = calculateCriticality([
    streamingProtocolReport.criticality,
    serviceReachabilityReport.criticality,
  ]);

  return {
    streamingProtocol: streamingProtocolReport,
    serviceReachability: serviceReachabilityReport,
    criticality,
  };
}

function generateVideoReport(report: DeepPartial<GlobalReport>): VideoReport {
  const connections = Object.values(
    report.network?.webrtc?.connections ?? {},
  ).filter((c) => c?.adaptiveStreamingMode === "Automatic");

  let videoQualityValue: QualityLevel = "High";
  let criticality: GoodPoorBad = "Good";

  if (connections.every((c) => (c?.qualityLevel ?? "High") === "High")) {
    criticality = "Good";
    videoQualityValue = "High";
  }

  if (connections.some((c) => (c?.qualityLevel ?? "High") === "Medium")) {
    criticality = "Poor";
    videoQualityValue = "Medium";
  }

  if (connections.some((c) => (c?.qualityLevel ?? "High") === "Low")) {
    criticality = "Bad";
    videoQualityValue = "Low";
  }

  if (connections.length === 0) {
    criticality = "Good";
    videoQualityValue = "High";
  }

  return {
    videoQuality: { value: videoQualityValue, criticality },
    criticality,
  };
}

function generateCriticalityReport(
  report: DeepPartial<GlobalReport>,
): CriticalityReport {
  const networkReport = generateNetworkReport(report);
  const firewallReport = generateFirewallReport(report);
  const videoReport = generateVideoReport(report);

  const criticality = calculateCriticality([
    networkReport.criticality,
    firewallReport.criticality,
    videoReport.criticality,
  ]);

  return {
    network: networkReport,
    firewall: firewallReport,
    video: videoReport,
    criticality,
  };
}

export const TroubleShootingProvider = ({
  openSupportTicket,
  children,
}: TroubleShootingContextProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [networkExpanded, setNetworkExpanded] = useState<boolean>(false);
  const [firewallExpanded, setFirewallExpanded] = useState<boolean>(false);
  const [videoExpanded, setVideoExpanded] = useState<boolean>(false);

  const [detailedReportOpen, setDetailedReportOpen] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const [criticalityReport, setCriticalityReport] = useState<CriticalityReport>(
    {
      network: {
        outgoingBandwidth: { value: 0, criticality: "N/A" },
        incomingBandwidth: { value: 0, criticality: "N/A" },
        latency: { value: 0, criticality: "Normal" },
        packetLoss: { value: 0, criticality: "Normal" },
        criticality: "Good",
      },
      firewall: {
        streamingProtocol: { value: "UDP", criticality: "Good" },
        serviceReachability: { value: {}, criticality: "Good" },
        criticality: "Good",
      },
      video: {
        videoQuality: { value: "High", criticality: "Good" },
        criticality: "Good",
      },
      criticality: "Good",
    },
  );

  const { monitor } = useGlobalMonitor();

  const [lastCriticalState, setLastCriticalState] = useState<LastCriticalState>(
    { criticalityReport: null, timestamp: new Date() },
  );
  const initialTime = useRef(Date.now());

  useInterval(() => {
    console.debug("[CriticalityReport] report:", criticalityReport);
    console.debug("[LastCriticalState] state:", lastCriticalState);
  }, LOG_INTERVAL);

  useEffect(() => {
    const onReport = () =>
      setCriticalityReport((prev) => {
        const criticalityReport = generateCriticalityReport(monitor.report);

        if (
          Date.now() - initialTime.current > GRACE_PERIOD &&
          didCriticalityWorsen(prev, criticalityReport)
        ) {
          setLastCriticalState({ criticalityReport, timestamp: new Date() });
        }

        return criticalityReport;
      });

    monitor.subscribe(onReport);

    return () => {
      monitor.unsubscribe(onReport);
    };
  }, [monitor]);

  return (
    <TroubleShootingContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        networkExpanded,
        setNetworkExpanded,
        firewallExpanded,
        setFirewallExpanded,
        videoExpanded,
        setVideoExpanded,
        openSupportTicket,
        detailedReportOpen,
        setDetailedReportOpen,
        pressed,
        setPressed,
        arrowRef,
        setArrowRef,
        criticalityReport,
        lastCriticalState,
      }}
    >
      <>{children}</>
    </TroubleShootingContext.Provider>
  );
};
