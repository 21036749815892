import React, { Children, ReactElement, ReactNode } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { TroubleShootingStatusTextContainer } from "../TroubleShootingStatusText";
import {
  NormalGoodPoorBad,
  NormalGoodPoorBadNA,
} from "../TroubleShootingTypes";
import { useTroubleShooting } from "../TroubleShootingContext";
import {
  DescriptionTypography,
  TitleTypography,
  ValueTypography,
} from "../shared/CustomTypography";

type TroubleShootingMenuAccordionProps = {
  children: NonNullable<ReactNode>;
  expanded?: boolean;
};

export const TroubleShootingMenuAccordion = ({
  expanded,
  children,
}: TroubleShootingMenuAccordionProps) => {
  return (
    <Accordion
      {...(expanded == null ? {} : { expanded })}
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
        backgroundColor: "transparent",
        padding: "0 16px",
      }}
    >
      {children}
    </Accordion>
  );
};

type TroubleShootingMenuAccordionSummaryProps = {
  title: string;
  criticality: NormalGoodPoorBad;
  onClick?: () => void;
};

export const TroubleShootingMenuAccordionSummary = ({
  title,
  onClick,
  criticality,
}: TroubleShootingMenuAccordionSummaryProps) => {
  return (
    <AccordionSummary
      {...(onClick ? { onClick } : {})}
      expandIcon={<ExpandMore />}
      sx={{
        padding: "0",
        minHeight: "40px",
        "& .MuiAccordionSummary-content": {
          my: "0",
        },
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        width={"100%"}
        paddingRight={"4px"}
      >
        <TitleTypography>{title}</TitleTypography>
        <TroubleShootingStatusTextContainer criticality={criticality} />
      </Box>
    </AccordionSummary>
  );
};

export type TroubleShootingMenuAccordionDetailProps = {
  title: string;
  description: string;
  criticality: NormalGoodPoorBadNA;
  value?: string;
};

export const TroubleShootingMenuAccordionDetail = (
  _: TroubleShootingMenuAccordionDetailProps,
) => null;

type TroubleShootingMenuAccordionDetailsContainerProps = {
  children:
    | ReactElement<TroubleShootingMenuAccordionDetailProps>
    | ReactElement<TroubleShootingMenuAccordionDetailProps>[];
};

export const TroubleShootingMenuAccordionDetailsContainer = ({
  children,
}: TroubleShootingMenuAccordionDetailsContainerProps) => {
  const { colors } = useTheme();

  return (
    <AccordionDetails style={{ padding: "0 0 16px 0" }}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        alignSelf={"stretch"}
        width={"100%"}
        gap={"0"}
        borderRadius={"4px"}
        border={`1px solid ${colors.BlackLowEmphasis}`}
      >
        {Children.map(
          children,
          ({ props: { title, description, criticality, value } }) => (
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              alignSelf={"stretch"}
              borderBottom={`1px solid ${colors.BlackLowEmphasis}`}
              width={"100%"}
            >
              <Box display={"flex"} flexDirection={"column"} padding={"12px"}>
                <TitleTypography>{title}</TitleTypography>
                <DescriptionTypography>{description}</DescriptionTypography>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                borderLeft={`1px solid ${colors.BlackLowEmphasis}`}
                padding={"12px"}
                width={"88px"}
              >
                <TroubleShootingStatusTextContainer criticality={criticality} />
                <ValueTypography
                  {...(criticality === "N/A"
                    ? { color: colors.BlackDisabled }
                    : {})}
                >
                  {value ?? <br />}
                </ValueTypography>
              </Box>
            </Box>
          ),
        )}
      </Box>
    </AccordionDetails>
  );
};

export const TroubleShootingMenuNetworkAccordion = () => {
  const {
    networkExpanded,
    setNetworkExpanded,
    criticalityReport: {
      network: {
        outgoingBandwidth,
        incomingBandwidth,
        latency,
        packetLoss,
        criticality,
      },
    },
  } = useTroubleShooting();

  return (
    <TroubleShootingMenuAccordion expanded={networkExpanded}>
      <TroubleShootingMenuAccordionSummary
        title={"Network"}
        criticality={criticality}
        onClick={() => setNetworkExpanded(!networkExpanded)}
      />
      <TroubleShootingMenuAccordionDetailsContainer>
        <TroubleShootingMenuAccordionDetail
          title={"Upload bandwidth"}
          description={"The upload speed of your connection"}
          criticality={outgoingBandwidth.criticality}
          value={
            outgoingBandwidth.criticality === "N/A"
              ? "-"
              : `${(outgoingBandwidth.value / 1000000).toFixed(1)}Mbps`
          }
        />
        <TroubleShootingMenuAccordionDetail
          title={"Download bandwidth"}
          description={"The download speed of your connection"}
          criticality={incomingBandwidth.criticality}
          value={
            incomingBandwidth.criticality === "N/A"
              ? "-"
              : `${(incomingBandwidth.value / 1000000).toFixed(1)}Mbps`
          }
        />
        <TroubleShootingMenuAccordionDetail
          title={"Latency"}
          description={"The delay in audio/video transmission "}
          criticality={latency.criticality}
          value={`${latency.value.toFixed(0)}ms`}
        />
        <TroubleShootingMenuAccordionDetail
          title={"Packet loss"}
          description={"Data loss during audio/video transmission"}
          criticality={packetLoss.criticality}
          value={`${
            packetLoss.value.toFixed(2) === "0.00"
              ? "0"
              : packetLoss.value.toFixed(2)
          }%`}
        />
      </TroubleShootingMenuAccordionDetailsContainer>
    </TroubleShootingMenuAccordion>
  );
};

export const TroubleShootingMenuFirewallAccordion = () => {
  const {
    firewallExpanded,
    setFirewallExpanded,
    criticalityReport: {
      firewall: { streamingProtocol, serviceReachability, criticality },
    },
  } = useTroubleShooting();

  return (
    <TroubleShootingMenuAccordion expanded={firewallExpanded}>
      <TroubleShootingMenuAccordionSummary
        title={"Firewall"}
        criticality={criticality}
        onClick={() => setFirewallExpanded(!firewallExpanded)}
      />
      <TroubleShootingMenuAccordionDetailsContainer>
        <TroubleShootingMenuAccordionDetail
          title={"Streaming protocol"}
          description={"The protocol used for our streaming services"}
          criticality={streamingProtocol.criticality}
          value={streamingProtocol.value}
        />
        <TroubleShootingMenuAccordionDetail
          title={"Service reachability"}
          description={"The ability to access the streaming service"}
          criticality={serviceReachability.criticality}
        />
      </TroubleShootingMenuAccordionDetailsContainer>
    </TroubleShootingMenuAccordion>
  );
};

export const TroubleShootingMenuVideoAccordion = () => {
  const {
    videoExpanded,
    setVideoExpanded,
    criticalityReport: {
      video: { videoQuality, criticality },
    },
  } = useTroubleShooting();

  return (
    <TroubleShootingMenuAccordion expanded={videoExpanded}>
      <TroubleShootingMenuAccordionSummary
        title={"Video"}
        criticality={criticality}
        onClick={() => setVideoExpanded(!videoExpanded)}
      />
      <TroubleShootingMenuAccordionDetailsContainer>
        <TroubleShootingMenuAccordionDetail
          title={"Video Quality"}
          description={"The clarity and smoothness of video"}
          criticality={videoQuality.criticality}
          value={videoQuality.value}
        />
      </TroubleShootingMenuAccordionDetailsContainer>
    </TroubleShootingMenuAccordion>
  );
};

export const TroubleShootingMenuAccordionContainer = () => {
  const { colors } = useTheme();
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      gap={"4px"}
      width={"100%"}
      borderRadius={"4px"}
      border={`1px solid ${colors.BlackA200}`}
      style={{
        backgroundColor: colors.Grey50,
      }}
      py={"8px"}
    >
      <TroubleShootingMenuNetworkAccordion />
      <TroubleShootingMenuFirewallAccordion />
      <TroubleShootingMenuVideoAccordion />
    </Box>
  );
};
