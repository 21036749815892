import { LeafMonitor } from "../Monitor";

export type TCPReport = {
  online: boolean;
};

export class TCPMonitor extends LeafMonitor<TCPReport> {
  constructor() {
    super({});
  }

  run() {
    return undefined;
  }
}
