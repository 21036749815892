import { Box, useTheme } from "@mui/material";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import { useSpringValue } from "@react-spring/web";
import HoverListener from "../HoverListener/HoverListener";

export interface SpotlightGridProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface SpotlightedFeedProps {
  children: React.ReactNode | React.ReactNode[];
}

const snapGridMapPerFeedCount: Record<
  number,
  { max: number; sizes: number[] }
> = {
  2: {
    max: (6 * 100) / 12,
    sizes: [
      (2 * 100) / 12,
      (3 * 100) / 12,
      (4 * 100) / 12,
      (5 * 100) / 12,
      (6 * 100) / 12,
    ],
  },
  3: {
    max: (5 * 100) / 12,
    sizes: [(2 * 100) / 12, (3 * 100) / 12, (4 * 100) / 12, (5 * 100) / 12],
  },
  4: {
    max: (3 * 100) / 12,
    sizes: [(2 * 100) / 12, (3 * 100) / 12],
  },
};

const getSnapGrid = (feedCount: number) =>
  feedCount < 2
    ? snapGridMapPerFeedCount[2]
    : snapGridMapPerFeedCount[feedCount];

export const SpotlightedFeed: FC<SpotlightedFeedProps> = ({ children }) => {
  return <>{children}</>;
};

const SpotlightGrid: FC<SpotlightGridProps> = ({
  children,
}: SpotlightGridProps) => {
  const { colors, palette } = useTheme();

  const childArray = React.Children.toArray(children);
  const feedCount = childArray.length;
  const panelRef = useRef<ImperativePanelHandle>(null);
  const spring = useSpringValue(0);
  const [size, setSize] = useState(getSnapGrid(feedCount).max);
  const [isDragging, setDragging] = useState(false);
  const [isHovering, setHovering] = useState(false);

  const onDrag = useCallback(() => {
    setDragging(true);
    window.dispatchEvent(new CustomEvent("spotlight-resize"));
  }, [setDragging]);

  const onDragEnded = useCallback(async () => {
    setDragging(false);
    const panel = panelRef.current;
    if (!panel) return;

    const currentSize = panel.getSize();
    const closestAllowedSize = getSnapGrid(feedCount).sizes.reduce(
      (prev, curr) =>
        Math.abs(curr - currentSize) < Math.abs(prev - currentSize)
          ? curr
          : prev,
    );

    await spring.start({
      from: currentSize,
      to: closestAllowedSize,
      onChange: (result) => panel.resize(result as unknown as number),
    });

    window.dispatchEvent(new Event("resize"));
  }, [spring, feedCount, setDragging]);

  useEffect(() => {
    const panel = panelRef.current;
    if (!panel) return;

    if (panel.getSize() >= getSnapGrid(feedCount).max) {
      spring.start({
        from: panel.getSize(),
        to: getSnapGrid(feedCount).max,
        onChange: (result) => panel.resize(result as unknown as number),
        onRest: () => setSize(getSnapGrid(feedCount).max),
      });
    } else {
      setSize(getSnapGrid(feedCount).max);
    }
  }, [feedCount, spring]);

  return (
    <Box data-testid="spotlight-grid" height="100%">
      <PanelGroup
        direction="horizontal"
        onLayout={() =>
          window.dispatchEvent(new CustomEvent("spotlight-resize"))
        }
      >
        <Panel
          style={{ overflow: "visible" }}
          defaultSize={(9 * 100) / 12}
          minSize={100 - size}
          maxSize={(10 * 100) / 12}
        >
          <Box
            data-testid="spotlighted-feed"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            {childArray.find(
              (child) => (child as JSX.Element).type === SpotlightedFeed,
            )}
          </Box>
        </Panel>
        <HoverListener
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          <PanelResizeHandle
            style={{
              background:
                isDragging || isHovering
                  ? colors.Brand100
                  : palette.background.default,
              width: "16px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              transition: "all .2s ease",
            }}
            onDragging={(isDragging) => (isDragging ? onDrag() : onDragEnded())}
          >
            <span
              style={{
                width: isDragging ? "8px" : "4px",
                height: isDragging ? "40px" : "32px",
                background: palette.primary.main,
                borderRadius: isDragging ? "8px" : "3px",
                flex: "none",
                flexGrow: "0",
                transition: "all .2s ease",
              }}
            />
          </PanelResizeHandle>
        </HoverListener>
        <Panel
          style={{ overflow: "visible" }}
          minSize={(2 * 100) / 12}
          maxSize={size}
          ref={panelRef}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
            gap="8px"
          >
            {childArray.filter(
              (child) => (child as JSX.Element).type !== SpotlightedFeed,
            )}
          </Box>
        </Panel>
      </PanelGroup>
    </Box>
  );
};

export default SpotlightGrid;
