import styled from "@emotion/styled";

export const TelestrationWrapperStyled = styled.div<{
  telestrationActive: boolean;
  scale: number;
  aspectRatio: number;
  containerAspect: number;
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  overflow: hidden;
  ${({ containerAspect, aspectRatio }) =>
    containerAspect > aspectRatio ? "height:100%" : "width : 100%;"};

  .canvas-container {
    transform: scale(${({ scale }) => scale});
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  }

  canvas {
    pointer-events: ${({ telestrationActive }) =>
      telestrationActive ? "all" : "none"};
  }

  canvas.lower-canvas {
    position: relative !important;
  }
`;
