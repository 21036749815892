import React, { FC, useEffect, useRef, useState } from "react";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { EventTypes, PtzUser } from "../../utils/PtzUser";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import OpenWidthIcon from "@mui/icons-material/OpenWith";
import { user } from "@proximie/api";
import { useCameraLabel } from "../../contexts/camera-label-context";

export interface PtzStatusLabelProps {
  user: user.User;
  ptzUser: PtzUser;
  label: string;
  index: number;
}

const PtzStatusLabel: FC<PtzStatusLabelProps> = (
  props: PtzStatusLabelProps,
): JSX.Element => {
  const { colors } = useTheme();
  const { session, members } = useSessionContext();

  const { get: getCameraLabel } = useCameraLabel();

  const [controllerId, setControllerId] = useState<number>(0);
  const [controllerName, setControllerName] = useState<string>("");
  const isTextOverflownRef = useRef<boolean>(false);

  const textRef = useRef<HTMLElement>(null);

  const theme = useTheme();

  useEffect(() => {
    props.ptzUser.on(
      EventTypes.ControlNotification,
      (myControllerId: number): void => {
        setControllerId(myControllerId);
        if (myControllerId && session) {
          const participant = members?.find((member) => {
            return member.profileId === myControllerId;
          });

          setControllerName(participant?.name || "UNKNOWN");
        } else {
          setControllerName("");
        }
      },
    );
    props.ptzUser.requestStatusAndControl();
  }, [members, props.ptzUser, session]);

  useEffect(() => {
    const handleResize = () => {
      if (!textRef.current) {
        return;
      }

      const text = textRef.current;

      isTextOverflownRef.current = text.offsetWidth < text.scrollWidth;
    };

    window.addEventListener("spotlight-resize", handleResize);
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("spotlight-resize", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cameraLabel = getCameraLabel(props.label);

  const text =
    controllerId === props.user?.profileId
      ? `You control: ${cameraLabel} (${props.index + 1})`
      : controllerId
      ? `Controlled by: ${controllerName}`
      : `Control available: ${cameraLabel} (${props.index + 1})`;

  return (
    <Tooltip
      title={text}
      placement="top"
      disableHoverListener={!isTextOverflownRef.current}
      disableFocusListener={!isTextOverflownRef.current}
      disableTouchListener={!isTextOverflownRef.current}
      componentsProps={{
        tooltip: {
          sx: { margin: "5px !important" },
        },
      }}
      arrow
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          borderRadius: "4px",
          color: colors.White,
          backgroundColor:
            controllerId === props.user?.profileId
              ? colors.BrandSecondary
              : colors.BlackInactive,
        }}
      >
        <Box display="flex" paddingX="5px" paddingY="3px">
          <OpenWidthIcon />
        </Box>
        <Typography
          pr={1}
          noWrap
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...theme.typography.body2,
          }}
          ref={textRef}
        >
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default PtzStatusLabel;
