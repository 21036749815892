import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToRemoveSpotlightedFeed extends BaseException {
  constructor(meta?: unknown) {
    super("User is not allowed to remove spotlight feed", meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_REMOVE_SPOTLIGHTED_FEED;
  }
}
