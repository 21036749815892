import React, { FC } from "react";
import NameCard from "./../NameCard/NameCard";
import MicIndicator from "./../MicIndicator/MicIndicator";
import Flexbox from "../Flexbox/Flexbox";
import { ConnectionMetadata } from "@proximie/media";

export interface ParticipantProps {
  name: string;
  id?: number;
  userUUID?: string;
  isConnected?: boolean;
  isMuted?: boolean;
  isActive?: boolean;
  isLocal?: boolean;
  role?: string;
  metadata?: ConnectionMetadata;
  streamId?: string;
  email?: string;
}

const Participant: FC<ParticipantProps> = (props: ParticipantProps) => {
  const { name, isConnected, isMuted, isActive } = props;

  return (
    <Flexbox
      alignItems="center"
      justifyContent="space-between"
      data-cy={`participant-${props.name}`}
    >
      <NameCard name={name} />
      <MicIndicator
        isConnected={!!isConnected}
        isMuted={!!isMuted}
        isActive={!!isActive}
      />
    </Flexbox>
  );
};

export default Participant;
