import { ENV_NAMES } from "../../../../../infra-naming-constants";

export const isLocalOrE2EEnvironment = (environmentName: string) => {
  return [ENV_NAMES.DEFAULT, ENV_NAMES.LOCAL, ENV_NAMES.E2E].includes(
    environmentName,
  );
};

export const isE2EEnvironment = (environmentName: string) => {
  return environmentName === ENV_NAMES.E2E;
};
