import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface RecordingNotAllowedExceptionMeta {
  organisationId: string;
}

export class RecordingNotAllowedException extends BaseException {
  constructor(meta: RecordingNotAllowedExceptionMeta) {
    super("Recording not allowed", meta);

    this.code = ExceptionCodes.RECORDING_NOT_ALLOWED;
  }
}
