import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class InvalidFirstSequenceNumberException extends BaseException {
  constructor(message = "Invalid first sequence number", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_FIRST_SEQUENCE_NUMBER;
  }
}
