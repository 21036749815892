import React, { FC } from "react";
import MuiButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";

const ButtonGroup: FC<ButtonGroupProps> = (
  props: ButtonGroupProps,
): JSX.Element => {
  return (
    <MuiButtonGroup
      {...props}
      sx={{
        "& .MuiButtonGroup-grouped:not(:last-of-type)": {
          borderRight: "unset !important",
        },
        ...props.sx,
      }}
    ></MuiButtonGroup>
  );
};

export default ButtonGroup;
