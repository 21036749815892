import React, { FC } from "react";
import RecordingIndicator from "../RecordingIndicator/RecordingIndicator";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { Button } from "@proximie/components";
import { Pause as PauseIcon } from "@mui/icons-material";
import {
  RecordingContext,
  RecordingStateStatus,
  useRecording as useRecordingHook,
} from "../../contexts/recording-context/recording-context";
import { Timer } from "./Timer.style";

export interface RecordingControlsProps {
  useRecording?: () => RecordingContext;
}

const RecordingControls: FC<RecordingControlsProps> = ({
  useRecording = useRecordingHook,
}: RecordingControlsProps): JSX.Element => {
  const theme = useTheme();
  const { elapsedTime, status, previousStatus, toggleRecording } =
    useRecording();
  const isRecording = status === RecordingStateStatus.Recording;
  const isLoading = status === RecordingStateStatus.Loading;
  const recordButton = recordingButtonStateReducer(previousStatus, status);

  return (
    <>
      <Box ml={4} flexShrink={0} display={"flex"} alignItems={"center"}>
        <Timer data-cy="recording-timer" theme={theme}>
          {elapsedTime}
        </Timer>{" "}
      </Box>
      <Box ml={2} flexShrink={0} display={"flex"} alignItems={"center"}>
        <RecordingIndicator isActive={isRecording} />
      </Box>
      <Box ml={2} flexShrink={0} display={"flex"} alignItems={"center"}>
        <Button
          data-cy={
            isRecording ? "recording-pause-button" : "recording-start-button"
          }
          startIcon={recordButton.startIcon}
          variant="primary"
          size="small"
          onClick={toggleRecording}
          disabled={isLoading}
          style={{
            minWidth: 120,
          }}
        >
          {recordButton.text}
        </Button>
      </Box>
    </>
  );
};

export default RecordingControls;

export type RecordButtonState = {
  startIcon: React.ReactNode;
  text: string;
};

const recordingButtonStateReducer = (
  previousStatus: RecordingStateStatus,
  status: RecordingStateStatus,
): RecordButtonState => {
  switch (status) {
    case RecordingStateStatus.NotStarted:
    case RecordingStateStatus.Paused:
      return {
        startIcon: null,
        text: "Record",
      };

    case RecordingStateStatus.Recording:
      return {
        startIcon: <PauseIcon />,
        text: "Recording",
      };

    default: // RecordingStateStatus.Loading
      return {
        startIcon: <CircularProgress color={"inherit"} size={16} />,
        text:
          previousStatus === RecordingStateStatus.Recording
            ? "Pausing"
            : "Record",
      };
  }
};
