import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class SocketIOTransferErrorException extends BaseException {
  constructor(message = "Something is wrong with the sockets", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.SOCKET_IO_TRANSFER_ERROR;
  }
}
