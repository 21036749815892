import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class InvalidMuteMessageFormatException extends BaseException {
  constructor(message = "Invalid Mute message format", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_MUTE_MESSAGE_FORMAT;
  }
}
