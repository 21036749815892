import styled from "@emotion/styled";
import { Alert } from "@mui/material";

export const OutlinedAlertStyled = styled(Alert)<{
  margintop: number;
  marginbottom: number;
  marginleft: number;
  marginright: number;
}>`
  margin-bottom: ${({ marginbottom }) => `${marginbottom}px`};
  margin-top: ${({ margintop }) => `${margintop}px`};
  margin-left: ${({ marginleft }) => `${marginleft}px`};
  margin-right: ${({ marginright }) => `${marginright}px`};
`;
