import { OTLPLogExporter } from "@opentelemetry/exporter-logs-otlp-http/build/src/platform/browser";
import { OTLPExporterConfigBase } from "@opentelemetry/otlp-exporter-base";

type StringValueProvider = () => string;

type DynamicHeaders = {
  [key: string]: StringValueProvider;
};

type Exporter = {
  _transport: {
    _transport: {
      _parameters: {
        headers: Record<string, string>;
      };
    };
  };
};

export class DynamicHeaderOTLPLogExporter extends OTLPLogExporter {
  constructor(
    config?: OTLPExporterConfigBase,
    private dynamicHeaders?: DynamicHeaders,
  ) {
    super(config as never);
  }

  send(objects: never[], onSuccess: () => void, onError: never) {
    this.updateHeaders();
    super.send(objects, onSuccess, onError);
  }

  private updateHeaders() {
    if (this.dynamicHeaders) {
      const exporter = this as unknown as Exporter;
      const computedHeaders = Object.entries(this.dynamicHeaders).reduce(
        (acc, [key, valueProvider]) => {
          acc[key] = valueProvider();
          return acc;
        },
        {} as Record<string, string>,
      );
      const staticHeaders = exporter._transport._transport._parameters.headers;

      exporter._transport._transport._parameters.headers = {
        ...staticHeaders,
        ...computedHeaders,
      };
    }
  }
}
