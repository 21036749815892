import { makeNoContentRoute, makeRoute } from "./api";
import * as t from "io-ts";

const acceptInvitationRequest = t.type({
  id: t.number,
});

const createInvitationRequest = t.type({
  invitedUserId: t.number,
  appointmentId: t.number,
});

export const empty = t.type({});

export const acceptInvitation = makeNoContentRoute(
  "/appointment-invitations/:id/accept",
  acceptInvitationRequest,
);

export const createInvitation = makeRoute(
  "/appointment-invitations",
  createInvitationRequest,
  empty,
);

export type createInvitationRequest = t.TypeOf<typeof createInvitationRequest>;
export type AcceptInvitationRequest = t.TypeOf<typeof acceptInvitationRequest>;
