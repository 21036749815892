import React, { FC, ReactNode } from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import Icon from "../Icon/Icon";
import { IconKey } from "../../../types/IconKey";

export interface IconBtnProps extends IconButtonProps {
  name?: IconKey;
  iconsize?: "small" | "inherit" | "medium" | "large";
  iconcolor?: string;
  dataCy?: string;
  testId?: string;
  children?: ReactNode;
  /** Start of hack, https://github.com/mui-org/material-ui/issues/17226 */
  fullWidth?: boolean;
  disableElevation?: boolean;
  /** end of hack */
}

const IconBtn: FC<IconBtnProps> = (props: IconBtnProps): JSX.Element => {
  const {
    /** Start of hack */ fullWidth,
    disableElevation /** end of hack */,
    dataCy,
    testId,
    ...iconBtnProps
  } = props;
  return (
    <IconButton
      {...iconBtnProps}
      data-testid={props.testId}
      data-cy={props.dataCy}
      size="large"
    >
      {props.children}
      {props.name && (
        <Icon
          name={props.name}
          htmlColor={props.iconcolor}
          fontSize={props.iconsize}
        />
      )}
    </IconButton>
  );
};

export default IconBtn;
