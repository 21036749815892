import { AppointmentNotFoundExceptionMeta as _AppointmentNotFoundExceptionMeta } from "./AppointmentNotFoundException";
import { Auth0AuthenticationExceptionMeta as _Auth0AuthenticationExceptionMeta } from "./Auth0AuthenticationException";
import { Auth0CannotCreateUserExceptionMeta as _Auth0CannotCreateUserExceptionMeta } from "./Auth0CannotCreateUserException";
import { Auth0CannotTriggerPasswordResetExceptionMeta as _Auth0CannotTriggerPasswordResetExceptionMeta } from "./Auth0CannotTriggerPasswordResetException";
import { ConstraintsAreNotMetByAvailableHardwareExceptionMeta as _ConstraintsAreNotMetByAvailableHardwareExceptionMeta } from "./ConstraintsAreNotMetByAvailableHardwareException";
import { DeniedAccessToMicrophoneExceptionMeta as _DeniedAccessToMicrophoneExceptionMeta } from "./DeniedAccessToMicrophoneException";
import { DeniedAccessToWebcamExceptionMeta as _DeniedAccessToWebcamExceptionMeta } from "./DeniedAccessToWebcamException";
import { FailedToDeIdentifyExceptionMeta as _FailedToDeIdentifyExceptionMeta } from "./FailedToDeIdentifyException";
import { FailedToReIdentifyExceptionMeta as _FailedToReIdentifyExceptionMeta } from "./FailedToReIdentifyException";
import { FailedToDeleteRecordsByDeIdsExceptionMeta as _FailedToDeleteRecordsByDeIdsExceptionMeta } from "./FailedToDeleteRecordsByDeIdsException";
import { InvalidMaskingLoggerExceptionMeta as _InvalidMaskingLoggerExceptionMeta } from "./InvalidMaskingLoggerException";
import { InvalidMediaSessionIdExceptionMeta as _InvalidMediaSessionIdExceptionMeta } from "./InvalidMediaSessionIdException";
import { InvalidUserMediaConstraintExceptionMeta as _InvalidUserMediaConstraintExceptionMeta } from "./InvalidUserMediaConstraintException";
import { MicrophoneInUseExceptionMeta as _MicrophoneInUseExceptionMeta } from "./MicrophoneInUseException";
import { MicrophoneNotFoundExceptionMeta as _MicrophoneNotFoundExceptionMeta } from "./MicrophoneNotFoundException";
import { MissingEnvironmentVariablesExceptionMeta as _MissingEnvironmentVariablesExceptionMeta } from "./MissingEnvironmentVariablesException";
import { SpeakerNotFoundExceptionMeta as _SpeakerNotFoundExceptionMeta } from "./SpeakerNotFoundException";
import { UnableToScreenShareExceptionMeta as _UnableToScreenShareExceptionMeta } from "./UnableToScreenShareException";
import { UserDoesNotExistsInOrganisationExceptionMeta as _UserDoesNotExistsInOrganisationExceptionMeta } from "./UserDoesNotExistsInOrganisationException";
import { WebcamInUseExceptionMeta as _WebcamInUseExceptionMeta } from "./WebcamInUseException";
import { WebcamNotFoundExceptionMeta as _WebcamNotFoundExceptionMeta } from "./WebcamNotFoundException";
import { NotAllowedToResendInvitationExceptionMeta as _NotAllowedToResendInvitationExceptionMeta } from "./NotAllowedToResendInvitationException";
import { FailedToDeleteMediaSessionExceptionMeta as _FailedToDeleteMediaSessionExceptionMeta } from "./FailedToDeleteMediaSessionException";
import { FailedToUpdateMediaSessionExceptionMeta as _FailedToUpdateMediaSessionExceptionMeta } from "./FailedToUpdateMediaSessionException";
import { CannotDeleteMediaSessionDueToInvalidStatusExceptionMeta as _CannotDeleteMediaSessionDueToInvalidStatusExceptionMeta } from "./CannotDeleteMediaSessionDueToInvalidStatusException";
import { FailedToDeprovisionSessionsExceptionMeta as _FailedToDeprovisionSessionsExceptionMeta } from "./FailedToDeprovisionSessionsException";
import { UserNotFoundExceptionMeta as _UserNotFoundExceptionMeta } from "./UserNotFoundException";

export { AppointmentNotFoundException } from "./AppointmentNotFoundException";
export type AppointmentNotFoundExceptionMeta =
  _AppointmentNotFoundExceptionMeta;

export { CannotDeleteMediaSessionDueToInvalidStatusException } from "./CannotDeleteMediaSessionDueToInvalidStatusException";
export type CannotDeleteMediaSessionDueToInvalidStatusExceptionMeta =
  _CannotDeleteMediaSessionDueToInvalidStatusExceptionMeta;

export { Auth0AuthenticationException } from "./Auth0AuthenticationException";
export type Auth0AuthenticationExceptionMeta =
  _Auth0AuthenticationExceptionMeta;

export { Auth0CannotCreateUserException } from "./Auth0CannotCreateUserException";
export type Auth0CannotCreateUserExceptionMeta =
  _Auth0CannotCreateUserExceptionMeta;

export { Auth0CannotTriggerPasswordResetException } from "./Auth0CannotTriggerPasswordResetException";
export type Auth0CannotTriggerPasswordResetExceptionMeta =
  _Auth0CannotTriggerPasswordResetExceptionMeta;

export { Auth0UserAlreadyExistsException } from "./Auth0UserAlreadyExistsException";
export { Auth0CannotGetUserException } from "./Auth0CannotGetUserException";

export { FailedToDeleteMediaSessionException } from "./FailedToDeleteMediaSessionException";
export type FailedToDeleteMediaSessionExceptionMeta =
  _FailedToDeleteMediaSessionExceptionMeta;
export { FailedToUpdateMediaSessionException } from "./FailedToUpdateMediaSessionException";
export type FailedToUpdateMediaSessionExceptionMeta =
  _FailedToUpdateMediaSessionExceptionMeta;

export { BaseException } from "./BaseException";

export { DefaultOrganisationNotFoundException } from "./DefaultOrganisationNotFoundException";
export { InvalidChatEventException } from "./InvalidChatEventException";
export { InvalidSpotlightEventException } from "./InvalidSpotlightEventException";
export { SocketIOTransferErrorException } from "./SocketIOTransferErrorException";

export { InvalidTelestrationEventException } from "./InvalidTelestrationEventException";
export { InvalidInviteUserRequestException } from "./InvalidInviteUserRequestException";
export { InvalidOrganisationListRequestException } from "./InvalidOrganisationListRequestException";

export { FailedToSendOrgInvitationEmail } from "./FailedToSendOrgInvitationEmail";
export { FailedToSendAppointmentInvitationEmail } from "./FailedToSendAppointmentInvitationEmail";

export { FailedWebSocketHealthCheckException } from "./FailedWebSocketHealthCheckException";

export { ConflictingRecordingStatusForVideoAndAudioException } from "./ConflictingRecordingStatusForVideoAndAudioException";

export { FailedToCreateMediaServerSessionException } from "./FailedToCreateMediaServerSessionException";

export { FailedToAttachMediaServerSessionException } from "./FailedToAttachMediaServerSessionException";

export { FailedToChangeRecordingStatusException } from "./FailedToChangeRecordingStatusException";

export { FailedToFetchProfileException } from "./FailedToFetchProfileException";

export { FailedToDeleteMediaSessionsException } from "./FailedToDeleteMediaSessionsException";

export { FailedToDeprovisionSessionsException } from "./FailedToDeprovisionSessionsException";
export type FailedToDeprovisionSessionsExceptionMeta =
  _FailedToDeprovisionSessionsExceptionMeta;

export { FailedToGetMediaCredentialsException } from "./FailedToGetMediaCredentialsException";

export { NotAllowedToJoinSessionException } from "./NotAllowedToJoinSessionException";

export { MediaServerParametersNotFoundException } from "./MediaServerParametersNotFoundException";

export { MediaSessionNotFoundException } from "./MediaSessionNotFoundException";

export { FailedToDeIdentifyException } from "./FailedToDeIdentifyException";
export type FailedToDeIdentifyExceptionMeta = _FailedToDeIdentifyExceptionMeta;

export { InvalidRecordingMessageFormatException } from "./InvalidRecordingMessageFormatException";

export { InvalidBroadcastMessageFormatException } from "./InvalidBroadcastMessageFormatException";

export { InvalidMuteMessageFormatException } from "./InvalidMuteMessageFormatException";

export { FailedToReIdentifyException } from "./FailedToReIdentifyException";
export type FailedToReIdentifyExceptionMeta = _FailedToReIdentifyExceptionMeta;

export { FailedToDeleteRecordsByDeIdsException } from "./FailedToDeleteRecordsByDeIdsException";
export type FailedToDeleteRecordsByDeIdsExceptionMeta =
  _FailedToDeleteRecordsByDeIdsExceptionMeta;

export { InvalidMaskingLoggerException } from "./InvalidMaskingLoggerException";
export type InvalidMaskingLoggerExceptionMeta =
  _InvalidMaskingLoggerExceptionMeta;

export { InvalidMediaSessionIdException } from "./InvalidMediaSessionIdException";
export type InvalidMediaSessionIdExceptionMeta =
  _InvalidMediaSessionIdExceptionMeta;

export { MissingEnvironmentVariablesException } from "./MissingEnvironmentVariablesException";
export type MissingEnvironmentVariablesExceptionMeta =
  _MissingEnvironmentVariablesExceptionMeta;

export { InvalidFirstSequenceNumberException } from "./InvalidFirstSequenceNumberException";

export { InvalidLastSequenceNumberException } from "./InvalidLastSequenceNumberException";

export { InvalidSequenceNumberRangeException } from "./InvalidSequenceNumberRangeException";

export { NotAllowedToCreateOrganisationException } from "./NotAllowedToCreateOrganisationException";

export { NotAllowedToCreateAppointmentException } from "./NotAllowedToCreateAppointmentException";

export { NotAllowedToUpdateOrganisationException } from "./NotAllowedToUpdateOrganisationException";

export { NotAllowedToGetOrganisationUsersException } from "./NotAllowedToGetOrganisationUsersException";

export { NotAllowedToGetOrganisationException } from "./NotAllowedToGetOrganisationException";

export { NotAllowedToInviteUsersException } from "./NotAllowedToInviteUsersException";

export { NotAllowedToUpdateAppointmentException } from "./NotAllowedToUpdateAppointmentException";

export { NotAllowedToUpdateAppointmentInvitationException } from "./NotAllowedToUpdateAppointmentInvitationException";

export { NotAllowedToAddAppointmentInvitationException } from "./NotAllowedToAddAppointmentInvitationException";

export { NotAllowedToUpdateUserException } from "./NotAllowedToUpdateUserException";
export { NotAllowedToRemoveMemberFromOrganisationException } from "./NotAllowedToRemoveMemberFromOrganisationException";

export { NotAllowedToUpdateUserRoleException } from "./NotAllowedToUpdateUserRoleException";

export { NotAllowedToRecordSessionException } from "./NotAllowedToRecordSession";

export { PlaybackNotAllowedException } from "./PlaybackNotAllowedException";

export { UserNotPartOfOrganisationException } from "./UserNotPartOfOrganisationException";

export { UserNotPartOfAnyOrganisationException } from "./UserNotPartOfAnyOrganisationException";

export { NotAllowedToResendInvitationException } from "./NotAllowedToResendInvitationException";
export type NotAllowedToResendInvitationExceptionMeta =
  _NotAllowedToResendInvitationExceptionMeta;

export { SessionAlreadyStartedException } from "./SessionAlreadyStartedException";

export { UnauthenticatedException } from "./UnauthenticatedException";
export { UnauthorisedException } from "./UnauthorisedException";

export { ProfileNotCompletedException } from "./ProfileNotCompletedException";

export { OrganisationAlreadyExistsException } from "./OrganisationAlreadyExistsException";

export { RecordingHasNoVideosException } from "./RecordingHasNoVideosException";
export { RecordingNotFoundException } from "./RecordingNotFoundException";
export { RecordingNotAllowedException } from "./RecordingNotAllowedException";
export { PlaybackNotAvailableException } from "./PlaybackNotAvailableException";

export { UnsupportedNumberOfEmailsException } from "./UnsupportedNumberOfEmailsException";

export { UserAlreadyExistsInOrganisationException } from "./UserAlreadyExistsInOrganisationException";

export { UserDoesNotExistsInOrganisationException } from "./UserDoesNotExistsInOrganisationException";
export type UserDoesNotExistsInOrganisationExceptionMeta =
  _UserDoesNotExistsInOrganisationExceptionMeta;

export { UserNotFoundException } from "./UserNotFoundException";
export type UserNotFoundExceptionMeta = _UserNotFoundExceptionMeta;

export { EndpointNotInitializedException } from "./EndpointNotInitializedException";

export { VideoCapacityExceededException } from "./VideoCapacityExceededException";

export { WebcamInUseException } from "./WebcamInUseException";
export type WebcamInUseExceptionMeta = _WebcamInUseExceptionMeta;

export { WebcamNotFoundException } from "./WebcamNotFoundException";
export type WebcamNotFoundExceptionMeta = _WebcamNotFoundExceptionMeta;

export { UnableToScreenShareException } from "./UnableToScreenShareException";
export type UnableToScreenShareExceptionMeta =
  _UnableToScreenShareExceptionMeta;

export { SpeakerNotFoundException } from "./SpeakerNotFoundException";
export type SpeakerNotFoundExceptionMeta = _SpeakerNotFoundExceptionMeta;

export { MicrophoneInUseException } from "./MicrophoneInUseException";
export type MicrophoneInUseExceptionMeta = _MicrophoneInUseExceptionMeta;

export { MicrophoneNotFoundException } from "./MicrophoneNotFoundException";
export type MicrophoneNotFoundExceptionMeta = _MicrophoneNotFoundExceptionMeta;

export { InvalidUserMediaConstraintException } from "./InvalidUserMediaConstraintException";
export type InvalidUserMediaConstraintExceptionMeta =
  _InvalidUserMediaConstraintExceptionMeta;

export { FailedToSetSpeakerException } from "./FailedToSetSpeakerException";

export { FailedToSetMicrophoneException } from "./FailedToSetMicrophoneException";

export { DeniedAccessToWebcamException } from "./DeniedAccessToWebcamException";
export type DeniedAccessToWebcamExceptionMeta =
  _DeniedAccessToWebcamExceptionMeta;

export { DeniedAccessToMicrophoneException } from "./DeniedAccessToMicrophoneException";
export type DeniedAccessToMicrophoneExceptionMeta =
  _DeniedAccessToMicrophoneExceptionMeta;

export { ConstraintsAreNotMetByAvailableHardwareException } from "./ConstraintsAreNotMetByAvailableHardwareException";
export type ConstraintsAreNotMetByAvailableHardwareExceptionMeta =
  _ConstraintsAreNotMetByAvailableHardwareExceptionMeta;

export { InvalidSpotlightMessageFormatException } from "./InvalidSpotlightMessageFormatException";

export { NotAllowedToSpotlightFeedException } from "./NotAllowedToSpotlightFeed";

export { NotAllowedToRemoveSpotlightedFeed } from "./NotAllowedToRemoveSpotlightedFeed";

export { ServerAdapterDestroyedException } from "./ServerAdapterDestroyedException";
