import MonitorDeviceLocal from "./MonitorDeviceLocal";
import DeviceLocalCamera from "../Devices/DeviceLocalCamera";
import EndpointVirtual from "../Endpoints/EndpointVirtual";
import { MediaDeviceKind } from "../../../index";
export default class MonitorDeviceLocalCamera extends MonitorDeviceLocal {
  protected kind: MediaDeviceKind = "videoinput";

  constructor(protected room: EndpointVirtual) {
    super(room);
    this.initialisePermissionListener("camera" as PermissionName);
  }

  protected override createDevice(info: MediaDeviceInfo): DeviceLocalCamera {
    return new DeviceLocalCamera(info.deviceId, {
      deviceInfo: info,
    });
  }
}
