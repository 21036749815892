import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface AppointmentNotFoundExceptionMeta {
  appointmentId: number;
}

export class AppointmentNotFoundException extends BaseException {
  constructor(
    message = "Appointment not found",
    meta?: AppointmentNotFoundExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.APPOINTMENT_NOT_FOUND;
  }
}
