import CheckIcon from "@mui/icons-material/Check";
import WindowIcon from "@mui/icons-material/Window";
import { ListItemIcon, ListItemText, useTheme } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { SessionFeedView } from "../../models/SessionContext";
import { SpotlightIcon } from "../icons/SpotlightIcon/SpotlightIcon";

interface ViewSelectorMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export const ViewSelectorMenu: React.FC<ViewSelectorMenuProps> = (props) => {
  const { colors } = useTheme();
  const { view, setView, spotlight, user } = useSessionContext();
  const theme = useTheme();

  return (
    <Menu
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      color="primary"
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        "& .MuiMenu-paper": { borderRadius: "6px" },
      }}
    >
      <MenuItem
        sx={{
          padding: "12px 16px",
          opacity: "initial !important",
          ...theme.typography.subtitle2,
        }}
        color="primary"
        divider
        disabled
      >
        My layout
      </MenuItem>
      <MenuItem
        data-testid="spotlight-view-option"
        sx={{ padding: "12px 16px" }}
        color="primary"
        disabled={!spotlight.spotlightedFeed}
        onClick={() => {
          setView(SessionFeedView.SPOTLIGHT_VIEW);
          props.onClose();
        }}
      >
        <ListItemIcon color="primary">
          {view === SessionFeedView.SPOTLIGHT_VIEW ? (
            <CheckIcon
              data-testid="spotlight-view-check-icon"
              color="secondary"
            />
          ) : null}
        </ListItemIcon>
        <ListItemText color="primary">
          {!spotlight.spotlightedFeed ||
          spotlight.spotlightedFeed.spotlightedBy.profileUuid === user?.id
            ? "Spotlight view"
            : "Follow spotlight"}
        </ListItemText>
        <ListItemIcon sx={{ ml: 2 }}>
          <SpotlightIcon sx={{ color: colors.Black }} />
        </ListItemIcon>
      </MenuItem>
      <MenuItem
        data-testid="grid-view-option"
        sx={{ padding: "12px 16px" }}
        color="primary"
        divider
        onClick={() => {
          setView(SessionFeedView.GRID_VIEW);
          props.onClose();
        }}
      >
        <ListItemIcon color="primary">
          {view === SessionFeedView.GRID_VIEW ? (
            <CheckIcon data-testid="grid-view-check-icon" color="secondary" />
          ) : null}
        </ListItemIcon>
        <ListItemText color="primary">Grid view</ListItemText>
        <ListItemIcon sx={{ ml: 2 }}>
          <WindowIcon sx={{ color: colors.Black }} />
        </ListItemIcon>
      </MenuItem>
    </Menu>
  );
};
