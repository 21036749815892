import { FeedType, RectMask, ElipMask, MediaUtil } from "@proximie/media";

const RETENTION_PERIOD = 2678400000; // one month (31 days) in msecs

const LOCAL_STORAGE_KEY = "videos";

// {
// 	[userId]:{
// 		lastSessionId: sessionId,
// 		sessions:{
// 			[sessionId]:{
//        lastWritten: timestamp,
//        videos: [
// 				  video1,
// 				  video2
// 		  	]
//      }
// 		}
// 	}
// }

//LATER - during transition key may be either profileId (numeric) or userId (uuid)
export type StateInfo = Record<string, UserInfo>;
export type UserInfo = {
  lastSessionId: string;
  sessions: Record<string, SessionInfo>;
};
export type SessionInfo = {
  lastWritten: number;
  videos: VideoInfo[];
};
export type VideoInfo = {
  type: FeedType;
  streamId: string;
  order: string;
  deviceId: string;
  uniqueId: string;
  label: string;
  masks?: Array<RectMask | ElipMask>;
};

export default class VideoStore {
  private stateInfo: StateInfo = {};

  constructor(
    private sessionId: string,
    private userId: string,
    profileId: number, //LATER - we won't need profileId
  ) {
    this.stateInfo = VideoStore.read();

    //LATER - remove: converts profileId->userId for this user only
    if (!this.stateInfo[userId] && this.stateInfo[profileId]) {
      this.stateInfo[userId] = this.stateInfo[profileId];
      delete this.stateInfo[profileId];
    }

    const now = Date.now();

    // deprecated format:
    // {
    // 	[userId]:{
    // 		lastSessionId: sessionId,
    // 		sessions:{
    // 			[sessionId]:[
    // 		    video1,
    // 			  video2
    // 	  	]
    //  	}
    // 	}
    // }

    //LATER - remove: mutate into new format
    Object.keys(this.stateInfo).forEach((myUserId: string): void => {
      Object.keys(this.stateInfo[myUserId].sessions).forEach(
        (mySessionId: string): void => {
          if (Array.isArray(this.stateInfo[myUserId].sessions[mySessionId])) {
            this.stateInfo[myUserId].sessions[mySessionId] = {
              lastWritten: now,
              videos: VideoStore.addMissingFields(
                this.stateInfo[myUserId].sessions[
                  mySessionId
                ] as unknown as VideoInfo[],
              ),
            };
          }
        },
      );
    });
    // at this point stateInfo is in the new format although we have profileId
    // instead of userId for other users

    // traverse all sessions looking for out of date ones and remove them
    Object.keys(this.stateInfo).forEach((myUserId: string): void => {
      Object.keys(this.stateInfo[myUserId].sessions).forEach(
        (mySessionId: string): void => {
          if (
            this.stateInfo[myUserId].sessions[mySessionId].lastWritten +
              RETENTION_PERIOD <
            now
          ) {
            //NB. this could mean that lastSessionId points to a missing session
            delete this.stateInfo[myUserId].sessions[mySessionId];
          }
        },
      );
      if (Object.keys(this.stateInfo[myUserId].sessions).length === 0) {
        delete this.stateInfo[myUserId];
      }
    });

    if (!this.stateInfo[userId]) {
      this.stateInfo[userId] = {
        lastSessionId: this.sessionId,
        sessions: {},
      };
    }

    if (!this.stateInfo[userId].sessions[this.sessionId]) {
      this.stateInfo[userId].sessions[this.sessionId] = {
        lastWritten: now,
        videos: [],
      };
    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.stateInfo));
  }

  private static read(): StateInfo {
    const stateItem = localStorage.getItem(LOCAL_STORAGE_KEY);
    let stateInfo: StateInfo = {};

    if (stateItem) {
      try {
        stateInfo = JSON.parse(stateItem);
      } catch {
        // do nothing
      }
    }
    return stateInfo;
  }

  // for backwards compatibility we set uniqueId to deviceId if it doesn't exist,
  // and generate order if it doesn't exist
  //LATER - remove me!
  private static addMissingFields(videos?: VideoInfo[]): VideoInfo[] {
    return (videos ?? []).map((video: VideoInfo): VideoInfo => {
      if (!video.uniqueId) {
        video.uniqueId = video.deviceId;
      }
      if (!video.order) {
        video.order = MediaUtil.generateOrderFromStreamId(video.streamId);
      }
      return video;
    });
  }

  private write(videos: VideoInfo[]): void {
    this.stateInfo[this.userId].sessions[this.sessionId] = {
      lastWritten: Date.now(),
      videos,
    };
    this.stateInfo[this.userId].lastSessionId = this.sessionId;

    // write-through to localStorage
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.stateInfo));
    console.debug("VideoStore set=", JSON.stringify(this.stateInfo));
  }

  get videos(): VideoInfo[] {
    return this.stateInfo[this.userId].sessions[this.sessionId].videos;
  }

  get lastStateVideos(): VideoInfo[] {
    const lastSessionId = this.stateInfo[this.userId].lastSessionId;

    return this.stateInfo[this.userId].sessions[lastSessionId]?.videos ?? [];
  }

  add(video: VideoInfo): void {
    const videos = this.videos.filter(
      (myVideo: VideoInfo): boolean => myVideo.streamId !== video.streamId,
    );

    videos.push(video);

    this.write(videos);
  }

  remove(streamId: string): void {
    const videos = this.videos.filter(
      (video: VideoInfo): boolean => video.streamId !== streamId,
    );

    this.write(videos);
  }

  setMasks(streamId: string, masks: Array<RectMask | ElipMask>): void {
    const videos = this.videos.map(
      (video: VideoInfo): VideoInfo =>
        video.streamId === streamId ? { ...video, masks } : video,
    );

    this.write(videos);
  }

  clearMasks(streamId: string): void {
    const videos = this.videos.map(
      (video: VideoInfo): VideoInfo =>
        video.streamId === streamId ? { ...video, masks: undefined } : video,
    );

    this.write(videos);
  }
}
