import React from "react";

export const EMPTY_THUMBNAIL =
  "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";

export interface ThumbnailProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
  imageURL?: string;
}

export function Thumbnail({ alt, imageURL, ...props }: ThumbnailProps) {
  const loadFallback = (event: { currentTarget: { src: string } }) => {
    event.currentTarget.src = EMPTY_THUMBNAIL;
  };

  return <img {...props} src={imageURL} alt={alt} onError={loadFallback} />;
}
