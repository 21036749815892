import MonitorDeviceLocal from "./MonitorDeviceLocal";
import DeviceLocalMicrophone from "../Devices/DeviceLocalMicrophone";
import EndpointVirtual from "../Endpoints/EndpointVirtual";
import { MediaDeviceKind } from "../../../index";

export default class MonitorDeviceLocalMicrophone extends MonitorDeviceLocal {
  protected kind: MediaDeviceKind = "audioinput";

  constructor(protected room: EndpointVirtual) {
    super(room);
    this.initialisePermissionListener("microphone" as PermissionName);
  }

  protected override createDevice(
    info: MediaDeviceInfo,
  ): DeviceLocalMicrophone {
    return new DeviceLocalMicrophone(info.deviceId, {
      deviceInfo: info,
    });
  }
}
