export enum ExceptionCodes {
  AUTH0_CANNOT_CREATE_USER = "AUTH0_CANNOT_CREATE_USER",
  AUTH0_CANNOT_GET_USER = "AUTH0_CANNOT_GET_USER",
  AUTH0_CANNOT_TRIGGER_PASSWORD_RESET = "AUTH0_CANNOT_TRIGGER_PASSWORD_RESET",
  AUTH0_FAILED_AUTHENTICATION = "AUTH0_FAILED_AUTHENTICATION",
  AUTH0_USER_ALREADY_EXISTS = "AUTH0_USER_ALREADY_EXISTS",
  APPOINTMENT_NOT_FOUND = "APPOINTMENT_NOT_FOUND",
  APPOINTMENT_INVITATION_ALREADY_EXISTS = "APPOINTMENT_INVITATION_ALREADY_EXISTS",
  CANNOT_DELETE_MEDIA_SESSION_DUE_TO_INVALID_STATUS = "CANNOT_DELETE_MEDIA_SESSION_DUE_TO_INVALID_STATUS",
  CONFLICTING_RECORDING_STATUS_FOR_VIDEO_AND_AUDIO = "CONFLICTING_RECORDING_STATUS_FOR_VIDEO_AND_AUDIO",
  CONSTRAINTS_ARE_NOT_MET_BY_AVAILABLE_HARDWARE = "CONSTRAINTS_ARE_NOT_MET_BY_AVAILABLE_HARDWARE",
  DEFAULT_ORGANISATION_NOT_FOUND = "DEFAULT_ORGANISATION_NOT_FOUND",
  DENIED_ACCESS_TO_MICROPHONE = "DENIED_ACCESS_TO_MICROPHONE",
  DENIED_ACCESS_TO_WEBCAM = "DENIED_ACCESS_TO_WEBCAM",
  FAILED_TO_ATTACH_MEDIA_SERVER_SESSION = "FAILED_TO_ATTACH_MEDIA_SERVER_SESSION",
  FAILED_TO_CHANGE_RECORDING_STATUS = "FAILED_TO_CHANGE_RECORDING_STATUS",
  FAILED_TO_CREATE_MEDIA_SERVER_SESSION = "FAILED_TO_CREATE_MEDIA_SERVER_SESSION",
  FAILED_TO_DELETE_MEDIA_SESSION = "FAILED_TO_DELETE_MEDIA_SESSION",
  FAILED_TO_DELETE_MEDIA_SESSIONS = "FAILED_TO_DELETE_MEDIA_SESSIONS",
  FAILED_TO_DELETE_RECORDS_BY_DE_IDS = "FAILED_TO_DELETE_RECORDS_BY_DE_IDS",
  FAILED_TO_DEPROVISION_SESSION = "FAILED_TO_DEPROVISION_SESSION",
  FAILED_TO_DEPROVISION_SESSIONS = "FAILED_TO_DEPROVISION_SESSIONS",
  FAILED_TO_DE_IDENTIFY = "FAILED_TO_DE_IDENTIFY",
  FAILED_TO_FETCH_PROFILE_EXCEPTION = "FAILED_TO_FETCH_PROFILE_EXCEPTION",
  FAILED_TO_GET_MEDIA_CREDENTIALS = "FAILED_TO_GET_MEDIA_CREDENTIALS",
  FAILED_TO_RE_IDENTIFY = "FAILED_TO_RE_IDENTIFY",
  FAILED_TO_SEND_APPOINTMENT_INVITATION_EMAIL = "FAILED_TO_SEND_APPOINTMENT_INVITATION_EMAIL",
  FAILED_TO_SEND_ORG_INVITATION_EMAIL = "FAILED_TO_SEND_ORG_INVITATION_EMAIL",
  FAILED_TO_SET_MICROPHONE = "FAILED_TO_SET_MICROPHONE",
  FAILED_TO_SET_SPEAKER = "FAILED_TO_SET_SPEAKER",
  FAILED_TO_UPDATE_MEDIA_SESSION = "FAILED_TO_UPDATE_MEDIA_SESSION",
  FAILED_WEB_SOCKET_HEALTH_CHECK = "FAILED_WEB_SOCKET_HEALTH_CHECK",
  INPUT_VALIDATION_ERROR = "INPUT_VALIDATION_ERROR",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  INVALID_CHAT_EVENT = "INVALID_CHAT_EVENT",
  INVALID_SPOTLIGHT_EVENT = "INVALID_SPOTLIGHT_EVENT",
  INVALID_FIRST_SEQUENCE_NUMBER = "INVALID_FIRST_SEQUENCE_NUMBER",
  INVALID_LAST_SEQUENCE_NUMBER = "INVALID_LAST_SEQUENCE_NUMBER",
  INVALID_MASKING_LOGGER = "INVALID_MASKING_LOGGER",
  INVALID_MEDIA_SESSION_ID = "INVALID_MEDIA_SESSION_ID",
  INVALID_RECORDING_MESSAGE_FORMAT = "INVALID_RECORDING_MESSAGE_FORMAT",
  INVALID_SPOTLIGHT_MESSAGE_FORMAT = "INVALID_SPOTLIGHT_MESSAGE_FORMAT",
  INVALID_BROADCAST_MESSAGE_FORMAT = "INVALID_BROADCAST_MESSAGE_FORMAT",
  INVALID_MUTE_MESSAGE_FORMAT = "INVALID_MUTE_MESSAGE_FORMAT",
  INVALID_SEQUENCE_NUMBER_RANGE = "INVALID_SEQUENCE_NUMBER_RANGE",
  INVALID_TELESTRATION_EVENT = "INVALID_TELESTRATION_EVENT",
  INVALID_INVITE_USER_REQUEST = "INVALID_INVITE_USER_REQUEST",
  INVALID_ORGANISATION_LIST_REQUEST = "INVALID_ORGANISATION_LIST_REQUEST",
  INVALID_USER_MEDIA_CONSTRAINT = "INVALID_USER_MEDIA_CONSTRAINT",
  MEDIA_SERVER_PARAMETERS_NOT_FOUND = "MEDIA_SERVER_PARAMETERS_NOT_FOUND",
  MEDIA_SESSION_NOT_FOUND = "MEDIA_SESSION_NOT_FOUND",
  MICROPHONE_IN_USE = "MICROPHONE_IN_USE",
  MICROPHONE_NOT_FOUND = "MICROPHONE_NOT_FOUND",
  MISSING_ENVIRONMENT_VARIABLES = "MISSING_ENVIRONMENT_VARIABLES",
  NOT_ALLOWED_TO_CREATE_ORGANISATION = "NOT_ALLOWED_TO_CREATE_ORGANISATION",
  NOT_ALLOWED_TO_CREATE_APPOINTMENT = "NOT_ALLOWED_TO_CREATE_APPOINTMENT",
  NOT_ALLOWED_TO_GET_ORGANISATION = "NOT_ALLOWED_TO_GET_ORGANISATION",
  NOT_ALLOWED_TO_GET_ORGANISATION_USERS = "NOT_ALLOWED_TO_GET_ORGANISATION_USERS",
  NOT_ALLOWED_TO_INVITE_USERS = "NOT_ALLOWED_TO_INVITE_USERS",
  NOT_ALLOWED_TO_JOIN_SESSION = "NOT_ALLOWED_TO_JOIN_SESSION",
  NOT_ALLOWED_TO_REMOVE_ADMIN = "NOT_ALLOWED_TO_REMOVE_ADMIN",
  NOT_ALLOWED_TO_REMOVE_MEMBER_FROM_ORGANISATION = "NOT_ALLOWED_TO_REMOVE_MEMBER_FROM_ORGANISATION",
  NOT_ALLOWED_TO_REMOVE_MEMBER_IN_SESSION = "NOT_ALLOWED_TO_REMOVE_MEMBER_IN_SESSION",
  NOT_ALLOWED_TO_RESEND_INVITATION = "NOT_ALLOWED_TO_RESEND_INVITATION",
  NOT_ALLOWED_TO_UPDATE_APPOINTMENT = "NOT_ALLOWED_TO_UPDATE_APPOINTMENT",
  NOT_ALLOWED_TO_DELETE_APPOINTMENT = "NOT_ALLOWED_TO_DELETE_APPOINTMENT",
  NOT_ALLOWED_TO_UPDATE_APPOINTMENT_INVITATION = "NOT_ALLOWED_TO_UPDATE_APPOINTMENT_INVITATION",
  NOT_ALLOWED_TO_ADD_APPOINTMENT_INVITATION = "NOT_ALLOWED_TO_ADD_APPOINTMENT_INVITATION",
  NOT_ALLOWED_TO_UPDATE_ORGANISATION = "NOT_ALLOWED_TO_UPDATE_ORGANISATION",
  NOT_ALLOWED_TO_UPDATE_USER = "NOT_ALLOWED_TO_UPDATE_USER",
  NOT_ALLOWED_TO_UPDATE_USER_ROLE = "NOT_ALLOWED_TO_UPDATE_USER_ROLE",
  NOT_ALLOWED_TO_RECORD_SESSION = "NOT_ALLOWED_TO_RECORD_SESSION",
  NOT_ALLOWED_TO_SPOTLIGHT_FEED = "NOT_ALLOWED_TO_SPOTLIGHT_FEED",
  NOT_ALLOWED_TO_REMOVE_SPOTLIGHTED_FEED = "NOT_ALLOWED_TO_REMOVE_SPOTLIGHT_FEED",
  ORGANISATION_ALREADY_EXISTS = "ORGANISATION_ALREADY_EXISTS",
  PLAYBACK_NOT_AVAILABLE = "PLAYBACK_NOT_AVAILABLE",
  PLAYBACK_NOT_ALLOWED = "PLAYBACK_NOT_ALLOWED",
  RECORDING_ALREADY_EXISTS = "RECORDING_ALREADY_EXISTS",
  RECORDING_NOT_FOUND = "RECORDING_NOT_FOUND",
  RECORDING_INVALID_STATE = "RECORDING_INVALID_STATE",
  RECORDING_NOT_ALLOWED = "RECORDING_NOT_ALLOWED",
  SESSION_ALREADY_STARTED = "SESSION_ALREADY_STARTED",
  SOCKET_IO_TRANSFER_ERROR = "SOCKET_IO_TRANSFER_ERROR",
  SPEAKER_NOT_FOUND = "SPEAKER_NOT_FOUND",
  PROFILE_NOT_COMPLETED = "PROFILE_NOT_COMPLETED",
  UNABLE_TO_SCREENSHARE = "UNABLE_TO_SCREENSHARE",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  UNAUTHORISED = "UNAUTHORISED",
  UNSUPPORTED_COUNTRY = "UNSUPPORTED_COUNTRY",
  UNSUPPORTED_NUMBER_OF_EMAILS = "UNSUPPORTED_NUMBER_OF_EMAILS",
  USER_ALREADY_EXISTS_IN_ORGANISATION = "USER_ALREADY_EXISTS_IN_ORGANISATION",
  USER_DOES_NOT_EXISTS_IN_ORGANISATION = "USER_DOES_NOT_EXISTS_IN_ORGANISATION",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  USER_NOT_PART_OF_ANY_ORGANISATION = "USER_NOT_PART_OF_ANY_ORGANISATION",
  USER_NOT_PART_OF_ORGANISATION = "USER_NOT_PART_OF_ORGANISATION",
  VIDEO_CAPACITY_EXCEPTION_EXCEEDED = "VIDEO_CAPACITY_EXCEPTION_EXCEEDED",
  ENDPOINT_NOT_INITIALIZED = "ENDPOINT_NOT_INITIALIZED",
  DUPLICATE_VIDEO_STREAM = "DUPLICATE_VIDEO_STREAM",
  WEBCAM_IN_USE = "WEBCAM_IN_USE",
  WEBCAM_NOT_FOUND = "WEBCAM_NOT_FOUND",
  SERVER_ADAPTER_DESTROYED = "SERVER_ADAPTER_DESTROYED",
}
