import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface InvalidMediaSessionIdExceptionMeta {
  sessionId: string;
}

export class InvalidMediaSessionIdException extends BaseException {
  constructor(
    message = "Invalid media session id",
    meta: InvalidMediaSessionIdExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_MEDIA_SESSION_ID;
  }
}
