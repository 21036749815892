import { updateUserSettings } from "./helpers";
import { SessionUserSettings, mergeUserSettingsWithDefault } from "./schema";

const getUserSessionSettingsKey = (userId: string): string =>
  `${userId}-session_settings`;

export const readUserSessionSettings =
  (userId?: string) => (): ReturnType<typeof mergeUserSettingsWithDefault> => {
    if (!userId) {
      return mergeUserSettingsWithDefault(null);
    }
    const rawUserSettings = sessionStorage.getItem(
      getUserSessionSettingsKey(userId),
    );
    let originalUserSettings = {};
    try {
      originalUserSettings = JSON.parse(rawUserSettings ?? "{}");
    } catch (error) {
      console.error(
        "failed to parse user settings from session storage",
        rawUserSettings,
      );
    }
    return mergeUserSettingsWithDefault(originalUserSettings);
  };

const rawSaveUserSessionSettings = (
  userId: string,
  newSettings: SessionUserSettings,
) => {
  sessionStorage.setItem(
    getUserSessionSettingsKey(userId),
    JSON.stringify(newSettings),
  );
};

export const saveUserSettings =
  (userId?: string) =>
  (updatedSettings: Parameters<typeof updateUserSettings>[1]) => {
    if (!userId) return;
    const originalUserSettings = readUserSessionSettings(userId)();
    const newUserSettings = updateUserSettings(
      originalUserSettings,
      updatedSettings,
    );
    rawSaveUserSessionSettings(userId, newUserSettings);
  };
