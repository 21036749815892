import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface CannotDeleteMediaSessionDueToInvalidStatusExceptionMeta {
  mediaSessionStatus: string;
}

export class CannotDeleteMediaSessionDueToInvalidStatusException extends BaseException {
  constructor(
    message = "Cannot delete media session due to invalid status",
    meta: CannotDeleteMediaSessionDueToInvalidStatusExceptionMeta,
  ) {
    super(message, meta);

    this.code =
      ExceptionCodes.CANNOT_DELETE_MEDIA_SESSION_DUE_TO_INVALID_STATUS;
  }
}
