import MonitorDeviceLocal from "./MonitorDeviceLocal";
import DeviceLocalSpeaker from "../Devices/DeviceLocalSpeaker";
import { MediaDeviceKind } from "../../WebRTCUtil";

export default class MonitorDeviceLocalSpeaker extends MonitorDeviceLocal {
  protected kind: MediaDeviceKind = "audiooutput";

  protected override createDevice(info: MediaDeviceInfo): DeviceLocalSpeaker {
    return new DeviceLocalSpeaker(info.deviceId, {
      deviceInfo: info,
    });
  }
}
