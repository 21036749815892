// Screenfall was rejected due to ongoing issues and poor support
// https://github.com/sindresorhus/screenfull.js
// https://github.com/sindresorhus/screenfull.js/issues/126

// NB: Due to no polyfil around the fullscreen api
// to ensure cross-browser compatibility we do an any hack
// this is to stop the typechecker flagging a false positive
// e.g. webkitFullscreenElement would be an error

export const IsFullScreen = (): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const vendorShim = document as any;
  const isFullScreen =
    vendorShim.fullscreenElement ||
    vendorShim.webkitFullscreenElement ||
    vendorShim.mozFullScreenElement ||
    vendorShim.msFullscreenElement ||
    null;

  return isFullScreen === null ? false : true;
};

export const CloseFullScreen = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const vendorShim = document as any;
  if (vendorShim.exitFullscreen) {
    vendorShim.exitFullscreen();
  } else if (vendorShim.mozCancelFullScreen) {
    /* Firefox */
    vendorShim.mozCancelFullScreen();
  } else if (vendorShim.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    vendorShim.webkitExitFullscreen();
  } else if (vendorShim.msExitFullscreen) {
    /* IE/Edge */
    vendorShim.msExitFullscreen();
  }
};

export const OpenFullScreen = (element?: HTMLElement) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const vendorShim =
    typeof element !== "undefined"
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (element as any)
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (document.documentElement as any);

  if (vendorShim.requestFullscreen) {
    vendorShim.requestFullscreen();
  } else if (vendorShim.mozRequestFullScreen) {
    /* Firefox */
    vendorShim.mozRequestFullScreen();
  } else if (typeof vendorShim.webkitRequestFullscreen !== "undefined") {
    /* Chrome, Safari and Opera */
    vendorShim.webkitRequestFullscreen();
  } else if (vendorShim.msRequestFullscreen) {
    /* IE/Edge */
    vendorShim.msRequestFullscreen();
  }
};
