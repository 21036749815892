import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface SpeakerNotFoundExceptionMeta {
  originalError: Error;
}

export class SpeakerNotFoundException extends BaseException {
  constructor(
    message = "Speaker not found",
    meta: SpeakerNotFoundExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.SPEAKER_NOT_FOUND;
  }
}
