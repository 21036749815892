import React, { ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
import { CheckCircle, Info } from "@mui/icons-material";
import { useTroubleShooting } from "../TroubleShootingContext";
import { Subtitle3Typography } from "../shared/CustomTypography";

export const GoodTroubleShootingReportITSuggestion = () => {
  const { colors } = useTheme();

  return (
    <TroubleShootingReportITSuggestion
      backgroundColor={colors.Green100}
      textColor={colors.Green700}
      borderColor={colors.Green300}
      icon={<CheckCircle sx={{ color: colors.Green500 }} />}
      message={
        "Everything looks good. Your audio and video should be working smoothly."
      }
    />
  );
};

export const BadTroubleShootingReportITSuggestion = () => {
  const { colors } = useTheme();

  return (
    <TroubleShootingReportITSuggestion
      backgroundColor={colors.Blue50}
      textColor={colors.Blue700}
      borderColor={colors.Blue300}
      icon={<Info sx={{ color: colors.Blue500 }} />}
      message={
        "We recommend taking a photo of this screen and sending it to your IT department."
      }
    />
  );
};

export const TroubleShootingReportITSuggestion = ({
  message,
  backgroundColor,
  textColor,
  borderColor,
  icon,
}: {
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  icon: ReactNode;
  message: string;
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      padding={"7px 8px"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={"6px"}
      sx={{
        backgroundColor,
        color: textColor,
        borderRadius: "6px",
        border: `1px solid ${borderColor}`,
      }}
    >
      {icon}
      <Subtitle3Typography lineHeight={"20px"} letterSpacing={"0.035px"}>
        {message}
      </Subtitle3Typography>
    </Box>
  );
};

export const TroubleShootingReportITSuggestionContainer = () => {
  const {
    lastCriticalState: { criticalityReport },
  } = useTroubleShooting();

  if (!criticalityReport) {
    return <GoodTroubleShootingReportITSuggestion />;
  }

  return {
    Good: <GoodTroubleShootingReportITSuggestion />,
    Poor: <BadTroubleShootingReportITSuggestion />,
    Bad: <BadTroubleShootingReportITSuggestion />,
  }[criticalityReport.criticality];
};
