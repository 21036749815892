import { Box, Button, useTheme } from "@mui/material";
import React, { FC } from "react";

export interface RemoveSpotlightButtonProps {
  isVideoSpotlighted: boolean;
  handleSpotlightRemoved: () => void;
}

export const RemoveSpotlightButton: FC<RemoveSpotlightButtonProps> = ({
  isVideoSpotlighted,
  handleSpotlightRemoved,
}) => {
  const { colors } = useTheme();

  if (!isVideoSpotlighted) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        maxWidth: "80%",
        height: "32px",
        right: "8px",
        top: "8px",
        zIndex: 1,
      }}
    >
      <Button
        data-testid="remove-spotlight-button"
        onClick={handleSpotlightRemoved}
        sx={{
          color: "white",
          bgcolor: colors.BlackA500,
          borderRadius: "4px",
          "&:hover": {
            background: colors.BlackA900,
          },
        }}
      >
        Remove spotlight
      </Button>
    </Box>
  );
};
