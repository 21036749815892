import * as t from "io-ts";
import { makeRoute } from "./api";
import { empty } from "./common";

const healthStatus = t.type({
  status: t.string,
});

export const get = makeRoute("/health", empty, healthStatus);

export type HealthStatus = t.TypeOf<typeof healthStatus>;
