//mostly copied from dashboard-frontend
import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { augmentObjectWithSequentialID } from "../common/augmentObjectWithLegacyID";
import { FullSession } from "@proximie/api";

export const useSession = (
  sessionId: string | undefined | null,
  opts?: SWRConfiguration,
) => {
  const getSession = async (): Promise<FullSession> => {
    const session = (
      await axios.get<FullSession>(
        `${environment.apiUrl}/api/v2/sessions/${sessionId}`,
      )
    ).data;

    const participantsUUID = [session.session.owner];

    const augmentParticipantWithSequantialID =
      await augmentObjectWithSequentialID(participantsUUID);

    session.session.owner.profileId = augmentParticipantWithSequantialID.find(
      (participant) => participant.id === session.session.owner.id,
    )?.profileId;

    return {
      ...session,
      session: {
        ...session.session,
        id: session.id,
      },
    };
  };

  const { data, error, mutate } = useSWR<FullSession>(
    sessionId ? `sessions/${sessionId}` : null,
    getSession,
    opts,
  );

  const endSession = async (): Promise<void> => {
    await axios.delete(`${environment.apiUrl}/api/v2/sessions/${sessionId}`);
    await mutate();
  };

  const inviteParticipant = async (participantId: string): Promise<void> => {
    await axios.patch(
      `${environment.apiUrl}/api/v2/sessions/${sessionId}/participants`,
      { participants: [{ id: participantId, role: "PARTICIPANT" }] },
    );
    await mutate();
  };

  return {
    ...data,
    sessionId: data?.id,
    isSessionLoading: !error && !data,
    isSessionError: error,
    endSession,
    inviteParticipant,
  };
};
