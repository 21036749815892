/* eslint-disable @typescript-eslint/no-explicit-any */
import Connection from "../Connection";
import EndpointJanus from "../../Endpoints/Janus/EndpointJanus";
import Endpoint, { EndpointType } from "../../Endpoints/Endpoint";
import { JanusJS } from "@proximie/janus-gateway";
import ConnectionMetadata from "../../../models/ConnectionMetadata";

export default abstract class ConnectionJanus extends Connection {
  protected handle: JanusJS.PluginHandle | null = null;
  protected abstract attachOptions: JanusJS.PluginOptions;
  private intervalId: ReturnType<typeof setInterval> | null = null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  protected parseParticipantsList: Function | null = null;
  protected abstract join(params: ConnectionMetadata): Promise<void>;
  protected createRoom = false;

  protected validateIceCandidates(): void {
    let iceCandidatesGenerated = false;

    if (!this.pc) {
      return;
    }

    this.pc.addEventListener("icecandidate", (): void => {
      iceCandidatesGenerated = true;
    });

    this.pc.addEventListener(
      "icegatheringstatechange",
      (event: Event): void => {
        if (
          (event.target as any).iceGatheringState === "complete" &&
          !iceCandidatesGenerated
        ) {
          console.warn(
            {
              streamId: this.streamId,
            },
            "No ICE candidates have been generated - closing connection",
          );
          this.close(new Error("No ICE candidates"));
        }
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected defaultAttachCallbacks: { [key: string]: Function } = {
    onmessage: (message: JanusJS.Message): void => {
      console.debug(
        {
          streamId: this.streamId,
        },
        "message",
        message,
      );
    },
    onlocalstream: this.onLocalStream.bind(this),
    onremotestream: this.onRemoteStream.bind(this),
    oncleanup: (): void => {
      console.debug(
        {
          streamId: this.streamId,
        },
        "oncleanup",
      );
      if (this.handle) {
        // in case we missed the initial event - close the connection
        this.close();
      }
    },
    detached: (): void => {
      console.debug(
        {
          streamId: this.streamId,
        },
        "detached",
      );
      if (this.handle) {
        // in case we missed the initial event - close the connection
        this.close();
      }
    },
    error: (error: any): void => {
      console.warn(
        {
          streamId: this.streamId,
        },
        "Error from connection=",
        error,
      );
      this.close(new Error(error));
    },
    consentDialog: (on: boolean): void => {
      console.debug(
        {
          streamId: this.streamId,
        },
        `consentDialog=${on}`,
      );
    },
    webrtcState: (isConnected: boolean): void => {
      console.debug(
        {
          streamId: this.streamId,
        },
        `webrtcState=${isConnected}`,
      );
      if (isConnected && this.handle) {
        this.quality?.start(this.streamId, this.handle);
      } else {
        this.quality?.stop();
      }
    },
    iceState: (state: "connected" | "failed"): void => {
      console.debug(
        {
          streamId: this.streamId,
        },
        `iceState=${state}`,
      );
    },
    mediaState: (media: "audio" | "video", state: boolean): void => {
      console.debug(
        {
          streamId: this.streamId,
        },
        `mediaState ${media} ${state}`,
      );
    },
    slowLink: (state: { uplink: boolean }): void => {
      console.debug(
        {
          streamId: this.streamId,
        },
        `slowLink=${state}`,
      );
    },
  };

  protected onLocalStream(stream: MediaStream): void {
    console.debug(
      {
        streamId: this.streamId,
      },
      "ConnectionJanus:onLocalStream",
      stream,
    );

    this.validateIceCandidates();

    this.emit("connected", stream);
  }

  protected onRemoteStream(stream: MediaStream): void {
    console.debug(
      {
        streamId: this.streamId,
      },
      "ConnectionJanus:onRemoteStream",
      stream,
    );

    this.validateIceCandidates();

    this.emit("connected", stream);
  }

  override async open(): Promise<void> {
    const endpoint = this.endpoints[EndpointType.Video];
    if (!endpoint) {
      throw new Error("No video endpoint");
    }
    return this.startJanus(endpoint);
  }

  private endpointReconnectedHandler = () => {
    console.warn(
      {
        streamId: this.streamId,
      },
      "Room re-connected - synchronise participant list",
    );
    this.requestParticipants();
  };

  protected async startJanus(endpoint: Endpoint): Promise<void> {
    console.debug({ streamId: this.streamId }, "startJanus");

    if (!(endpoint instanceof EndpointJanus)) {
      console.warn({ streamId: this.streamId }, "Not a Janus endpoint");
      return;
    }

    if (this.handle) {
      throw new Error("Connection already open");
    }

    this.handle = await endpoint.attach(this.attachOptions);

    if (this.createRoom) {
      await endpoint.create(this.handle);
    }

    await this.join(this.params);

    //TODO -don't bother doing that here - move it to audio
    if (this.parseParticipantsList) {
      // if parseParticipantsList exists then we have the concept of a participant
      // list in the connection so we should ensure that we synchronise:
      // 1) When the connection to Janus is re-established
      // 2) Every x seconds (just to be sure)
      endpoint.on("reconnected", this.endpointReconnectedHandler);

      this.intervalId = setInterval(() => {
        // poll the participants every 30s in case we missed any
        this.requestParticipants();
      }, 30000);
    }
  }

  override async close(error?: Error): Promise<void> {
    console.debug("ConnectionJanus:close - handle=", !!this.handle);
    return new Promise<void>((resolve, reject) => {
      if (!this.handle) {
        // we've already closed the connection
        //LATER - we could have a race condition here
        return resolve();
      }

      //LATER - EndpointType should be either Janus, DCP or Virtual
      const endpoint =
        this.endpoints[EndpointType.Audio] ||
        this.endpoints[EndpointType.Video] ||
        this.endpoints[EndpointType.Echo];
      if (endpoint) {
        endpoint.off("reconnected", this.endpointReconnectedHandler);
      }

      this.quality?.stop();

      // ensure that close() cannot be called multiple times by clearing handle
      const tmpHandle = this.handle;
      this.handle = null;

      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
        this.stream = null;
      }

      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }

      tmpHandle.hangup();

      tmpHandle.detach({
        success: () => {
          this.handle = null;
          resolve();
        },
        error: (myError: any) => {
          // if we got an error while detaching then that is likely to imply that something
          // really bad happened - so pass on the error
          console.debug("ConnectionJanus: close detach - error=", myError);
          reject(new Error(myError));
        },
      });
    })
      .catch((myError: Error) => {
        console.warn("Failed to close connection - error=", myError);
        error = error || myError;
      })
      .finally(() => {
        super.close(error);
      });
  }

  requestParticipants(): void {
    const request = {
      message: {
        request: "listparticipants",
        room: this.mediaSessionId || "",
      },
      success: (result: { participants: [] }) => {
        if (this.parseParticipantsList) {
          this.parseParticipantsList(result.participants);
        }
      },
      error: (error: any) => {
        console.warn("Error getting participants - error=", error);
      },
    };
    this.handle && this.handle.send(request);
  }

  get pc(): RTCPeerConnection {
    return this.handle?.webrtcStuff.pc as unknown as RTCPeerConnection;
  }
}
