import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface RecordingHasNoVideosExceptionMeta {
  recordingId: string;
}

export class RecordingHasNoVideosException extends BaseException {
  constructor(meta: RecordingHasNoVideosExceptionMeta) {
    super("Recording has no videos", meta);

    this.code = ExceptionCodes.RECORDING_NOT_FOUND;
  }
}
