import Button from "@mui/material/Button";
import React, { useCallback } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useSessionUserSettings } from "../../hooks/useSessionUserSettings/useSessionUserSettings";
import { FormControlLabel, FormGroup } from "@mui/material";
import { useDialogContext } from "@proximie/components";

export interface SpotlightDialogProps {
  isVideoSpotlighted: boolean;
  handleAction: () => void;
  handleClose: () => void;
}
export const useSpotlightDialog = () => {
  const { showDialog, closeDialog } = useDialogContext();

  const { updateUserSettings } = useSessionUserSettings();

  const handleActionButtonClicked = useCallback(
    (
      { isVideoSpotlighted, handleAction, handleClose }: SpotlightDialogProps,
      isCheckboxChecked: boolean,
    ) => {
      handleAction();
      if (isCheckboxChecked) {
        isVideoSpotlighted
          ? updateUserSettings({
              spotlight: {
                showConfirmRemoveSpotlightDialog: false,
              },
            })
          : updateUserSettings({
              spotlight: {
                showConfirmAddSpotlightDialog: false,
              },
            });
      }
      handleClose();
    },
    [updateUserSettings],
  );

  return {
    showSpotlightDialog: ({
      isVideoSpotlighted,
      handleAction,
      handleClose,
    }: SpotlightDialogProps) => {
      let isCheckboxChecked = false;
      showDialog({
        onClose: handleClose,
        title: isVideoSpotlighted
          ? "Remove spotlight for everyone in the session?"
          : "Spotlight video feed for everyone in the session?",
        contentType: "COMPLEX",
        content: (
          <FormGroup>
            <FormControlLabel
              onChange={() => {
                isCheckboxChecked = !isCheckboxChecked;
              }}
              value={isCheckboxChecked}
              control={
                <Checkbox
                  color="secondary"
                  inputProps={{ "aria-label": "controlled" }}
                  data-testid="spotlight-dialog-do_not_show_again-checkbox"
                />
              }
              label="Don't show this again"
            />
          </FormGroup>
        ),
        actions: (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                handleActionButtonClicked(
                  {
                    isVideoSpotlighted,
                    handleAction,
                    handleClose,
                  },
                  isCheckboxChecked,
                )
              }
              size={"large"}
              data-testid="spotlight-dialog-action-button"
              data-cy="spotlight-dialog-action-button"
            >
              {isVideoSpotlighted ? "Remove spotlight" : "Spotlight video feed"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              data-testid="spotlight-dialog-cancel-button"
              size={"large"}
            >
              Cancel
            </Button>
          </>
        ),
      });
    },
    closeSpotlightDialog: closeDialog,
  };
};
