import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface NotAllowedToResendInvitationExceptionMeta {
  userId: number;
}

export class NotAllowedToResendInvitationException extends BaseException {
  constructor(
    message = "Not allowed to resend invitation",
    meta?: NotAllowedToResendInvitationExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_RESEND_INVITATION;
  }
}
