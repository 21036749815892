import { ModeType } from "./Quality";
import {
  OUTBOUND_SCREENSHARE_QUALITY_LIMITS,
  ENDPOINT_CONFIG,
} from "../../Constants";
import QualityVideoOutgoing, {
  QualityLevel,
  SimulcastLayers,
} from "./QualityVideoOutgoing";
import { JanusJS } from "@proximie/janus-gateway";

enum BitrateOptions {
  Low = 0,
  Lowish = 1,
  Highish = 2,
  High = 3,
}

export default class QualityVideoOutgoingScreenshare extends QualityVideoOutgoing {
  // bitrate for each level are defined as percentages of the connection bitrate
  private getBitrateByLevel(level: BitrateOptions): number {
    const bitrate = this.options.bitrate ?? ENDPOINT_CONFIG.video.bitrate;

    switch (level) {
      case BitrateOptions.Low:
        return bitrate * (500000 / bitrate);
      case BitrateOptions.Lowish:
        return bitrate * (1000000 / bitrate);
      case BitrateOptions.Highish:
        return bitrate * (1500000 / bitrate);
      case BitrateOptions.High:
        return bitrate * (2000000 / bitrate);
    }
  }

  public override start(streamId: string, handle: JanusJS.PluginHandle) {
    super.start(streamId, handle);

    this.setBitrate(BitrateOptions.High);
  }

  /**
   * We get the stream at 24 FPS to define the max "Capability" of the stream,
   * then we change it to 12 to define the default "constraint"
   * that will be used to re-requestOutgoingVideoQuality after the stream starts.
   * this step will re-set the framerate to 12 in constraints and actual stream
   * and allow changing FPS between 6 and 24.
   */
  private constraints: MediaStreamConstraints = {
    audio: false,
    video: {
      frameRate: { max: 12, ideal: 12 },
      cursor: "always",
    },
  } as MediaStreamConstraints;

  static override getInitialConstraints(): MediaStreamConstraints {
    return {
      audio: false,
      video: {
        frameRate: { max: 24, ideal: 24 },
        // Typescript definition do not define cursor
        cursor: "always",
      },
    } as unknown as MediaStreamConstraints;
  }

  public controls = {
    mode: {
      value: ModeType.Automatic,
      options: [
        { value: ModeType.Manual, label: "Manual" },
        { value: ModeType.Automatic, label: "Automatic" },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoInCam: auto value=",
          newValue,
        );
        this.controls.mode.value = newValue;
        this.reset();
      },
    },
    bitrate: {
      value: BitrateOptions.High,
      options: [
        {
          value: BitrateOptions.High,
          label: String(
            Math.round(this.getBitrateByLevel(BitrateOptions.High)),
          ),
        },
        {
          value: BitrateOptions.Highish,
          label: String(
            Math.round(this.getBitrateByLevel(BitrateOptions.Highish)),
          ),
        },
        {
          value: BitrateOptions.Lowish,
          label: String(
            Math.round(this.getBitrateByLevel(BitrateOptions.Lowish)),
          ),
        },
        {
          value: BitrateOptions.Low,
          label: String(Math.round(this.getBitrateByLevel(BitrateOptions.Low))),
        },
      ],
      onChange: async (newValue: number): Promise<void> => {
        console.debug(
          {
            streamId: this.streamId,
          },
          "QualityVideoInScr: bitrate value=",
          newValue,
        );
        if (newValue < BitrateOptions.Low || newValue > BitrateOptions.High) {
          console.warn(
            {
              streamId: this.streamId,
            },
            "QualityVideoInCam: invalid bitrate value=",
            newValue,
          );
          return;
        }
        if (!this.isAutoMode) {
          try {
            await this.setBitrate(newValue);
          } catch {
            //ignore error
          }
        }
      },
    },
  };

  private get isAutoMode() {
    return !!this.controls.mode.value;
  }

  protected limits = OUTBOUND_SCREENSHARE_QUALITY_LIMITS;

  get bitrate() {
    return this.controls.bitrate.value;
  }

  set bitrate(value: number) {
    const oldValue = this.controls.bitrate.value;
    this.controls.bitrate.value = value;
    this.emit("bitrate", oldValue, value);
    this.reset();
  }

  async setBitrate(value: number): Promise<void> {
    console.debug(
      {
        streamId: this.streamId,
      },
      "VideoOutScr:setBitrate - bitrate=",
      value,
    );

    const sender = (
      this.handle?.webrtcStuff.pc as unknown as RTCPeerConnection
    ).getSenders()?.[0];
    if (!sender) {
      throw new Error("No sender");
    }

    const parameters = sender.getParameters();

    parameters.encodings = [
      {
        active: true,
        maxBitrate: this.getBitrateByLevel(value),
      },
    ];

    parameters.degradationPreference = "maintain-resolution";

    await sender.setParameters(parameters);

    this.bitrate = value;
  }

  protected downgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    if (this.controls.bitrate.value === BitrateOptions.Low) {
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "OUTBOUND ALG downgrade",
      this.controls.bitrate.value,
    );

    this.reset();

    this.setBitrate(this.controls.bitrate.value - 1);

    return true;
  }

  protected upgrade(): boolean {
    if (!this.isAutoMode) {
      // we're not in automatic mode - ignore
      return false;
    }

    if (this.controls.bitrate.value >= BitrateOptions.High) {
      return false;
    }

    console.log(
      {
        streamId: this.streamId,
      },
      "OUTBOUND ALG upgrade",
      this.controls.bitrate.value,
    );

    this.reset();

    this.setBitrate(this.controls.bitrate.value + 1);

    return true;
  }

  // workaround to get at static initial constraints when we have the instance
  get initialConstraints(): MediaStreamConstraints {
    return QualityVideoOutgoingScreenshare.getInitialConstraints();
  }

  override isSimulcastActive(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): boolean {
    return false;
  }

  override getSimulcastBitrate(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): number {
    return 0;
  }

  override getSimulcastScale(
    _level: QualityLevel,
    _layer: SimulcastLayers,
  ): number {
    return 0;
  }
}
