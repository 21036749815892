/* eslint-disable sonarjs/no-duplicate-string */
import {
  Theme as MuiTheme,
  ThemeOptions as MuiThemeOptions,
} from "@mui/material";
import {
  DefaultFont,
  FontToString,
  fontWeights,
  SuisseIntlBold,
  SuisseIntlBoldItalic,
  SuisseIntlExtraBold,
  SuisseIntlExtraBoldItalic,
  SuisseIntlItalic,
  SuisseIntlMedium,
  SuisseIntlMediumItalic,
  SuisseIntlRegular,
  SuisseIntlSemiBold,
  SuisseIntlSemiBoldItalic,
  Typographys,
} from "../../typography";
import "@mui/lab/themeAugmentation";
import { colors as defaultColors, Colors } from "../../colors";
import { spacersAsString } from "../../spacers";
export interface Theme extends MuiTheme {
  colors: Colors;
}

export interface ThemeOptions extends MuiThemeOptions {
  colors?: Colors;
}

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    compact: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    auxilliary: Palette["primary"];
    tertiary: Palette["primary"];
  }

  interface PaletteOptions {
    auxilliary: PaletteOptions["primary"];
    tertiary: PaletteOptions["primary"];
  }

  interface Theme {
    colors: Colors;
  }

  interface ThemeOptions {
    colors?: Colors;
  }
}

export const generateTheme = (
  colors: Colors = defaultColors,
): MuiThemeOptions => {
  return {
    palette: {
      mode: "light",
      primary: {
        main: colors.BrandPrimary,
        light: colors.Brand700,
      },
      secondary: {
        main: colors.BrandSecondary,
        dark: colors.BrandSecondaryDark,
      },
      grey: {
        "50": colors.Grey50,
        "100": colors.Grey100,
        "200": colors.Grey200,
        "300": colors.Grey300,
        "400": colors.Grey400,
        "500": colors.Grey500,
        "600": colors.Grey600,
        "700": colors.Grey700,
        "800": colors.Grey800,
        "900": colors.Grey900,
      },
      info: {
        main: colors.Blue500,
        dark: colors.Blue500,
        contrastText: colors.White,
      },
      auxilliary: {
        main: colors.Green100,
        contrastText: colors.Green600,
      },
      tertiary: {
        main: colors.Brand50,
        contrastText: colors.Brand600,
      },
      background: {
        default: colors.Grey50,
      },
      text: {
        primary: colors.BlackHighEmphasis,
        secondary: colors.BlackMediumEmphasis,
      },
    },
    colors: colors,
    typography: {
      htmlFontSize: 10,
      fontFamily: DefaultFont,
      fontWeightLight: fontWeights.regular,
      fontWeightRegular: fontWeights.regular,
      fontWeightMedium: fontWeights.medium,
      fontWeightBold: fontWeights.semiBold,
      ...Typographys,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
      ${FontToString(SuisseIntlRegular)}
      ${FontToString(SuisseIntlBold)}
      ${FontToString(SuisseIntlBoldItalic)}
      ${FontToString(SuisseIntlExtraBold)}
      ${FontToString(SuisseIntlExtraBoldItalic)}
      ${FontToString(SuisseIntlItalic)}
      ${FontToString(SuisseIntlMedium)}
      ${FontToString(SuisseIntlMediumItalic)}
      ${FontToString(SuisseIntlSemiBold)}
      ${FontToString(SuisseIntlSemiBoldItalic)}
      html {
        font-size: 62.5%;
      }
      html,
      body,
      #root {
        height: 100%;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
      p {
        margin: 0;
      }
      li {
        list-style-type: none;
      }
      .uppercase {
        text-transform: uppercase;
      }
      `,
      },
      MuiMenu: {
        defaultProps: {
          MenuListProps: {
            disablePadding: true,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            lineHeight: 1.6923076923,
            padding: "9px 16px",
            boxShadow: "none",
          },
          sizeSmall: {
            lineHeight: 1.6923076923,
            padding: "7px 12px",
            fontSize: "13px",
          },
          outlinedSizeSmall: {
            padding: "6px 11px",
          },
          sizeMedium: {
            lineHeight: 1.7142857143,
            padding: "8px 16px",
            fontSize: "14px",
          },
          outlinedSizeMedium: {
            padding: "7px 15px",
          },
          sizeLarge: {
            lineHeight: 1.5,
            fontSize: "20px",
            padding: "12px 16px",
          },
          outlinedSizeLarge: {
            padding: "11px 15px",
          },
        },
        variants: [
          {
            props: { size: "compact" },
            style: {
              padding: `7px`,
              minWidth: 0,
            },
          },
        ],
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiDialog-paper": {
              padding: spacersAsString.lg,
            },
            "& .MuiDialog-paper > *": {
              padding: spacersAsString.none,
            },
            "& .MuiIconButton-root": {
              color: colors.BlackMediumEmphasis,
            },
            "& .MuiDialog-container .MuiPaper-root": {
              "@media (min-width: 600px)": {
                minWidth: "538px",
              },
            },
            "& .MuiDialogContent-root": {
              padding: spacersAsString.none,
            },
            "& .dialog-title": {
              flexWrap: "wrap",
            },
            "& .dialog-title + .MuiDialogContent-root": {
              marginTop: spacersAsString.sm,
            },
            "& .MuiDialogActions-root": {
              padding: spacersAsString.none,
              flexWrap: "wrap",
            },
            "& * + .MuiDialogActions-root": {
              marginTop: spacersAsString.xl,
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: "flex-start",
            fontWeight: fontWeights.regular,
            "& :not(:nth-of-type(1))": {
              marginLeft: "16px",
            },
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          switchBase: {
            padding: 1,
          },
          thumb: {
            boxShadow: "none",
            width: 18,
            height: 18,
            margin: 1,
            padding: 0,
            backgroundColor: colors.White,
          },
          track: {
            opacity: 1,
            borderRadius: 22 / 2,
            backgroundColor: colors.Grey500,
          },
          root: {
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: colors.Grey300,
            borderRadius: 24 / 2,
            width: 44,
            height: 24,
            padding: 1,
            margin: 1,
            "& .MuiSwitch-track": {
              "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 18,
                height: 18,
                opacity: 1,
              },
              "&:before": {
                background: `no-repeat fixed center url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.13046 6.26053L1.69796 3.82803L0.869629 4.65053L4.13046 7.91137L11.1305 0.911367L10.308 0.0888672L4.13046 6.26053Z" fill="white"/></svg>')`,
                left: 4,
              },
              "&:after": {
                background: `no-repeat fixed center url('data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.08334 1.73925L8.26084 0.916748L5 4.17758L1.73917 0.916748L0.916672 1.73925L4.17751 5.00008L0.916672 8.26091L1.73917 9.08341L5 5.82258L8.26084 9.08341L9.08334 8.26091L5.8225 5.00008L9.08334 1.73925Z" fill="white"/></svg>')`,
                right: 4,
              },
            },
            "& .MuiSwitch-switchBase": {
              "&.Mui-checked": {
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  backgroundColor: (t: MuiTheme) => t.palette.primary.main,
                },
              },
              "&.Mui-disabled": {
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  backgroundColor: colors.Grey300,
                },
              },
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            "& .MuiAlertTitle-root": { fontWeight: 600 },
            "& .MuiAlert-icon": { marginRight: "7px" },
          },
          filledSuccess: {
            backgroundColor: colors.Green600,
            color: "white",
            fontWeight: 400,
            fontSize: "14px",
          },
          filledInfo: {
            backgroundColor: colors.Brand500,
            color: "white",
            fontWeight: 400,
            fontSize: "14px",
          },
          filledWarning: {
            backgroundColor: colors.Amber400,
            color: "black",
            fontWeight: 400,
            fontSize: "14px",
          },
          filledError: {
            backgroundColor: "#D32F2F",
            color: "white",
            fontWeight: 400,
            fontSize: "14px",
          },
          outlinedSuccess: {
            backgroundColor: colors.Green50,
            border: `1px solid ${colors.Green300}`,
            color: colors.Green900,
            padding: "0 0 0 9px",
            svg: { color: colors.Green900 },
          },
          outlinedInfo: {
            backgroundColor: colors.Brand50,
            border: `1px solid ${colors.Brand200}`,
            color: colors.ProximieBlue,
            padding: "0 0 0 9px",
            svg: { color: colors.ProximieBlue },
          },
          outlinedWarning: {
            backgroundColor: colors.Amber50,
            border: `1px solid ${colors.Amber200}`,
            color: colors.BlackHighEmphasis,
            padding: "0 0 0 9px",
            svg: { color: colors.Amber500 },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            width: 24,
            height: 24,
            borderRadius: "50%",
            "&.Mui-active": {
              fill: colors.ProximieBlue,
              text: {
                fill: colors.White,
              },
            },
            "&.Mui-completed": {
              fill: colors.BlackMediumEmphasis,
              color: "transparent",
            },
          },
        },
      },
    },
  };
};
