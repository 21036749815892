import MonitorDeviceLocal from "./MonitorDeviceLocal";
import DeviceLocalCamera from "../Devices/DeviceLocalCamera";
import { MediaDeviceKind } from "../../../index";

export default class MonitorDeviceLocalCamera extends MonitorDeviceLocal {
  protected kind: MediaDeviceKind = "videoinput";

  protected override createDevice(info: MediaDeviceInfo): DeviceLocalCamera {
    return new DeviceLocalCamera(info.deviceId, {
      deviceInfo: info,
    });
  }
}
