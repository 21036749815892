import { makeNoContentRoute, makeRoute } from "./api";
import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import { nullable } from "io-ts/lib/Type";
import { MinimalProfilePresentation, ProfileNoRelations } from "./profiles";
import { empty } from "./common";
import { Organisation, shortOrganisationPresentation } from "./organisations";
import { shortSpecialityPresentation } from "./specialities";

const AppointmentAccess = t.type({
  id: t.number,
  name: t.string,
});

export enum MediaSessionStatus {
  SCHEDULED = "SCHEDULED",
  LAUNCHING = "LAUNCHING",
  ACTIVE = "ACTIVE",
  CLOSING = "CLOSING",
  CLOSED = "CLOSED",
}

const MediaSessionStatusIO = nullable(t.keyof(MediaSessionStatus));

export type MediaSessionStatusPresentation = t.TypeOf<
  typeof MediaSessionStatusIO
>;

export const createAppointmentRequest = t.type({
  title: t.string,
  scheduledDateAndTime: DateFromISOString,
  estimatedDuration: t.number,
  description: nullable(t.string),
  typeId: nullable(t.union([t.undefined, t.number])),
  speciality: nullable(t.union([t.undefined, t.string])),
  accessId: t.number,
  organisationId: t.number,
  invitedUsersEmails: t.array(t.string),
});

const UpdateSessionIdRequest = t.type({
  appointmentId: t.number,
  mediaSessionId: t.string,
});

export const updateAppointmentRequest = t.type({
  appointmentId: t.number,
  title: t.string,
  scheduledDateAndTime: DateFromISOString,
  estimatedDuration: t.number,
  description: nullable(t.string),
  typeId: nullable(t.union([t.undefined, t.number])),
  speciality: nullable(t.union([t.undefined, t.string])),
  accessId: t.number,
  invitedUsersEmails: t.array(t.string),
});

export const deleteAppointmentRequest = t.type({
  appointmentId: t.number,
});

const DateResolutionType = t.keyof({
  Day: "day",
  Month: "month",
  All: "all",
});

// TODO: remove nullable when implemented the backend part of get appointment list filtering
export const getAppointmentsRequest = t.partial({
  startScheduledDate: t.union([t.undefined, DateFromISOString]),
  dateResolution: t.union([t.undefined, DateResolutionType]),
});

const AppointmentPresentationShared = {
  id: t.number,
  appointmentId: t.string,
  title: t.string,
  scheduledDateAndTime: nullable(DateFromISOString),
  estimatedDuration: t.number,
  description: nullable(t.string),
  ownerProfile: ProfileNoRelations,
  type: shortSpecialityPresentation,
  access: AppointmentAccess,
  numberOfParticipants: t.number,
  numberOfInvited: t.number,
  numberOfAccepted: t.number,
  ownerOrganisation: t.type({
    id: t.number,
    name: t.string,
  }),
  editedAt: nullable(DateFromISOString),
};

const AppointmentPresentation = t.type({
  ...AppointmentPresentationShared,
  ownerOrganisation: nullable(Organisation),
  invitationStatus: t.string,
  invitationId: t.number,
  sessionStatus: nullable(t.string),
});

export const AppointmentWithInvitationsPresentation = t.type({
  ...AppointmentPresentationShared,
  ownerOrganisation: nullable(shortOrganisationPresentation),
  invitedProfiles: t.array(ProfileNoRelations),
  createdBy: MinimalProfilePresentation,
  updatedBy: MinimalProfilePresentation,
  createdAt: DateFromISOString,
  updatedAt: DateFromISOString,
  lastUpdateDuration: t.string,
  sessionStatus: nullable(t.string),
});

const mediaSessionUrl = t.type({
  mediaSessionUrl: t.string,
});

export const create = makeRoute(
  "/appointments",
  createAppointmentRequest,
  AppointmentPresentation,
);

export const list = makeRoute(
  "/appointments",
  getAppointmentsRequest,
  t.array(AppointmentPresentation),
);

export const get = makeRoute(
  // eslint-disable-next-line sonarjs/no-duplicate-string
  "/appointments/:id",
  empty,
  AppointmentWithInvitationsPresentation,
);

export const m2mGet = makeRoute(
  "/m2m/appointments/:id",
  empty,
  AppointmentWithInvitationsPresentation,
);

export const getMediaSessionUrl = makeRoute(
  "/appointments/:id/media-session-url",
  empty,
  mediaSessionUrl,
);

export const updateSessionId = makeNoContentRoute(
  "/m2m/appointments/:id/update-session-id",
  UpdateSessionIdRequest,
);

export const update = makeNoContentRoute(
  // eslint-disable-next-line sonarjs/no-duplicate-string
  "/appointments/:id",
  updateAppointmentRequest,
);

export const deleteAppointment = makeNoContentRoute(
  // eslint-disable-next-line sonarjs/no-duplicate-string
  "/appointments/:id",
  deleteAppointmentRequest,
);

export type DateResolution = t.TypeOf<typeof DateResolutionType>;
export type createAppointmentRequest = t.TypeOf<
  typeof createAppointmentRequest
>;
export type getAppointmentsRequest = t.TypeOf<typeof getAppointmentsRequest>;

export type mediaSessionUrl = t.TypeOf<typeof mediaSessionUrl>;

export type updateAppointmentRequest = t.TypeOf<
  typeof updateAppointmentRequest
>;
export type deleteAppointmentRequest = t.TypeOf<
  typeof deleteAppointmentRequest
>;
export type AppointmentPresentation = t.TypeOf<typeof AppointmentPresentation>;
export type UpdateSessionIdRequest = t.TypeOf<typeof UpdateSessionIdRequest>;
export type AppointmentWithInvitationsPresentation = t.TypeOf<
  typeof AppointmentWithInvitationsPresentation
>;
