import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class EndpointNotInitializedException extends BaseException {
  constructor(message = "Endpoint not initialised", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.ENDPOINT_NOT_INITIALIZED;
  }
}
