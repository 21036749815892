import { SvgIcon as MuiSvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const SpotlightIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.13574 21.5911H17.0451V3.40894H1.13574V21.5911ZM23.8634 7.95447H19.3179V3.40894H23.8634V7.95447ZM23.8634 21.5911H19.3179V17.0455H23.8634V21.5911ZM23.8634 14.7728H19.3179V10.2272H23.8634V14.7728ZM7.10433 15.5776L5.11566 13.731V10.6533H13.0703V13.731L11.0817 15.5776V18.6554H7.10433V15.5776ZM9.75589 6.34453H8.43011V8.19115H9.75589V6.34453ZM4.39311 7.86492L3.45843 8.73283L4.86376 10.0378L5.79844 9.16986L4.39311 7.86492ZM13.7863 7.86492L12.3809 9.16986L13.3222 10.0378L14.7209 8.73283L13.7863 7.86492Z"
      />
    </MuiSvgIcon>
  );
};
