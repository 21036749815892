import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Fade,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";

export interface PTZTooltipAction {
  text: string;
  action: () => void;
  dataTestId?: string;
}

const variants = {
  up: {
    borderRadius: "8px",
  },
  none: {
    borderRadius: "0px 0px 8px 8px",
  },
  down: {
    borderRadius: "8px",
  },
};

export type PTZTooltipVariants = keyof typeof variants;

export interface PTZTooltipProps {
  text: string;
  autoHandoverDuration?: number;
  showAutoHandoverDuration?: boolean;
  variant: PTZTooltipVariants;
  actions?: PTZTooltipAction[];
  anchorEl?: HTMLElement | null;
  boundaryElement?: HTMLElement | null;
}

const MIN_WIDTH = 304;
const MAX_WIDTH = 400;

export const PTZTooltip = ({
  text,
  autoHandoverDuration,
  actions,
  variant,
  showAutoHandoverDuration,
  anchorEl,
  boundaryElement,
  ...props
}: // eslint-disable-next-line sonarjs/cognitive-complexity
PTZTooltipProps & Omit<TooltipProps, "title" | "children">) => {
  const { colors, typography } = useTheme();

  const [internalVariant, setInternalVariant] =
    useState<PTZTooltipVariants>("none");

  const computedMaxWidth = useMemo(
    () => (actions && !!actions.length ? MAX_WIDTH : MIN_WIDTH),
    [actions],
  );

  useEffect(() => {
    const handleResize = () => {
      if (!anchorEl) {
        return;
      }

      if (anchorEl.offsetWidth < computedMaxWidth) {
        setInternalVariant(variant);
      } else {
        setInternalVariant("none");
      }
    };

    window.addEventListener("spotlight-resize", handleResize);
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("spotlight-resize", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [anchorEl, variant, computedMaxWidth]);

  const component = (
    <Box
      data-testid={"ptz-permission-label"}
      style={{
        position: internalVariant !== "none" ? "inherit" : "absolute",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        background: colors.Black,
        color: colors.WhiteA900,
        maxWidth: `${computedMaxWidth}px`,
        ...typography.body2,
        ...variants[internalVariant],
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "8px 16px",
          gap: "4px",
        }}
      >
        <Typography>{text}</Typography>
        {showAutoHandoverDuration && !!autoHandoverDuration && (
          <Box
            data-testid={"auto-handover-timer"}
            style={{
              padding: "2px 4px",
              background: colors.WhiteA400,
              color: colors.WhiteA700,
            }}
          >
            <Typography>Auto handover: {autoHandoverDuration}</Typography>
          </Box>
        )}
      </Box>
      <Divider
        flexItem
        orientation="vertical"
        sx={{ backgroundColor: colors.WhiteA400 }}
      />
      {actions && !!actions.length && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="stretch"
        >
          {actions.map(
            ({ text: actionText, action, dataTestId }, index, array) => (
              <React.Fragment key={index}>
                <Button
                  data-testid={dataTestId}
                  onClick={action}
                  fullWidth
                  sx={{
                    borderRadius: 0,
                    height: "100%",
                    color: colors.WhiteA900,
                    "&:hover": {
                      background: colors.WhiteA400,
                    },
                  }}
                >
                  {actionText}
                </Button>
                {index < array.length - 1 && (
                  <Divider
                    flexItem
                    sx={{ backgroundColor: colors.WhiteA400 }}
                  />
                )}
              </React.Fragment>
            ),
          )}
        </Box>
      )}
    </Box>
  );

  if (!props.open) {
    return null;
  }

  return internalVariant !== "none" ? (
    <Tooltip
      {...props}
      placement={internalVariant === "up" ? "top" : "bottom"}
      title={component}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            padding: "0",
            background: "transparent",
            marginY: "6px !important",
            maxWidth:
              actions && !!actions.length ? `${MAX_WIDTH}px` : `${MIN_WIDTH}px`,
          },
        },
        arrow: {
          sx: {
            color: colors.Black,
          },
        },
        popper: {
          anchorEl: anchorEl,
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                boundary: boundaryElement,
              },
            },
          ],
        },
      }}
    >
      <span />
    </Tooltip>
  ) : (
    <Fade in={true}>{component}</Fade>
  );
};
