import React, { FC, useEffect, useState } from "react";
import { Radio as MuiRadio } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box } from "@mui/system";
import { Typography } from "../../index";

export interface RadioItem {
  value: string | number;
  label: string;
  description?: string;
}

type Size = "small" | "medium";

export interface RadioProps {
  label?: string;
  value?: string | number;
  row?: boolean;
  size?: Size;
  list: RadioItem[];
  onChange: RadioOnChange;
  disabled?: boolean;
}

export type RadioOnChange = (value: string) => void;

const RadioItemToFormControlLabel = (
  item: RadioItem,
  index: number,
  label?: string,
  size?: Size,
) => {
  const control = (
    <MuiRadio
      data-testid={
        label ? `radio-${label}-${item.label}` : `radio-${item.label}`
      }
      sx={({ colors }) => ({
        fill: `${colors.Black}!important`,
        color: `${colors.Black}!important`,
      })}
    />
  );

  return (
    <Box key={index}>
      <FormControlLabel
        value={item.value}
        control={control}
        label={item.label}
        aria-label={item.label}
        sx={{
          ".MuiRadio-root + .MuiTypography-root": {
            fontWeight: size === "small" ? "400" : "600!important",
          },
        }}
      />
      {item.description && (
        <Box sx={{ paddingLeft: "2em", marginBottom: "1em" }}>
          <Typography key={index} variant="body2" color="BlackA700">
            {item.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const Radio: FC<RadioProps> = ({ ...props }: RadioProps) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /*
     * Material-UI: A component is changing the uncontrolled value state of RadioGroup to be controlled.
     * Elements should not switch from uncontrolled to controlled (or vice versa).
     * Decide between using a controlled or uncontrolled RadioGroup element for the lifetime of the component.
     * The nature of the state is determined during the first render, it's considered controlled if the value is not `undefined`.
     *
     * To keep inline with this rule, we only use the local state if conditional !!props.value
     */
    if (props.value) {
      setValue((event.target as HTMLInputElement).value);
    }
    props.onChange((event.target as HTMLInputElement).value);
  };
  return (
    <FormControl component="fieldset" disabled={props.disabled}>
      {props.label && (
        <FormLabel
          sx={({ colors }) => ({
            color: `${colors.BlackA700}!important`,
            marginBottom: props.size === "small" ? "8px" : "2em",
          })}
          component="legend"
        >
          {props.label}
        </FormLabel>
      )}
      <RadioGroup
        value={value}
        row={props.row}
        aria-label="radio-list"
        name="radio-list"
        onChange={handleChange}
      >
        {props.list.map((item: RadioItem, index: number) =>
          RadioItemToFormControlLabel(item, index, props.label, props.size),
        )}
      </RadioGroup>
      {props.list.length === 0 && (
        <Typography variant="body1">Selection list is empty</Typography>
      )}
    </FormControl>
  );
};

export default Radio;
