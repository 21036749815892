import { Font } from "../models/font";
import { Typography, TypographyVariants } from "../models/typography";
import Fonts from "../assets/fonts/index";
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const fontWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 900,
};

export type FontWeightName = keyof typeof fontWeights;

const SuisseIntlRegularURL = Fonts.Regular;
const SuisseIntlItalicURL = Fonts.Italic;
const SuisseIntlMediumURL = Fonts.Medium;
const SuisseIntlBoldURL = Fonts.Bold;
const SuisseIntlMediumItalicURL = Fonts.MediumItalic;
const SuisseIntlSemiBoldURL = Fonts.SemiBold;
const SuisseIntlSemiBoldItalicURL = Fonts.SemiBoldItalic;
const SuisseIntlBoldItalicURL = Fonts.BoldItalic;
const SuisseIntlExtraBoldURL = Fonts.Black;
const SuisseIntlExtraBoldItalicURL = Fonts.BlackItalic;

export const SuisseIntlRegular: Font = {
  fontFamily: "SuisseIntl",
  fontWeight: fontWeights.regular,
  fontStyle: "normal",
  src: `url('${SuisseIntlRegularURL}') format('truetype')`,
};

export const SuisseIntlItalic: Font = {
  fontFamily: "SuisseIntl",
  fontStyle: "italic",
  fontWeight: fontWeights.regular,
  src: `url('${SuisseIntlItalicURL}') format('truetype')`,
};

export const SuisseIntlMedium: Font = {
  fontFamily: "SuisseIntl",
  fontWeight: fontWeights.medium,
  fontStyle: "normal",
  src: `url('${SuisseIntlMediumURL}') format('truetype')`,
};

export const SuisseIntlMediumItalic: Font = {
  fontFamily: "SuisseIntl",
  fontWeight: fontWeights.medium,
  fontStyle: "italic",
  src: `url('${SuisseIntlMediumItalicURL}') format('truetype')`,
};

export const SuisseIntlSemiBold: Font = {
  fontFamily: "SuisseIntl",
  fontStyle: "normal",
  fontWeight: fontWeights.semiBold,
  src: `url('${SuisseIntlSemiBoldURL}') format('truetype')`,
};

export const SuisseIntlSemiBoldItalic: Font = {
  fontFamily: "SuisseIntl",
  fontStyle: "italic",
  fontWeight: fontWeights.semiBold,
  src: `url('${SuisseIntlSemiBoldItalicURL}') format('truetype')`,
};

export const SuisseIntlBold: Font = {
  fontFamily: "SuisseIntl",
  fontStyle: "normal",
  fontWeight: fontWeights.bold,
  src: `url('${SuisseIntlBoldURL}') format('truetype')`,
};

export const SuisseIntlBoldItalic: Font = {
  fontFamily: "SuisseIntl",
  fontStyle: "italic",
  fontWeight: fontWeights.bold,
  src: `url('${SuisseIntlBoldItalicURL}') format('truetype')`,
};

export const SuisseIntlExtraBold: Font = {
  fontFamily: "SuisseIntl",
  fontStyle: "normal",
  fontWeight: fontWeights.extraBold,
  src: `url('${SuisseIntlExtraBoldURL}') format('truetype')`,
};

export const SuisseIntlExtraBoldItalic: Font = {
  fontFamily: "SuisseIntl",
  fontStyle: "italic",
  fontWeight: fontWeights.extraBold,
  src: `url('${SuisseIntlExtraBoldItalicURL}') format('truetype')`,
};

export const DefaultFont = `"SuisseIntl", Helvetica, Arial, sans-serif`;

export type TypographyTheme = Record<TypographyVariants, Typography> &
  TypographyOptions;

export const Typographys: TypographyTheme = {
  h1: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    fontSize: "96px",
    lineHeight: "112px",
    letterSpacing: "-0.015em",
  },
  h2: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    fontSize: "60px",
    lineHeight: "78px",
    letterSpacing: "-0.005em",
  },
  h3: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    fontSize: "48px",
    lineHeight: "62px",
    letterSpacing: "0",
  },
  h4: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    fontSize: "34px",
    lineHeight: "44px",
    letterSpacing: "0.0025em",
  },
  h5: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    fontSize: "24px",
    lineHeight: "31px",
    letterSpacing: "0",
  },
  h6: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.0015em",
  },
  subtitle1: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.semiBold,
    fontSize: "18px",
    lineHeight: "23px",
    letterSpacing: "0.0015em",
  },
  subtitle2: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.medium,
    fontSize: "16px",
    lineHeight: "21px",
    letterSpacing: "0.001em",
  },
  subtitle3: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.001em",
  },
  body1: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    fontSize: "16px",
    lineHeight: "21px",
    letterSpacing: "0.005em",
  },
  body2: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.0025em",
  },
  button: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.medium,
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.0125em",
    textTransform: "none",
  },
  caption: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.004em",
  },
  overline: {
    fontFamily: DefaultFont,
    fontStyle: "normal",
    fontWeight: fontWeights.regular,
    fontSize: "10px",
    lineHeight: "13px",
    letterSpacing: "0.015em",
  },
};

export const FontToString = (font: Font): string => {
  return `@font-face {
    font-family: ${font.fontFamily};
    font-weight: ${font.fontWeight};
    font-style: ${font.fontStyle};
    font-display: swap;
    src: ${font.src};
  }`;
};

export const TypoToCSS = (t: Typography): string => `
  font-family: ${t.fontFamily};
  font-style: ${t.fontStyle};
  font-weight: ${t.fontWeight};
  font-size: ${t.fontSize};
  line-height: ${t.lineHeight};
  letter-spacing: ${t.letterSpacing};
`;

const typographyVariants: TypographyVariants[] = Object.keys(
  Typographys,
) as unknown as TypographyVariants[];

export const TypographysCSS = typographyVariants.reduce(
  (acc, k: TypographyVariants) => {
    return {
      ...acc,
      [k]: TypoToCSS(Typographys[k]),
    };
  },
  {} as Record<TypographyVariants, string>,
);

export default {
  FontToString,
  SuisseIntlRegular,
  SuisseIntlBold,
  SuisseIntlBoldItalic,
  SuisseIntlExtraBold,
  SuisseIntlExtraBoldItalic,
  SuisseIntlItalic,
  SuisseIntlMedium,
  SuisseIntlMediumItalic,
  SuisseIntlSemiBold,
  SuisseIntlSemiBoldItalic,
  fontWeights,
  DefaultFont,
};
