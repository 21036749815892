import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface MicrophoneInUseExceptionMeta {
  originalError: Error;
}

export class MicrophoneInUseException extends BaseException {
  constructor(
    message = "Microphone in use",
    meta: MicrophoneInUseExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.MICROPHONE_IN_USE;
  }
}
