import React, { FC, PropsWithChildren, ReactNode } from "react";
import {
  FlagProvider,
  useFlag,
  useUnleashContext,
  useVariant,
  useFlagsStatus,
} from "@unleash/proxy-client-react";

const sharedConfig = {
  refreshInterval: 15,
};

interface ConfigInterface {
  clientKey: string;
  appName: string;
  url: string;
  environment?: string;
}

interface FeatureFlagProviderInterface {
  config: ConfigInterface;
  children: ReactNode;
}

export const FeatureFlagProvider: FC<
  PropsWithChildren<FeatureFlagProviderInterface>
> = ({ config, children }) => {
  return (
    <FlagProvider
      config={{
        ...sharedConfig,
        url: config.url,
        appName: config.appName,
        clientKey: config.clientKey,
        environment: config.environment || "production",
      }}
    >
      {children}
    </FlagProvider>
  );
};

export { useFlag, useVariant, useUnleashContext, useFlagsStatus };
