import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class InvalidRecordingMessageFormatException extends BaseException {
  constructor(message = "Invalid recording message format", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.INVALID_RECORDING_MESSAGE_FORMAT;
  }
}
