import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface Auth0AuthenticationExceptionMeta {
  originalError: Error;
}

export class Auth0AuthenticationException extends BaseException {
  constructor(
    message = "Failed to authenticate to auth0",
    meta: Auth0AuthenticationExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.AUTH0_FAILED_AUTHENTICATION;
  }
}
