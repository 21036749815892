import React from "react";
import { SvgIcon as MuiSvgIcon } from "@mui/material";
import { TroubleShootingIndicatorBarColors } from "../../TroubleShootingIndicator";

type SignalCellularAltProps = {
  color: string;
  barColors?: TroubleShootingIndicatorBarColors;
};

export const SignalCellularAlt = (
  { color, barColors }: SignalCellularAltProps = { color: "white" },
) => (
  <MuiSvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path d="M4.5 14.5H7.5V20.5H4.5V14.5Z" fill={barColors?.first ?? color} />
    <path d="M10.5 9.5H13.5V20.5H10.5V9.5Z" fill={barColors?.second ?? color} />
    <path d="M16.5 4.5H19.5V20.5H16.5V4.5Z" fill={barColors?.third ?? color} />
  </MuiSvgIcon>
);
