import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

export const RecordingIndicatorWrapper = styled.div<{ isActive: boolean }>`
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  line-height: 0;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;

export const pulse = keyframes`
  from, 10%, 90%, to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }
`;

export const RecordingIndicatorPulse = styled.div<{ isActive: boolean }>`
  width: 6px;
  height: 6px;
  margin: 0;
  padding: 0;
  line-height: 0;
  border-radius: 50%;
  box-sizing: border-box;
  transform-origin: center;
  background-color: currentColor;
  animation: ${({ isActive }) =>
    isActive
      ? css`
          ${pulse} 2s ease-in-out infinite
        `
      : "none"};
`;
