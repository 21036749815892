import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import { Typography, TypographyProps, useTheme } from "@mui/material";

type HasChildren = {
  children: ReactNode;
};

export const Subtitle2Typography = ({
  children,
  ...props
}: HasChildren & TypographyProps) => (
  <Typography
    fontFamily={"SuisseIntl"}
    fontSize={"16px"}
    fontStyle={"normal"}
    fontWeight={600}
    lineHeight={"normal"}
    letterSpacing={"0.016px"}
    {...props}
  >
    {children}
  </Typography>
);

export const Subtitle3Typography = forwardRef(
  function Subtitle3TypographyPreRef(
    { children, ...props }: HasChildren & TypographyProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) {
    return (
      <Typography
        fontFamily={"SuisseIntl"}
        fontSize={"14px"}
        fontStyle={"normal"}
        fontWeight={400}
        lineHeight={"normal"}
        letterSpacing={"0.014px"}
        ref={ref}
        {...props}
      >
        {children}
      </Typography>
    );
  },
);

export const DescriptionTypography = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { colors } = useTheme();
  return (
    <Subtitle3Typography
      letterSpacing={"0.035px"}
      color={colors.BlackMediumEmphasis}
    >
      {children}
    </Subtitle3Typography>
  );
};

export const TitleTypography = ({ children }: HasChildren) => {
  const { colors } = useTheme();

  return (
    <Subtitle3Typography fontWeight={600} color={colors.Grey900}>
      {children}
    </Subtitle3Typography>
  );
};

export const ValueTypography = ({ children }: HasChildren) => {
  const { colors } = useTheme();
  return (
    <Subtitle3Typography color={colors.BlackMediumEmphasis}>
      {children}
    </Subtitle3Typography>
  );
};
