import axios from "axios";
import { HasIdentifiers } from "../../models/HasIdentifiers";
import { environment } from "./../../../environments/environment";

export const augmentObjectWithSequentialID = async <T extends HasIdentifiers>(
  users: T[],
) => {
  const usersSequentialId = await axios.post(
    `${environment.api.baseUrl}/v1/uuid-to-int/`,
    {
      userIds: [...new Set(users?.map((m) => m.id))],
    },
  );

  return users?.map((user) => {
    return {
      ...user,
      profileId: usersSequentialId.data?.find(
        (obj: { sequentialId: number; userId: string }) => {
          return obj.userId === user.id;
        },
      ).sequentialId,
    } as T;
  });
};
