import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToAddAppointmentInvitationException extends BaseException {
  constructor(meta?: unknown) {
    super("Not allowed to add appointment invitation", meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_ADD_APPOINTMENT_INVITATION;
  }
}
