import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { Typography } from "@proximie/components";

interface SessionLoadingProps {
  children?: string;
}
const SessionLoading = ({ children }: SessionLoadingProps): JSX.Element => {
  return (
    <Box
      data-cy={"session-loader"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
      width={"100%"}
      textAlign={"center"}
    >
      <Box>
        <CircularProgress />
        <Box mt={2}>
          <Typography variant="body1">{children}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SessionLoading;
