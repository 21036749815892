import React, { useEffect, useState } from "react";

import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckIcon from "@mui/icons-material/Check";
import { UseServerAdapterContext } from "../../contexts/server-adapter-context";
import { FeatureFlags } from "../constants";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import DeviceLocalMicrophone from "../../../lib/ServerAdapter/Devices/DeviceLocalMicrophone";
import DeviceLocalSpeaker from "../../../lib/ServerAdapter/Devices/DeviceLocalSpeaker";

export interface AudioSettingsMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export enum MenuStates {
  audio = "Audio Settings",
  mic = "Microphone",
  speaker = "Speaker",
}

const overflowElipsis = {
  textOverflow: "ellipsis",
  overflow: "hidden",
};

const AudioSettingsMenu = ({ anchorEl, onClose }: AudioSettingsMenuProps) => {
  const serverAdapterContext = UseServerAdapterContext();
  const [state, setState] = useState<MenuStates>(MenuStates.audio);
  const [mics, setMics] = useState<DeviceLocalMicrophone[]>(
    serverAdapterContext?.mics ?? [],
  );
  const [speakers, setSpeakers] = useState<DeviceLocalSpeaker[]>(
    serverAdapterContext?.speakers ?? [],
  );
  const [echoCancellation, setEchoCancellation] = useState<boolean>(
    serverAdapterContext?.echoCancellation ?? false,
  );
  const [noiseSuppression, setNoiseSuppression] = useState<boolean>(
    serverAdapterContext?.noiseSuppression ?? false,
  );
  const [autoGainControl, setAutoGainControl] = useState<boolean>(
    serverAdapterContext?.autoGainControl ?? false,
  );
  const [localPlayback, setLocalPlayback] = useState<boolean>(
    serverAdapterContext?.localPlayback ?? false,
  );

  const theme = useTheme();

  const hasAudioMixer = useFlag(FeatureFlags.HAS_AUDIO_MIXER);

  useEffect(() => {
    setMics(serverAdapterContext?.mics ?? []);
  }, [serverAdapterContext?.mics]);
  useEffect(() => {
    setSpeakers(serverAdapterContext?.speakers ?? []);
  }, [serverAdapterContext?.speakers]);
  useEffect(() => {
    setEchoCancellation(serverAdapterContext?.echoCancellation ?? false);
  }, [serverAdapterContext?.echoCancellation]);
  useEffect(() => {
    setNoiseSuppression(serverAdapterContext?.noiseSuppression ?? false);
  }, [serverAdapterContext?.noiseSuppression]);
  useEffect(() => {
    setAutoGainControl(serverAdapterContext?.autoGainControl ?? false);
  }, [serverAdapterContext?.autoGainControl]);
  useEffect(() => {
    setLocalPlayback(serverAdapterContext?.localPlayback ?? false);
  }, [serverAdapterContext?.localPlayback]);

  useEffect(() => {
    !!anchorEl && setState(MenuStates.audio);
  }, [anchorEl]);

  const getMainMenu = () => {
    return (
      <>
        <MenuItem
          onClick={() => setState(MenuStates.mic)}
          data-testid="audio-settings-mic"
        >
          <ListItemIcon sx={{ paddingRight: "16px" }}>
            <MicIcon
              sx={{ color: theme.palette.text.primary }}
              fontSize="large"
            />
          </ListItemIcon>
          <ListItemText>
            <Typography fontWeight={500}>Microphone</Typography>
            {mics.length > 0 ? (
              mics.map((mic: DeviceLocalMicrophone) => (
                <Typography
                  sx={overflowElipsis}
                  key={mic.deviceId}
                  title={mic.label}
                  color={theme.palette.grey[700]}
                >
                  {mic.label}
                </Typography>
              ))
            ) : (
              <Typography color={theme.palette.grey[700]}>None</Typography>
            )}
          </ListItemText>
          <ListItemIcon sx={{ paddingLeft: "16px" }}>
            <ArrowForwardIosIcon
              sx={{ color: theme.palette.text.primary }}
              fontSize="small"
            />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          onClick={() => setState(MenuStates.speaker)}
          data-testid="audio-settings-speaker"
        >
          <ListItemIcon sx={{ paddingRight: "16px" }}>
            <VolumeUpIcon
              sx={{ color: theme.palette.text.primary }}
              fontSize="large"
            />
          </ListItemIcon>
          <ListItemText>
            <Typography fontWeight={500}>Speaker</Typography>
            {speakers.length > 0 ? (
              speakers.map((speaker: DeviceLocalSpeaker) => (
                <Typography
                  color={theme.palette.grey[700]}
                  sx={overflowElipsis}
                  key={speaker.deviceId}
                  title={speaker.label}
                >
                  {speaker.label}
                </Typography>
              ))
            ) : (
              <Typography color={theme.palette.grey[700]}>Default</Typography>
            )}
          </ListItemText>
          <ListItemIcon sx={{ paddingLeft: "16px" }}>
            <ArrowForwardIosIcon
              sx={{ color: theme.palette.text.primary }}
              fontSize="small"
            />
          </ListItemIcon>
        </MenuItem>
        {hasAudioMixer && (
          <>
            <MenuItem
              onClick={() =>
                serverAdapterContext?.setEchoCancellation(!echoCancellation)
              }
            >
              <ListItemIcon>
                {echoCancellation && (
                  <CheckIcon
                    sx={{ color: theme.palette.text.primary }}
                    fontSize="medium"
                  />
                )}
              </ListItemIcon>
              <Typography
                lineHeight={2}
                sx={overflowElipsis}
                title="Echo Cancellation"
              >
                Echo Cancellation
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                serverAdapterContext?.setNoiseSuppression(!noiseSuppression)
              }
            >
              <ListItemIcon>
                {noiseSuppression && (
                  <CheckIcon
                    sx={{ color: theme.palette.text.primary }}
                    fontSize="medium"
                  />
                )}
              </ListItemIcon>
              <Typography
                lineHeight={2}
                sx={overflowElipsis}
                title="Noise Suppression"
              >
                Noise Suppression
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                serverAdapterContext?.setAutoGainControl(!autoGainControl)
              }
            >
              <ListItemIcon>
                {autoGainControl && (
                  <CheckIcon
                    sx={{ color: theme.palette.text.primary }}
                    fontSize="medium"
                  />
                )}
              </ListItemIcon>
              <Typography
                lineHeight={2}
                sx={overflowElipsis}
                title="Auto Gain Control"
              >
                Auto Gain Control
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                serverAdapterContext?.setLocalPlayback(!localPlayback)
              }
            >
              <ListItemIcon>
                {localPlayback && (
                  <CheckIcon
                    sx={{ color: theme.palette.text.primary }}
                    fontSize="medium"
                  />
                )}
              </ListItemIcon>
              <Typography
                lineHeight={2}
                sx={overflowElipsis}
                title="Local Playback"
              >
                Local Playback
              </Typography>
            </MenuItem>
          </>
        )}
      </>
    );
  };

  const getMicMenu = () => {
    const myMics = (serverAdapterContext?.microphoneMonitor?.deviceList ||
      []) as DeviceLocalMicrophone[];

    return myMics.length > 0 ? (
      <>
        {myMics.map((item) => {
          const isSelected = !!mics.find(
            (mic: DeviceLocalMicrophone): boolean =>
              mic.deviceId === item.deviceId,
          );
          return (
            <MenuItem
              onClick={() => serverAdapterContext?.addOrRemoveMic(item)}
              key={item.deviceId}
              disabled={mics.length === 1 && isSelected}
            >
              <ListItemIcon>
                {isSelected && (
                  <CheckIcon
                    sx={{ color: theme.palette.text.primary }}
                    fontSize="medium"
                  />
                )}
              </ListItemIcon>
              <Typography
                lineHeight={2}
                sx={overflowElipsis}
                title={item.label}
              >
                {item.label}
              </Typography>
            </MenuItem>
          );
        })}
      </>
    ) : (
      <Typography lineHeight={2}>No audio input devices found...</Typography>
    );
  };

  const getSpeakerMenu = () => {
    const mySpeakers = (serverAdapterContext?.speakerMonitor?.deviceList ||
      []) as DeviceLocalSpeaker[];

    return mySpeakers.length > 0 ? (
      <>
        {mySpeakers.map((item) => {
          const isSelected = !!speakers.find(
            (speaker: DeviceLocalSpeaker): boolean =>
              speaker.deviceId === item.deviceId,
          );
          return (
            <MenuItem
              onClick={() => serverAdapterContext?.addOrRemoveSpeaker(item)}
              key={item.deviceId}
              disabled={speakers.length === 1 && isSelected}
            >
              <ListItemIcon>
                {isSelected && (
                  <CheckIcon
                    sx={{ color: theme.palette.text.primary }}
                    fontSize="medium"
                  />
                )}
              </ListItemIcon>
              <Typography
                lineHeight={2}
                sx={overflowElipsis}
                title={item.label}
              >
                {item.label}
              </Typography>
            </MenuItem>
          );
        })}
      </>
    ) : (
      <Typography lineHeight={2}>No audio output devices found...</Typography>
    );
  };

  return (
    <Menu
      data-testid="audio-settings-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Box
        sx={{
          minHeight: "181px",
          width: "480px",
        }}
      >
        <MenuItem
          onClick={() => setState(MenuStates.audio)}
          data-testid="audio-settings-title"
          sx={{ padding: "12px 16px" }}
          divider
        >
          {state !== MenuStates.audio && (
            <ListItemIcon>
              <ArrowBackIosIcon
                sx={{ color: theme.palette.text.primary }}
                fontSize="medium"
              />
            </ListItemIcon>
          )}
          <Typography fontWeight={"600"} lineHeight={2}>
            {state}
          </Typography>
        </MenuItem>

        {state === MenuStates.audio && getMainMenu()}

        {state === MenuStates.mic && getMicMenu()}

        {state === MenuStates.speaker && getSpeakerMenu()}
      </Box>
    </Menu>
  );
};

export default AudioSettingsMenu;
