import React from "react";
import { Chip as MuiChip, ChipProps as MuiChipProps } from "@mui/material";

export interface ChipProps extends Omit<MuiChipProps, "color"> {
  backgroundColor?: string;
  color?: string;
  colorVariant?: MuiChipProps["color"];
}

const Chip = (props: ChipProps) => {
  const { color, backgroundColor, colorVariant, sx, ...restProps } = props;
  return (
    <MuiChip
      sx={{
        color: color,
        borderRadius: "2px",
        backgroundColor: backgroundColor,
        ...sx,
      }}
      color={colorVariant}
      {...restProps}
    />
  );
};

export default Chip;
