/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import { UsageTrackerEvents, useUsageTracker } from "@proximie/tracking";

export const PendoInit = ({
  profileReference,
  organisationName,
  environmentName,
  dnsRecordName,
  isInternalUser,
}: {
  profileReference: string;
  organisationName: string;
  environmentName: string;
  dnsRecordName: string;
  isInternalUser: boolean;
}) => {
  const { tracker } = useUsageTracker();

  useEffect(() => {
    // @ts-ignore
    const pendo = window.pendo;
    if (pendo && pendo.isReady && pendo.updateOptions) {
      if (!pendo?.isReady()) {
        pendo.initialize({
          dataHost: `https://pendo.${dnsRecordName}`,
          contentHost: `https://pendo.${dnsRecordName}`,
          excludeAllText: true,
          visitor: {
            id: profileReference,
            InternalUser: isInternalUser,
            environment: environmentName,
          },
          account: {
            id: organisationName,
            InternalUser: isInternalUser,
            environment: environmentName,
          },
        });
      } else {
        pendo.updateOptions({
          account: {
            id: organisationName,
            InternalUser: isInternalUser,
            environment: environmentName,
          },
        });
      }
    }

    const onSessionOwnerChanged = () => {
      pendo?.track(UsageTrackerEvents.SessionOwnerChanged.name, {});
    };
    const onModeratorInvitedToSession = () => {
      pendo?.track(UsageTrackerEvents.ModeratorInvitedToSession.name, {});
    };

    tracker.on(UsageTrackerEvents.SessionOwnerChanged, onSessionOwnerChanged);
    tracker.on(
      UsageTrackerEvents.ModeratorInvitedToSession,
      onModeratorInvitedToSession,
    );

    return () => {
      tracker.off(
        UsageTrackerEvents.SessionOwnerChanged,
        onSessionOwnerChanged,
      );
      tracker.off(
        UsageTrackerEvents.ModeratorInvitedToSession,
        onModeratorInvitedToSession,
      );
    };
  }, [profileReference, organisationName, environmentName, isInternalUser]);
  return null;
};
