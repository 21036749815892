import { Typography, TypographyProps } from "@mui/material";
import React, { ForwardedRef, forwardRef, ReactNode } from "react";

type HasChildren = {
  children: ReactNode;
};

export const H6SemiboldTypography = ({
  children,
  ...props
}: HasChildren & TypographyProps) => (
  <Typography
    fontFamily={"SuisseIntl"}
    fontSize={"20px"}
    fontStyle={"normal"}
    fontWeight={600}
    lineHeight={"160%"}
    letterSpacing={"0.15px"}
    {...props}
  >
    {children}
  </Typography>
);

export const Subtitle2Typography = forwardRef(
  function Subtitle2TypographyPreRef(
    { children, ...props }: HasChildren & TypographyProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) {
    return (
      <Typography
        fontFamily={"SuisseIntl"}
        fontSize={"16px"}
        fontStyle={"normal"}
        fontWeight={400}
        lineHeight={"24px"}
        letterSpacing={"0.15px"}
        ref={ref}
        {...props}
      >
        {children}
      </Typography>
    );
  },
);

export const Body2Typography = forwardRef(function Body2TypographyPreRef(
  { children, ...props }: HasChildren & TypographyProps,
  ref: ForwardedRef<HTMLSpanElement>,
) {
  return (
    <Typography
      fontFamily={"SuisseIntl"}
      fontSize={"14px"}
      fontStyle={"normal"}
      fontWeight={400}
      lineHeight={"20px"}
      letterSpacing={"0.035px"}
      ref={ref}
      {...props}
    >
      {children}
    </Typography>
  );
});

export const CaptionTypography = ({
  children,
  ...props
}: HasChildren & TypographyProps) => (
  <Typography
    fontFamily={"SuisseIntl"}
    fontSize={"12px"}
    fontStyle={"normal"}
    fontWeight={400}
    lineHeight={"normal"}
    letterSpacing={"0.048px"}
    {...props}
  >
    {children}
  </Typography>
);
