import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class NotAllowedToUpdateAppointmentInvitationException extends BaseException {
  constructor(
    message = "Not allowed to update appointment invitation",
    meta?: unknown,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.NOT_ALLOWED_TO_UPDATE_APPOINTMENT_INVITATION;
  }
}
