import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Close, Restore } from "@mui/icons-material";
import { H6SemiboldTypography, Subtitle2Typography } from "../shared";
import { Button, SnackbarContext } from "@proximie/components";
import { useCameraLabel } from "../../contexts/camera-label-context";
import { useTranslation } from "react-i18next";

export type RenameDeviceDialogProps = {
  open: boolean;
  onClose: () => void;
  defaultName: string;
};

type ValidationError = "BLANK_NAME" | "VALID";

const ValidationMessage: Record<ValidationError, string | null> = {
  VALID: null,
  BLANK_NAME: "Please enter a name",
};

const MAX_CHARACTERS = 100;

function isNewNameValid(defaultName: string, name: string): boolean {
  if (name === defaultName) {
    return true;
  }

  return name.trim().length > 0;
}

export const RenameDeviceDialog = ({
  open,
  onClose,
  defaultName,
}: RenameDeviceDialogProps) => {
  const { colors } = useTheme();
  const { get: getCameraLabel, set: setCameraLabel } = useCameraLabel();
  const { showSnackbar } = SnackbarContext.useSnackbarContext();
  const { t } = useTranslation();

  const [updatedName, setUpdatedName] = useState<string>(
    getCameraLabel(defaultName).substring(0, MAX_CHARACTERS),
  );
  const [validationResult, setValidationResult] = useState<boolean>(true);
  const [alreadyFocused, setAlreadyFocused] = useState<boolean>(false);

  const updateCameraLabel = () => {
    const validationResult = isNewNameValid(defaultName, updatedName);
    setValidationResult(validationResult);

    if (validationResult) {
      try {
        setCameraLabel(defaultName, updatedName.trim());
        showSnackbar({
          message: {
            body: t("common.components.snackbar.messages.deviceRenamed", {
              name: updatedName,
              interpolation: {
                escapeValue: false,
              },
            }),
          },
          severity: "success",
        });
      } catch (e) {
        showSnackbar({
          message: {
            body: t("common.components.snackbar.messages.deviceRenamedFailure"),
          },
        });
      }
      onClose();
    }
  };

  const restore = () => {
    setUpdatedName(defaultName.substring(0, MAX_CHARACTERS));
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (/[\r\n]+/.test(event.target.value)) {
      return;
    }
    setValidationResult(true);
    setUpdatedName(event.target.value.substring(0, MAX_CHARACTERS));
  };

  return (
    <Dialog
      data-testid={"rename-device-dialog"}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          updateCameraLabel();
        }
      }}
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          marginTop: "100px",
        },
      }}
    >
      <DialogContent>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          gap={"8px"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <H6SemiboldTypography alignContent={"center"}>
              Rename device
            </H6SemiboldTypography>
            <Close
              onClick={onClose}
              width={"14px"}
              height={"14px"}
              sx={{ color: colors.BlackA700, cursor: "pointer" }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            padding={"8px 0 16px 0"}
            gap={"24px"}
          >
            <Subtitle2Typography color={colors.BlackA700}>
              Default name: {defaultName}
            </Subtitle2Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"flex-start"}
              gap={"3px"}
            >
              <TextField
                autoFocus
                multiline
                minRows={1}
                maxRows={5}
                onFocus={(e) => {
                  if (!alreadyFocused) {
                    setAlreadyFocused(true);
                    e.target.select();
                  }
                }}
                label={"Enter new name"}
                value={updatedName}
                onChange={onChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Restore default" arrow>
                        <IconButton onClick={restore}>
                          <Restore />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  sx: {
                    padding: "18px 12px !important",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: colors.BlackA900,
                    fontFamily: "Suisse Int'l",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.048px",
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    color: colors.BlackA700,
                    fontFamily: "Suisse Int'l",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "0.048px",
                  },
                }}
                error={!validationResult}
                helperText={
                  validationResult
                    ? `${updatedName.length}/${MAX_CHARACTERS}`
                    : "Please enter a name"
                }
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ marginTop: "16px !important" }}>
        <Button variant={"primary"} onClick={updateCameraLabel}>
          Rename
        </Button>
        <Button variant={"secondary"} onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
