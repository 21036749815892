import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface DeniedAccessToMicrophoneExceptionMeta {
  originalError: Error;
}

export class DeniedAccessToMicrophoneException extends BaseException {
  constructor(
    message = "Denied access to microphone exception",
    meta: DeniedAccessToMicrophoneExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.DENIED_ACCESS_TO_MICROPHONE;
  }
}
