import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";

type shared = "initial" | "center" | "flex-start" | "flex-end";
export interface FlexProps {
  className?: string;
  children?: ReactNode;
  flexWrap?: "initial" | "nowrap" | "wrap" | "wrap-reverse";
  justifyContent?: shared | "space-between" | "space-around";
  alignContent?: shared | "stretch" | "space-between" | "space-around";
  flexDirection?:
    | "initial"
    | "row"
    | "row-reverse"
    | "column"
    | "column-reverse";
  alignItems?: shared | "stretch" | "baseline";
  flexGrow?: number;
  onClick?: () => void;
  mt?: number;
}

const Flexbox: FC<FlexProps> = (props: FlexProps): JSX.Element => {
  return (
    <Box display="flex" {...props} onClick={props.onClick}>
      {props.children}
    </Box>
  );
};

export default Flexbox;
