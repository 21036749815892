import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PageRouter from "./pages/support/PageRouter/PageRouter";

// Temporary solution to log as much as possible during demos
// Will be replaced with a proper client logging
window.addEventListener("error", (event) => {
  const { message, filename, lineno, colno, error } = event;
  console.error(message, ` ${filename} ${lineno}:${colno} `, error);
  // Prevent duplicated errors in browser console
  event.preventDefault();
});

export const App = () => {
  return (
    <Router>
      <PageRouter />
    </Router>
  );
};

export default App;
