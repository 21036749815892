import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface Auth0CannotTriggerPasswordResetExceptionMeta {
  originalError: Error;
}

export class Auth0CannotTriggerPasswordResetException extends BaseException {
  constructor(
    message = "Failed to trigger password reset in auth0",
    meta: Auth0CannotTriggerPasswordResetExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.AUTH0_CANNOT_TRIGGER_PASSWORD_RESET;
  }
}
