import { SvgIcon as MuiSvgIcon } from "@mui/material";
import React from "react";

export type CursorIconProps = {
  color?: string;
};

export const CursorIcon = ({ color }: CursorIconProps = { color: "white" }) => {
  return (
    <MuiSvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.315 21.9064C17.315 21.9064 17.315 21.4851 17.315 20.2212C17.315 18.7466 19.1512 18.536 19.3807 16.4295C19.3807 16.4295 19.3807 14.7443 19.3807 11.5846C19.3807 9.68871 17.0855 9.47806 16.856 10.3207C16.856 8.63546 14.7903 8.21416 14.1017 9.26741C14.1017 7.58221 11.577 7.37156 11.3475 9.26741C11.3475 8.84611 11.118 6.10767 11.118 2.94792C10.8885 1.68403 8.8228 1.68403 8.59328 2.94792C8.59328 2.94792 8.59328 6.73961 8.59328 14.5336C8.59328 14.5336 8.36376 13.4804 7.67521 10.5313C7.21617 8.63546 4.69146 9.05676 5.15049 11.1633C5.15049 11.1633 5.60953 13.0591 6.52761 17.0614C6.75713 17.4827 6.98665 17.6934 7.21617 17.904C8.8228 18.9573 9.74088 19.5892 9.74088 19.5892C9.9704 19.7999 9.9704 20.0105 9.9704 20.4318C9.9704 21.4851 9.9704 21.9064 9.9704 21.9064C10.1999 22.117 17.0855 21.9064 17.315 21.9064Z"
          fill={color}
          fillOpacity="0.87"
        />
      </svg>
    </MuiSvgIcon>
  );
};
