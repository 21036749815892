import { DeviceType } from "./Device";
import DeviceLocal from "./DeviceLocal";
import Connection from "../Connections/Connection";
import FeedType from "../../models/FeedType";

export default class DeviceLocalSpeaker extends DeviceLocal {
  public override readonly deviceType = DeviceType.Speaker;
  public override readonly mediaType = FeedType.Audio;

  public override invoke(): Connection | null {
    throw new Error("Cannot make a connection from a speaker device");
  }
}
