/* eslint-disable sonarjs/cognitive-complexity */
import React, { FC, useEffect, useRef, useState } from "react";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { EventTypes, PtzUser } from "../../utils/PtzUser";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PTZTooltip, PTZTooltipVariants } from "./PTZTooltip";
import { useTranslation } from "react-i18next";

export interface PtzPermissionLabelProps {
  ptzUser: PtzUser;
  videoWithMask: boolean;
  streamId: string;
  checkIfVideoWithMasks: (streamId: string) => void;
  anchorEl?: HTMLElement | null;
  boundaryElement?: HTMLElement | null;
  variant?: PTZTooltipVariants;
}

const PERMISSION_NOTIFICATION_TIME = 4000;
const PERMISSION_REQUEST_PROMPT_TIME = 20000; //ms

type Status = {
  template: string;
  profileId?: number;
};

const PtzPermissionLabel: FC<PtzPermissionLabelProps> = ({
  ptzUser,
  streamId,
  checkIfVideoWithMasks,
  videoWithMask,
  anchorEl,
  boundaryElement,
  variant,
}: PtzPermissionLabelProps): JSX.Element => {
  const { colors } = useTheme();
  const { session, members } = useSessionContext();
  const { t } = useTranslation();

  const [status, setStatus] = useState<Status | null>(null);
  const [countdownEndTime, setCountdownEndTime] = useState<number>(0);
  const [timeRemaining, setTimeRemaining] = useState<number>(
    PERMISSION_REQUEST_PROMPT_TIME,
  );

  const [infosOpen, setInfosOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (status?.template === "liveSession.ptzPermissionLabel.requested") {
      interval = setInterval(() => {
        const rem = Math.round((countdownEndTime - Date.now()) / 1000) * 1000;
        if (rem < 1000) {
          ptzUser.rescindControl();
          clearInterval(interval);
          return;
        }
        setTimeRemaining(rem);
      }, 1000);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, countdownEndTime]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const updateStatus = (template: string, profileId?: number) => {
    if (template === "liveSession.ptzPermissionLabel.requested") {
      setCountdownEndTime(Date.now() + PERMISSION_REQUEST_PROMPT_TIME);
      setTimeRemaining(PERMISSION_REQUEST_PROMPT_TIME);
    }

    setStatus({ template, profileId });

    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(
      () => {
        setStatus(null);
      },
      template === "liveSession.ptzPermissionLabel.requested"
        ? PERMISSION_REQUEST_PROMPT_TIME
        : PERMISSION_NOTIFICATION_TIME,
    );
  };

  useEffect(() => {
    const controlAlreadyRequested = () => {
      console.debug(
        { streamId },
        "controlAlreadyRequested",
        ptzUser.controllerId,
      );
      updateStatus("liveSession.ptzPermissionLabel.busy", ptzUser.controllerId);
    };
    ptzUser.on(EventTypes.ControlAlreadyRequested, controlAlreadyRequested);

    const controlCancelled = () => {
      console.debug({ streamId }, "controlCancelled");
      updateStatus("liveSession.ptzPermissionLabel.ended");
    };
    ptzUser.on(EventTypes.ControlCancelled, controlCancelled);

    const controlDenied = (profileId: number) => {
      console.debug({ streamId }, "controlDenied", profileId);
      updateStatus("liveSession.ptzPermissionLabel.busy", profileId);
      setInfosOpen(false);
    };
    ptzUser.on(EventTypes.ControlDenied, controlDenied);

    const controlAssigned = (profileId: number) => {
      console.debug(
        { streamId },
        "controlAssigned",
        profileId,
        ptzUser.controllerId,
      );
      if (ptzUser.controllerId === 0) {
        updateStatus("liveSession.ptzPermissionLabel.owned");
      } else {
        updateStatus("liveSession.ptzPermissionLabel.received");
      }
      checkIfVideoWithMasks(streamId);
      setInfosOpen(true);
    };
    ptzUser.on(EventTypes.ControlAssigned, controlAssigned);

    const controlRequested = (profileId: number) => {
      console.debug(
        { streamId },
        "controlRequested",
        profileId,
        ptzUser.controllerId,
      );
      if (ptzUser.controllerId !== 0) {
        updateStatus("liveSession.ptzPermissionLabel.requested", profileId);
      }
    };
    ptzUser.on(EventTypes.ControlRequested, controlRequested);

    ptzUser.requestStatusAndControl();

    return () => {
      ptzUser.off(EventTypes.ControlAlreadyRequested, controlAlreadyRequested);
      ptzUser.off(EventTypes.ControlCancelled, controlCancelled);
      ptzUser.off(EventTypes.ControlDenied, controlDenied);
      ptzUser.off(EventTypes.ControlAssigned, controlAssigned);
      ptzUser.off(EventTypes.ControlRequested, controlRequested);
    };
  }, [ptzUser, streamId, checkIfVideoWithMasks]);

  const allow = () => {
    console.debug({ streamId }, "allow");
    ptzUser.rescindControl();
    updateStatus("liveSession.ptzPermissionLabel.given", status?.profileId);
  };

  const deny = () => {
    console.debug({ streamId }, "deny");
    ptzUser.denyControl();
    updateStatus("liveSession.ptzPermissionLabel.denied");
  };

  const buildStatusString = (status: Status): string => {
    let profileName = "UNKNOWN";
    if (status.profileId && session) {
      const profile = members?.find(
        (member) => member.profileId === status.profileId,
      );
      profileName = profile?.name ?? profileName;
    }

    return t(status.template, { name: profileName });
  };

  return (
    <>
      <PTZTooltip
        text={status ? buildStatusString(status) : ""}
        variant={variant ?? "up"}
        autoHandoverDuration={timeRemaining / 1000}
        showAutoHandoverDuration={
          status?.template === "liveSession.ptzPermissionLabel.requested"
        }
        open={!!status}
        anchorEl={anchorEl}
        boundaryElement={boundaryElement}
        actions={
          status?.template === "liveSession.ptzPermissionLabel.requested"
            ? [
                {
                  text: "Allow",
                  action: allow,
                  dataTestId: "ptz-permission-btn-allow",
                },
                {
                  text: "Deny",
                  action: deny,
                  dataTestId: "ptz-permission-btn-deny",
                },
              ]
            : []
        }
      />

      {videoWithMask && (
        <Dialog
          open={infosOpen}
          onClose={() => {
            setInfosOpen(false);
          }}
        >
          <DialogTitle sx={{ m: 1, p: 2 }}>
            Privacy warning
            <IconButton
              aria-label="close"
              onClick={() => {
                setInfosOpen(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            Request successful. You now control this interactive device.
            <Alert
              severity="warning"
              data-testid="pii-ptz-warning"
              sx={{ mt: 3, border: `1px solid ${colors.Amber200}` }}
            >
              This PTZ camera feed has a privacy mask applied. Moving the PTZ
              camera could expose sensitive information.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color={"secondary"}
              onClick={() => {
                setInfosOpen(false);
              }}
              data-testid={"close-more-infos"}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PtzPermissionLabel;
