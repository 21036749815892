import React from "react";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface SidebarPanelProps {
  children: React.ReactNode;
  closePanel: () => void;
  title: string;
}

const SidebarPanel = (props: SidebarPanelProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      overflow="auto"
      display="flex"
      flexDirection="column"
      maxHeight={"100%"}
      width={300}
      minWidth={300}
      bgcolor={theme.palette.background.paper}
      sx={{ boxShadow: 3 }}
      position={"relative"}
    >
      <Box px={2} textAlign="right">
        <IconButton
          onClick={props.closePanel}
          data-testid="close-sidebar-panel-btn"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box pt={0.5} pb={2} px={2}>
        <Typography variant="h5" component={"div"}>
          {props.title}
        </Typography>
      </Box>
      {props.children}
    </Box>
  );
};
export default SidebarPanel;
