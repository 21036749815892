import React, { Children, ReactElement } from "react";
import { Box, useTheme } from "@mui/material";
import { TroubleShootingStatusTextContainer } from "../TroubleShootingStatusText";
import {
  DescriptionTypography,
  TitleTypography,
  ValueTypography,
} from "../shared/CustomTypography";
import { NormalGoodPoorBadNA } from "../TroubleShootingTypes";

export type TroubleShootingReportPanelGridRowProps = {
  title: string;
  description: string;
  criticality: NormalGoodPoorBadNA;
  value?: string;
};

export const TroubleShootingReportPanelGridRow = (
  _: TroubleShootingReportPanelGridRowProps,
) => null;

type TroubleShootingMenuAccordionDetailsContainerProps = {
  children:
    | ReactElement<TroubleShootingReportPanelGridRowProps>
    | ReactElement<TroubleShootingReportPanelGridRowProps>[];
};

export const TroubleShootingReportPanelGrid = ({
  children,
}: TroubleShootingMenuAccordionDetailsContainerProps) => {
  const { colors } = useTheme();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      alignSelf={"stretch"}
      width={"100%"}
      gap={"0"}
    >
      {Children.map(
        children,
        ({ props: { title, description, criticality, value } }, index) => (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            alignSelf={"stretch"}
            borderBottom={`${
              index < Children.count(children) - 1 ? 1 : 0
            }px solid ${colors.BlackLowEmphasis}`}
            width={"100%"}
          >
            <Box display={"flex"} flexDirection={"column"} flex={1}>
              <TitleTypography>{title}</TitleTypography>
              <DescriptionTypography>{description}</DescriptionTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              borderLeft={`1px solid ${colors.BlackLowEmphasis}`}
              padding={"12px"}
              width={"88px"}
              flex={0.22}
            >
              <TroubleShootingStatusTextContainer criticality={criticality} />
              <ValueTypography
                {...(criticality === "N/A"
                  ? { color: colors.BlackDisabled }
                  : {})}
              >
                {value ?? <br />}
              </ValueTypography>
            </Box>
          </Box>
        ),
      )}
    </Box>
  );
};
