import styled from "@emotion/styled";

const VideoStatsStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0;
  width: 279px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;

export default VideoStatsStyled;
