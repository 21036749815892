import React, { FC, useEffect, useState } from "react";
import DisplayTimeStyled from "./DisplayTime.style";
import { getElapsedTimeStringFromDate } from "../../utils/timer";

interface DisplayTimeProps {
  startedAt: Date;
}

const DisplayTime: FC<DisplayTimeProps> = (props: DisplayTimeProps) => {
  const [elapsedTime, setElapsedTime] = useState(
    getElapsedTimeStringFromDate(props.startedAt),
  );
  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime(getElapsedTimeStringFromDate(props.startedAt));
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [props.startedAt]);

  return (
    <DisplayTimeStyled data-cy="session-timer">{elapsedTime}</DisplayTimeStyled>
  );
};

export default DisplayTime;
