import { Dialog, DialogActions, Button } from "@mui/material";
import { DialogTitle } from "@proximie/components";
import React, { FC } from "react";
import { navigateToDashboard } from "../../utils";
import { useDashboardUrl } from "../../hooks/useDashboardUrl";

const Error404: FC = () => {
  const dashboardUrl = useDashboardUrl();

  return (
    <Dialog open={true}>
      <DialogTitle>Error: Not found</DialogTitle>
      <DialogActions>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          onClick={() => navigateToDashboard(dashboardUrl)}
        >
          Back to dashboard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Error404;
