import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class VideoCapacityExceededException extends BaseException {
  constructor(message = "Video capacity exceeded", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.VIDEO_CAPACITY_EXCEPTION_EXCEEDED;
  }
}
