type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

// this is the type of the session saved user setting
export interface SessionUserSettings {
  spotlight: {
    showConfirmRemoveSpotlightDialog: boolean;
    showConfirmAddSpotlightDialog: boolean;
    showTooltipForSpotlighter: boolean;
    showTooltipForFollowingSpotlight: boolean;
  };
}

export type IUpdateUserSettings = DeepPartial<SessionUserSettings>;

// this variable defines the default user settings
export const defaultSessionUserSettings: SessionUserSettings = {
  spotlight: {
    showConfirmRemoveSpotlightDialog: true,
    showConfirmAddSpotlightDialog: true,
    showTooltipForSpotlighter: true,
    showTooltipForFollowingSpotlight: true,
  },
};

// this handles converting basically anything to a valid user setting object
// if something fails, we default using the defaultSessionUserSettings
// because users have the ability to change the session storage
// it should be able to handle any input
export const mergeUserSettingsWithDefault = (
  sessionUserSettings: null | Record<string, unknown>,
): SessionUserSettings => {
  const originalUserSettings: DeepPartial<SessionUserSettings> =
    sessionUserSettings ?? {};
  return {
    spotlight: {
      showConfirmRemoveSpotlightDialog: Boolean(
        originalUserSettings?.spotlight?.showConfirmRemoveSpotlightDialog ??
          defaultSessionUserSettings.spotlight.showConfirmRemoveSpotlightDialog,
      ),
      showConfirmAddSpotlightDialog: Boolean(
        originalUserSettings?.spotlight?.showConfirmAddSpotlightDialog ??
          defaultSessionUserSettings.spotlight.showConfirmAddSpotlightDialog,
      ),
      showTooltipForSpotlighter: Boolean(
        originalUserSettings?.spotlight?.showTooltipForSpotlighter ??
          defaultSessionUserSettings.spotlight.showTooltipForSpotlighter,
      ),
      showTooltipForFollowingSpotlight: Boolean(
        originalUserSettings?.spotlight?.showTooltipForFollowingSpotlight ??
          defaultSessionUserSettings.spotlight.showTooltipForFollowingSpotlight,
      ),
    },
  };
};
