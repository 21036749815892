import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToDeIdentifyExceptionMeta {
  originalError: Error;
}

export class FailedToDeIdentifyException extends BaseException {
  // FYI:
  // DE-IDENTIFICATION
  // input                  |  output
  // user@proximie.com      |  d41018f5-a2b2-4820-9eef-d03e03ce4967
  //
  constructor(
    message = "Failed to de-identify entities",
    meta?: FailedToDeIdentifyExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_DE_IDENTIFY;
  }
}
