import * as t from "io-ts";
import { nullable } from "io-ts/lib/Type";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";

const requiredProperties = t.type({
  id: t.number,
  invitedAt: nullable(DateFromISOString),
  organisationId: nullable(t.number),
  organisationUuid: nullable(t.string),
  organisationName: nullable(t.string),
  roleId: t.number,
  roleName: t.string,
  organisationReference: t.string,
});
const optionalProperties = t.partial({
  uuid: t.string,
});

export const organisationMembershipPresentation = t.intersection([
  requiredProperties,
  optionalProperties,
]);

export type organisationMembershipPresentation = t.TypeOf<
  typeof organisationMembershipPresentation
>;
