import React, { useEffect, useRef, useState } from "react";
import { Device, MaskHandlerEvents, VideoMaskHandler } from "@proximie/media";
import {
  Box,
  Button,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close, ErrorOutline, HelpOutline } from "@mui/icons-material";
import EditorControls from "./EditorControls";
import MaskedVideo from "./MaskedVideo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useFlavor } from "@proximie/flavorinator";

export interface VideoMaskingEditorProps {
  maskHandler: VideoMaskHandler;
  confirmMaskSendFeed: (device: Device) => void;
  cancelMaskSendFeed: () => void;
  edit: boolean;
  enableBackButton: boolean;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 363,
  },
});

const VideoMaskingEditor = React.forwardRef(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (props: VideoMaskingEditorProps, _ref) => {
    const { colors, palette } = useTheme();
    const { t } = useTranslation();
    const { getConfiguration } = useFlavor();

    const [isPtz, setIsPtz] = useState(false);
    const [disableApply, setDisableApply] = useState(true);
    const videoEditorRef = useRef<HTMLDivElement>(null);

    const matchesHeight = useMediaQuery("(min-height:800px)");

    useEffect(() => {
      const disableApply = (disabled: boolean) => setDisableApply(disabled);

      props.maskHandler.on(MaskHandlerEvents.HistoryChanged, () =>
        disableApply(!props.maskHandler.canUndo()),
      );

      return () => {
        props.maskHandler.off(MaskHandlerEvents.HistoryChanged, disableApply);
      };
    }, [props.maskHandler, setDisableApply]);

    return (
      <Box
        bgcolor="white"
        maxWidth="900px"
        width={matchesHeight ? "900px" : "760px"}
        minWidth="760px"
        minHeight="200px"
        display="flex"
        justifyContent="flex-start"
        borderRadius="8px"
        padding="24px"
        flexDirection="column"
        gap={matchesHeight ? "16px" : "8px"}
        marginY="4px"
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Box pt="0 !important">
          <Box
            display="flex"
            justifyContent={
              props.enableBackButton ? "flex-start" : "space-between"
            }
            alignItems="center"
          >
            {props.enableBackButton && (
              <IconButton
                size="medium"
                sx={{
                  color: colors.BrandSecondary,
                  margin: 0,
                  marginRight: "16px",
                  marginLeft: "-12px",
                  "&:hover": {
                    backgroundColor: colors.BrandSecondary8,
                  },
                }}
                onClick={() => props.cancelMaskSendFeed()}
                data-testid="editor-back-button"
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography
              variant={matchesHeight ? "h5" : "h6"}
              data-testid="editor-title"
            >
              {props.edit ? "Privacy Control" : "Preview"}
            </Typography>
            {!props.enableBackButton && (
              <IconButton
                sx={{
                  color: palette.grey[500],
                }}
                onClick={() => props.cancelMaskSendFeed()}
                data-testid="editor-x-button"
              >
                <Close />
              </IconButton>
            )}
          </Box>
          <Typography
            sx={{
              color: palette.text.secondary,
            }}
            variant="caption"
            display="block"
            fontSize={matchesHeight ? "14px" : "12px"}
            mt={matchesHeight ? 2 : 1}
          >
            {props.edit ? "Block" : "You can block"}{" "}
            {t("liveSession.videoMaskingEditor.description")}
            <br />
            Step 1: Select a shape. Step 2: Click and drag to draw a blur, then
            click &quot;{props.edit ? "Apply" : "Add Feed"}&quot;.
          </Typography>
        </Box>
        {isPtz && (
          <Box
            display="flex"
            border={`1px solid ${colors.BlackLowEmphasis}`}
            padding="8px"
            borderRadius="4px"
            alignItems="center"
            columnGap="8px"
            data-testid="ptz-warning"
            my={0}
          >
            <ErrorOutline style={{ color: colors.BlackMediumEmphasis }} />
            <Typography
              variant="body2"
              sx={{ fontSize: matchesHeight ? "14px" : "12px" }}
            >
              This is a PTZ camera. Privacy masks drawn over objects will not
              track or follow movement when using PTZ controls.
            </Typography>
          </Box>
        )}
        <Box
          ref={videoEditorRef}
          borderRadius="6px"
          overflow="hidden"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          minHeight="310px"
          flex="1 1 auto"
          sx={{
            backgroundColor: colors.Grey50,
            border: "1px solid",
            borderColor: colors.Grey300,
            color: palette.text.secondary,
          }}
        >
          <EditorControls {...props} setIsPtz={setIsPtz} />
          <MaskedVideo maskHandler={props.maskHandler} />
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            color={colors.BlackMediumEmphasis}
            marginTop="0"
          >
            <Box margin="0" padding="0" width="467px">
              <Typography width="467px" fontSize="11px" flex={6}>
                {t("liveSession.videoMaskingEditor.explanation")}
              </Typography>
            </Box>
            <Box flex={4}>
              <CustomWidthTooltip
                title={
                  <span>
                    {t("liveSession.videoMaskingEditor.warningTooltipLine1")}
                    <br />
                    <br />
                    {t("liveSession.videoMaskingEditor.warningTooltipLine2", {
                      appName: getConfiguration("appName"),
                    })}
                  </span>
                }
                placement="top"
              >
                <IconButton
                  sx={{
                    color: colors.Grey700,
                    width: "28px",
                    height: "28px",
                    "&:hover": {
                      backgroundColor: colors.Brand50,
                    },
                  }}
                >
                  <HelpOutline />
                </IconButton>
              </CustomWidthTooltip>
            </Box>
          </Box>
        </Box>
        <Box marginTop="8px">
          <Box
            display="inline-flex"
            justifyContent="flex-start"
            gap={matchesHeight ? "16px" : "8px"}
          >
            <Button
              variant="contained"
              color={"secondary"}
              style={{
                width: "148px",
                height: matchesHeight ? "48px" : "40px",
              }}
              onClick={() =>
                props.confirmMaskSendFeed(props.maskHandler.getDevice())
              }
              disabled={props.edit && disableApply}
              data-testid="add-feed-button"
              data-cy="add-feed-button"
            >
              {props.edit ? "Apply" : "Add Feed"}
            </Button>
            <Button
              variant="outlined"
              color={"primary"}
              style={{
                width: "100px",
                height: matchesHeight ? "48px" : "40px",
              }}
              onClick={() => props.cancelMaskSendFeed()}
              data-testid="editor-cancel-button"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    );
  },
);

VideoMaskingEditor.displayName = "VideoMaskingEditor";
export default VideoMaskingEditor;
