import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface FailedToDeleteMediaSessionsExceptionMeta {
  originalError: Error;
}

export class FailedToDeleteMediaSessionsException extends BaseException {
  constructor(
    message = "Failed to delete media session events",
    meta?: FailedToDeleteMediaSessionsExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.FAILED_TO_DELETE_MEDIA_SESSIONS;
  }
}
