import React from "react";

export type HoverListenerProps = {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: React.ReactNode | React.ReactNode[];
};

export default function HoverListener({
  onMouseEnter,
  onMouseLeave,
  children,
}: HoverListenerProps) {
  return (
    <div
      style={{ display: "contents" }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
}
