import * as t from "io-ts";

const OrganisationMembership = t.type({
  id: t.string,
  name: t.string,
  role: t.string,
  invitedAt: t.string,
});

const User = t.type({
  id: t.string,
  profileId: t.union([t.undefined, t.number]),
  email: t.string,
  hasAcceptedTerms: t.boolean,
  name: t.union([t.undefined, t.string]),
  city: t.union([t.undefined, t.number]),
  phone: t.union([t.undefined, t.string]),
  userTitle: t.union([t.undefined, t.string]),
  speciality: t.union([t.undefined, t.string]),
  countryISO: t.union([t.undefined, t.string]),
  profileReference: t.union([t.undefined, t.string]),
  organisationMemberships: t.union([
    t.undefined,
    t.array(OrganisationMembership),
  ]),
});

export type OrganisationMembership = t.TypeOf<typeof OrganisationMembership>;
export type User = t.TypeOf<typeof User>;
