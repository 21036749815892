import React from "react";
import { LinearProgress, CircularProgress } from "@mui/material";

type Props = {
  type?: "linear" | "circular";
  color?: "primary" | "secondary";
  style?: React.CSSProperties;
};

export function Loading({ type, ...otherProps }: Props) {
  const props = {
    color: "primary" as const,
    ...otherProps,
  };

  if (type === "linear") {
    return <LinearProgress style={{ width: "100%" }} {...props} />;
  }

  return <CircularProgress {...props} />;
}
