import React, { FC } from "react";
import { Mic, MicNone, MicOff } from "@mui/icons-material";

export interface MicIndicatorProps {
  isConnected: boolean;
  isMuted: boolean;
  isActive: boolean;
}

const mutedIcon = (
  <MicOff
    color="inherit"
    name="micMuted"
    data-testid="mic-muted"
    data-cy="icon-muted"
  />
);
const activeIcon = (
  <Mic
    name="micActive"
    color={"inherit"}
    data-testid="mic-active"
    data-cy="icon-unmuted"
  />
);
const inactiveIcon = (
  <MicNone
    name="micUnmuted"
    data-testid="mic-inactive"
    data-cy="icon-unmuted"
  />
);
const noDataIcon = (
  <MicNone
    color={"inherit"}
    name="micUnmuted"
    data-testid="mic-no-data"
    data-cy="icon-unmuted"
  />
);

const MicIndicator: FC<MicIndicatorProps> = (props: MicIndicatorProps) => {
  const { isConnected, isMuted, isActive } = props;

  const micStatus = () => {
    return isConnected || isMuted ? mutedMicStatus() : noDataIcon;
  };

  const mutedMicStatus = () => {
    return isMuted ? mutedIcon : activeMicStatus();
  };

  const activeMicStatus = () => {
    return isActive ? activeIcon : inactiveIcon;
  };

  return micStatus();
};

export default MicIndicator;
