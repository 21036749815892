import React, { FC } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { SearchFieldStyled } from "./SearchField.style";
import Flexbox from "../Flexbox/Flexbox";
import { useTheme } from "@mui/material/styles";

export interface SearchFieldProps {
  name?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  icon?: React.ReactNode;
}

const Component: FC<SearchFieldProps> = (
  props: SearchFieldProps,
): JSX.Element => {
  const theme = useTheme();
  return (
    <SearchFieldStyled theme={theme}>
      <Paper className={"container"} elevation={0} variant="outlined" square>
        <Flexbox>
          <InputBase
            className="search-input"
            placeholder={props.placeholder}
            fullWidth
            inputProps={{ "aria-label": "enter search term" }}
            onChange={(e) => props.onChange(e.target.value)}
          />
          {props.icon && <div className={"icon"}>{props.icon}</div>}
        </Flexbox>
      </Paper>
    </SearchFieldStyled>
  );
};

export default Component;
