import React, { FC } from "react";
import { NumberChip } from "./ChatIcon.style";
import { Icon } from "@proximie/components";

export interface ChatIconProps {
  numberOfUnreadMessage?: number;
}

const ChatIcon: FC<ChatIconProps> = (props: ChatIconProps): JSX.Element => (
  <>
    <Icon name="chat" fontSize="small" />
    {props.numberOfUnreadMessage !== undefined &&
      props.numberOfUnreadMessage > 0 && (
        <NumberChip
          label={props.numberOfUnreadMessage}
          color="secondary"
          size="small"
          data-testid="unread-number"
        />
      )}
  </>
);

export default ChatIcon;
