import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface UserDoesNotExistsInOrganisationExceptionMeta {
  userId: number;
  organisationId: number;
}

export class UserDoesNotExistsInOrganisationException extends BaseException {
  constructor(
    message = "User does not exist in organization",
    meta: UserDoesNotExistsInOrganisationExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.USER_DOES_NOT_EXISTS_IN_ORGANISATION;
  }
}
