import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class ConflictingRecordingStatusForVideoAndAudioException extends BaseException {
  constructor(
    message = "Conflicting recording status for video and audio",
    meta?: unknown,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.CONFLICTING_RECORDING_STATUS_FOR_VIDEO_AND_AUDIO;
  }
}
