import { Tooltip, useTheme } from "@mui/material";
import React, { MouseEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import { SignalCellularAlt } from "../icons/SignalCellularAlt";
import { TroubleShootingMenu } from "./TroubleShootingMenu";
import {
  TroubleShootingProvider,
  useTroubleShooting,
} from "./TroubleShootingContext";
import { TroubleShootingIndicatorStyled } from "./TroubleShootingIndicator.style";
import {
  CustomerSupportDialog,
  Permissions,
  SnackbarContext,
} from "@proximie/components";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { useTranslation } from "react-i18next";
import { environment } from "../../../environments/environment";
import { TroubleShootingReport } from "./TroubleShootingReport";

const { usePermission } = Permissions;

type TroubleShootingIndicatorProps = {
  backgroundColor: string;
  hoverColor: string;
  pressColor: string;
  iconColor: string;
  firstBarColor?: string;
};

const TroubleShootingIndicator = ({
  backgroundColor,
  hoverColor,
  pressColor,
  iconColor,
  firstBarColor,
}: TroubleShootingIndicatorProps) => {
  const { menuOpen, setMenuOpen, pressed, setPressed } = useTroubleShooting();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const anchorRef = useRef<HTMLElement>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useLayoutEffect(() => {
    if (anchorRef.current) {
      setAnchor(anchorRef.current);
    }
  }, [anchorRef]);

  const onMouseDown = (e: MouseEvent) => {
    e.stopPropagation();
    setPressed(true);
  };
  const onMouseUp = () => {
    setMenuOpen((prev) => !prev);
    setPressed(false);
  };

  useEffect(() => {
    if (tooltipOpen && menuOpen) {
      setTooltipOpen(false);
    }
  }, [menuOpen, tooltipOpen]);

  return (
    <>
      <TroubleShootingMenu anchor={anchor} open={menuOpen} />
      <Tooltip
        title={"Troubleshooting"}
        placement="top"
        open={tooltipOpen}
        onOpen={() => setTooltipOpen(true)}
        onClose={() => setTooltipOpen(false)}
        disableTouchListener={menuOpen}
        disableHoverListener={menuOpen}
        disableFocusListener={menuOpen}
      >
        <TroubleShootingIndicatorStyled
          ref={anchorRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          backgroundColor={pressed ? pressColor : backgroundColor}
          hoverColor={pressed ? pressColor : hoverColor}
          data-testid={"troubleshooting-indicator-icon"}
        >
          <SignalCellularAlt color={iconColor} firstBarColor={firstBarColor} />
        </TroubleShootingIndicatorStyled>
      </Tooltip>
    </>
  );
};

const GoodTroubleShootingIndicator = () => {
  const { colors } = useTheme();
  return (
    <TroubleShootingIndicator
      backgroundColor={colors.NewGreen600}
      hoverColor={colors.NewGreen700}
      pressColor={colors.NewGreen800}
      iconColor={colors.WhiteHighEmphasis}
    />
  );
};

const PoorTroubleShootingIndicator = () => {
  const { colors } = useTheme();
  return (
    <TroubleShootingIndicator
      backgroundColor={colors.NewAmber400}
      hoverColor={colors.NewAmber500}
      pressColor={colors.NewAmber600}
      iconColor={colors.BlackDisabled}
      firstBarColor={colors.Black}
    />
  );
};

const CriticalTroubleShootingIndicator = () => {
  const { colors } = useTheme();
  return (
    <TroubleShootingIndicator
      backgroundColor={colors.NewRed600}
      hoverColor={colors.NewRed700}
      pressColor={colors.NewRed800}
      iconColor={colors.WhiteDisabled}
    />
  );
};

const TroubleShootingIndicatorSelector = () => {
  const {
    criticalityReport: { criticality },
  } = useTroubleShooting();

  return {
    Good: <GoodTroubleShootingIndicator />,
    Poor: <PoorTroubleShootingIndicator />,
    Bad: <CriticalTroubleShootingIndicator />,
  }[criticality];
};

export const TroubleShootingIndicatorContainer = () => {
  const [supportTicketRaised, setSupportTicketRaised] = useState(false);

  const sessionContext = useSessionContext();
  const showSupportForm = usePermission("support-ticket:create");
  const { t } = useTranslation();
  const { showSnackbar } = SnackbarContext.useSnackbarContext();

  const handleSupportTicketError = () => {
    showSnackbar({
      message: {
        title: t(
          "common.components.snackbar.messages.failedToSubmitSupportTicketTitle",
        ),
        body: t(
          "common.components.snackbar.messages.failedToSubmitSupportTicket",
        ),
      },
      severity: "error",
    });
  };

  return (
    <>
      <CustomerSupportDialog
        onClose={() => setSupportTicketRaised(false)}
        onError={handleSupportTicketError}
        open={supportTicketRaised}
        baseUrl={environment.api.baseUrl}
        mode="in-session"
        context={{
          organizationName: sessionContext.organisation?.name,
          userName: sessionContext.user?.name,
          userId: sessionContext.user?.id,
          sessionId: sessionContext.session?.id,
          streamId: sessionContext.sessionParams?.mediaSessionId,
          email: sessionContext.user?.email,
          organisations: sessionContext.organisation
            ? [
                {
                  name: sessionContext.organisation?.name,
                  id: sessionContext.organisation?.id,
                },
              ]
            : [],
        }}
        supportLines={environment.supportLines}
        showSupportForm={showSupportForm}
      />
      <TroubleShootingProvider
        openSupportTicket={() => setSupportTicketRaised(true)}
      >
        <TroubleShootingIndicatorSelector />
        <TroubleShootingReport />
      </TroubleShootingProvider>
    </>
  );
};
