export type Spacer = keyof typeof spacers;

export const spacers = {
  /** 0 */
  none: 0,
  /** 4 */
  xs: 4,
  /** 8 */
  sm: 8,
  /** 16 */
  md: 16,
  /** 24 */
  lg: 24,
  /** 32 */
  xl: 32,
  /** 40 */
  xxl: 40,
  /** 64 */
  xxxl: 64,
  /** 80 */
  xxxxl: 80,
};

export const spacersAsString = {
  /** 0 */
  none: `${spacers.none}`,
  /** 4px */
  xs: `${spacers.xs}px`,
  /** 8px */
  sm: `${spacers.sm}px`,
  /** 16px */
  md: `${spacers.md}px`,
  /** 24px */
  lg: `${spacers.lg}px`,
  /** 32px */
  xl: `${spacers.xl}px`,
  /** 40px */
  xxl: `${spacers.xxl}px`,
  /** 64px */
  xxxl: `${spacers.xxxl}px`,
  /** 80px */
  xxxxl: `${spacers.xxxxl}px`,
};
