import { useEffect } from "react";
import { UsageTrackerEvents, useUsageTracker } from "@proximie/tracking";

export const NewRelicTrackerInit = () => {
  const { tracker } = useUsageTracker();

  useEffect(() => {
    const logToNewRelic = (event: UsageTrackerEvents) => (payload: unknown) => {
      console.info(tracker.prefix, event.name, payload);
    };

    const subscriptions = [
      tracker.on(
        UsageTrackerEvents.FeedSpotlighted,
        logToNewRelic(UsageTrackerEvents.FeedSpotlighted),
      ),
      tracker.on(
        UsageTrackerEvents.FeedSpotlightRemoved,
        logToNewRelic(UsageTrackerEvents.FeedSpotlightRemoved),
      ),
    ];

    return () => {
      subscriptions.forEach((subscription) => subscription.stop());
    };
  }, [tracker]);

  return null;
};
