import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SnackbarContext, timer } from "@proximie/components";
import { useSessionContext } from "../session-context/session-context";
import { liveApiSockets } from "@proximie/dataregion-api";
import { useTranslation } from "react-i18next";

const { getElapsedTimeString } = timer;

export enum RecordingStateStatus {
  NotStarted = "NotStarted",
  Recording = "Recording",
  Paused = "Paused",
}

export interface RecordingContextProviderProps {
  children: typeof React.Children | ReactElement;
}

export interface RecordingContext {
  elapsedMilliseconds: number;
  status: RecordingStateStatus;
  elapsedTime: string;
  toggleRecording: () => void;
}

const DEFAULT_USAGE_TRACKER_CONTEXT: RecordingContext = {
  elapsedMilliseconds: 0,
  status: RecordingStateStatus.NotStarted,
  elapsedTime: "",
  toggleRecording: () => {
    return null;
  },
};

export const RecordingContext = createContext<RecordingContext>(
  DEFAULT_USAGE_TRACKER_CONTEXT,
);
export const useRecording = () => useContext(RecordingContext);

interface RecordingAccum {
  lastRecordingTimestamp?: number;
  durationSum: number;
}

export interface RecordingState {
  elapsedMilliseconds: number;
  status: RecordingStateStatus;
}

export const RecordingContextProvider = ({
  children,
}: RecordingContextProviderProps) => {
  const { showSnackbar } = SnackbarContext.useSnackbarContext();
  const { t } = useTranslation();
  const { socket } = useSessionContext();
  const [recordingState, setRecordingState] = useState<RecordingState>({
    elapsedMilliseconds: 0,
    status: RecordingStateStatus.NotStarted,
  });
  const countRef = useRef(0);
  const [elapsedTime, setElapsedTime] = useState(
    getElapsedTimeString(recordingState.elapsedMilliseconds),
  );

  const getElapsedMilliseconds = (
    acc: RecordingAccum,
    recording: liveApiSockets.RecordingStatusEvent,
    curIndex: number,
    arr: liveApiSockets.RecordingStatusEvents,
  ): RecordingAccum => {
    if (recording.isEnabled && !acc.lastRecordingTimestamp) {
      const isLastElement = curIndex + 1 === arr.length;
      const nowTimestamp = Date.now();
      const duration = isLastElement ? nowTimestamp - recording.timestamp : 0;

      return {
        durationSum: acc.durationSum + duration,
        lastRecordingTimestamp: recording.timestamp,
      };
    }

    if (!recording.isEnabled && acc.lastRecordingTimestamp) {
      const duration = recording.timestamp - acc.lastRecordingTimestamp;

      return {
        lastRecordingTimestamp: undefined,
        durationSum: acc.durationSum + duration,
      };
    }

    return acc;
  };

  useEffect(() => {
    if (recordingState.status === RecordingStateStatus.Recording) {
      const counter = setInterval(() => {
        countRef.current++;
        setElapsedTime(
          getElapsedTimeString(
            recordingState.elapsedMilliseconds + countRef.current * 1000,
          ),
        );
      }, 1000);

      return () => {
        clearInterval(counter);
      };
    } else {
      countRef.current = 0;
      setElapsedTime(getElapsedTimeString(recordingState.elapsedMilliseconds));
    }
  }, [recordingState]);

  useEffect(() => {
    const updateState = (
      recordingEvents: liveApiSockets.RecordingStatusEvents,
    ) => {
      let status = RecordingStateStatus.NotStarted;
      let elapsedMilliseconds = 0;

      if (recordingEvents.length > 0) {
        if (recordingEvents[recordingEvents.length - 1].isEnabled) {
          status = RecordingStateStatus.Recording;
          console.info("UX Event: Recording started");
        } else {
          status = RecordingStateStatus.Paused;
          console.info("UX Event: Recording paused");
        }

        const result = recordingEvents.reduce(getElapsedMilliseconds, {
          lastRecordingTimestamp: undefined,
          durationSum: 0,
        });

        elapsedMilliseconds = result.durationSum;
      }

      setRecordingState({
        elapsedMilliseconds,
        status,
      });
    };

    const fetchRecordingStatus = async (): Promise<void> => {
      if (!socket) return;
      try {
        const result = await socket.sendAsync(
          liveApiSockets.MediaSessionEventType.getRecordingStatus,
          {},
        );
        updateState(result as liveApiSockets.RecordingStatusEvents);
      } catch {
        showSnackbar({
          message: {
            body: t(
              "common.components.snackbar.messages.unableToGetRecordingStatus",
            ),
          },
          severity: "error",
        });
      }
    };

    if (socket) {
      socket.on(
        liveApiSockets.MediaSessionEventType.recordingStatus,
        fetchRecordingStatus,
      );
      fetchRecordingStatus();
    }

    return () => {
      if (socket) {
        socket.off(
          liveApiSockets.MediaSessionEventType.recordingStatus,
          fetchRecordingStatus,
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const handleChangeError = () => {
    showSnackbar({
      message: {
        body: t(
          "common.components.snackbar.messages.unableToChangeRecordingStatus",
        ),
      },
      severity: "error",
    });
  };

  const enableRecording = async (): Promise<void> => {
    if (!socket) return;
    try {
      console.debug("Recording button was clicked and enabled");
      await socket.sendAsync(
        liveApiSockets.MediaSessionEventType.recordingStatus,
        {
          isEnabled: true,
        },
      );
    } catch (error) {
      console.error("Recording button was toggled - error", error);
      handleChangeError();
    }
  };

  const disableRecording = async (): Promise<void> => {
    if (!socket) return;
    try {
      console.debug("Recording button was clicked and disabled");
      await socket.sendAsync(
        liveApiSockets.MediaSessionEventType.recordingStatus,
        {
          isEnabled: false,
        },
      );
    } catch (error) {
      console.error("Recording button was toggled - error", error);
      handleChangeError();
    }
  };

  const toggleRecording = (): void => {
    console.info("Toggle Enable/Disable Recording button is clicked");
    if (recordingState.status === RecordingStateStatus.Recording) {
      disableRecording();
    } else {
      enableRecording();
    }
  };

  return (
    <RecordingContext.Provider
      value={{
        ...recordingState,
        elapsedTime,
        toggleRecording,
      }}
    >
      <>{children}</>
    </RecordingContext.Provider>
  );
};
