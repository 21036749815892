import { LeafMonitor } from "../Monitor";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { isLocalOrE2EEnvironment } from "@proximie/common";

const REQUIRED_URLS = {
  auth: {
    url: `https://${environment.oAuth.domain}/reachability`,
    domain: environment.oAuth.domain,
  },
  featureFlag: {
    url: `${environment.featureFlag.url}/health`,
    domain: `featureflags.${environment.dnsRecordName}`,
  },
  pendo: {
    url: `https://pendo.${environment.dnsRecordName}/reachability`,
    domain: `pendo.${environment.dnsRecordName}`,
  },
  watchrtc: {
    url: `https://watchrtc.${environment.dnsRecordName}/reachability`,
    domain: `watchrtc.${environment.dnsRecordName}`,
  },
  newRelic: {
    url: `https://nr.${environment.dnsRecordName}/reachability`,
    domain: `nr.${environment.dnsRecordName}`,
  },
  api: {
    url: `${environment.apiUrl}/reachability`,
    domain: `my.${environment.dnsRecordName}`,
  },
} as const;

const OPTIONAL_URLS = {} as const;

export type FetchResult = { success: boolean; domain: string };

const REQUIRED_URL_KEYS = Object.keys(REQUIRED_URLS);
export type RequiredUrlKeys = keyof typeof REQUIRED_URLS;

const OPTIONAL_URL_KEYS = Object.keys(OPTIONAL_URLS);
export type OptionalUrlKeys = keyof typeof OPTIONAL_URLS;

export type AllUrlKeys = RequiredUrlKeys | OptionalUrlKeys;

export type XHRReport = {
  required: Record<RequiredUrlKeys, FetchResult>;
  optional: Record<OptionalUrlKeys, FetchResult>;
};

const timeout = 5000;

export class XHRMonitor extends LeafMonitor<XHRReport> {
  constructor() {
    super({ enabled: true, intervalMs: 10000 });
  }

  async getUrl(
    key: AllUrlKeys,
    { url, domain }: { url: string; domain: string },
  ) {
    const source = axios.CancelToken.source();
    const t = setTimeout(source.cancel, timeout);
    const isSuccess = isLocalOrE2EEnvironment(environment.name)
      ? true
      : await axios
          .get(url, {
            cancelToken: source.token,
          })
          .then(() => {
            clearTimeout(t);
            return true;
          })
          .catch(() => false);

    return { [key]: { success: isSuccess, domain } };
  }

  async run(): Promise<XHRReport> {
    const response = await Promise.all(
      Object.entries({ ...REQUIRED_URLS, ...OPTIONAL_URLS }).map(
        ([key, service]) => this.getUrl(key as AllUrlKeys, service),
      ),
    );

    const requiredURLResults = response
      .filter((e) => REQUIRED_URL_KEYS.some((key) => e[key] != null))
      .reduce(
        (acc, curr) => ({
          ...acc,
          ...curr,
        }),
        {},
      ) as Record<RequiredUrlKeys, FetchResult>;

    const optionalURLResults = response
      .filter((e) => OPTIONAL_URL_KEYS.some((key) => e[key] != null))
      .reduce(
        (acc, curr) => ({
          ...acc,
          ...curr,
        }),
        {},
      ) as Record<OptionalUrlKeys, FetchResult>;

    return { required: requiredURLResults, optional: optionalURLResults };
  }
}
