import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface WebcamInUseExceptionMeta {
  originalError: Error;
}

export class WebcamInUseException extends BaseException {
  constructor(message = "Webcam in use", meta: WebcamInUseExceptionMeta) {
    super(message, meta);

    this.code = ExceptionCodes.WEBCAM_IN_USE;
  }
}
