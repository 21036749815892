import React from "react";
import { Paper } from "@mui/material";

interface ExampleComponentProps {
  throwError: boolean;
}

const ExampleComponent = ({ throwError }: ExampleComponentProps) => {
  if (throwError) {
    throw Error("Hello Error");
  }
  return (
    <Paper>
      <span>Hello World</span>
    </Paper>
  );
};

export default ExampleComponent;
