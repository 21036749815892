import { EndpointType } from "../Endpoint";
import EndpointJanus, { CreateOptions } from "./EndpointJanus";
import { ENDPOINT_CONFIG } from "../../../Constants";

export default class EndpointJanusEcho extends EndpointJanus {
  override endpointType = EndpointType.Echo;

  protected pluginName = ENDPOINT_CONFIG.echo.name;
  protected createOptions: CreateOptions = {
    plugin: this.pluginName,
  };
  // ignoreErrors irrelevant for echotest
  protected ignoreErrors = [];
}
