import React from "react";
import { SvgIcon as MuiSvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const RemoveSpotlightIcon: React.FC<SvgIconProps> = (props) => {
  const { colors } = useTheme();

  return (
    <MuiSvgIcon {...props}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.15845 0.484209L0.739746 1.88548L8.01695 9.15478L12.5495 13.6824L19.149 20.2748L20.4834 18.7891L15.3751 13.6868L13.9593 12.2725L10.8382 9.15478L8.836 7.15478L2.15845 0.484209Z"
          fill={colors.BrandPrimaryDark}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.90709 12.1548L7.90709 15.1548V20.1548H13.9071V15.1548L15.3751 13.6868L13.9593 12.2725L12.5495 13.6824L11.9071 14.3248V18.1548H9.90709V14.3248L6.90709 11.3248V9.15478H8.01695H10.8382L8.836 7.15478H4.90709V12.1548ZM11.6634 7.15478L13.6252 9.15478H14.9071V10.4617L16.7358 12.3261L16.9071 12.1548V9.82276V7.15478H15.4531H11.6634ZM9.90709 0.154785H11.9071V3.15478H9.90709V0.154785ZM2.40909 4.02878L3.82309 2.61478L5.94509 4.73478L4.53109 6.14978L2.40909 4.02878ZM15.8671 4.73678L17.9901 2.61678L19.4031 4.03278L17.2801 6.15278L15.8671 4.73678Z"
          fill={colors.BrandPrimaryDark}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4531 7.15478H11.6634L13.6252 9.15478H14.9071V10.4617L16.7358 12.3261L16.9071 12.1548V9.82276L15.4531 7.15478Z"
          fill={colors.BrandPrimaryDark}
        />
        <path
          d="M11.9071 18.1548V14.3248L12.5495 13.6824L8.01695 9.15478H6.90709V11.3248L9.90709 14.3248V18.1548H11.9071Z"
          fill={colors.BrandPrimaryDark}
        />
        <path
          d="M14.9071 10.4617V9.15478H13.6252L14.9071 10.4617Z"
          fill={colors.BrandPrimaryDark}
        />
      </svg>
    </MuiSvgIcon>
  );
};
