import { EventEmitter } from "events";
import Endpoint, { EndpointType } from "../Endpoints/Endpoint";
import Quality from "../Quality/Quality";
import Device, { DeviceType } from "../Devices/Device";
import ConnectionMetadata from "../../models/ConnectionMetadata";
import FeedType from "../../models/FeedType";
import ServerParams from "../../models/ServerParams";

export enum ConnectionType {
  None,
  DcpVideoIncoming,
  JanusAudio,
  JanusEcho,
  JanusVideoIncoming,
  JanusVideoOutgoing,
  DcpVideoOutgoing,
  JanusControl,
}

// these are values that are passed for every connection - the connection
// is able to modify the values if required (but be careful!)
export type ConnectionGlobals = {
  serverParams?: ServerParams;
  localJanus?: Endpoint;
  janusMutex?: Promise<void>;
};

export type ConnectionOptions = {
  quality?: Quality;
  params?: ConnectionMetadata;
  boundData?: unknown;
  hostDeviceId?: string;
  globals?: ConnectionGlobals;
};

export enum Direction {
  None,
  Incoming,
  Outgoing,
  Both,
}

export default abstract class Connection extends EventEmitter {
  public abstract connectionType: ConnectionType;
  public abstract direction: Direction;
  public stream: MediaStream | null = null;
  public boundData: unknown;
  public devices: Partial<Record<DeviceType, Device>> = {};
  public endpoints: Partial<Record<EndpointType, Endpoint>> = {};
  protected mediaSessionId = "";

  constructor(
    endpoint: Endpoint,
    device: Device,
    public streamId: string,
    public options: ConnectionOptions,
  ) {
    super();

    if (this.quality) {
      this.quality.on("error", (error: Error) => {
        console.debug({ streamId: this.streamId }, "Quality error=", error);
        this.close(error);
      });
    }

    this.options.params = {
      deviceType: device.deviceType,
      mediaType: device.mediaType,
      ...this.options.params,
    };

    this.mediaSessionId = endpoint.mediaSessionId;

    this.boundData = this.options.boundData;

    this.devices[device.deviceType] = device;

    this.endpoints[endpoint.endpointType] = endpoint;

    endpoint.on("closed", this.endpointClosedHandler);
    endpoint.on("error", this.endpointErrorHandler);
    device.on("closed", this.deviceClosedHandler);
  }

  private endpointErrorHandler = (error: Error): void => {
    console.warn({ streamId: this.streamId }, "Endpoint has error=", error);
    this.close(error);
  };

  private endpointClosedHandler = (): void => {
    console.warn({ streamId: this.streamId }, "Endpoint has been closed");
    this.close(new Error("Endpoint has been closed"));
  };

  private deviceClosedHandler = (): void => {
    console.warn({ streamId: this.streamId }, "Device has been closed");
    this.close(new Error("Device has closed"));
  };

  abstract open(endpoint: Endpoint): Promise<void>;

  async close(error?: Error): Promise<void> {
    console.debug(
      { streamId: this.streamId },
      "Connection:close - error=",
      error,
    );

    Object.values(this.endpoints).forEach((endpoint: Endpoint) => {
      endpoint.off("closed", this.endpointClosedHandler);
      endpoint.off("error", this.endpointErrorHandler);
      delete this.endpoints[endpoint.endpointType];
    });

    Object.values(this.devices).forEach((device: Device) => {
      device.off("closed", this.deviceClosedHandler);
      delete this.devices[device.deviceType];
    });

    if (error) {
      this.emit("error", error);
    } else {
      this.emit("closed");
    }
  }

  // send() is only relevant for outgoing connections
  send(_stream: MediaStream): Promise<void> {
    return Promise.resolve();
  }

  kick(): Promise<void> {
    console.debug({ streamId: this.streamId }, "Kick");
    return this.close();
  }

  get type(): FeedType {
    return this.options.params?.mediaType || FeedType.None;
  }

  get params(): ConnectionMetadata {
    return this.options?.params || {};
  }

  get quality(): Quality | null {
    return this.options?.quality || null;
  }

  //TODO - obsolete this method shortly
  get isOutgoingVideo(): boolean {
    return this.direction === Direction.Outgoing;
  }

  addDevice(device: Device, endpoint: Endpoint): void {
    console.debug({ streamId: this.streamId }, "Adding device", device);
    this.devices[device.deviceType] = device;

    this.endpoints[endpoint.endpointType] = endpoint;

    endpoint.on("closed", this.endpointClosedHandler);
    endpoint.on("error", this.endpointErrorHandler);
    device.on("closed", this.deviceClosedHandler);
  }

  getDeviceById(deviceId: string): Device | null {
    return (
      Object.values(this.devices).find(
        (device: Device): boolean => device.deviceId === deviceId,
      ) || null
    );
  }

  get isDcpHost(): boolean {
    if (!this.options.hostDeviceId) {
      return false;
    }

    const device = this.devices[DeviceType.Dcp];
    if (!device) {
      return false;
    }

    return device.deviceId === this.options.hostDeviceId;
  }

  get order(): string {
    return this.options.params?.order ?? "";
  }

  get userId(): string {
    return this.options.params?.userId ?? "";
  }
}
