import styled from "@emotion/styled";
import proximieBackgroundProgressive from "./../../../src/assets/images/Proximie-Background-Progressive.jpg";

const AuthBackground = styled.div`
  background: url("${proximieBackgroundProgressive}");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
  background-position: center center;
  position: fixed;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  ::after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(2, 29, 71, 0.65);
  }
`;

export default AuthBackground;
