export type Recommendation = {
  index: number;
  value: string;
  extras?: string[];
};

export const ReduceFeedsRecommendation: Recommendation = {
  index: 0,
  value: "If possible, reduce the number of video feeds.",
};
export const WiredConnectionRecommendation: Recommendation = {
  index: 1,
  value: "Use a wired connection instead of Wi-Fi.",
};
export const DifferentNetworkRecommendation: Recommendation = {
  index: 2,
  value:
    "Try connecting to a different Wi-Fi network, preferably the one provided by your IT department for this platform.",
};
export const DistanceToRouterRecommendation: Recommendation = {
  index: 3,
  value: "Position your device closer to the router for a stronger signal.",
};
export const FirewallITRecommendation = (extras: string[]): Recommendation => ({
  index: 4,
  value:
    "Contact your IT department to open the following blocked domains and ports. Refer to your Firewall and Network configuration document.",
  extras,
});
export const VideoQualityRecommendation: Recommendation = {
  index: 5,
  value:
    "Manually set your preferred video quality setting on each feed. Prioritize important feeds with higher settings.",
};

export function pushRecommendationIfNotExist(
  array: Recommendation[],
  ...values: Recommendation[]
) {
  for (const v of values) {
    if (array.map((item) => item.value).includes(v.value)) {
      continue;
    }
    array.push(v);
  }
}
