import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface MicrophoneNotFoundExceptionMeta {
  originalError: Error;
}

export class MicrophoneNotFoundException extends BaseException {
  constructor(
    message = "Microphone not found",
    meta: MicrophoneNotFoundExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.MICROPHONE_NOT_FOUND;
  }
}
