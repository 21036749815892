import React, { FC, ReactNode } from "react";
import { Link } from "@mui/material";
import { TextButtonStyled } from "./TextButton.style";

export interface TextButtonProps {
  onClick: () => void;
  children: ReactNode;
}
const TextButton: FC<TextButtonProps> = (
  props: TextButtonProps,
): JSX.Element => {
  return (
    <TextButtonStyled>
      <Link onClick={props.onClick}>{props.children}</Link>
    </TextButtonStyled>
  );
};

export default TextButton;
