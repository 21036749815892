import React, { useEffect, useState } from "react";
import { Box, Slider, Popper, Paper, ClickAwayListener } from "@mui/material";
import { UseServerAdapterContext } from "../../contexts/server-adapter-context";

export interface VolumeSliderProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

const VolumeSlider = ({ anchorEl, onClose }: VolumeSliderProps) => {
  const serverAdapterContext = UseServerAdapterContext();

  const [volume, setVolume] = useState<number>(
    serverAdapterContext?.audioVolume ?? 100,
  );
  const [uncommitedVolume, setUncommitedVolume] = useState<number>(
    serverAdapterContext?.audioVolume ?? 100,
  );
  const [lastChange, setLastChange] = useState<number>(0);

  //throttle context changeVolume calls
  const handleChangeVolume = (
    _event: Event,
    value: number | number[],
  ): void => {
    const myValue = Array.isArray(value) ? value[0] : value;
    setUncommitedVolume(myValue);

    const now = Date.now();
    if (now - lastChange > 50 && value !== volume) {
      serverAdapterContext?.setAudioVolume(myValue);
      setLastChange(now);
      setVolume(myValue);
    }
  };

  const handleChangeVolumeCommitted = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[],
  ): void => {
    const myValue = Array.isArray(value) ? value[0] : value;
    serverAdapterContext?.setAudioVolume(myValue);
    setUncommitedVolume(myValue);
    setVolume(myValue);
  };

  useEffect(() => {
    setUncommitedVolume(serverAdapterContext?.audioVolume ?? 100);
    setVolume(serverAdapterContext?.audioVolume ?? 100);
  }, [serverAdapterContext?.audioVolume]);

  return (
    <ClickAwayListener
      onClickAway={onClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Popper
        data-testid="audio-volume-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        placement="top"
      >
        <Paper>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              height: "180px",
              width: "48px",
              padding: "24px 8px 24px 8px",
            }}
          >
            <Slider
              data-testid={"audio-volume-slider"}
              orientation="vertical"
              defaultValue={100}
              value={uncommitedVolume}
              onChange={handleChangeVolume}
              onChangeCommitted={handleChangeVolumeCommitted}
              step={1}
              valueLabelDisplay="auto"
              min={0}
              max={100}
            />
          </Box>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default VolumeSlider;
