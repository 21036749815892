import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface ConstraintsAreNotMetByAvailableHardwareExceptionMeta {
  originalError: Error;
}

export class ConstraintsAreNotMetByAvailableHardwareException extends BaseException {
  constructor(
    message = "Constraint are not met by available hardware",
    meta: ConstraintsAreNotMetByAvailableHardwareExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.CONSTRAINTS_ARE_NOT_MET_BY_AVAILABLE_HARDWARE;
  }
}
