import styled from "@emotion/styled";
import { List, Button } from "@mui/material";

export const ScrollBottomStyled = styled(Button)`
  position: absolute;
  bottom: 75px;
  right: 0;
`;

export const ChatListStyled = styled(List)`
  overflow-y: scroll;
  flex: 1;
  .text-container {
    word-break: break-word;
    white-space: pre-line;
  }
`;
