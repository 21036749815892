import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { DialogTitle } from "../../components/DialogTitle/DialogTitle";

export interface DialogProviderProps {
  children: typeof React.Children | ReactElement;
}

type ContentType = "TEXT" | "COMPLEX";

export interface ShowDialogProps {
  onClose?: () => void;
  title?: string;
  content?: string | React.ReactNode | React.ReactNode[];
  contentType?: ContentType;
  actions?: ReactElement | React.ReactNode | React.ReactNode[];
  testId?: string;
}

export interface DialogContext {
  showDialog: (props: ShowDialogProps) => void;
  closeDialog: () => void;
}

const DEFAULT_DIALOG_CONTEXT: DialogContext = {
  showDialog: () => null,
  closeDialog: () => null,
};

export const DialogContext = createContext<DialogContext>(
  DEFAULT_DIALOG_CONTEXT,
);

export const useDialogContext = () => useContext(DialogContext);

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [onClose, setOnClose] = useState<(() => void) | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [content, setContent] = useState<
    string | React.ReactNode | React.ReactNode[] | undefined
  >(undefined);
  const [contentType, setContentType] = useState<ContentType | undefined>(
    undefined,
  );
  const [actions, setActions] = useState<
    ReactElement | React.ReactNode | React.ReactNode[] | undefined
  >(undefined);
  const [testId, setTestId] = useState<string | undefined>(undefined);

  const showDialog = useCallback((args: ShowDialogProps) => {
    setOnClose(() => args.onClose);
    setTitle(args.title);
    setContent(args.content);
    setContentType(args.contentType);
    setActions(args.actions);
    setTestId(args.testId);

    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => setOpen(false), []);

  return (
    <DialogContext.Provider
      value={{
        showDialog,
        closeDialog,
      }}
    >
      <>
        {children}
        <Dialog data-cy={testId} open={open}>
          {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
          {content && (
            <DialogContent>
              {contentType && contentType === "TEXT" ? (
                <DialogContentText>{content}</DialogContentText>
              ) : (
                <>{content}</>
              )}
            </DialogContent>
          )}
          {actions && <DialogActions>{actions}</DialogActions>}
        </Dialog>
      </>
    </DialogContext.Provider>
  );
};
