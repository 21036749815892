import React, { useState } from "react";

import { IconButton, Stack } from "@mui/material";
import { VolumeDown, VolumeOff, VolumeUp } from "@mui/icons-material";

import { MediaControlProps } from "../../MediaControls";
import { StyledSlider } from "../../MediaControls.style";

interface VolumeSliderProps {
  isMuted: boolean;
  volume: number;
  onToggleMute: () => void;
  onUpdateVolume: (evt: Event, value: number | number[]) => void;
}

export const VolumeSlider = ({
  isMuted,
  volume,
  onToggleMute,
  onUpdateVolume,
}: VolumeSliderProps) => {
  function getVolumeIcon() {
    if (isMuted || volume === 0) return <VolumeOff />;
    return volume < 50 ? <VolumeDown /> : <VolumeUp />;
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{ width: "150px" }}
      alignItems={"center"}
      marginRight={1}
    >
      <IconButton aria-label="Mute" onClick={onToggleMute}>
        {getVolumeIcon()}
      </IconButton>

      <StyledSlider
        aria-label="Volume"
        defaultValue={100}
        step={1}
        min={0}
        max={100}
        value={isMuted ? 0 : volume}
        onChange={onUpdateVolume}
      />
    </Stack>
  );
};

const VolumeControl = (props: MediaControlProps) => {
  const [isMuted, setMuted] = useState(false);
  const [volume, setVolume] = useState(100);

  const onToggleMute = () => {
    setMuted(!isMuted);

    props.element.muted = !isMuted;
  };

  const onUpdateVolume = (_: Event, value: number | number[]) => {
    setMuted(false);
    setVolume(value as number);

    props.element.muted = false;
    props.element.volume = volume / 100;
  };

  return (
    <VolumeSlider
      volume={volume}
      isMuted={isMuted}
      onToggleMute={onToggleMute}
      onUpdateVolume={onUpdateVolume}
    />
  );
};

export default VolumeControl;
