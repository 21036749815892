import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class UnauthenticatedException extends BaseException {
  constructor(message = "Unauthenticated", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.UNAUTHENTICATED;
  }
}
