import styled from "@emotion/styled";
import { Theme } from "@mui/material";

export const SearchFieldStyled = styled.div<{ theme: Theme }>`
  & .container {
    border: 1px solid ${(props) => props.theme.palette.secondary.main} !important;
    border-radius: 5px;
    line-height: 32px;
    height: 36px;
    box-sizing: border-box;
    & .search-input {
      & input {
        margin-left: 10px;
        padding: 0 7px;
        line-height: 36px;
        height: 36px;
      }
    }
  }
  & .icon {
    margin: 0 5px;
    align-items: center;
    display: flex;
  }
`;
