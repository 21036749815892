import React, { RefObject, useEffect, useState } from "react";
import VolumeControl from "./Controls/VolumeControl/VolumeControl";
import PlayPauseButton from "./Controls/PlayPauseButton/PlayPauseButton";
import TimeStamp from "./Controls/Timestamp/Timestamp";
import ProgressBar from "./Controls/ProgressBar/ProgressBar";
import FullscreenToggle from "../FullscreenToggle/FullscreenToggle";
import { StyledControlRow, StyledMediaControls } from "./MediaControls.style";

export interface MediaControlProps {
  element: HTMLMediaElement;
}

const MediaControls = ({
  videoRef,
  className = "",
}: {
  videoRef: RefObject<HTMLMediaElement | null>;
  className?: string;
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (videoRef.current) {
      setLoading(false);
    }
  }, [videoRef, setLoading]);

  if (videoRef?.current === null || loading) {
    return null;
  }

  return (
    <StyledMediaControls className={className}>
      <ProgressBar element={videoRef.current} />
      <StyledControlRow>
        <PlayPauseButton element={videoRef.current} />
        <VolumeControl element={videoRef.current} />
        <div style={{ marginLeft: "auto" }}></div>
        <TimeStamp element={videoRef.current} />
        <FullscreenToggle element={videoRef.current} />
      </StyledControlRow>
    </StyledMediaControls>
  );
};

export default MediaControls;
