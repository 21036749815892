import QualityVideoIncoming from "./QualityVideoIncoming";

export default class QualityVideoIncomingDcp extends QualityVideoIncoming {
  private degraded = false;
  public controls = null;

  protected override limits = {
    downgrade: {
      // these shouldn't be used (we've inhibited the event emission) but just in
      // case we set them very high indeed!
      pktLossPercent: 90.0,
      roundTripTimeMs: 5000,
      processingTimeMs: 5000,
    },
    upgrade: {
      pktLossPercent: 15.0,
      roundTripTimeMs: 600,
      processingTimeMs: 50,
    },
  };

  protected downgrade(): boolean {
    console.log("downgrade - current=", this.degraded);
    if (!this.degraded) {
      this.degraded = true;
      //this.emit("degraded", this.degraded);
    }
    return true;
  }

  protected upgrade(): boolean {
    console.log("upgrade - current=", this.degraded);
    if (this.degraded) {
      this.degraded = false;
      //this.emit("degraded", this.degraded);
    }
    return true;
  }
}
