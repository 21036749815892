import { Monitor } from "./Monitor";
import { NetworkMonitor, NetworkReport } from "./monitors/NetworkMonitor";
import {
  TimeDeviationMonitor,
  TimeDeviationReport,
} from "./monitors/TimeMonitor";

export type GlobalReport = {
  network: NetworkReport;
  timeDeviation: TimeDeviationMonitor;
};

type SubReport = NetworkReport | TimeDeviationReport;

export class GlobalMonitor extends Monitor<GlobalReport, SubReport> {
  constructor() {
    const networkMonitor = new NetworkMonitor();
    const timeDeviationMonitor = new TimeDeviationMonitor();

    super({
      subMonitors: {
        network: networkMonitor,
        timeDeviation: timeDeviationMonitor,
      },
    });

    (window as any)["gm"] = this;

    this.start();
  }
}
