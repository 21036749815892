import { Alert, styled } from "@mui/material";
import IconButton from "../IconButton/IconButton";

export const StyledDropArea = styled("div")<{
  isDraggedOver: boolean;
}>(
  ({ theme, isDraggedOver }) => `
  transition: background 250ms ease;
  padding: 0 24px 10px 24px;

  background: ${isDraggedOver ? theme.colors.Brand50 : theme.colors.White};

  & .MuiIconButton-root.MuiIconButton-sizeLarge {
    color: ${isDraggedOver ? theme.colors.Grey400 : theme.colors.BlackInactive};
  }
`,
);

export const StyledWrapper = styled("div")`
  width: 100%;
  padding: 100px 1em;
  text-align: center;
`;

export const StyledLabel = styled("label")`
  width: 130px;
  height: 40px;
`;

export const StyledUploadButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.grey["200"],
  width: "140px",
  height: "140px",
  color: theme.palette.grey["400"],
  hover: {
    background: theme.palette.grey["600"],
  },
  svg: {
    fontSize: "51px",
  },
}));

export const StyledAlert = styled(Alert)`
  min-width: 200px !important;
  width: fit-content;
  margin: 1em auto 0;
`;
