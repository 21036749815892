import ConnectionJanus from "./ConnectionJanus";
import { ConnectionType, Direction } from "../Connection";
import { JanusJS } from "@proximie/janus-gateway";
import { PrxMessage, PrxEchotestEvents } from "./message.d";
import { EndpointType } from "../../Endpoints/Endpoint";
import { ConnectionMetadata } from "../../../../index";
import Deferred from "../../../utils/Deferred";

export default class ConnectionJanusEcho extends ConnectionJanus {
  override connectionType = ConnectionType.JanusEcho;
  override direction = Direction.Both;
  // we don't want to resolve the send() promise until we get a "configured"
  // or "error" message from Janus.
  private dfd: Deferred<void> | null = null;

  protected attachOptions: JanusJS.PluginOptions = {
    plugin: "OVERWRITE_ME",
    ...this.defaultAttachCallbacks,
    onmessage: this.onMessage.bind(this),
    onremotestream: this.onRemoteStream.bind(this),
    onlocalstream: undefined,
  };

  private onMessage(message: PrxMessage, jsep?: JanusJS.JSEP): void {
    console.debug("EchoTest:onMessage - message=", message);
    if (jsep) {
      this.handle?.handleRemoteJsep({
        jsep,
      });
    }

    if (!message.echotest || message.echotest !== PrxEchotestEvents.Event) {
      console.warn("EchoTest:onMessage: Not an echotest message=", message);
    } else if (message.error) {
      console.warn("EchoTest:onMessage: error=", message.error);
    } else if (message.result === "done") {
      console.warn("EchoTest:onMessage: connection done");
      this.close();
    } else if (message.result === "ok") {
      this.dfd && this.dfd.resolve();
      // give calling process time to process before we delete it
      Promise.resolve(() => (this.dfd = null));
    } else {
      console.warn("EchoTest:onMessage: Unknown message=", message);
    }
  }

  protected override join(_params: ConnectionMetadata): Promise<void> {
    return Promise.resolve();
  }

  override async open(): Promise<void> {
    const endpoint = this.endpoints[EndpointType.Echo];
    if (!endpoint) {
      throw new Error("No echo endpoint");
    }
    return this.startJanus(endpoint);
  }

  override send(stream: MediaStream): Promise<void> {
    console.debug("EchoTest:send stream=", stream);

    this.dfd = new Deferred();

    this.stream = stream;

    const body = {
      request: "", // not used in echotest
      audio: stream.getAudioTracks().length > 0,
      video: stream.getVideoTracks().length > 0,
    };

    this.handle?.createOffer({
      stream,
      media: { audio: false, video: true },
      success: (jsep: JanusJS.JSEP) => {
        this.emit("connecting");
        this.handle?.send({ message: body, jsep });
      },
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any) => {
        console.warn("EchoTest:send failed - error=", error);
        this.dfd && this.dfd.reject(new Error(error));
        // give calling process time to process before we delete it
        Promise.resolve(() => (this.dfd = null));
      },
    });
    return this.dfd.promise;
  }
}
