type CountryISOCodes = {
  alpha2: string;
  alpha3: string;
};

export const parseCountryISO = (iso: string): CountryISOCodes => {
  const result = iso.match(/([A-Z]{2}) \/ ([A-Z]{3})/);
  if (result?.length !== 3) {
    throw new Error(
      `Provided argument ${iso} does not match expected format 'XY / XYZ'`,
    );
  }

  return { alpha2: result[1], alpha3: result[2] };
};
