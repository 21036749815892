import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class OrganisationAlreadyExistsException extends BaseException {
  constructor(message = "Organization already exists", meta?: unknown) {
    super(message, meta);

    this.code = ExceptionCodes.ORGANISATION_ALREADY_EXISTS;
  }
}
