import { EmbeddingProvider } from "./EmbeddingProvider";
import EventEmitter from "events";

export type ElectronData = {
  virtualCameraPattern?: string;
  virtualCameraSubsectionTitle?: string;

  [k: string]: unknown;
};

const ELECTRON_APP_API_KEY: string = "PxDesktopAppApi" as const;

export type ElectronAppApi = {
  getApiData: () => Promise<ElectronData>;
  onApiDataUpdated: (
    callback: (event: unknown, payload: ElectronData) => void,
  ) => () => Promise<void>;
};

export class ElectronEmbeddingProvider
  extends EventEmitter
  implements EmbeddingProvider<ElectronData>
{
  private readonly electronAppApi?: ElectronAppApi;
  private offHandler?: () => Promise<void>;

  public _data: ElectronData | null = null;

  public constructor() {
    super();
    this.electronAppApi = (window as never)[ELECTRON_APP_API_KEY];
  }

  public async init() {
    if (!this.isEmbedded || !this.electronAppApi) {
      return;
    }

    this.data = await this.loadData();
    this.offHandler = this.electronAppApi.onApiDataUpdated(
      (_, payload) => (this.data = payload),
    );
  }

  public async destroy() {
    await this.offHandler?.();
  }

  private async loadData(): Promise<ElectronData | null> {
    if (!this.electronAppApi) {
      return null;
    }

    return this.electronAppApi.getApiData();
  }

  public get isEmbedded() {
    return this.electronAppApi != null;
  }

  public get data() {
    return this._data;
  }

  private set data(data: ElectronData | null) {
    this._data = data;
    this.emit("updated", this.data);
  }
}
