import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip";
import React from "react";
import { SpotlightIcon } from "../icons/SpotlightIcon/SpotlightIcon";
import Close from "@mui/icons-material/Close";

export enum TooltipType {
  NORMAL,
  FOR_SPOTLIGHTER,
  FOR_FOLLOWING_SPOTLIGHT,
}

export type SpotlightTooltipProps = {
  type: TooltipType;
  handleClose: () => void;
} & Omit<TooltipProps, "title">;

const SpotlightTooltipTitle: React.FC<{
  type: TooltipType;
  handleClose: () => void;
}> = ({ type, handleClose }) => {
  const { colors } = useTheme();

  return type === TooltipType.NORMAL ? (
    <>My layout</>
  ) : (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        backgroundColor: colors.Black,
        padding: "8px",
        gap: "13px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "9px",
        }}
      >
        <SpotlightIcon />
        <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "17px",
              color: colors.White,
            }}
            data-testid="spotlight-tooltip-title"
            data-cy="spotlight-tooltip-title"
          >
            {type === TooltipType.FOR_FOLLOWING_SPOTLIGHT
              ? "You are now following spotlight"
              : "You’ve started a spotlight"}
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: colors.WhiteMediumEmphasis,
            }}
            data-testid="spotlight-tooltip-content"
          >
            {type === TooltipType.FOR_FOLLOWING_SPOTLIGHT
              ? "You are now in spotlight mode. To switch back to grid view, click the layout button."
              : "Click here to switch to grid view; this does not switch your viewers to grid view. The spotlight will remain until you click ‘remove spotlight’."}
          </Typography>
        </Box>
      </Box>
      <IconButton
        onClick={handleClose}
        style={{
          color: colors.WhiteMediumEmphasis,
          padding: 0,
        }}
        data-testid="spotlight-tooltip-close"
        data-cy="spotlight-tooltip-close"
      >
        <Close />
      </IconButton>
    </Box>
  );
};

export const SpotlightTooltip: React.FC<SpotlightTooltipProps> = ({
  type,
  handleClose,
  ...props
}) => {
  return (
    <Tooltip
      {...props}
      title={<SpotlightTooltipTitle type={type} handleClose={handleClose} />}
    />
  );
};
