import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export class UserNotPartOfOrganisationException extends BaseException {
  constructor(meta?: unknown) {
    super("User not part of current organization", meta);

    this.code = ExceptionCodes.USER_NOT_PART_OF_ORGANISATION;
  }
}
