import React, { FC, ReactNode } from "react";
import Participant, { ParticipantProps } from "../Participant/Participant";
import { Typography, Box, Divider, Paper } from "@mui/material";

export interface ParticipantListProps {
  participants: ParticipantProps[];
  title: string;
  children?: ReactNode;
}

const ParticipantList: FC<ParticipantListProps> = (
  props: ParticipantListProps,
) => {
  const { title, participants, children } = props;

  return (
    <Paper elevation={1}>
      <Box
        px={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6" display="block" component={"h2"}>
          {title}
        </Typography>
        {children}
      </Box>
      {participants && participants.length ? (
        participants.map((participant: ParticipantProps) => (
          <React.Fragment key={participant.id}>
            <Participant
              id={participant.id}
              name={participant.name}
              isConnected={participant.isConnected}
              isMuted={participant.isMuted}
              isActive={participant.isActive}
              metadata={participant.metadata}
              streamId={participant.streamId}
            />
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <Box p={2}>
          <Typography variant="body2" color="inherit" display="block">
            No Participants
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default ParticipantList;
