import { ExceptionCodes } from "../exception-codes";
import { BaseException } from "./BaseException";

export interface Auth0CannotCreateUserExceptionMeta {
  originalError: Error;
}

export class Auth0CannotCreateUserException extends BaseException {
  constructor(
    message = "Failed to create user in auth0",
    meta: Auth0CannotCreateUserExceptionMeta,
  ) {
    super(message, meta);

    this.code = ExceptionCodes.AUTH0_CANNOT_CREATE_USER;
  }
}
