export type PageInfo = {
  hasPrev: boolean;
  hasNext: boolean;
  prevCursor: string;
  nextCursor: string;
  count: number;
};

export type ResponseData<T> = {
  pageInfo: PageInfo;
} & {
  [key: string]: T[];
};

export type GetParams = QueryParams;

export type Get<T> = (params: GetParams) => Promise<ResponseData<T>>;

export type AddParams = {
  [key: string]: string;
};

export type Add<T> = (params: AddParams) => Promise<T>;

export type DelParams = {
  [key: string]: string;
};

export type Del = (params: DelParams) => Promise<void>;

export type UpdateParams = {
  [key: string]: string;
};

export type Update<T> = (params: UpdateParams) => Promise<T>;

export type MutateOne<T> = (
  identifierName: keyof T,
  identifierValue: string,
  newValue: Partial<T>,
  updateFn?: () => Promise<void>,
) => Promise<void>;

export type LoaderRefCallback = (node: Element | null) => void;

export type QueryParams = {
  paginationType: PaginationType;
  nextCursor: Cursor;
  limit: string;
} & Filters;

export type Cursor = string | undefined;
export type PaginationType = "CURSOR";
export type SortOrder = "ASC" | "DESC";

export type FacilitiesAndRoomsFilter = {
  facilityId: string | null;
  roomIds: (string | null)[];
};

export type Filters = {
  search?: string;
  sort_by?: string;
  sort_order?: SortOrder;
  sessionOwner?: string[];
  facilitiesAndRooms?: FacilitiesAndRoomsFilter[];
  speciality?: string[];
} & {
  [key: string]:
    | FacilitiesAndRoomsFilter[]
    | string[]
    | string
    | boolean
    | number
    | undefined;
};
