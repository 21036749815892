export interface EmbeddingProvider<T> {
  isEmbedded: boolean;
  data: T | null;

  init(): Promise<void>;

  setData(partialData: T): Promise<void>;

  destroy(): Promise<void>;
}
